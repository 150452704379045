import React, { useState, useEffect } from "react";
import moment from "moment";
import locale from "antd/lib/locale/th_TH";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../resources/index";

// settings
import services from "../../resources/variables/services";

// hooks
import useQuery from "../../hooks/useQuery";

// components
import { ConfigProvider, DatePicker, Select, Typography, Tooltip, Card, Collapse, Divider } from "antd";
import ExporterFilter from "./ExporterFilter";
import ExportValueFilter from "../ExportValueFilter";
import ActivityFilter from "../ActivitiyFilter";


const { Text } = Typography;
const { RangePicker } = DatePicker;
const { Option } = Select;
const dateFormat = "YYYY-MM-DD";



export default function BasicFilter(props) {
  // console.log(props.filter);
  const location = useLocation();
  const dispatch = useDispatch();
  const query = useQuery(location?.search);
  // rules check current path
  const LOCATION_PATH = {
    IS_PROTENTIAL_PRODUCT: location?.pathname === "/PotentialProduct",
    IS_PROTENTIAL_COUNTRY: location?.pathname === "/PotentialCountry",
    IS_SUGGEST_ACTIVITY: location?.pathname === "/SuggestActivity",
  };
  const activityTimeRange = {
    start: props?.startDate?.date_activity?.startdate.split("-")[0] || "ทั้งหมด",
    end: props?.startDate?.date_activity?.enddate.split("-")[0] || "ทั้งหมด",
  };
  const exporterTimeRange = {
    start: props?.startDate?.date_export?.startdate.split("-")[0] || "ทั้งหมด",
    end: props?.startDate?.date_export?.enddate.split("-")[0] || "ทั้งหมด",
  };
  const initialTextExtraDisplay = {
    activity: {
      country_activity: {
        ellipsisText: (
          <ul className='animate-pulse'>
            <li className='w-11/12 h-[1rem] bg-slate-200'></li>
          </ul>
        ),
        fullText: "กำลังโหลด ..."
      },
      product_activity: {
        ellipsisText: (
          <ul className='animate-pulse'>
            <li className='w-11/12 h-[1rem] bg-slate-200'></li>
          </ul>
        ),
        fullText: "กำลังโหลด ..."
      },
      timeRange: {
        ellipsisText: (
          <ul className='animate-pulse'>
            <li className='w-11/12 h-[1rem] bg-slate-200'></li>
          </ul>
        ),
        fullText: `${activityTimeRange.start} - ${activityTimeRange.end}`
      },
      activity_type: {
        ellipsisText: (
          <ul className='animate-pulse'>
            <li className='w-11/12 h-[1rem] bg-slate-200'></li>
          </ul>
        ),
        fullText: "กำลังโหลด ..."
      },
      activity_keyword: {
        ellipsisText: (
          <ul className='animate-pulse'>
            <li className='w-11/12 h-[1rem] bg-slate-200'></li>
          </ul>
        ),
        fullText: "กำลังโหลด ..."
      },
    },
    exporter: {
      exporter_region: {
        ellipsisText: (
          <ul className='animate-pulse'>
            <li className='w-11/12 h-[1rem] bg-slate-200'></li>
          </ul>
        ),
        fullText: "กำลังโหลด ..."
      },
      exporter_size: {
        ellipsisText: (
          <ul className='animate-pulse'>
            <li className='w-11/12 h-[1rem] bg-slate-200'></li>
          </ul>
        ),
        fullText: "กำลังโหลด ..."
      },
      exporter_size_sme: {
        ellipsisText: (
          <ul className='animate-pulse'>
            <li className='w-11/12 h-[1rem] bg-slate-200'></li>
          </ul>
        ),
        fullText: "กำลังโหลด ..."
      },
      exporter_member_type: {
        ellipsisText: (
          <ul className='animate-pulse'>
            <li className='w-11/12 h-[1rem] bg-slate-200'></li>
          </ul>
        ),
        fullText: "กำลังโหลด ..."
      },
      exporter_award: {
        ellipsisText: (
          <ul className='animate-pulse'>
            <li className='w-11/12 h-[1rem] bg-slate-200'></li>
          </ul>
        ),
        fullText: "กำลังโหลด ..."
      },
    },
    exportValue: {
      country_export: {
        ellipsisText: (
          <ul className='animate-pulse'>
            <li className='w-11/12 h-[1rem] bg-slate-200'></li>
          </ul>
        ),
        fullText: "กำลังโหลด ..."
      },
      product_export: {
        ellipsisText: (
          <ul className='animate-pulse'>
            <li className='w-11/12 h-[1rem] bg-slate-200'></li>
          </ul>
        ),
        fullText: "กำลังโหลด ..."
      },
      timeRange: {
        ellipsisText: (
          <ul className='animate-pulse'>
            <li className='w-11/12 h-[1rem] bg-slate-200'></li>
          </ul>
        ),
        fullText: `${activityTimeRange.start} - ${activityTimeRange.end}`,
      },
    }
  };

  const actions = bindActionCreators(actionCreators, dispatch);
  const { inputfilter, textExtraFilterRedux } = useSelector((state) => ({
    inputfilter: state?.filter_input,
    textExtraFilterRedux: state?.text_extra_filter?.text_extra_filter
  }));
  // console.log(inputfilter);
  // console.log(textExtraFilterRedux);
  const [textExtraFilter, setTextExtraFilter] = useState(initialTextExtraDisplay);

  const [productTab, setProductTab] = useState("ditp");

  const getRequestDataByPathname = (payload) => {
    if (location.pathname.includes("/PotentialProduct")) {
      actions.postPotentialP(payload);
    } else if (location.pathname.includes("/PotentialCountry")) {
      actions.postPotentialC(payload);
    } else if (location.pathname.includes("/Activity")) {
      actions.getActivity(payload);
    } else if (location.pathname.includes("/Exporter")) {
      actions.postExporter(payload);
    } else if (location.pathname.includes("/SuggestActivity")) {
      actions.getSuggestActivity(payload);
    } else if (location.pathname.includes("/ManageSMS")) {
      actions.postExporter(payload);
    }
  };

  /**
   * initialize filters checked-all fitlers (preparing format)
   */
  async function setDefault() {
    /**
     * set default value into Filter Component
     * @param {Array<String>} ditpCountryKeys set checked default into Filter Component
     * @param {Array<String>} ditpProductKeys set checked default into Filter Component
     * @param {Array<String>} dateInitialize set checked default into Filter Component
     * @param {Array<String>} activityTypeKeys set checked default into Filter Component
     * @param {Array<String>} regionKeys set checked default into Filter Component
     * @param {Array<String>} dateInitialize set checked default into Filter Component
     * @param {Array<String>} exporterSizeKeys set checked default into Filter Component
     * @param {Array<String>} exporterSizeSMEKeys set checked default into Filter Componen
     * @param {Array<String>} exporterAwardKeys set checked default into Filter Component
     * @param {Array<String>} exporterMemberTypeKeys set checked default into Filter Component
    */

    // initialize date (startDate, currentDate)
    const dateInitialize = "2017-01-01";
    const dateCurrentInitialize = moment({ dateFormat }).format("YYYY-MM-DD");

    // variable for storing obj for filter (flat 1D-array)
    let ditpCountryList = [];
    let ditpProductList = [];
    let activityTypeList = [];
    let activityKeywordList = [];

    let exporterSizeList = [];
    let exporterSizeSMEList = [];
    let exporterMemberTypeList = [];
    let exporterAwardList = [];
    let exporterRegionList = [];

    // generate from multilevel array to 1D array (flat)
    services.listGenerator(JSON.parse(JSON.stringify(props?.filter?.ditp_country)), ditpCountryList);
    services.listGenerator(JSON.parse(JSON.stringify(props?.filter?.ditp_product)), ditpProductList);

    services.listGenerator(props?.filter?.activity_type, activityTypeList);
    services.listGenerator(props?.filter?.activity_keyword, activityKeywordList);

    services.listGenerator(props?.filter?.comsize_export, exporterSizeList);
    services.listGenerator(props?.filter?.comsize_sme, exporterSizeSMEList);
    services.listGenerator(props?.filter?.company_member_type, exporterMemberTypeList);
    services.listGenerator(props?.filter?.award, exporterAwardList);
    services.listGenerator(props?.filter?.exporter_region, exporterRegionList);

    // store keys
    // console.log(ditpCountryList);
    // console.log(ditpProductList);
    const ditpCountryKeys = ditpCountryList.filter(obj => obj.key.includes("leaf")).map(obj => obj.key);
    const ditpProductKeys = ditpProductList.filter(obj => obj.key.includes("leaf")).map(obj => obj.key);
    const activityTypeKeys = activityTypeList.map(obj => obj.key);
    const activityKeywordKeys = activityKeywordList.map(obj => obj.key);

    const exporterSizeKeys = exporterSizeList.map(obj => obj.key);
    const exporterSizeSMEKeys = exporterSizeSMEList.map(obj => obj.key);
    const exporterMemberTypeKeys = exporterMemberTypeList.map(obj => obj.key);
    const exporterAwardKeys = exporterAwardList.map(obj => obj.key);
    const exporterRegionKeys = exporterRegionList.filter(obj => obj.key.includes('leaf')).map(obj => obj.key);

    // variable for storing textExtra
    let ditpCountryFullText = [];
    let ditpProductFullText = [];
    let activityTypeFullText = [];
    let activityKeywordFullText = [];
    let regionFullText = [];
    let exporterSizeFullText = [];
    let exporterSizeSMEFullText = [];
    let exporterMemberTypeFullText = [];
    let awardFullText = [];



    ditpCountryFullText = ditpCountryList
      .filter(country => country.key.includes("leaf"))
      .map(country => country.title)
      .join(", ");

    ditpProductFullText = ditpProductList
      .filter(product => product.key.includes("leaf"))
      .map(product => product.title)
      .join(", ");

    activityTypeFullText = activityTypeList
      .map(typeOfActivity => typeOfActivity.title)
      .join(", ");

    activityKeywordFullText = activityKeywordList
      .map(activity => activity.title)
      .join(", ");

    regionFullText = exporterRegionList
      .filter(region => region.key.includes("leaf"))
      .map(region => region.title)
      .join(", ");

    exporterSizeFullText = exporterSizeList
      .map(size => size.title)
      .join(", ");

    exporterSizeSMEFullText = exporterSizeSMEList
      .map(size => size.title)
      .join(", ");

    exporterMemberTypeFullText = exporterMemberTypeList
      .map(memberType => memberType.title)
      .join(", ");

    awardFullText = exporterAwardList
      .map(memberType => memberType.title)
      .join(", ");



    let checkInput = {
      ...inputfilter.filter_input,
      search_text: "",
      country_activity: {
        type: "ditp",
        value: ditpCountryKeys,
      },
      product_activity: {
        type: "ditp",
        value: ditpProductKeys,
      },
      date_activity: {
        startdate: dateInitialize,
        enddate: dateCurrentInitialize,
      },
      score_period: {
        startdate: dateInitialize,
        enddate: dateCurrentInitialize,
      },
      activity_id: [],
      activity_type: activityTypeKeys,
      exporter_region: exporterRegionKeys,
      exporter_size: exporterSizeKeys,
      exporter_size_sme: exporterSizeSMEKeys,
      exporter_award: exporterAwardKeys,
      exporter_member_type: exporterMemberTypeKeys,
      activity_keyword: activityKeywordKeys,
      country_export: {
        type: 'ditp',
        value: ditpCountryKeys,
      },
      product_export: {
        type: 'ditp',
        value: ditpProductKeys,
      },
      date_export: {
        startdate: dateInitialize,
        enddate: dateCurrentInitialize,
      },
      score_weight: {
        ExportValueBaht: 0.1,
        ExportGrowth: 0.1,
        ExportCountCountry: 0.1,
        payment_amt: 0.1,
        CountHSCode6: 0.1,
        CountPMAward: 0.1,
        CountTMark: 0.1,
        CountDMark: 0.1,
        Activity_LevelStart: 0.1,
        Activity_LevelMiddle: 0.1,
        Activity_LevelEnd: 0.1,
      },
      // display_row: "100",
    };

    const activityYearStart = checkInput.date_activity.startdate.split("-")[0] || "";
    const activityYearEnd = checkInput.date_activity.enddate.split("-")[0] || "";
    const exporterYearStart = checkInput.date_activity.startdate.split("-")[0] || "";
    const exporterYearEnd = checkInput.date_activity.enddate.split("-")[0] || "";

    let textExtraFilterUpdated = {
      activity: {
        country_activity: {
          ellipsisText: "ทั้งหมด",
          fullText: ditpCountryFullText
        },
        product_activity: {
          ellipsisText: "ทั้งหมด",
          fullText: ditpProductFullText
        },
        timeRange: {
          ellipsisText: `${activityYearStart} - ${activityYearEnd}`,
          fullText: `${activityYearStart} - ${activityYearEnd}`,
        },
        activity_type: {
          ellipsisText: "ทั้งหมด",
          fullText: activityTypeFullText
        },
        activity_keyword: {
          ellipsisText: "ทั้งหมด",
          fullText: activityKeywordFullText
        },
      },
      exporter: {
        exporter_region: {
          ellipsisText: "ทั้งหมด",
          fullText: regionFullText
        },
        exporter_size: {
          ellipsisText: "ทั้งหมด",
          fullText: exporterSizeFullText
        },
        exporter_size_sme: {
          ellipsisText: "ทั้งหมด",
          fullText: exporterSizeSMEFullText
        },
        exporter_member_type: {
          ellipsisText: "ทั้งหมด",
          fullText: exporterMemberTypeFullText
        },
        exporter_award: {
          ellipsisText: "ทั้งหมด",
          fullText: awardFullText
        },
      },
      exportValue: {
        country_export: {
          ellipsisText: "ทั้งหมด",
          fullText: ditpCountryFullText
        },
        product_export: {
          ellipsisText: "ทั้งหมด",
          fullText: ditpProductFullText
        },
        timeRange: {
          ellipsisText: `${exporterYearStart} - ${exporterYearEnd}`,
          fullText: `${exporterYearStart} - ${exporterYearEnd}`,
        },
      }
    };

    const paramCountry = query.get("country");
    const paramProduct = query.get("product");

    if (paramCountry) {
      // keyCheck = ditpCountryList
      //   .filter((item) => item.ori_key == paramCountry)
      //   .map((item) => item.key);
      const countryObjByParam = ditpCountryList.find(obj => String(obj.ori_key) === paramCountry);
      console.log(countryObjByParam);
      let countries = [];
      let nameCountries = [];
      if (countryObjByParam && countryObjByParam?.key.startsWith("leaf")) {
        nameCountries.push(countryObjByParam.title);
        countries.push(countryObjByParam.key);
      } else {
        // const ditpCountryObj = props?.filter?.ditp_country.find(country => country.key === countryObjByParam.key);
        // services.listGenerator(ditpCountryObj.children, countries);
        // console.log(countries);
        countries = ditpCountryList.filter(country => country.key.startsWith(`leaf-${countryObjByParam.key}-`));
        nameCountries = countries.map(country => country.title);
        countries = countries
          .filter(country => country.key.startsWith('leaf'))
          .map(country => country.key);
      }
      console.log(countries);

      checkInput = {
        ...checkInput,
        country_activity: { type: "ditp", value: countries },
      };

      textExtraFilterUpdated = {
        ...textExtraFilterUpdated,
        activity: {
          ...textExtraFilterUpdated.activity,
          country_activity: {
            ellipsisText: services.formatArrayWithEllipsis(nameCountries),
            fullText: nameCountries.join(", ")
          }
        },
      };

    } else if (paramProduct) {
      const productObjByParam = ditpProductList.find(obj => String(obj.ori_key) === paramProduct);
      console.log(productObjByParam);
      let products = [];
      let nameProducts = [];
      if (productObjByParam && productObjByParam?.key.startsWith("leaf")) {
        nameProducts.push(productObjByParam.title);
        products.push(productObjByParam.key);
      } else {
        products = ditpProductList.filter(product => product.key.startsWith(`leaf-${productObjByParam.key}-`));
        console.log(products);
        nameProducts = products.map(product => product.title);
        products = products
          .filter(product => product.key.startsWith('leaf'))
          .map(product => product.key);
      }
      console.log(products);

      checkInput = {
        ...checkInput,
        product_activity: { type: "ditp", value: products },
      };
      textExtraFilterUpdated = {
        ...textExtraFilterUpdated,
        activity: {
          ...textExtraFilterUpdated.activity,
          product_activity: {
            ellipsisText: services.formatArrayWithEllipsis(nameProducts),
            fullText: nameProducts.join(", ")
          }
        },
      };
    }

    setTextExtraFilter(textExtraFilterUpdated);
    actions.setTextExtraFilter(textExtraFilterUpdated);
    actions.setFilter(checkInput);
    getRequestDataByPathname(checkInput);
  };


  // function updateFilter

  /**
   * set state for display text extra, text-extra is a checked value from Tree Filter
   * @param {String} textExtra text extra for display checked value
   * @param {String} parentType Category of Filter (parent filter) {"activity" | "exporter" | "exportValue"}
   * @param {String} subType sub-category each parent filter
   */
  const handleCheckedKeys = (textExtra, fullText, parentType, subType) => {
    // console.log(`[handleCheckedKeys] ${subType}`, textExtra, fullText);
    let updateState = null;
    setTextExtraFilter(prevState => {
      updateState = {
        ...prevState,
        [parentType]: {
          ...prevState[parentType],
          [subType]: {
            ellipsisText: textExtra,
            fullText
          }
        }
      };
      return updateState;
    });
    actions.setTextExtraFilter(updateState);
  };

  const handleDatePickerChange = (parentType, subType, date, dateString) => {
    // console.log(parentType, subType, date, dateString);
    if (date) {
      const startdate = moment(date[0]).format("YYYY-MM-DD");
      const enddate = moment(date[1]).format("YYYY-MM-DD");
      const new_data = {
        ...inputfilter.filter_input,
        [subType]: { startdate: startdate, enddate: enddate },
        score_period: {
          startdate: startdate,
          enddate: enddate,
        },
      };
      // console.log(new_data);
      let updateState = null;
      const startDateString = startdate.split('-')[0] || "xxxx";
      const endDateString = enddate.split('-')[0] || "xxxx";
      updateState = {
        ...textExtraFilter,
        [parentType]: {
          ...textExtraFilter[parentType],
          timeRange: {
            ellipsisText: `${startDateString} - ${endDateString}`,
            fullText: `${startDateString} - ${endDateString}`
          }
        }
      };

      console.log(updateState);
      setTextExtraFilter(updateState);
      actions.setTextExtraFilter(updateState);
      actions.setFilter(new_data);
    }
  };

  const ExporterTO = [
    {
      key: "โลก",
      title: "โลก",
    },
    {
      key: "ไทย",
      title: "ไทย",
    },
  ];

  const exporterOrder = (data) => {
    if (LOCATION_PATH.IS_PROTENTIAL_PRODUCT) {
      const input = {
        ...inputfilter.filter_input,
        exporter: data,
      };
      actions.postPotentialP(input);

    } else if (LOCATION_PATH.IS_PROTENTIAL_COUNTRY) {
      const input = {
        ...inputfilter.filter_input,
        exporter: data,
      };
      actions.postPotentialC(input);
    } else {
    }
  };

  useEffect(() => {
    console.log('useEffect.setDefault()');
    setDefault();
  }, []);

  useEffect(() => {
    console.log(`textExtraFilterRedux changed`, textExtraFilterRedux);
    // actions.setTextExtraFilter(textExtraFilterRedux);
    if (textExtraFilterRedux) setTextExtraFilter(textExtraFilterRedux);
  }, [textExtraFilterRedux]);

  // useEffect(() => {
  //   console.log(`inputfilter has changes\n`, inputfilter);
  // }, [inputfilter])

  return (
    <div className="basic-filter">
      {/* If location-path is "วิเคราะห์โอกาส (Protential)" */}
      {LOCATION_PATH.IS_PROTENTIAL_PRODUCT || LOCATION_PATH.IS_PROTENTIAL_COUNTRY ? (
        <Card title="ผู้ส่งออก" bordered={false} size={"small"}>
          <div className="export-to w-full flex justify-center p-3">
            <Select
              onChange={exporterOrder}
              defaultValue={"ไทย"}
              style={{ width: "90%", borderRadius: "8px" }}
            >
              {ExporterTO.map((item) => (
                <Option key={item.key}>{item.title}</Option>
              ))}
            </Select>
          </div>
          <hr />
          <div className="p-3">
            <div className="protiential-country">
              <h1>
                {LOCATION_PATH.IS_PROTENTIAL_PRODUCT || LOCATION_PATH.IS_PROTENTIAL_COUNTRY
                  ? "ประเทศปลายทาง"
                  : "ประเทศ"}
                <Tooltip placement={"right"} title={textExtraFilter.activity.country_activity.fullText}>
                  <p className="text-primary-grey m-0">
                    {textExtraFilter.activity.country_activity.ellipsisText}
                  </p>
                </Tooltip>
              </h1>
              <ActivityFilter
                parentType={"activity"}
                type={"country_activity"}
                dataFilter={props?.filter?.ditp_country}
                defaultKey={inputfilter?.filter_input?.country_activity?.value || ['leaf-9999']}
                inputfilter={inputfilter}
                onCheckedKeys={handleCheckedKeys}
              />
            </div>
            <Divider />
            <div className="protential-product">
              <h1>
                {LOCATION_PATH.IS_PROTENTIAL_PRODUCT ||
                  LOCATION_PATH.IS_PROTENTIAL_COUNTRY
                  ? "สินค้าที่ส่งออก"
                  : "สินค้า"}
                <Tooltip placement={"right"} title={textExtraFilter.activity.product_activity.fullText}>
                  <p className="text-primary-grey m-0">
                    {textExtraFilter.activity.product_activity.ellipsisText}
                  </p>
                </Tooltip>
              </h1>
              {productTab && (
                <ActivityFilter
                  parentType={"activity"}
                  type={"product_activity"}
                  dataFilter={props?.filter?.ditp_product}
                  defaultKey={inputfilter?.filter_input?.product_activity?.value}
                  inputfilter={inputfilter}
                  onCheckedKeys={handleCheckedKeys}
                />
              )}
            </div>
          </div>
        </Card>
      ) : (
        <>
          {/* Activity Filter */}
          <Card title='กิจกรรม' bordered={false} size='small'>
            {props.filter && (
              <div>
                <Collapse
                  className="country-filter"
                  accordion
                  size='small'
                  bordered={false}
                  expandIconPosition={"right"}
                >
                  <Collapse.Panel key={1} header={
                    <>
                      <h1>
                        {LOCATION_PATH.IS_PROTENTIAL_PRODUCT || LOCATION_PATH.IS_PROTENTIAL_COUNTRY
                          ? "ประเทศปลายทาง"
                          : "ประเทศที่จัดกิจกรรม"}
                      </h1>
                      {
                        <Tooltip placement={"right"} title={textExtraFilter.activity.country_activity.fullText}>
                          <p className="text-primary-grey m-0">
                            {textExtraFilter.activity.country_activity.ellipsisText}
                          </p>
                        </Tooltip>
                      }
                    </>
                  }>
                    <ActivityFilter
                      parentType={"activity"}
                      type={"country_activity"}
                      dataFilter={props?.filter?.ditp_country}
                      defaultKey={inputfilter?.filter_input?.country_activity?.value || ['leaf-9999']}
                      inputfilter={inputfilter}
                      onCheckedKeys={handleCheckedKeys}
                    />
                  </Collapse.Panel>

                  <Collapse.Panel key={2} header={
                    <>
                      <h1>
                        {LOCATION_PATH.IS_PROTENTIAL_PRODUCT ||
                          LOCATION_PATH.IS_PROTENTIAL_COUNTRY
                          ? "สินค้าที่ส่งออก"
                          : "สินค้าที่จัดกิจกรรม"}
                      </h1>
                      <Tooltip placement={"right"} title={textExtraFilter.activity.product_activity.fullText}>
                        <p className="text-primary-grey m-0">
                          {textExtraFilter.activity.product_activity.ellipsisText}
                        </p>
                      </Tooltip>
                    </>
                  }
                  >
                    {productTab && (
                      <ActivityFilter
                        parentType={"activity"}
                        type={"product_activity"}
                        dataFilter={props?.filter?.ditp_product}
                        defaultKey={inputfilter?.filter_input?.product_activity?.value}
                        inputfilter={inputfilter}
                        onCheckedKeys={handleCheckedKeys}
                      />
                    )}
                  </Collapse.Panel>

                  <Collapse.Panel key={4} header={
                    <>
                      <h1>
                        ประเภทกิจกรรม
                      </h1>
                      <Tooltip placement={"right"} title={textExtraFilter.activity.activity_type.fullText}>
                        <p className="text-primary-grey m-0">
                          {textExtraFilter.activity.activity_type.ellipsisText}
                        </p>
                      </Tooltip>
                    </>
                  }>
                    <ActivityFilter
                      parentType={"activity"}
                      type={"activity_type"}
                      dataFilter={props?.filter?.activity_type}
                      defaultKey={inputfilter?.filter_input?.activity_type}
                      inputfilter={inputfilter}
                      onCheckedKeys={handleCheckedKeys}
                    />
                  </Collapse.Panel>

                  <Collapse.Panel key={5} header={
                    <>
                      <h1>
                        คำสำคัญของกิจกรรม
                      </h1>
                      <Tooltip placement={"right"} title={textExtraFilter.activity.activity_keyword.fullText}>
                        <p className="text-primary-grey m-0">
                          {textExtraFilter.activity.activity_keyword.ellipsisText}
                        </p>
                      </Tooltip>
                    </>
                  }>
                    <ActivityFilter
                      parentType={"activity"}
                      type={"activity_keyword"}
                      dataFilter={props?.filter?.activity_keyword}
                      defaultKey={inputfilter?.filter_input?.activity_keyword}
                      inputfilter={inputfilter}
                      onCheckedKeys={handleCheckedKeys}
                    />
                  </Collapse.Panel>


                  {LOCATION_PATH.IS_PROTENTIAL_PRODUCT ||
                    LOCATION_PATH.IS_PROTENTIAL_COUNTRY ||
                    LOCATION_PATH.IS_SUGGEST_ACTIVITY ? null : (
                    <Collapse.Panel key={3} header={
                      <>
                        <h1>
                          ช่วงเวลาที่จัดกิจกรรม
                        </h1>
                        <Tooltip placement={"right"} title={textExtraFilter.activity.timeRange.fullText}>
                          <p className="text-primary-grey m-0">
                            {textExtraFilter.activity.timeRange.ellipsisText}
                          </p>
                        </Tooltip>
                      </>
                    }>
                      <div className="time-range">
                        <Text>
                          {props.startDate && (
                            <ConfigProvider locale={locale}>
                              <RangePicker
                                defaultValue={[
                                  moment(
                                    props.startDate.date_activity.startdate,
                                    "YYYY-MM-DD"
                                  ),
                                  moment({ dateFormat }),
                                ]}
                                placeholder={["วันที่เริ่มต้น", "วันที่สิ้นสุด"]}
                                picker="year"
                                onChange={(date, dateString) => handleDatePickerChange("activity", "date_activity", date, dateString)} />
                            </ConfigProvider>
                          )}
                        </Text>
                      </div>
                    </Collapse.Panel>
                  )}

                </Collapse>
              </div>
            )}
          </Card>
          <Divider />

          {/* Exporter Filter */}
          <Card title='ผู้ประกอบการ' bordered={false} size='small'>
            {props.filter && (
              <div>
                {/* Region Filter */}
                <Collapse
                  className="country-filter"
                  accordion
                  size='small'
                  bordered={false}
                  expandIconPosition={"right"}
                >

                  <Collapse.Panel key={1} header={<>
                    <h1>
                      สินค้าที่ผู้ประกอบการส่งออก
                    </h1>
                    <Tooltip placement={"right"} title={textExtraFilter.exportValue.product_export.fullText}>
                      <p className="text-primary-grey m-0">
                        {textExtraFilter.exportValue.product_export.ellipsisText}
                      </p>
                    </Tooltip>
                  </>}>
                    <ExportValueFilter
                      type={"product_export"}
                      activityType={props?.filter?.ditp_product}
                      dataFilter={props?.filter?.ditp_product}
                      activityId={inputfilter.filter_input}
                      onCheckedKeys={handleCheckedKeys} />
                  </Collapse.Panel>

                  <Collapse.Panel key={2} header={<>
                    <h1>
                      ประเทศที่ผู้ประกอบการส่งออก
                    </h1>
                    <Tooltip placement={"right"} title={textExtraFilter.exportValue.country_export.fullText}>
                      <p className="text-primary-grey m-0">
                        {textExtraFilter.exportValue.country_export.ellipsisText}
                      </p>
                    </Tooltip>
                  </>}>
                    <ExportValueFilter
                      type={"country_export"}
                      activityType={props?.filter?.ditp_country}
                      dataFilter={props?.filter?.ditp_country}
                      activityId={inputfilter.filter_input}
                      onCheckedKeys={handleCheckedKeys} />
                  </Collapse.Panel>


                  <Collapse.Panel key={3} header={<>
                    <h1>
                      ขนาดผู้ประกอบการตามมูลค่าการส่งออก
                    </h1>
                    <Tooltip placement={"right"} title={textExtraFilter.exporter.exporter_size.fullText}>
                      <p className="text-primary-grey m-0">
                        {textExtraFilter.exporter.exporter_size.ellipsisText}
                      </p>
                    </Tooltip>
                  </>}>
                    <ExporterFilter
                      parentType={"exporter"}
                      type={"exporter_size"}
                      showExporterSize={true}
                      dataFilter={props?.filter?.comsize_export}
                      onCheckedKeys={handleCheckedKeys} />
                  </Collapse.Panel>


                  {LOCATION_PATH.IS_PROTENTIAL_PRODUCT ||
                    LOCATION_PATH.IS_PROTENTIAL_COUNTRY ||
                    LOCATION_PATH.IS_SUGGEST_ACTIVITY ? null : (
                    <Collapse.Panel key={4} header={<>
                      <h1>
                        ขนาดผู้ประกอบการตามเกณฑ์ สสว.
                      </h1>
                      <Tooltip placement={"right"} title={textExtraFilter.exporter.exporter_size_sme.fullText}>
                        <p className="text-primary-grey m-0">
                          {textExtraFilter.exporter.exporter_size_sme.ellipsisText}
                        </p>
                      </Tooltip>
                    </>}>
                      <ExporterFilter
                        parentType={"exporter"}
                        type={"exporter_size_sme"}
                        showExporterSize={true}
                        dataFilter={props?.filter?.comsize_sme}
                        onCheckedKeys={handleCheckedKeys} />
                    </Collapse.Panel>
                  )}

                  <Collapse.Panel key={5} header={<>
                    <h1>
                      ประเภทสมาชิกของผู้ประกอบการ
                    </h1>
                    <Tooltip placement={"right"} title={textExtraFilter.exporter.exporter_member_type.fullText}>
                      <p className="text-primary-grey m-0">
                        {textExtraFilter.exporter.exporter_member_type.ellipsisText}
                      </p>
                    </Tooltip>

                  </>}>
                    <ExporterFilter
                      parentType={"exporter"}
                      type={"exporter_member_type"}
                      showExporterSize={true}
                      dataFilter={props?.filter?.company_member_type}
                      onCheckedKeys={handleCheckedKeys} />
                  </Collapse.Panel>


                  <Collapse.Panel key={6} header={<>
                    <h1>
                      ช่วงเวลาที่ผู้ประกอบการส่งออก
                    </h1>
                    <Tooltip placement={"right"} title={textExtraFilter.exportValue.timeRange.fullText}>
                      <p className="text-primary-grey m-0">
                        {textExtraFilter.exportValue.timeRange.ellipsisText}
                      </p>
                    </Tooltip>
                  </>}>
                    <div className="time-range">
                      <Text>
                        {props.startDate && (
                          <ConfigProvider locale={locale}>
                            <RangePicker
                              defaultValue={[
                                moment(
                                  props.startDate.date_export.startdate,
                                  "YYYY-MM-DD"
                                ),
                                moment({ dateFormat }),
                              ]}
                              placeholder={["วันที่เริ่มต้น", "วันที่สิ้นสุด"]}
                              picker="year"
                              onChange={(date, dateString) => handleDatePickerChange("exportValue", 'date_export', date, dateString)} />
                          </ConfigProvider>
                        )}
                      </Text>
                    </div>
                  </Collapse.Panel>

                  <Collapse.Panel key={7} header={<>
                    <h1>
                      รางวัลของผู้ประกอบการ
                    </h1>
                    <Tooltip placement={"right"} title={textExtraFilter.exporter.exporter_award.fullText}>
                      <p className="text-primary-grey m-0">
                        {textExtraFilter.exporter.exporter_award.ellipsisText}
                      </p>
                    </Tooltip>

                  </>}>
                    <ExporterFilter
                      parentType={"exporter"}
                      type={"exporter_award"}
                      showExporterSize={true}
                      dataFilter={props?.filter?.award}
                      onCheckedKeys={handleCheckedKeys} />
                  </Collapse.Panel>

                  <Collapse.Panel key={8} header={<>
                    <h1>
                      ภูมิภาคของผู้ประกอบการ
                    </h1>

                    <Tooltip placement={"right"} title={textExtraFilter.exporter.exporter_region.fullText}>
                      <p className="text-primary-grey m-0">
                        {textExtraFilter.exporter.exporter_region.ellipsisText}
                      </p>
                    </Tooltip>
                  </>}
                  >
                    <ExporterFilter
                      parentType={"exporter"}
                      type={"exporter_region"}
                      showExporterRegion={true}
                      dataFilter={props?.filter?.exporter_region}
                      onCheckedKeys={handleCheckedKeys} />
                  </Collapse.Panel>

                </Collapse>
              </div>
            )}
          </Card>
        </>
      )
      }
    </div>
  );
}
