// ---- Library ----
import React, { useState, useEffect } from "react";
import { NavLink, Link, useHistory, useLocation } from "react-router-dom";
import Cookies from "js-cookie";

// ---- assests ----
import "../assets/CSS/mod_navbar.css";
import DITP_LOGO from "../assets/img/ditp-logo.png";
import UserIcon from "../assets/img/user-Icon.png";
import BURGER_ICON from "../assets/img/burger.png";

// ---- Components ----
import { Menu, Dropdown, Button, Drawer, Typography, Modal, Input } from "antd";
import Icon, { CaretDownFilled } from "@ant-design/icons";

// ---- Services/Resource ----
import { ROUTE_URL } from "../resources/variables";
import AuthService from "../services/auth.service";

// ---- settings/declaration ----
const { TextArea } = Input;
const { Text } = Typography;
const { SubMenu } = Menu;

export default function Navbar() {
  const history = useHistory();
  const location = useLocation();
  const [path, setPath] = useState(ROUTE_URL.HOME);
  const [visible, setVisible] = useState(false);
  const [userProfile, setUserProfile] = useState(AuthService.getCurrentUser());

  // Modal
  const [isModalVisible, setIsModalVisible] = useState(false);

  const ROLES = {
    IS_SS0_EXPORTER: !!userProfile?.roles?.includes("SSO_exporter"),
    IS_SS0_PERSON: !!userProfile?.roles?.includes("SSO_person"),
    IS_ADMINISTRATOR: !!userProfile?.roles?.includes("Administrator"),
    IS_STAFF: !!userProfile?.roles?.includes("เจ้าหน้าที่ทั่วไป"),
  };

  useEffect(() => {
    async function fetchCurrentUser() {
      // console.log(userProfile);
      if (!userProfile?.roles) {
        setUserProfile(await AuthService.fetchCurrentUser());
      }
    }

    pushPath();
    fetchCurrentUser();
  }, []);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const CopyKeys = async () => {
    const copyValue = document.getElementById("apiKey");
    console.log(copyValue.value);
    copyValue.select();

    try {
      await navigator.clipboard.writeText(copyValue.value);
    } catch (e) {
      console.log("Error", e);
    }

    setIsModalVisible(false);
  };

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const onRouterPush = (pathURI) => {
    setVisible(false);
    history.push(pathURI);
  };

  const jwt_token = Cookies.get("_aspNetCoreJwtToken", {
    domain: ".ditp.go.th",
  });

  const DOCS_MANUAL_OVERLAY_DROPDOWN = (
    <Menu style={{ fontFamily: "prompt" }}>
      {ROLES.IS_ADMINISTRATOR ? (
        <>
          <Menu.Item
            key={1}
            onClick={() =>
              window.open(
                "https://drive.google.com/file/d/1TK7cAIsAplQDr0FzmkQdmzPh05rHlTFx/view?usp=sharing",
                "_blank"
              )
            }
          >
            {/* <Link
            // href="https://doc-00-84-docs.googleusercontent.com/docs/securesc/trrpmgg5fngo84ir6ctl6dq8rt7h6oad/dd1o382aetnjdhp2blbbgnk76apj296r/1652151075000/14952503450886791080/09521618049316721001/1TK7cAIsAplQDr0FzmkQdmzPh05rHlTFx?e=download&ax=ACxEAsan4ncovx3G-jVljJ0wAKpxjWl8JEAUxpox_mB_V5tj4yjgp7R0AaYHpO11Mvz6mcwrUoEwP9i3Cx-hcyDQyqWa_zwNjKYvNvIpdeGbQC8hASPcNC500gnLFSYq2-FV3iR53_paat2CG4vk6ZTUa5rZF_bmdTOt1ZBrU6g2wH1Al_Q6VvXHVnpns-t58o1hzKQj0CL_W-anRuVKrdtpmFIEX8nC62X8-m1_OMX3rV5J2L-V1gQO0fJBNZEyew1n2msZSu4fAXYKGE21yGFoCR6Ecly0q3EtCWYU8p_uf45UYfANqMTf0tNh1DAX3KQu_vD_9gKmdhdP1idwv5U-cvopfsfVUW2wrcp-YkiOyVntA7jguPYDDqzGaX3zj1bkNPd-VZ9oQkwOP2oRXLqKV6BQSgC9Tl4lQgrNaQg_zjfRzCPtF4-MTGF3Y_QVhzoZE9bNOICeQSBZ-SKPOWQtN64H9P2uuvVC1H58_4RtBIBWNAoZV3fb8oe-FUT13aR2IcE54PRC_qwN4awzf-XgFPYoi_Q0rIko96PJbe07hV7nIRH63VQSjJqSr92feuxJbLQGCj51T3Folkgw1D5ChdgNGeo3bW89s2fx2QGgyIZ7J60QZAbDxxjchHWfSI2j5GV-3MW2nWPWJtKi7gmzvziapBNNcAOISfx4le7AuSMP47AVQ8wc0xX-6W8zy4UCZp3N6BXeKhUx45XMbdT-cLlLPhznsdtkP7NBG3K-kzoZ6CLi4aEuAredzyKPDD2KrrkndPG_KgvfdmMD8sUIqg&authuser=0"
            // download
          > */}
            คู่มือการเรียกใช้ API Trade Analytics
            {/* </Link> */}
          </Menu.Item>
        </>
      ) : null}
      {ROLES.IS_ADMINISTRATOR || ROLES.IS_STAFF ? (
        <Menu.Item
          key={3}
          onClick={() =>
            window.open(
              "https://drive.google.com/file/d/1NTvqOeG-zaPR5wE9SlQyWyAXxCAfemH6/view?usp=sharing",
              "_blank"
            )
          }
        >
          {/* <Link
            href="https://doc-0c-84-docs.googleusercontent.com/docs/securesc/trrpmgg5fngo84ir6ctl6dq8rt7h6oad/s57l2qcqqqr8nb803sjh5ikqns9ulvpn/1652151075000/14952503450886791080/09521618049316721001/1NTvqOeG-zaPR5wE9SlQyWyAXxCAfemH6?e=download&ax=ACxEAsbpWoCpzO-wn2uQXdZoOOmXUQZtcZ7ImYwkvXLGqvKidiWp4Oy3WaHqw1PThUgorWczrpaL0790K8LGc5PwPs9CpvZiYRqaPHp4-bGZawz_heg6O4vKQ84tTNA4OWcTHNsBwECenKh39tgLtwXnEL0WhTNRwsMV3tWx5fwruzMYxClCo4EpvK2s2mHZYLsPY_rASVDbU6ojYHjkVg0bTtrrM8bQEKhdjwFlZNrOGDKguRHUFIS31zigMEnPO4VIZZzLifc7zOA4dTNfe5wl-406fSCHNW_B_B2uL1DxuVQETgJeigN4fd0-gNEQwkT3_s1mzaU4Lk4DWiXet87LmKTsbCZee--TZ2wxT-NcWAZ12EKm0IN2ojmourWTunBMY0ztRocDXyJ86q5h4NOsyqfJMY7m3gRI2DIG_w8bZyHkCT7bOl-hlQp8Mupj6bKZ_VdugaWYydCL_Dcw0865TxAE19sejqhdRUJvGWvGN17uV_JMTgBkOAFvaSKSl6K76Kn1vrdkaaFmHoYmA57YZeF2LAFpq4oKwiRA7pbnXthfhuWZ2zxkKr62HglCnO6zqsjwEXPbt-EasfQgMn4LnZcLiNeeEKx0XXDfTt_f0DEXyU6ErMvoeTz_-tLK8-kGLecBwUB-_x356zd7yUne03WT0XA4S_ETPyjaPD2TPl8DFQ9y3uqi3LeKruGLfn_edomU2KmfNGzJI7TNzi_S34cWCuAgkYsA6DZ5wgviVjj8Cz8uXjOqB8uyxhf28MG7n8oZAOHgDY7_D4SXVIpRpA&authuser=0"
            download
          > */}
          คู่มือการใช้งานและบริหารระบบ
          {/* </Link> */}
        </Menu.Item>
      ) : null}
    </Menu>
  );

  const PotentialOverlayMenu = (
    <Menu style={{ fontFamily: "prompt" }}>
      <Menu.Item key={1}>
        <NavLink
          exact
          activeClassName='active font-bold underline'
          to='/PotentialProduct'
        >
          ศักยภาพสินค้า
        </NavLink>
      </Menu.Item>
      <Menu.Item key={2}>
        <NavLink
          exact
          activeClassName='active font-bold underline'
          to='/PotentialCountry'
        >
          ศักยภาพตลาด
        </NavLink>
      </Menu.Item>
    </Menu>
  );

  const ActivityOverlayMenu = (
    <Menu style={{ fontFamily: "prompt" }}>
      <Menu.Item key={1}>
        <Link to='/Activity'>หน้าวิเคราะห์กิจกรรม</Link>
      </Menu.Item>
      <Menu.Item key={2}>
        <Link
          onClick={() =>
            window.location.replace(
              "https://activityanalytics.ditp.go.th/ActivityDashboard"
            )
          }
        >
          หน้าสรุปวิเคราะห์กิจกรรม (Dashboard)
        </Link>
      </Menu.Item>
    </Menu>
  );

  const ExporterOverlayMenu = (
    <Menu style={{ fontFamily: "prompt" }}>
      <Menu.Item key={1}>
        <Link to='/Exporter'>หน้าวิเคราะห์ผู้ประกอบการ</Link>
      </Menu.Item>
      <Menu.Item key={2}>
        <Link
          onClick={() =>
            window.location.replace(
              "https://activityanalytics.ditp.go.th/ActivityDashboard"
            )
          }
        >
          หน้าสรุปวิเคราะห์ผู้ประกอบการ (Dashboard)
        </Link>
      </Menu.Item>
    </Menu>
  );

  const onRedirectLogin = () => (window.location = AuthService.getLoginURL());

  const logout = () => {
    AuthService.logout();
  };

  const User = () => {
    if (userProfile) {
      return ROLES.IS_SS0_EXPORTER || ROLES.IS_SS0_PERSON ? (
        <Menu style={{ fontFamily: "prompt" }}>
          <Menu.ItemGroup
            key='Name'
            title={userProfile ? userProfile?.displayname : "Anonymous"}
          />
          <Menu.Divider></Menu.Divider>
          <Menu.Item key={6} onClick={logout}>
            ออกจากระบบ
          </Menu.Item>
        </Menu>
      ) : (
        <Menu style={{ fontFamily: "prompt" }}>
          <Menu.ItemGroup
            key='Name'
            title={userProfile ? userProfile?.displayname : "Anonymous"}
          />

          <Menu.Divider></Menu.Divider>
          <Menu.Item
            key={1}
            onClick={() =>
              window.location.replace(
                "http://activityanalytics.ditp.go.th/Identity/Account/Manage"
              )
            }
          >
            ข้อมูลส่วนตัว
          </Menu.Item>

          {ROLES.IS_ADMINISTRATOR && (
            <>
              <Menu.Item key={2}>
                <Link to='/ScoreSetting'>ตั้งค่าการคำนวณดัชนี</Link>
              </Menu.Item>
              {/* <Menu.Item key={3}>
                <Link to="/ResultDelay">ตั้งค่าการหน่วงผลลัพธ์</Link>
              </Menu.Item>
              <Menu.Item key={4}>
                <Link to="/StatusSMS">ตรวจสอบ SMS</Link>
              </Menu.Item> */}
              <Menu.Item
                key={5}
                onClick={
                  () =>
                    // window.location.replace(
                    //   "http://activityanalytics.ditp.go.th/Admin"
                    // )
                    window.open(
                      "http://activityanalytics.ditp.go.th/Admin",
                      "_blank"
                    )
                  // window.open("https://api.analytics.ditp.go.th/admin", '_blank')
                }
              >
                จัดการสิทธ์ผู้ใช้
              </Menu.Item>
              <Menu.Item
                key={5}
                onClick={() =>
                  window.open("http://analytics.ditp.go.th:5601", "_blank")
                }
              >
                Logs Dashboard
              </Menu.Item>

              <Menu.Item
                key={6}
                onClick={() =>
                  // showModal()
                  window.open(
                    "https://api-analytics.ditp.go.th/admin",
                    "_blank"
                  )
                }
              >
                API Management
              </Menu.Item>
            </>
          )}
          <Menu.Item key={7} onClick={logout}>
            ออกจากระบบ
          </Menu.Item>
        </Menu>
      );
    }
  };

  const pushPath = () => {
    // console.log(userProfile, ROUTE_URL);
    if (userProfile && Array.isArray(userProfile?.roles)) {
      if (ROLES.IS_SS0_EXPORTER || ROLES.IS_SS0_PERSON) {
        setPath(ROUTE_URL.SELF_ASSESSMENT);
      } else {
        setPath(ROUTE_URL.HOME);
      }
    }
  };

  // main navbar menu
  return (
    <>
      <div className='navbar shadow h-full'>
        {/* DEVELOPER MODAL */}
        <Modal
          title='API Key'
          visible={isModalVisible}
          onOk={CopyKeys}
          okText='Copy'
          onCancel={() => setIsModalVisible(false)}
          cancelButtonProps={{ style: { display: "none" } }}
        >
          <Text>
            API Key สำหรับนักพัฒนาที่ต้องการเรียกใช้ API (โปรดเก็บ Key
            ไว้ในที่ปลอดภัย)
          </Text>
          <TextArea id='apiKey' rows={9} value={jwt_token} readOnly />
        </Modal>

        {/* DESKTOP-BREAKPOINT */}
        <div className='container'>
          {/* DITP-LOGO */}
          <div className='navbar-brand lg:flex lg:flex-col lg:justify-center'>
            <Link to={path}>
              <img className='w-24 md:w-32' src={DITP_LOGO} alt='DITP-LOGO' />
            </Link>
          </div>

          {(userProfile || location.pathname === ROUTE_URL.CRM_DETAIL) && (
            <div className='navbar-menu'>
              <NavLink exact activeClassName='active' to='/'>
                หน้าหลัก
              </NavLink>
              <Link
                onClick={() =>
                  window.location.replace(
                    "https://activityanalytics.ditp.go.th/Export"
                  )
                }
              >
                วิเคราะห์เป้าส่งออก
              </Link>
              {/* <NavLink  exact activeClassName="active" to="/PotentialProduct">
                วิเคราะห์โอกาส
              </NavLink> */}

              <Dropdown overlay={PotentialOverlayMenu}>
                <NavLink exact activeClassName='active' to='/PotentialProduct'>
                  <>
                    วิเคราะห์โอกาส
                    <Icon
                      component={CaretDownFilled}
                      style={{ color: "#00529b" }}
                    />
                  </>
                </NavLink>
              </Dropdown>

              <Dropdown overlay={ActivityOverlayMenu}>
                <NavLink exact activeClassName='active' to='/Activity'>
                  <>
                    วิเคราะห์กิจกรรม
                    <Icon
                      component={CaretDownFilled}
                      style={{ color: "#00529b" }}
                    />
                  </>
                </NavLink>
              </Dropdown>

              <Dropdown overlay={ExporterOverlayMenu}>
                <NavLink exact activeClassName='active' to='/Exporter'>
                  <>
                    วิเคราะห์ผู้ประกอบการ
                    <Icon
                      component={CaretDownFilled}
                      style={{ color: "#00529b" }}
                    />
                  </>
                </NavLink>
              </Dropdown>

              <NavLink exact activeClassName='active' to='/SuggestActivity'>
                แนะนำกิจกรรม
              </NavLink>
              <NavLink exact activeClassName='active' to='/Movement'>
                การเคลื่อนที่ของผู้ประกอบการ
              </NavLink>

              {(ROLES.IS_ADMINISTRATOR || ROLES.IS_STAFF) && (
                <Dropdown overlay={DOCS_MANUAL_OVERLAY_DROPDOWN}>
                  <div
                    className='ant-dropdown-link cursor-pointer '
                    onClick={(e) => e.preventDefault()}
                  >
                    คู่มือการใช้งานระบบ
                    <Icon
                      component={CaretDownFilled}
                      style={{ color: "#00529b" }}
                    />
                  </div>
                </Dropdown>
              )}
            </div>
          )}
        </div>

        {/* USER-ICON BUTTON */}

        {!userProfile?.roles ? (
          <div>
            <span
              className={`m-0 mr-4 font-bold text-blue-800 hover:text-blue-500 active:text-blue-900 hover:cursor-pointer`}
              onClick={onRedirectLogin}
            >
              เข้าสู่ระบบ
            </span>
          </div>
        ) : (
          <div className='user-button'>
            <Dropdown
              placement='topRight'
              overlay={() => User()}
              trigger={["click"]}
            >
              <Button
                type='primary'
                shape='circle'
                icon={<img width='30' src={UserIcon} alt='userIcon' />}
              />
            </Dropdown>
          </div>
        )}

        {/* MOBILE, TABLET BREAKPOINT */}
        <div className='mobile-icon'>
          <img
            src={BURGER_ICON}
            style={{ width: 40 }}
            alt='hamburger'
            onClick={showDrawer}
          />
          <Drawer
            placement={"right"}
            onClose={onClose}
            visible={visible}
            size={"default"}
          >
            {userProfile && (
              <div className='sider-drawer'>
                {/* <NavLink
                key={0}
                id="home-link"
                onClick={() => onClose()}
                exact
                activeClassName="active"
                to="/"
              >
                หน้าหลัก
              </NavLink>
              <Link
                exact
                key={1}
                activeClassName="active"
                onClick={() => {
                  onClose();
                  window.location.replace("https://activityanalytics.ditp.go.th/Export");
                }}
              >
                วิเคราะห์เป้าส่งออก
              </Link> */}
                <Menu
                  mode={"inline"}
                  // initialize open keys
                  defaultOpenKeys={["1", "2", "3", "4", "5", "6", "7", "8"]}
                  // set keys are open
                  openKeys={["1", "2", "3", "4", "5", "6", "7", "8"]}
                >
                  <Menu.Item
                    onClick={() => onClose()}
                    key={"1"}
                    className='main-menu'
                  >
                    <NavLink
                      exact
                      to='/'
                      id='home-link'
                      onClick={() => onClose()}
                      activeClassName='active'
                    >
                      หน้าหลัก
                    </NavLink>
                  </Menu.Item>

                  <Menu.Item
                    onClick={() => onClose()}
                    key={"2"}
                    className='main-menu'
                  >
                    <Link
                      exact
                      activeClassName='active'
                      onClick={() => {
                        onClose();
                        window.location.replace(
                          "https://activityanalytics.ditp.go.th/Export"
                        );
                      }}
                    >
                      หน้าวิเคราะห์เป้าส่งออก
                    </Link>
                  </Menu.Item>

                  <SubMenu
                    key={"3"}
                    title={"หน้าวิเคราะห์โอกาส"}
                    onTitleClick={() => onRouterPush("/PotentialProduct")}
                  >
                    <Menu.Item onClick={() => onClose()}>
                      <NavLink
                        exact
                        to='/PotentialProduct'
                        activeClassName='active'
                      >
                        ศักยภาพสินค้า
                      </NavLink>
                    </Menu.Item>
                    <Menu.Item onClick={() => onClose()}>
                      <NavLink exact to='/PotentialCountry'>
                        ศักยภาพตลาด
                      </NavLink>
                    </Menu.Item>
                  </SubMenu>

                  <SubMenu
                    key={"4"}
                    title={"หน้าวิเคราะห์กิจกรรม"}
                    onTitleClick={() => onRouterPush("/Activity")}
                  >
                    <Menu.Item onClick={() => onClose()}>
                      <NavLink exact to='/Activity' activeClassName='active'>
                        วิเคราะห์กิจกรรม
                      </NavLink>
                    </Menu.Item>
                    <Menu.Item onClick={() => onClose()}>
                      <Link
                        exact
                        activeClassName='active'
                        onClick={() => {
                          onClose();
                          window.location.replace(
                            "https://activityanalytics.ditp.go.th/ActivityDashboard"
                          );
                        }}
                      >
                        หน้าสรุปวิเคราะห์กิจกรรม
                      </Link>
                    </Menu.Item>
                  </SubMenu>

                  <SubMenu
                    key={"5"}
                    title={"หน้าวิเคราะห์ผู้ประกอบการ"}
                    onTitleClick={() => onRouterPush("/Exporter")}
                  >
                    <Menu.Item>
                      <NavLink exact to='/Exporter' activeClassName='active'>
                        วิเคราะห์ผู้ประกอบการ
                      </NavLink>
                    </Menu.Item>
                    <Menu.Item>
                      <Link
                        onClick={() =>
                          window.location.replace(
                            "https://activityanalytics.ditp.go.th/ActivityDashboard"
                          )
                        }
                      >
                        หน้าสรุปวิเคราะห์ผู้ประกอบการ (Dashboard)
                      </Link>
                    </Menu.Item>
                  </SubMenu>

                  <Menu.Item
                    key={"6"}
                    className='main-menu'
                    onClick={() => onClose()}
                  >
                    <NavLink
                      exact
                      activeClassName='active'
                      to='/SuggestActivity'
                    >
                      แนะนำกิจกรรม
                    </NavLink>
                  </Menu.Item>

                  <Menu.Item
                    key={"7"}
                    className='main-menu'
                    onClick={() => onClose()}
                  >
                    <NavLink exact activeClassName='active' to='/Movement'>
                      การเคลื่อนที่ของผู้ประกอบการ
                    </NavLink>
                  </Menu.Item>

                  {ROLES.IS_ADMINISTRATOR || ROLES.IS_STAFF ? (
                    <SubMenu key='8' title='คู่มือการใช้งานระบบ'>
                      {ROLES.IS_ADMINISTRATOR ? (
                        <>
                          <Menu.Item onClick={() => onClose()} key={12}>
                            <Link
                              href='https://doc-00-84-docs.googleusercontent.com/docs/securesc/trrpmgg5fngo84ir6ctl6dq8rt7h6oad/dd1o382aetnjdhp2blbbgnk76apj296r/1652151075000/14952503450886791080/09521618049316721001/1TK7cAIsAplQDr0FzmkQdmzPh05rHlTFx?e=download&ax=ACxEAsan4ncovx3G-jVljJ0wAKpxjWl8JEAUxpox_mB_V5tj4yjgp7R0AaYHpO11Mvz6mcwrUoEwP9i3Cx-hcyDQyqWa_zwNjKYvNvIpdeGbQC8hASPcNC500gnLFSYq2-FV3iR53_paat2CG4vk6ZTUa5rZF_bmdTOt1ZBrU6g2wH1Al_Q6VvXHVnpns-t58o1hzKQj0CL_W-anRuVKrdtpmFIEX8nC62X8-m1_OMX3rV5J2L-V1gQO0fJBNZEyew1n2msZSu4fAXYKGE21yGFoCR6Ecly0q3EtCWYU8p_uf45UYfANqMTf0tNh1DAX3KQu_vD_9gKmdhdP1idwv5U-cvopfsfVUW2wrcp-YkiOyVntA7jguPYDDqzGaX3zj1bkNPd-VZ9oQkwOP2oRXLqKV6BQSgC9Tl4lQgrNaQg_zjfRzCPtF4-MTGF3Y_QVhzoZE9bNOICeQSBZ-SKPOWQtN64H9P2uuvVC1H58_4RtBIBWNAoZV3fb8oe-FUT13aR2IcE54PRC_qwN4awzf-XgFPYoi_Q0rIko96PJbe07hV7nIRH63VQSjJqSr92feuxJbLQGCj51T3Folkgw1D5ChdgNGeo3bW89s2fx2QGgyIZ7J60QZAbDxxjchHWfSI2j5GV-3MW2nWPWJtKi7gmzvziapBNNcAOISfx4le7AuSMP47AVQ8wc0xX-6W8zy4UCZp3N6BXeKhUx45XMbdT-cLlLPhznsdtkP7NBG3K-kzoZ6CLi4aEuAredzyKPDD2KrrkndPG_KgvfdmMD8sUIqg&authuser=0'
                              download
                            >
                              คู่มือการเรียกใช้ API Trade Analytics
                            </Link>
                          </Menu.Item>
                          <Menu.Item
                            key={13}
                            onClick={() =>
                              window.open(
                                "https://drive.google.com/file/d/1Qo3ZfaFBeZ0lPdUDEFYJGIyoEIXIdQzq/view?usp=sharing",
                                "_blank"
                              )
                            }
                          >
                            คู่มือ API เป้าส่งออก
                          </Menu.Item>
                        </>
                      ) : null}
                      {ROLES.IS_ADMINISTRATOR || ROLES.IS_STAFF ? (
                        <Menu.Item onClick={() => onClose()} key={14}>
                          <Link
                            href='https://doc-0c-84-docs.googleusercontent.com/docs/securesc/trrpmgg5fngo84ir6ctl6dq8rt7h6oad/s57l2qcqqqr8nb803sjh5ikqns9ulvpn/1652151075000/14952503450886791080/09521618049316721001/1NTvqOeG-zaPR5wE9SlQyWyAXxCAfemH6?e=download&ax=ACxEAsbpWoCpzO-wn2uQXdZoOOmXUQZtcZ7ImYwkvXLGqvKidiWp4Oy3WaHqw1PThUgorWczrpaL0790K8LGc5PwPs9CpvZiYRqaPHp4-bGZawz_heg6O4vKQ84tTNA4OWcTHNsBwECenKh39tgLtwXnEL0WhTNRwsMV3tWx5fwruzMYxClCo4EpvK2s2mHZYLsPY_rASVDbU6ojYHjkVg0bTtrrM8bQEKhdjwFlZNrOGDKguRHUFIS31zigMEnPO4VIZZzLifc7zOA4dTNfe5wl-406fSCHNW_B_B2uL1DxuVQETgJeigN4fd0-gNEQwkT3_s1mzaU4Lk4DWiXet87LmKTsbCZee--TZ2wxT-NcWAZ12EKm0IN2ojmourWTunBMY0ztRocDXyJ86q5h4NOsyqfJMY7m3gRI2DIG_w8bZyHkCT7bOl-hlQp8Mupj6bKZ_VdugaWYydCL_Dcw0865TxAE19sejqhdRUJvGWvGN17uV_JMTgBkOAFvaSKSl6K76Kn1vrdkaaFmHoYmA57YZeF2LAFpq4oKwiRA7pbnXthfhuWZ2zxkKr62HglCnO6zqsjwEXPbt-EasfQgMn4LnZcLiNeeEKx0XXDfTt_f0DEXyU6ErMvoeTz_-tLK8-kGLecBwUB-_x356zd7yUne03WT0XA4S_ETPyjaPD2TPl8DFQ9y3uqi3LeKruGLfn_edomU2KmfNGzJI7TNzi_S34cWCuAgkYsA6DZ5wgviVjj8Cz8uXjOqB8uyxhf28MG7n8oZAOHgDY7_D4SXVIpRpA&authuser=0'
                            download
                          >
                            คู่มือการใช้งานและบริหารระบบ
                          </Link>
                        </Menu.Item>
                      ) : null}
                    </SubMenu>
                  ) : null}
                </Menu>
              </div>
            )}
            <div className='sider-user'>
              <Menu mode='inline'>
                {userProfile &&
                  (ROLES.IS_SS0_EXPORTER ? (
                    <SubMenu
                      key='sub1'
                      title={
                        userProfile ? userProfile.displayname : "Anonymous"
                      }
                    >
                      <Menu.Item key={1} onClick={logout}>
                        ออกจากระบบ
                      </Menu.Item>
                    </SubMenu>
                  ) : (
                    <SubMenu
                      key='sub1'
                      title={
                        userProfile ? userProfile.displayname : "Anonymous"
                      }
                    >
                      <Menu.Item
                        key={1}
                        onClick={() =>
                          window.location.replace(
                            "http://activityanalytics.ditp.go.th/Identity/Account/Manage"
                          )
                        }
                      >
                        ข้อมูลส่วนตัว
                      </Menu.Item>
                      {ROLES.IS_ADMINISTRATOR ? (
                        <>
                          <Menu.Item onClick={() => onClose()} key={2}>
                            <Link to='/ScoreSetting'>ตั้งค่าการคำนวณดัชนี</Link>
                          </Menu.Item>
                          {/* <Menu.Item onClick={() => onClose()} key={3}>
                          <Link to="/ResultDelay">ตั้งค่าการหน่วงผลลัพธ์</Link>
                        </Menu.Item>
                        <Menu.Item onClick={() => onClose()} key={4}>
                          <Link to="/StatusSMS">ตรวจสอบ SMS</Link>
                        </Menu.Item> */}
                          <Menu.Item
                            key={5}
                            onClick={() =>
                              window.location.replace(
                                "http://activityanalytics.ditp.go.th/Admin"
                              )
                            }
                          >
                            จัดการสิทธ์ผู้ใช้
                          </Menu.Item>
                        </>
                      ) : null}

                      <Menu.Item key={6} onClick={logout}>
                        ออกจากระบบ
                      </Menu.Item>
                    </SubMenu>
                  ))}
              </Menu>
            </div>
          </Drawer>
        </div>
      </div>
    </>
  );
}
