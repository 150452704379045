import { useState, useEffect } from 'react';

const useScreenSize = () => {
    const [screenSize, setScreenSize] = useState('');

    const handleResize = () => {
        const width = window.innerWidth;
        let screenSizeCurrent = 'laptop';

        if (width < 768) {
            screenSizeCurrent = 'mobile';
        } else if (width < 1200) {
            screenSizeCurrent = 'tablet';
        } else if (width < 1500) {
            screenSizeCurrent = 'laptop';
        } else {
            screenSizeCurrent = 'desktop';
        }

        setScreenSize(screenSizeCurrent);
    };

    useEffect(() => {
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return screenSize;
};

export default useScreenSize;
