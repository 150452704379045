import { Card, Tabs } from "antd";
import "../assets/CSS/mod_scoresetting.css";
import React, { useState, useEffect } from "react";
import Scoreweight from "../components/Scoreweight";
import { domainName } from "../resources/actions";
import axios from "axios";

const { TabPane } = Tabs;


export default function ScoreSetting() {
  const [defScore, setDefScore] = useState();
  async function getDefScoreValue() {
    await axios
      .get(`${domainName}/score-setting/`, { withCredentials: true })
      .then((res) => setDefScore(res.data[0]))
      .catch((err) => console.log(err));
  }

  useEffect(() => {
    getDefScoreValue();
  }, []);


  function callback(key) {
    console.log(key);
  }

  return (
    <div className="debug">
      {/* <h2>ตั้งค่าการคำนวณดัชนี</h2>
      <TextArea rows={11} onBlur={(e) => handlechange(e)} defaultValue={json} />
      <Button onClick={buttonClick}>Download</Button> */}
      <Card style={{ height: "100%", padding: "1.5rem" }}>
        <Tabs defaultActiveKey="1" onChange={callback}>
          {/* <TabPane tab="ตั้งค่าเป้า (Target)" key="target">
            {defScore ? <TargetSetting scoreTarget={defScore.target} /> : null}
          </TabPane> */}
          <TabPane tab="ค่าน้ำหนัก" key="weight">
            {defScore ? <Scoreweight defScore={defScore} /> : null}
          </TabPane>
        </Tabs>
      </Card>
    </div>
  );
}
