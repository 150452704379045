import React from "react";
import Chart from "chart.js/auto";

const optionsChart = {
  responsive: true,
  plugins: {
    legend: {
      display: true,
      position: "bottom",
      align: "start",
    },
  },
};

function BarChart({ data, config = optionsChart, ...props }) {

  const initializeChart = (model) => {
    const chartInstance = new Chart(
      document.getElementById("bar-canvas").getContext("2d"),
      model
    );

    return chartInstance;
  };

  // watching on ExporterTable selection rows
  React.useEffect(() => {
    const model = { type: "line", data: data, options: config };
    const instance = initializeChart(model);
    return () => instance.destroy();
  }, [data]);

  return (
    <>
      <div id='chart-container' className={"cssClass" + " h-[300px]"}>
        <canvas id='bar-canvas'></canvas>
      </div>
    </>
  );
}

export default BarChart;
