import React from "react";
import { Card, Typography, Button } from "antd";
import Loading from "./Loading";
const { Text } = Typography;

export default function ActivityDetail(props) {
  const hasActivityDetailUrl = !!props?.details?.RegisterActivityURL;
  // console.log(props);
  const getActivityDetailLink = (linkUrl) => {
    window.open(linkUrl, "_blank");
  };

  const waitADtail = () => {
    if (props?.details) {
      return (
        <Loading spinning={props.isLoading}>
          <Card
            // style={{
            //   height: "100%",
            // }}
            bodyStyle={{
              minHeight: "50vh",
              maxHeight: "80vh",
              overflowY: "scroll"
              // overflow: "hidden",
            }}
            title={<Text>{props?.details?.Activity_NameTH}</Text>}
            actions={[<Button
              // block
              type={"primary"}
              disabled={!hasActivityDetailUrl}
              style={{ borderRadius: "8px", width: "40%" }}
              onClick={() => getActivityDetailLink(props?.details?.RegisterActivityURL)}>
              รายละเอียดกิจกรรม
            </Button>]}
          >
            <div className="table py-11 px-3 gap-1">
              <div className="t-row text-sm">
                <div className="t-row l">
                  รหัสกิจกรรม <Text>:</Text>
                </div>
                <div className="t-row r">{props?.details?.Activity_Id || "ไม่ระบุ"}</div>
              </div>

              <div className="t-row text-sm">
                <div className="t-row l">
                  ประเภทของกิจกรรม <Text>:</Text>
                </div>
                <div className="t-row r">{props?.details?.Activity_TypeNameTH || "ไม่ระบุ"}</div>
              </div>

              {/* <div className="t-row text-sm">
              <div className="t-row l">
                กลุ่มของกิจกรรม <Text>:</Text>
              </div>
              <div className="t-row r">{props?.details?.ActivityGroup || "ไม่ระบุ"}</div>
            </div> */}

              <div className="t-row text-sm">
                <div className="t-row l">
                  หน่วยงานที่จัด <Text>:</Text>
                </div>
                <div className="t-row r">{props?.details?.Department_Name_Th || "ไม่ระบุ"}</div>
              </div>

              <div className="t-row text-sm">
                <div className="t-row l">
                  ประเทศที่จัด <Text>:</Text>
                </div>
                <div className="t-row r">{props?.details?.CountryNameTH || "ไม่ระบุ"}</div>
              </div>

              <div className="t-row text-sm">
                <div className="t-row l">
                  สถานที่จัด <Text>:</Text>
                </div>
                <div className="t-row r">{props?.details?.Location_Name_Th || "ไม่ระบุ"}</div>
              </div>

              <div className="t-row text-sm">
                <div className="t-row l">
                  วันที่เริ่มกิจกรรม <Text>:</Text>
                </div>
                <div className="t-row r">
                  {props?.details?.Activity_Start_Date.substring(0, 10)}
                </div>
              </div>

              <div className="t-row text-sm">
                <div className="t-row l">
                  วันที่สิ้นสุดกิจกรรม <Text>:</Text>
                </div>
                <div className="t-row r">
                  {props?.details?.Activity_End_Date.substring(0, 10)}
                </div>
              </div>

              <div className="t-row text-sm">
                <div className="t-row l">
                  จำนวนผู้ประกอบการที่เข้าร่วม <Text>:</Text>
                </div>
                <div className="t-row r">
                  {props?.details?.count_exporter
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </div>
              </div>
            </div>
          </Card>
        </Loading>
      );
    } else {
      return (
        <Card
          bodyStyle={{
            height: "60vh", 
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center" }}
        >
          <p className="text-primary-grey-dark">
            คลิกชื่อกิจกรรมที่ท่านต้องการดูรายละเอียด
          </p>
        </Card>
      );
    }
  };

  return <div className="detail lg:h-[60vh]">
    <Loading spinning={props.isLoading} overrideCss={{ justifyContent : "flex-start"}}>
      {waitADtail()}
    </Loading>
  </div>;
}
