import { combineReducers } from "redux";
import { ACTIONS_TYPE } from "../variables";
import reducer from "./reducers";

const reducers = combineReducers({
  exporter: reducer,
  exporterMovement: reducer,
  exporterMovementSummary: reducer,
  [ACTIONS_TYPE.SUMMARY_GROUP]: reducer,
  [ACTIONS_TYPE.SUMMARY_BY_ROWS]: reducer,
  [ACTIONS_TYPE.TEXT_EXTRA_FILTER]: reducer,
  detail: reducer,
  filter_input: reducer,
  activity: reducer,
  [ACTIONS_TYPE.ACTIVITY_DETAIL]: reducer,
  [ACTIONS_TYPE.ACTIVITY_COMPARE_LIST]: reducer,
  potential: reducer,
  suggestActivity: reducer,
  suggestDetail: reducer,
  potential_detail: reducer,
  potential_Country: reducer,
  user: reducer,
  filter: reducer,
  compare: reducer,
  exporterData: reducer,
  statusSMS: reducer,
  smsActivity: reducer,
  updateExporter: reducer,
  statusSetting: reducer,
  weightSetting: reducer,
  delayStatus: reducer,
  exporter_statistics: reducer,
  time_data: reducer,
  searchExporter: reducer,
});

export default reducers;
