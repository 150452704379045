import React from "react";
import { Spin } from "antd";


const CSS_STYLES = {
  height: "100%",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
};

export default function Loading({
  message = "กำลังโหลดข้อมูล",
  overrideCss = CSS_STYLES,
  spinSize = "large",
  ...props
}) {
  return (
    <div style={{ ...CSS_STYLES, ...overrideCss }}>
      <Spin tip={message} size={spinSize} {...props}>
        {props.children}
      </Spin>
    </div>
  );
}

