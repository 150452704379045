import React from "react";
import { Radar } from "react-chartjs-2";

export default function SelfRadarChart(props) {
  const list_name = [
    "Activity_LevelStart",
    "Activity_LevelMiddle",
    "Activity_LevelEnd",
    "payment_amt",
    "CountPMAward",
    "CountTMark",
    "CountDMark",
    "ExportValueBaht",
    "ExportGrowth",
    "ExportCountCountry",
    "CountHSCode6",
    "ExportYear",
  ];

  return (
    <div className="radar-chart">
      {console.log("props", props.selected)}
      <Radar
        data={{
          labels: [
            "จำนวนกิจกรรมต้นน้ำที่เข้าร่วม",
            "จำนวนกิจกรรมกลางน้ำที่เข้าร่วม",
            "จำนวนกิจกรรมปลายน้ำที่เข้าร่วม",
            "ต้นทุนในการเข้าร่วมกิจกรรม",
            "จำนวนรางวัล PM Award ที่ได้รับ",
            "จำนวนตรามาตรฐาน T-Mark ที่ได้รับ",
            "จำนวนตราสัญลักษณ์ Demark ที่ได้รับ",
            "ยอดส่งออก",
            "อัตตราการเติมโตยอดส่งออก",
            "จำนวนตลาดส่งออก",
            "ความหลากหลายของสินค้า",
            "จำนวนปี(ประสบการณ์)ที่เคยส่งออก",
          ],
          // labels: [
          //   "ExportValueBaht",
          //   "ExportGrowth",
          //   "ExportCountCountry",
          //   "payment_amt",
          //   "CountHSCode6",
          //   "CountPMAward",
          //   "CountTMark",
          //   "CountDMark",
          //   "Activity_LevelStart",
          //   "Activity_LevelMiddle",
          //   "Activity_LevelEnd",
          // ],
          datasets: [
            {
              label: "ค่าดัชนีของผู้ประกอบการที่เลือก",
              // data: [38, 58, 60, 39, 50, 47, 40, 40, 45, 30, 40],
              data: props.selected
                ? list_name.map((item) => {
                    console.log("iteminRadar", props.selected[item]);
                    return props.selected[item];
                  })
                : [],
              fill: true,
              backgroundColor: "rgba(0, 132, 0, 0.2)",
              borderColor: "rgba(0, 132, 0)",
              pointBackgroundColor: "rgba(0, 132, 0, 0.2)",
              pointBorderColor: "#fff",
              pointHoverBackgroundColor: "#fff",
              pointHoverBorderColor: "rgba(0, 132, 0, 0.2)",
            },
            {
              label: "ค่าเฉลี่ยของกลุ่ม",
              // data: [28, 38, 30, 27, 30, 37, 30, 40, 40, 25, 26],
              data: list_name.map((item) => {
                return props.mean[item];
              }),
              fill: true,
              backgroundColor: "rgba(255, 99, 132, 0.2)",
              borderColor: "rgb(255, 99, 132)",
              pointBackgroundColor: "rgb(255, 99, 132)",
              pointBorderColor: "#fff",
              pointHoverBackgroundColor: "#fff",
              pointHoverBorderColor: "rgb(255, 99, 132)",
            },
            {
              label: "ค่าสูงสุดของกลุ่ม",
              // data: [50, 59, 90, 81, 56, 55, 50, 70, 60, 50, 60],
              data: list_name.map((item) => {
                return props.max[item];
              }),
              fill: true,
              backgroundColor: "rgba(54, 162, 235, 0.2)",
              borderColor: "rgb(54, 162, 235)",
              pointBackgroundColor: "rgb(54, 162, 235)",
              pointBorderColor: "#fff",
              pointHoverBackgroundColor: "#fff",
              pointHoverBorderColor: "rgb(54, 162, 235)",
            },
          ],
        }}
        height={350}
        width={360}
        options={{
          maintainAspectRatio: false,
          responsive: true,
          scales: {
            r: {
              pointLabels: {
                font: {
                  size: 12,
                },
              },
            },
          },
        }}
      />
    </div>
  );
}
