import axios from "axios";
// import { useDispatch } from "react-redux";
import { ACTIONS_TYPE } from "../variables";
// import { useLocation } from "react-router-dom";
// import download from "downloadjs";
// require("downloadjs")(data, strFileName, strMimeType);

// export const domainName = "http://analytics.ditp.go.th:8000";

export const instance = axios.create({
  withCredentials: true,
});

export const domainName = process.env.REACT_APP_BACKEND_URL;

export const setActivityComparisonLocal = (activities = []) => {
  console.log(activities);
  localStorage.setItem("actvitiy_compare", JSON.stringify(activities));
};

export const setActivityComparison = (payload) => {
  console.log(payload);
  return (dispatch) => {
    dispatch({
      type: ACTIONS_TYPE.ACTIVITY_COMPARE_LIST,
      payload,
    });
  };
};

export const setTextExtraFilter = (payload) => {
  // console.log({
  //   type: ACTIONS_TYPE.TEXT_EXTRA_FILTER,
  //   payload
  // });
  localStorage.setItem(
    [ACTIONS_TYPE.TEXT_EXTRA_FILTER],
    JSON.stringify(payload)
  );

  // return (dispatch) => {
  //   dispatch(obj)
  // }
  return (dispatch) => {
    dispatch({
      type: ACTIONS_TYPE.TEXT_EXTRA_FILTER,
      payload: payload,
    });
  };
};

export const postExporter = (payload) => {
  // console.log(`[actions.postExporter]: input-filter `, payload);
  return (dispatch) => {
    // set for loading icon while wait data
    dispatch({
      type: "exporter",
      payload: undefined,
    });

    instance
      .post(`${domainName}/exporter/filter_new/`, payload)
      .then((res) => {
        console.log("response", res.data);
        // alert("ได้รับข้อมูลแล้ว");
        dispatch({
          type: "exporter",
          payload: res.data,
        });
      })
      .catch((err) => {
        console.log(err);
        const intialTable = {
          score_table: [],
          statistic_score: {
            min: [],
            mean: [],
            max: [],
          },
        };
        dispatch({
          type: "exporter",
          payload: intialTable,
        });
      });
  };
};

/**
 * get 'summary-statistics' group by filter from filter input components
 * @param {object} payload object is contains value from Filters
 * @returns callback dispatch (redux-thunk)
 */
export const getSummaryGroupByFilters = (payload) => {
  // console.log(`[action.getSummaryGroupByFilters] fetching api ...`);
  return (dispatch) => {
    // SETTINGS COMPONENTS ARE USING THIS STATE LOADING
    dispatch({
      type: ACTIONS_TYPE.SUMMARY_GROUP,
      payload: undefined,
    });

    // request for data
    instance
      .post(`${domainName}/exporter/summary_group/`, payload)
      .then((response) =>
        dispatch({ type: ACTIONS_TYPE.SUMMARY_GROUP, payload: response.data })
      )
      .catch((err) => {
        console.log(err);
        const initialData = {
          export_product: {
            min: 0.0,
            mean: 0,
            max: 0,
          },
          export_country: {
            min: 0.0,
            mean: 0,
            max: 0,
          },
          export_value: {
            min: 0.0,
            mean: 0,
            max: 0,
          },
          cagr: {
            min: 0.0,
            mean: 0.0,
            max: 0.0,
          },
          num_company: {
            all: 0,
            S: 0,
            M: 0,
            L: 0,
            non_export: 0,
          },
        };

        dispatch({ type: ACTIONS_TYPE.SUMMARY_GROUP, payload: initialData });
      });
  };
};

export const getExporterMovement = (payload) => {
  return (dispatch) => {
    // set for loading icon while wait data
    dispatch({
      type: "exporterMovement",
      payload: undefined,
    });

    // console.log();

    instance
      .post(`${domainName}/exporter/movement/`, payload)
      .then((res) => {
        console.log("response", res.data);
        dispatch({
          type: "exporterMovement",
          payload: res.data,
        });
      })
      .catch((err) => console.log(err));
  };
};

export const getExporterMovementSummary = (payload) => {
  return (dispatch) => {
    // set for loading icon while wait data
    dispatch({
      type: "exporterMovementSummary",
      payload: undefined,
    });

    // console.log();

    instance
      .post(`${domainName}/exporter/movement_summary/`, payload)
      .then((res) => {
        console.log("response", res.data);
        dispatch({
          type: "exporterMovementSummary",
          payload: res.data,
        });
      })
      .catch((err) => console.log(err));
  };
};

export const postExporterStatistics = (payload) => {
  return (dispatch) => {
    // set for loading icon while wait data
    dispatch({
      type: "exporter_statistics",
      payload: undefined,
    });

    // console.log("calll");

    instance
      .post(`${domainName}/exporter/statistics/`, payload)
      .then((res) => {
        console.log("response..", res.data);
        dispatch({
          type: "exporter_statistics",
          payload: res.data,
        });
      })
      .catch((err) => console.log(err));
  };
};

export const getDetail = (params) => {
  return (dispatch) => {
    instance
      .get(`${domainName}/exporter/` + params)
      .then((res) => {
        localStorage.setItem("detail", JSON.stringify(res.data));
        // console.log("finish   getDetail");
        dispatch({
          type: "details",
          payload: res.data,
        });
      })
      .catch((err) => console.log(err));
  };
};

export const setFilter = (payload) => {
  // console.log("action.setFilter is called: ", payload);
  return (dispatch) => {
    dispatch({
      type: "inputfilter",
      payload: payload,
    });
  };
};

export const getActivity = (payload) => {
  return (dispatch) => {
    dispatch({
      type: "activity",
      payload: undefined,
    });

    // set initial state for 'activity-detail'
    dispatch({
      type: ACTIONS_TYPE.ACTIVITY_DETAIL,
      payload: {
        isLoading: false,
        data: undefined,
      },
    });

    instance
      .post(`${domainName}/activity/filter/`, payload)
      .then((res) => {
        const new_data = res.data.map((data) => {
          return { ...data, key: data.Activity_id };
        });
        dispatch({
          type: "activity",
          payload: new_data,
        });
      })
      .catch((err) => console.log(err));
  };
};

export const getActivityDetail = (params) => {
  return (dispatch) => {
    if (params === undefined) {
      dispatch({
        type: ACTIONS_TYPE.ACTIVITY_DETAIL,
        payload: {
          data: undefined,
          isLoading: false,
        },
      });
    } else {
      dispatch({
        type: ACTIONS_TYPE.ACTIVITY_DETAIL,
        payload: {
          data: undefined,
          isLoading: true,
        },
      });

      instance
        .get(`${domainName}/activity/` + params)
        .then((res) => {
          localStorage.setItem("activityDetail", JSON.stringify(res.data));
          dispatch({
            type: ACTIONS_TYPE.ACTIVITY_DETAIL,
            payload: {
              data: res.data,
              isLoading: false,
            },
          });
        })
        .catch((err) => console.log(err));
    }
  };
};

export const postPotentialP = (payload) => {
  return (dispatch) => {
    dispatch({
      type: "potentialP",
      payload: undefined,
    });
    instance
      .post(`${domainName}/potential/product/`, payload)
      .then((res) => {
        dispatch({ type: "potentialP", payload: res.data });
      })
      .catch((err) => console.log(err));
  };
};

export const postPotentialC = (payload) => {
  return (dispatch) => {
    dispatch({
      type: "potentialC",
      payload: undefined,
    });
    instance
      .post(`${domainName}/potential/country/`, payload)
      .then((res) => {
        dispatch({ type: "potentialC", payload: res.data });
      })
      .catch((err) => console.log(err));
  };
};

export const getSuggestActivity = (payload) => {
  return (dispatch) => {
    dispatch({
      type: "suggestActivity",
      payload: undefined,
    });
    instance
      .post(`${domainName}/activity/suggest/`, payload)
      .then((res) => {
        dispatch({ type: "suggestActivity", payload: res.data });
        // localStorage.setItem("suggestActivity", JSON.stringify(res.data));
      })
      .catch((err) => console.log(err))
      .finally(() =>
        console.log(`fetching data 'suggest-activity is successful`)
      );
  };
};

export const getSuggestDetail = (params) => {
  return (dispatch) => {
    dispatch({
      type: "suggestDetail",
      payload: undefined,
    });

    instance
      .get(`${domainName}/activity/` + params)
      .then((res) => {
        localStorage.setItem("activityDetail", JSON.stringify(res.data));
        dispatch({
          type: "suggestDetail",
          payload: res.data,
        });
      })
      .catch((err) => console.log(err));
  };
};

export const postPotentialPDetail = (payload) => {
  return (dispatch) => {
    dispatch({
      type: "potentialDetail",
      payload: payload,
    });
  };
};

export const postPotentialCDetail = (payload) => {
  console.log("postPotentialCDetail", payload);
  return (dispatch) => {
    dispatch({
      type: "potentialCountry",
      payload: payload,
    });
  };
};

export const getFilter = () => {
  return (dispatch) => {
    instance.get(`${domainName}/filter/`).then((res) => {
      localStorage.setItem("filter", JSON.stringify(res.data));
      dispatch({
        type: "filter",
        payload: res.data,
      });
    });
  };
};

export const compareActivity = (payload) => {
  return (dispatch) => {
    instance
      .get(
        `${domainName}/activity/compare/?q=${payload.stringData}&startdate=${payload.startdate}&enddate=${payload.enddate}`
      )
      .then((res) => {
        dispatch({
          type: "compareActivity",
          payload: res.data,
        });
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };
};

export const getExporterData = (payload) => {
  return (dispatch) => {
    instance
      .get(`${domainName}/exporter/${payload}/`)
      .then((res) => {
        dispatch({ type: "exporterData", payload: res.data });
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };
};

export const upDateStatusSMS = (payload) => {
  return (dispatch) => {
    instance
      .post(`${domainName}/sms/update_status/`, payload)
      .then((res) => {
        dispatch({ type: "statusSMS", payload: res.data });
      })
      .catch((err) => console.log("Error", err));
  };
};

export const smsActivityId = (payload) => {
  return (dispatch) => {
    instance
      .get(`${domainName}/sms/activity/?activity_id=${payload}`)
      .then((res) => {
        dispatch({ type: "smsActivity", payload: res.data[0] });
      })
      .catch((err) => console.log("Error", err));
  };
};

export const upDateExporter = (payload) => {
  return (dispatch) => {
    instance
      .post(`${domainName}/sms/update_exporter/`, payload)
      .then((res) => {
        dispatch({ type: "updateExporter", payload: res.data });
      })
      .catch((err) => console.log("Error", err));
  };
};

export const settingScore = (payload) => {
  return (dispatch) => {
    dispatch({
      type: "settingScore",
      payload: undefined,
    });
    instance
      .post(`${domainName}/score-setting/target/`, payload)
      .then((res) => {
        dispatch({ type: "settingScore", payload: res.status });
      });
  };
};

export const weightSetting = (id, payload) => {
  return (dispatch) => {
    dispatch({
      type: "weightSetting",
      payload: undefined,
    });
    instance
      .patch(`${domainName}/score-setting/${id}/`, payload)
      .then((res) => {
        dispatch({ type: "weightSetting", payload: res.status });
      });
  };
};

export const delaySetting = (payload) => {
  return (dispatch) => {
    dispatch({
      type: "delayStatus",
      payload: undefined,
    });
    instance
      .post(`${domainName}/score-setting/update_delay/`, payload)
      .then((res) => {
        dispatch({ type: "delayStatus", payload: res.status });
      });
  };
};

export const getTimeData = () => {
  return (dispatch) => {
    // dispatch({
    //   type: "timeData",
    // })
    instance.get(`${domainName}/potential/data_range_date/`).then((res) => {
      dispatch({
        type: "timeData",
        payload: res.data,
      });
    });
  };
};

export const getSearchExporter = (searchText) => {
  return (dispatch) => {
    instance.get(`${domainName}/exporter?search=${searchText}`).then((res) => {
      dispatch({
        type: "searchExporter",
        payload: res.data.results,
      });
    });
  };
};
