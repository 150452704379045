import React from "react";
import { Card, Typography, Button } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../resources/index";
import services from "../../resources/variables/services";

const { Text } = Typography;

export default function PotentialPDetail(props) {
  let history = useHistory();
  const dispatch = useDispatch();
  const actions = bindActionCreators(actionCreators, dispatch);
  const { inputfilter, Potentialetail, textExtraFilter } = useSelector((state) => ({
    inputfilter: state?.filter_input?.filter_input,
    Potentialetail: state?.potential_detail?.potential_detail,
    textExtraFilter: state?.text_extra_filter?.text_extra_filter
  }));
  const filter = JSON.parse(localStorage.getItem("filter"));

  const calPotential = () => {
    const sum =
      Potentialetail.product.potential - Potentialetail.product.actual_value;
    // return sum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return sum;
  };

  const calPercent = () => {
    const percent =
      (1 -
        Potentialetail.product.actual_value /
        Potentialetail.product.potential) *
      100;
    return percent.toFixed(1);
  };

  const activityJoin = (data) => {
    console.log("ActivityThat", data);
    const ditpProductObjList = [];
    services.listGenerator(filter.ditp_product, ditpProductObjList);
    const productObj = ditpProductObjList.find(obj => obj.title == data.key);
    let productKeys = [];
    let productNames = [];

    if (String(data.key).toLowerCase() === 'all') {
      const ditpProductFiltered = ditpProductObjList.filter(product => product.key.startsWith('leaf'));
      productKeys = ditpProductFiltered.map(product => product.key);
      productNames = ditpProductFiltered.map(product => product.title);
    }
    else if (productObj && productObj?.key.startsWith("leaf")) {
      productKeys.push(productObj.key);
      productNames.push(productObj.title);
    }
    else if (productKeys && !productObj?.key.startsWith("leaf")) {
      const ditpProductFiltered = ditpProductObjList
        .filter(product => product.key.includes(productObj.key))
        .filter(product => product.key.startsWith(`leaf-${productObj.key}-`));
      productKeys = ditpProductFiltered.map(product => product.key);
      productNames = ditpProductFiltered.map(product => product.title);
    }
    // console.log(productObj);
    // console.log(productKeys, productNames);
    // console.log(textExtraFilter);

    const updatedTextExtra = {
      ...textExtraFilter,
      activity: {
        ...textExtraFilter.activity,
        product_activity: {
          ellipsisText: String(data.key).toLowerCase() === 'all' ? "ทั้งหมด" : services.formatArrayWithEllipsis(productNames),
          fullText: productNames.join(", ")
        }
      }
    };

    const updatedFilterPayload = {
      ...inputfilter,
      product_activity: {
        ...inputfilter.product_activity,
        value: productKeys,
      },
    };

    actions.setTextExtraFilter(updatedTextExtra);
    actions.setFilter(updatedFilterPayload);
    history.push("/Activity");
  };

  const suggestActivity = (data) => {
    console.log("SuggestThat", data);

    const ditpProductObjList = [];
    services.listGenerator(filter.ditp_product, ditpProductObjList);
    const productObj = ditpProductObjList.find(obj => obj.title == data.key);
    let productKeys = [];
    let productNames = [];

    if (String(data.key).toLowerCase() === 'all') {
      console.log("[all]");
      const ditpProductFiltered = ditpProductObjList.filter(product => product.key.startsWith('leaf'));
      productKeys = ditpProductFiltered.map(product => product.key);
      productNames = ditpProductFiltered.map(product => product.title);
    }
    else if (productObj && productObj?.key.startsWith("leaf")) {
      productKeys.push(productObj.key);
      productNames.push(productObj.title);
    }
    else if (productKeys && !productObj?.key.startsWith("leaf")) {
      const ditpProductFiltered = ditpProductObjList
        .filter(product => product.key.includes(productObj.key))
        .filter(product => product.key.startsWith(`leaf-${productObj.key}-`));
      productKeys = ditpProductFiltered.map(product => product.key);
      productNames = ditpProductFiltered.map(product => product.title);
    }

    console.log(productObj);
    console.log(productKeys, productNames);
    console.log(textExtraFilter);

    const updatedTextExtra = {
      ...textExtraFilter,
      activity: {
        ...textExtraFilter.activity,
        product_activity: {
          ellipsisText: String(data.key).toLowerCase() === 'all' ? "ทั้งหมด" : services.formatArrayWithEllipsis(productNames),
          fullText: productNames.join(", ")
        }
      }
    };

    const updatedFilterPayload = {
      ...inputfilter,
      product_activity: {
        ...inputfilter.product_activity,
        value: productKeys,
      },
    };

    actions.setTextExtraFilter(updatedTextExtra);
    actions.setFilter(updatedFilterPayload);
    history.push("/SuggestActivity");
  };

  const dateDetails = (date) => {
    if (date) {
      const [year, mount, day] = date.split('-');
      const th_Mount = {
        "01": "ม.ค.",
        "02": "ก.พ.",
        "03": "มี.ค.",
        "04": "เม.ย.",
        "05": "พ.ค.",
        "06": "มิ.ย.",
        "07": "ก.ค.",
        "08": "ส.ค.",
        "09": "ก.ย.",
        "10": "ต.ค.",
        "11": "พ.ย.",
        "12": "ธ.ค.",
      };

      return {
        year_detail: parseInt(year) + 543,
        mount_detail: th_Mount[mount]
      };
    }
  };

  const waitDetail = () => {
    if (Potentialetail) {
      const currentYear = new Date().getFullYear();
      return (
        <Card
          bodyStyle={{
            minHeight: '50vh'
          }}
          title={
            <Text>
              มูลค่าส่งออกปี {dateDetails(props.year?.start_date)?.year_detail} เดือน {dateDetails(props.start_mount)?.mount_detail} - {dateDetails(props.end_mount)?.mount_detail} <br /> สินค้า :{" "}
              {/* มูลค่าส่งออกปี {currentYear+543} เดือน ม.ค. - มี.ค. <br /> สินค้า :{" "} */}
              {Potentialetail.product ? Potentialetail.product.data.key : null}
            </Text>
          }
          actions={[
            <div className="flex flex-col items-center gap-3">
              <Button
                onClick={() => activityJoin(Potentialetail.product.data)}
                style={{ width: "50%", borderRadius: "8px" }}
              >
                กิจกรรมที่เคยเข้าร่วม
              </Button>
              <Button
                onClick={() => suggestActivity(Potentialetail.product.data)}
                style={{ width: "50%", borderRadius: "8px" }}
              >
                กิจกรรมที่แนะนำ
              </Button>
            </div>,
          ]}
        >
          {Potentialetail.product ? (
            <div className="table p-4">
              <div className="t-row text-sm">
                <div className="t-row l">
                  ศักยภาพการส่งออก <Text>:</Text>
                </div>
                <div className="t-row r">
                  {Potentialetail.product.potential > 999 &&
                    Potentialetail.product.potential < 1000000
                    ? (Potentialetail.product.potential / 1000)
                      .toFixed()
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " แสนบาท"
                    : Potentialetail.product.potential > 1000000
                      ? (Potentialetail.product.potential / 1000000)
                        .toFixed()
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " ล้านบาท"
                      : null}
                  {/* {Potentialetail.product.potential
                    ? (Potentialetail.product.potential / 1000000)
                        .toFixed()
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " ล้านบาท"
                    : null} */}
                </div>
              </div>
              {console.log(Potentialetail.product.actual_value)}
              <div className="t-row text-sm">
                <div className="t-row l">
                  มูลค่าส่งออกจริง <Text>:</Text>
                </div>
                <div className="t-row r">
                  {Potentialetail.product.actual_value > 999 &&
                    Potentialetail.product.actual_value < 1000000
                    ? (Potentialetail.product.actual_value / 1000)
                      .toFixed()
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " แสนบาท"
                    : Potentialetail.product.actual_value > 1000000
                      ? (Potentialetail.product.actual_value / 1000000)
                        .toFixed()
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " ล้านบาท"
                      : null}
                  {/* {Potentialetail.product.actual_value
                    ? (Potentialetail.product.actual_value / 1000000)
                        .toFixed()
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " ล้านบาท"
                    : null} */}
                </div>
              </div>

              <div className="t-row text-sm">
                <div className="t-row l">
                  ศักยภาพที่ยังส่งออกได้ <Text>:</Text>
                </div>
                <div className="t-row r">
                  {/* { calPotential() < 0 ? "ไม่มี" :
                    calPotential() > 999 && calPotential() < 1000000
                    ? (calPotential() / 1000)
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
                      " แสนบาท".replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
                      " แสนบาท"
                    : calPotential() > 1000000
                    ? (calPotential() / 1000000)
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " ล้านบาท"
                    : null} */}
                  {calPotential() < 0
                    ? "ส่งออกเกินค่าศักยภาพจริง"
                    : calPotential() > 999 && calPotential() < 1000000
                      ? (calPotential() / 1000)
                        .toFixed()
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
                      " แสนบาท".replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
                      " แสนบาท"
                      : calPotential() > 1000000
                        ? (calPotential() / 1000000)
                          .toFixed()
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " ล้านบาท"
                        : null}
                  <br />
                  {calPercent() < 0 ? null : `(${calPercent()}%)`}
                </div>
              </div>
              {/* <div className="t-row text-sm">
              <div className="t-row l">
                ส่งออกแล้ว <Text>:</Text>
              </div>
              <div className="t-row r">
                {calPotential()} {"บาท"}
              </div>
            </div> */}
            </div>
          ) : null}
        </Card>
      );
    } else {
      return (
        <Card
          style={{
            height: 700,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <div className="text-lg text-primary-grey-dark text-center w-full">
            คลิกที่กล่องข้อมูลที่ต้องการดูรายละเอียด
          </div>
        </Card>
      );
    }
  };

  return <div className="potential-product-detail">{waitDetail()}</div>;
}
