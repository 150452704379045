// ---- Library/Module ----
import { useState, useEffect } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import { bindActionCreators } from "redux";
import { useDispatch } from "react-redux";

// ---------- Services ----------
import AuthService from "./services/auth.service";
import { actionCreators } from "./resources/index";

// ---------- Styles ----------
import "./App.css";
import "./assets/CSS/Responsive.css";
import "./assets/CSS/etc.css";
import "./assets/CSS/mod_fullDetail.css";

// ---- components ----
import { Row, Col, Layout } from "antd";
import Navbar from "./components/Navbar";
import Filter from "./components/Filter";
import ProtectedRoute from "./components/ProtectedRoute";

// ---- Import Page Components ----
import ExporterDetail from "./views/ExporterDetail";
import ActivityIndex from "./views/ActivityIndex";
import ActivityCompare from "./views/ActivityCompare";
import SuggestActivity from "./views/SuggestActivity";
import Movement from "./views/Movement";
import PotentialC from "./views/PotentialC";
import PotentialP from "./views/PotentialP";
import ScoreSetting from "./views/ScoreSetting";
import CheckSMS from "./views/CheckSMS";
import ManageSMS from "./views/ManageSMS";
import ResultDelay from "./views/ResultDelay";
import KnowledgeFullview from "./views/KnowledgeFullview";
import SelfAssessment from "./views/SelfAssessment";
import ExporterNew from "./views/ExporterNew";
import Home from "./views/Home";
import ExporterDEtailCRMPage from "./views/ExporterDetailCRMPage";
import NotFound404 from "./views/NotFound404";
import RedirectPage from "./views/RedirectPage";

import Modal from "./components/Modal";

// ---- settings/declaration ----
const { Header, Footer } = Layout;

function App() {
  // hooks
  const dispatch = useDispatch();
  const location = useLocation();
  // state
  const [user, setUser] = useState(AuthService.getCurrentUser());
  // redux
  const actions = bindActionCreators(actionCreators, dispatch);

  // define variables
  let RENDER_FILTER_COMPONENT = false; // condition render filter each page

  useEffect(() => {
    // console.log(`[useEffect] user state has changes`);
    if (!user) {
      // console.log(`[AuthService] fetchingCurrentUser`);
      setUser(AuthService.fetchCurrentUser());
    }
  }, [user]);

  useEffect(() => {
    actions.getFilter();
    actions.getTimeData();
  }, []);

  /**
   * condition for render <Filter /> component for some page
   * @param {*} param0 react useLocation() instance
   * @returns <filter /> component or null
   */
  const filterComponentByPageUrl = ({ pathname, ...location }) => {
    // page by following, no need filter component

    const PAGE_ALLOW_FILTER_LIST = [
      "/PotentialProduct",
      "/PotentialCountry",
      "/Exporter",
      "/Activity",
      "/SuggestActivity",
      "/Movement",
      "/ManageSMS",
    ];

    const isPathnameInAllowed = PAGE_ALLOW_FILTER_LIST.filter((p) => {
      const clientPathname = String(pathname).toLowerCase().trim();
      const pathnameAllowed = p.toLowerCase().trim();
      const IS_ALLOWED = clientPathname === pathnameAllowed;
      // console.log(clientPathname, pathnameAllowed, IS_ALLOWED);
      return IS_ALLOWED;
    });

    let componentFilter = null;
    if (isPathnameInAllowed.length > 0 && pathname !== "/") {
      componentFilter = (
        <div className='col-span-2 hidden h-[90vh] max-h-[90vh] xl:block xl:sticky xl:top-4'>
          <Filter />
        </div>
      );
    }

    RENDER_FILTER_COMPONENT = !!componentFilter;
    return componentFilter;
  };

  const [isModalOpen, setIsModalOpen] = useState(false); //set to true; for show service notice popup
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  return (
    <div className='App bg-slate-200 min-h-screen h-full flex flex-col'>
      {isModalOpen && <Modal isOpen={isModalOpen} onClose={handleCloseModal} />}
      {!isModalOpen && (
      <div>
        <Header>
          <Navbar />
        </Header>

        <div className='grid grid-cols-12 gap-4 p-3 pt-20 xl:pt-3 mb-auto'>
          {/* FILTER COMPONENT */}
          {filterComponentByPageUrl(location)}

          {/* MAIN COMPONENT */}
          <div
            className={
              !RENDER_FILTER_COMPONENT
                ? "col-span-full"
                : "col-span-full xl:col-span-10"
            }
          >
            <Switch>
              <ProtectedRoute exact={true} component={Home} path="/"></ProtectedRoute>
              <ProtectedRoute exact={true} component={PotentialP} path="/PotentialProduct"></ProtectedRoute>
              <ProtectedRoute exact={true} component={PotentialC} path="/PotentialCountry"></ProtectedRoute>
              <ProtectedRoute exact={true} component={RedirectPage} path="/potential-dashboard" />
              <ProtectedRoute exact={true} component={ExporterNew} path="/Exporter"></ProtectedRoute>
              <ProtectedRoute exact={true} component={ExporterDetail} path="/ExporterDetail/"></ProtectedRoute>
              <ProtectedRoute exact={true} component={ActivityIndex} path="/Activity"></ProtectedRoute>
              <ProtectedRoute exact={true} component={ActivityCompare} path="/ActivityCompare"></ProtectedRoute>
              <ProtectedRoute exact={true} component={SuggestActivity} path="/SuggestActivity"></ProtectedRoute>
              <ProtectedRoute exact={true} component={Movement} path="/Movement"></ProtectedRoute>
              <ProtectedRoute exact={true} component={KnowledgeFullview} path="/KnowledgeGraph" ></ProtectedRoute>
              <ProtectedRoute exact={true} component={ScoreSetting} path="/ScoreSetting"></ProtectedRoute>
              <ProtectedRoute exact={true} component={CheckSMS} path="/StatusSMS"></ProtectedRoute>
              <ProtectedRoute exact={true} component={ManageSMS} path="/ManageSMS"></ProtectedRoute>
              <ProtectedRoute exact={true} component={SelfAssessment} path="/SelfAssessment"></ProtectedRoute>
              <ProtectedRoute exact={true} component={ResultDelay} path="/ResultDelay"></ProtectedRoute>
              <Route exact={true} component={ExporterDEtailCRMPage} path="/crm-exporter-detail"></Route>

              <Route component={NotFound404} path='*'></Route>
            </Switch>
          </div>
        </div>

        <Footer>
          <div className='App-footer'>
            <Row>
              <Col span={24}>
                <p>
                  Department of International Trade Promotion, Ministry of
                  Commerce, Thailand
                </p>
              </Col>
              <Col span={24}>
                <p>Tel. +66 2507 8248 E-mail: sys_dev@ditp.go.th </p>
              </Col>
              <Col span={24}>
                <p>Copyright © 2023, Trade Promotion, Ministry of Commerce, </p>
              </Col>
              <Col span={24}>
                <p>Thailand All rights reserved.</p>
              </Col>
            </Row>
          </div>
        </Footer>
        </div>)}
    </div>
  );
}

export default App;
