import React from "react";

// ant components
import { Card } from "antd";

function CardBorderTriangle({ data, ...props }) {
  return (
    <div
      className={`relative hover:cursor-pointer ${props.className}`}
      onClick={props.onClick}
    >
      <div className='card-border w-full p-3 rounded-lg border-dashed border-2 border-primary'>
        <Card
          style={{ padding: "8px" }}
          className='shadow-md w-[200px] h-[200px] lg:h-[250px] md:w-[180px] md:max-w-[180px] z-10'
        >
          <div
            id='image-ring'
            className='w-fit h-fit mx-auto rounded-full  border-solid border border-primary-red'
          >
            <img
              className='w-[60px] h-[60px] object-fill  mx-auto p-2 rounded-full  border-solid border-2 border-white bg-primary-red'
              src={data?.image}
              alt='img-logo'
            />
          </div>
          <h1 className='text-center leading-none my-4'>{data.title}</h1>
          <p className='text-xs text-primary-grey-dark'>
            {data.detail}
          </p>
        </Card>

        {/* Square */}
        <div className='absolute shape-square right-0 bottom-0 rounded-md xl:rounded-lg lg:rounded-sm  bg-primary w-full h-full lg:h-[280px] xl:max-w-[140px] 2xl:w-[175px]'></div>

        {/* Triangle */}
        <div
          className='absolute rounded w-0 h-0 z-10
								-bottom-[12.65rem] right-0 left-0 xl:-right-[3rem] xl:left-auto xl:-top-1 

								border-solid 
								border-t-[50px] lg:border-t-[50px] xl:border-t-[141px] 
								border-r-[115px] md:border-r-[105px] xl:border-r-[0px] 
								border-b-[155px] lg:border-b-[152px] xl:border-b-[141px] 
								border-l-[115px] md:border-l-[105px] xl:border-l-[50px]
								
								border-t-primary  border-r-transparent border-b-transparent border-l-transparent
								xl:border-t-transparent  xl:border-r-transparent xl:border-b-transparent xl:border-l-primary'
        ></div>
        <span
          className='
						absolute z-10 text-white text-xl font-bold
						right-6 left-0 -bottom-6 translate-x-2/4
						xl:-right-4 xl:left-auto xl:bottom-auto xl:top-[40%] xl:translate-y-2/4 
				'
        >
          {data.key}
        </span>
      </div>
    </div>
  );
}

export default CardBorderTriangle;
