import { useEffect, useState } from "react";
import "../assets/CSS/mod_activity.css";
import { Input } from "antd";
import ActivityTable from "../components/ActivityTable";
import ActivityDetail from "../components/ActivityDetail";
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../resources/index";
import services from "../resources/variables/services";

const { Search } = Input;

export default function ActivityIndex() {
  const dispatch = useDispatch();
  const actions = bindActionCreators(actionCreators, dispatch);
  const { activity, inputfilter, actvityDetail } = useSelector((state) => ({
    activity: state.activity,
    inputfilter: state.filter_input,
    actvityDetail: state.activity_D
  }));

  const [activityTableData, setActivityTableData] = useState([]);


  useEffect(() => {
    // if (!activity) {
      const FILTER_INPUT_RESET_ACTIVITY_ID = {
        ...inputfilter.filter_input,
        activity_id: []
      };
      actions.getActivity(FILTER_INPUT_RESET_ACTIVITY_ID);
    // }
  }, []);

  useEffect(() => {
    setActivityTableData(activity.activity);
  }, [activity.activity]);

  const onChange = (event) => {
    const { value: inputText } = event.target;
    // console.log(inputText);
    const propName = "Activity_NameTH";
    const activityFilterd = services.searchObjectByString(inputText, activity?.activity, propName);
    // console.log(activityFilterd);
    setActivityTableData(activityFilterd);
  };

  return (
    <div className="ActivityIndex relative">
      <div className="activity-content">
        <Search
          onChange={onChange}
          placeholder="ค้นหากิจกรรม. . ."
          style={{ width: "100%" }}
        />
        {/* list of activity */}
        <ActivityTable
          isLoaded={!!activity.activity}
          // activity={activity.activity}
          activity={activityTableData}
          inputfilter={inputfilter.filter_input}
        />
      </div>

      {/* component will be loading when 'onClick' event inside 'ActivityTable' component are called */}
      <div className="activity-detail lg:sticky lg:top-4 lg:h-[80vh]">
        <ActivityDetail
          isLoading={actvityDetail.activity_D?.isLoading}
          details={actvityDetail.activity_D?.data}
        />
      </div>
    </div>
  );
}
