import React from "react";
import { Typography, Divider, Table } from "antd";
import { Loading } from "../resources/variables/index";
import SelfRadarChart from "./sub-components/SelfRadarChart";

const { Title, Text } = Typography;

export default function SelfData(props) {
  return (
    <div className="self-data">
      {props.detail ? (
        <div className="self-content">
          <Title style={{ fontSize: 26 }}>ข้อมูลธุรกิจ</Title>
          <Divider />
          <div className="detail-1">
            <div className="detail-1-content">
              <div className="table">
                <div className="t-row">
                  <div className="t-row l">
                    ชื่อผู้ประกอบการ <Text>:</Text>
                  </div>
                  <div className="t-row r">
                    {props.detail.exporter_detail.Corporate_NameTH}
                  </div>
                </div>
                <div className="t-row">
                  <div className="t-row l">
                    เลขประจำตัวผู้เสียภาษี <Text>:</Text>
                  </div>
                  <div className="t-row r">
                    {props.detail.exporter_detail.Tax_No}
                  </div>
                </div>
                <div className="t-row">
                  <div className="t-row l">
                    ทุนจดทะเบียน <Text>:</Text>
                  </div>

                  <div className="t-row r">
                    {props.detail.exporter_detail.DBD_CapitalValue
                      ? `${props.detail.exporter_detail.DBD_CapitalValue.toString().replace(
                          /\B(?=(\d{3})+(?!\d))/g,
                          ","
                        )} บาท`
                      : "ไม่มีข้อมูล"}
                  </div>
                </div>
                <div className="t-row">
                  <div className="t-row l">
                    ขนาดผู้ประกอบการ <Text>:</Text>
                  </div>
                  <div className="t-row r">
                    {props.detail.exporter_detail.ComSize}
                  </div>
                </div>
                <div className="t-row">
                  <div className="t-row l">
                    ข้อมูลธุรกิจ <Text>:</Text>
                  </div>
                  <div className="t-row r">
                    {props.detail.exporter_detail.DBD_ObjectDetail
                      ? props.detail.exporter_detail.DBD_ObjectDetail
                      : "ไม่มีข้อมูล"}
                  </div>
                </div>
                <div className="t-row">
                  <div className="t-row l">
                    สถานะ <Text>:</Text>
                  </div>
                  <div className="t-row r">
                    {props.detail.exporter_detail.DBD_juristicStatus
                      ? props.detail.exporter_detail.DBD_juristicStatus
                      : "ไม่มีข้อมูล"}
                  </div>
                </div>
                <div className="t-row">
                  <div className="t-row l">
                    ภูมิภาค <Text>:</Text>
                  </div>
                  <div className="t-row r">
                    {props.detail.exporter_detail.Region_Name_Th}
                  </div>
                </div>
                <div className="t-row">
                  <div className="t-row l">
                    จังหวัด <Text>:</Text>
                  </div>
                  <div className="t-row r">
                    {props.detail.exporter_detail.ProvinceName}
                  </div>
                </div>
                <div className="t-row">
                  <div className="t-row l">
                    อีเมล <Text>:</Text>
                  </div>
                  <div className="t-row r">
                    {props.detail.exporter_detail.email
                      ? props.detail.exporter_detail.email
                      : "ไม่มีข้อมูล"}
                  </div>
                </div>
              </div>
            </div>
            <div className="graph radar">
              {props.detail.statistic_score ? (
                <SelfRadarChart
                  selected={props.detail.statistic_score.exporter}
                  mean={props.detail.statistic_score.mean}
                  max={props.detail.statistic_score.max}
                />
              ) : null}
            </div>
          </div>
          <Divider />
          <Title style={{ fontSize: 26 }}>ประวัติการเข้าร่วมกิจกรรม</Title>
          <Divider />
          <Table
            columns={props.historyCol}
            dataSource={props.data.activity_attend}
            pagination={{ pageSize: 5 }}
            size="medium"
          />
        </div>
      ) : (
        <Loading tip={"กำลังโหลดข้อมูล"} />
      )}
    </div>
  );
}
