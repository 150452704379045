import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { bindActionCreators } from "redux";
import { actionCreators } from "../resources/index";
import { ACTIONS_TYPE } from "../resources/variables";

import { Table, Button, Typography } from "antd";
import Loading from "./Loading";

const { Text } = Typography;

export default function ActivityTable(props) {
  let history = useHistory();
  const [selectedRowKeys, setSelectedRowKeys] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();
  const actions = bindActionCreators(actionCreators, dispatch);

  const ACTIVITY_COLUMNS = [
    {
      title: "ลำดับ",
      dataIndex: "index",
      width: "4.5rem",
      align: "center",
      render: (value, item, index) => (currentPage - 1) * 10 + index + 1,
    },
    {
      title: "ชื่อกิจกรรม",
      dataIndex: "Activity_NameTH",
      render: (activity, row, index) => (
        <h1
          className='text-primary hover:text-sky-800 hover:font-bold active:text-sky-600 hover:cursor-pointer'
          onClick={() => onClick(row.Activity_id)}
        >
          {activity}
        </h1>
      ),
    },
    {
      title: "ระดับกิจกรรม",
      dataIndex: "Activity_LevelName",
      render: (activityLevelName) => <Text>{activityLevelName === "กลาง-ปลาย" ? "กลางน้ำ-ปลายน้ำ" : activityLevelName}</Text>,
      filters: [
        {
          text: "ต้นน้ำ",
          value: "ต้นน้ำ",
        },
        {
          text: "กลางน้ำ",
          value: "กลางน้ำ",
        },
        {
          text: "กลางน้ำ-ปลายน้ำ",
          value: "กลาง-ปลาย",
        },
        {
          text: "ปลายน้ำ",
          value: "ปลายน้ำ",
        },
      ],
      onFilter: (value, record) => record.Activity_LevelName === value,
    },
    {
      title: "ค่าดัชนี",
      dataIndex: "score",
      width: "7.5rem",
      align: "center",
      render: (value) => {
        return <Text>{value.toFixed(2)}</Text>;
      },
      sorter: (a, b) => a.score - b.score,
    },
  ];

  /**
   * send 'activity-id' for get detail about that activity,
   * the action 'getActivityDetail' will set component 'ActivityDetail' is Loading state
   * @param {Number} id of activity  
   */
  const onClick = (activityId) => {
    actions.getActivityDetail(activityId);
  };

  const setNullCheckbox = () => {
    setSelectedRowKeys(null);
  };

  useEffect(() => {
    setCurrentPage(1);
    setNullCheckbox();
  }, [props.activity]);


  const onSelectChange = (selectedRowKeys) => {
    console.log(`[activity-analysis]:\t`, selectedRowKeys);
    setSelectedRowKeys(selectedRowKeys);
  };

  const onActivityComparison = (rowKeys) => {
    // console.log(ACTIONS_TYPE.ACTIVITY_COMPARE_LIST,);
    const activityCompareList = props.activity.filter(activity => rowKeys.includes(activity.Activity_id));
    localStorage.setItem("activity_compare", JSON.stringify(activityCompareList));
    window.open("/ActivityCompare").focus()
  };

  const gotoExporter = (selectedRowKeys) => {
    const new_data = {
      ...props.inputfilter,
      search_text: "",
      // activity_id: selectedRowKeys,
    };
    actions.setFilter(new_data);
    const activityIdSelectedString = selectedRowKeys.join(",");
    // postExporter(new_data);
    return history.push({
      pathname: "/Exporter",
      search: `?activities=${activityIdSelectedString}`
    });
  };

  /**
   * if data is fetching, show skelaton loading, then show table with data
   * @param {boolean} isLoaded status of activity-data & filter data 
   */
  const waitData = (isLoaded) => {
    return (isLoaded) ? (
      <Table
        bordered
        loading={!isLoaded}
        columns={ACTIVITY_COLUMNS}
        rowSelection={{
          selectedRowKeys,
          onChange: onSelectChange,
        }}
        dataSource={props.activity}
        pagination={{
          showSizeChanger: true,
          onChange(page) {
            setCurrentPage(page);
          },
        }}
      />
    ) : (
      <div className="h-full w-full">
        <Loading spinning={true} size="large">
          <Table
            bordered
            columns={ACTIVITY_COLUMNS}
            dataSource={[]}
          />
        </Loading>
      </div>
    );
  };

  return (
    <div className="activity-table">
      {
        waitData(props.isLoaded)
      }
      {props.inputfilter && selectedRowKeys > [1] ? (
        <div className="activity-button">
          {/* <Link
            to={`/ActivityCompare?q=${queryString(selectedRowKeys)}&startdate=${props.inputfilter.date_activity.startdate
              }&enddate=${props.inputfilter.date_activity.enddate}`}
            target="_blank"
          > */}
          <Button
            disabled={selectedRowKeys.length < 2}
            onClick={() => onActivityComparison(selectedRowKeys)}
            id="b-1"
            shape="round"
            type="primary"
          >
            เปรียบเทียบกิจกรรม
          </Button>
          {/* </Link> */}
          <Button
            onClick={() => gotoExporter(selectedRowKeys)}
            id="b-2"
            shape="round"
            type="primary"
          >
            บริษัทที่เข้าร่วม
          </Button>
        </div>
      ) : (
        <div className="activity-button">
          <Button id="b-1" shape="round" disabled={true} type="primary">
            เปรียบเทียบกิจกรรม
          </Button>
          <Button
            onClick={() => gotoExporter(selectedRowKeys)}
            id="b-2"
            shape="round"
            type="primary"
            disabled={true}
          >
            บริษัทที่เข้าร่วม
          </Button>
        </div>
      )}
    </div>
  );
}
