import React from "react";
import PropsType from "prop-types";
import { Descriptions } from "antd";
import SkeletonContentLoading from "./SkeletonContentLoading";

function Description({ title, items, loading = false, ...props }) {
  return (
    <>
      {loading ? (
        <div className="h-full w-full">
          <h1 className="font-bold text-[16px]">{title || "ข้อมูลผู้ประกอบการ"}</h1>
          <SkeletonContentLoading />
        </div>
      ) : (
        <Descriptions
          title={title || "ข้อมูลผู้ประกอบการ"}
          bordered
          size={"small"}
          column={1}
        >
          {items.map((row, index) => {
            return (
              <Descriptions.Item 
                  key={index.toString()} 
                  label={row.title}
                >
                  {row.data}
              </Descriptions.Item>
            );
          })}
        </Descriptions>
      )}
    </>
  );
}

Description.propsType = {
  title: PropsType.string,
  items: PropsType.array.isRequired,
  loading: PropsType.bool
};

export default Description;
