import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../resources/index";
import services from "../resources/variables/services";

// Components
import { Tree, Input, Typography } from "antd";
import { CaretDownFilled } from "@ant-design/icons";
const { Text } = Typography;
const { Search } = Input;

const SUB_TYPE_IS_MULTI_LEVEL = ['country_activity', 'product_activity'];   //  these subType has multi-level of tree (children)

export default function ActivityFilter({ inputfilter, onCheckedKeys, ...props }) {
    // console.log(props);
    // console.log(inputfilter);

    // All State can use
    const keyRef = useRef();
    const dispatch = useDispatch();
    const { inputfilter: filter_input } = useSelector(state => ({
        inputfilter: state?.filter_input?.filter_input
    }));
    // console.log(props.type);
    
    const actions = bindActionCreators(actionCreators, dispatch);
    const [searchValue, setSearchValue] = useState("");
    const [activityScroll, setActivityScroll] = useState("");

    const getInitialCheckedKeysByFilter = (filter) => {
        let initializeCheckedKeys = [];
        if (filter) {
            if (filter && SUB_TYPE_IS_MULTI_LEVEL.includes(props.type)) {
                initializeCheckedKeys = filter[props.type]?.value?.map(key => String(key));
            } else { 
                initializeCheckedKeys = filter[props.type]?.map(key => key);
            }
        }
        return initializeCheckedKeys;
    };

    let initializeCheckedKeys = getInitialCheckedKeysByFilter(filter_input);
    const [checkedKeys, setCheckedKeys] = useState(initializeCheckedKeys);
    const [treeFilter, setTreeFilter] = useState(props.dataFilter);
    const [treeExpand, setTreeExpand] = useState([]);

    const checkAllList = [];

    const MAX_LENGTH = {
        country_activity: 52,
        product_activity: 43,
        activity_type: 16,
        activity_keyword: 38
    };

    // Activity Type Function
    const onSearch = (e, data) => {
        const { value } = e.target;
        const textSearchKeyword = value.toLowerCase();
        const IS_NULL_KEYWORD = !(!!textSearchKeyword);
        // console.log(props.dataFilter);
        // console.log(props.type);
        if (IS_NULL_KEYWORD) {
            setTreeFilter(props.dataFilter);
            setTreeExpand([]);
        } else {
            const dataInitial = JSON.parse(JSON.stringify(props.dataFilter));
            const filteredTree = services.filterFamilyTreeByName(
                dataInitial,
                textSearchKeyword
            );
            // console.log(filteredTree);

            let parentKeyList = [];
            services.listGenerator(filteredTree, parentKeyList);
            parentKeyList = parentKeyList
                .filter(item => {
                    if (props.type === 'product_activity' || props.type === "country_activity") {
                        return !item.key.startsWith("leaf");
                    }
                    return true;
                })
                .map(item => item.key);
            // console.log(parentKeyList);
            setTreeExpand(parentKeyList);
            setTreeFilter(filteredTree);
        }


        const scroll = data
            .map((items) => {
                if (items.title.indexOf(value) > -1) {
                    return items.key;
                }
                return null;
            })
            .filter((item, i, self) => item && self.indexOf(item) === i);

        setSearchValue(value);

        setActivityScroll(scroll[0]);

        // console.log("scroll", scroll);
    };

    /**
     * update tree-items is checked or not, and update textExtraEllipsis display following by checked-key
     * @param {Array} checkedKeysValue key of tree has click checked
     */
    const onCheck = (checkedKeysValue, event) => {
        // console.log(`onCheck (${props.type})`, checkedKeysValue, props.dataFilter);
        // console.log(`[onCheck] current keys:\n`, checkedKeysValue);
        // console.log(event.check ? "CHECKED" : "UNCHECK");
        // console.log(`[onCheck] event\n`, event);
        // let checkedKeysValue = [...checkedKeysValue];
        if (SUB_TYPE_IS_MULTI_LEVEL.includes(props.type)) {
            checkedKeysValue = checkedKeysValue.filter(key => key.includes("leaf"));
        }

        // initialize
        const storeFlatObject = []; // store flat array of filter (1d array with all of elements)
        services.listGenerator(props.dataFilter, storeFlatObject); // convert multi-level of array to flat array (1D Array)
        // console.log(storeFlatObject);
        let textExtraFull = [];
        let textExtraEllipsis = null;

        checkedKeysValue = [...new Set([...checkedKeys, ...checkedKeysValue])];
        // console.log(checkedKeysValue);
        if (!event.checked) {
            let removeItemList = [];
            const { node } = event;
            if (node?.children) {
                services.listGenerator(node.children, removeItemList);
            }
            removeItemList = removeItemList.map(item => item.key);
            removeItemList.push(node.key);
            checkedKeysValue = checkedKeys.filter(key => !removeItemList.includes(key));
        }

        let checkedKeyFilterList = storeFlatObject.filter(filter => checkedKeysValue.includes(filter.key)); // from all of element to remaining checked-keys only
        // console.log(checkedKeyFilterList);

        // filter out 'parent' of tree, but remains only children of parent
        if (SUB_TYPE_IS_MULTI_LEVEL.includes(props.type)) {
            checkedKeyFilterList = checkedKeyFilterList.filter(obj => obj.key.includes("leaf")); // key's children will be start with 'leaf'
            // console.log(checkedKeyFilterList);
        }

        /**
         * counting numbers of checked-keys, 
         * if length of checked-keys 'equals to' or 'more than' max-lenght of props-type are meaning 'check-all' by manually click
         * else checked some keys
         */
        if (checkedKeysValue.length >= MAX_LENGTH[props.type]) {
            textExtraEllipsis = "ทั้งหมด";
        } else {
            textExtraEllipsis = checkedKeyFilterList.map(item => item.title);  // convert Array<Object> to Array<String> are contains name's checked for display
            textExtraEllipsis = services.formatArrayWithEllipsis(textExtraEllipsis);  // convert Array<String> to String, overflow will be ellipsis (...)
        }
        textExtraFull = checkedKeyFilterList.map((obj) => obj.title).join(", "); // get only 'title' for display text-extra
        console.log(checkedKeyFilterList, checkedKeyFilterList.length);

        let TypeChecked = {}; // update redux inputFilter for request to backend
        if (SUB_TYPE_IS_MULTI_LEVEL.includes(props.type)) {
            // these filters in array, need special format payload
            TypeChecked = {
                ...inputfilter.filter_input,
                [props.type]: {
                    type: "ditp",
                    value: checkedKeysValue
                }
            };
        } else {
            TypeChecked = {
                ...inputfilter.filter_input,
                [props.type]: checkedKeysValue
            };
        }

        if (SUB_TYPE_IS_MULTI_LEVEL.includes(props.type)) {
            checkedKeysValue = checkedKeysValue.filter(key => key.includes("leaf"));
        }
        setCheckedKeys(checkedKeysValue);
        onCheckedKeys(textExtraEllipsis, textExtraFull, props.parentType, props.type); // update State of Parent 'setTextExtraFilter' (received from props)
        actions.setFilter(TypeChecked); // set Filter
    };

    const checkAll = (data, parentType, subType) => {
        // parse multi-level array to 1D array
        services.listGenerator(data, checkAllList);
        // console.log(data, checkAllList);
        // concat checked items to string
        const fullText = checkAllList
            .filter(item => {
                // filter-out parent of children (filter only children to display text-extra)
                if (SUB_TYPE_IS_MULTI_LEVEL.includes(subType)) {
                    return item.key.includes("leaf");
                }
                return item.key;
            })
            .map(item => item.title)
            .join(", ");

        // update checked items into filter
        let checkedKeyItems = checkAllList.map((item) => item.key);
        if (SUB_TYPE_IS_MULTI_LEVEL.includes(props.type)) {
            checkedKeyItems = checkedKeyItems.filter(key => key.includes("leaf"));
        }
        let filterCheckedUpdate = {};
        if (SUB_TYPE_IS_MULTI_LEVEL.includes(props.type)) {
            filterCheckedUpdate = {
                ...inputfilter.filter_input,
                [subType]: {
                    type: "ditp",
                    value: checkedKeyItems
                },
            };
        } else {
            filterCheckedUpdate = {
                ...inputfilter.filter_input,
                [subType]: checkedKeyItems
            };
        }

        // postExporter(filterCheckedUpdate);
        // console.log(checkedKeyItems);
        setCheckedKeys(checkedKeyItems);
        onCheckedKeys("ทั้งหมด", fullText, parentType, subType);
        actions.setFilter(filterCheckedUpdate);
    };

    const unCheckall = (parentType, subType) => {
        // console.log(`[unCheckAll]: `, parentType, subType, props.parentType, props.type);
        let filterCheckedUpdate = {};
        if (SUB_TYPE_IS_MULTI_LEVEL.includes(subType)) {
            filterCheckedUpdate = {
                ...inputfilter.filter_input,
                [subType]: {
                    type: "ditp",
                    value: []
                },
            };
        } else {
            filterCheckedUpdate = {
                ...inputfilter.filter_input,
                [props.type]: [],
            };
        }

        setCheckedKeys([]);
        onCheckedKeys("", "", parentType, subType);
        actions.setFilter(filterCheckedUpdate);
    };

    useEffect(() => {
        if (searchValue) {
            keyRef.current.scrollTo({ key: activityScroll, align: "top" });
        }
    }, [searchValue]);

    useEffect(() => {
        console.log(`[filterPayload (filter_input)] has changes\n`);
        setCheckedKeys(getInitialCheckedKeysByFilter(filter_input));
    }, [filter_input]);

    return (
        <div className="activity">
            <div className="activity-type">
                <div className="check-tree">
                    <Text onClick={() => checkAll(props.dataFilter, props.parentType, props.type)}>เลือกทั้งหมด</Text>
                    <Text onClick={() => unCheckall(props.parentType, props.type)}>ไม่เลือกทั้งหมด</Text>
                </div>
                <Search
                    allowClear
                    style={{ marginBottom: 8 }}
                    onChange={(e) => onSearch(e, props.dataFilter)}
                    placeholder="ค้นหา..."
                />
                <Tree
                    height={200}
                    ref={keyRef}
                    checkable
                    // icon={<CaretDownFilled />}
                    expandedKeys={treeExpand}
                    // onSelect={(selectedKeys, info) => setTreeExpand(prevKeys => [...prevKeys, ...selectedKeys])}
                    onExpand={(expandedKeys, info) => setTreeExpand(expandedKeys)}
                    onCheck={onCheck}
                    checkedKeys={checkedKeys}
                    treeData={treeFilter}
                />
            </div>
        </div>
    );
}
