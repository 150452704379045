import React, { useEffect, useState } from "react";
import "../assets/CSS/mod_suggest.css";
import { Input, Tabs, Typography } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../resources/index";
import { default as ActivityTable } from "../components/RecommendTable";
import { default as ActivityDetailById } from "../components/RecommendDetail";
import KnowledgeGraph from "../components/KnowledgeGraph";
import Loading from "../components/Loading";

const { TabPane } = Tabs;
const { Search } = Input;
const { Link } = Typography;

export default function SuggestActivity() {
  const dispatch = useDispatch();
  const actions = bindActionCreators(actionCreators, dispatch);
  const { RecomendedActivity, inputfilter, activityDataById } = useSelector((state) => ({
    RecomendedActivity: state?.suggestActivity?.suggestActivity,
    inputfilter: state?.filter_input,
    activityDataById: state?.suggestDetail?.suggestDetail
  }));

  console.log(`[useSelector]:\t`, RecomendedActivity);
  const [activityData, setActivityData] = useState(RecomendedActivity);
  // console.log(`[setState]: activityData\t`, activityData);

  const onRoutingKnowledgeGraph = () => {
    const win = window.open(`/KnowledgeGraph`);
    win.focus();
  };

  const onClickActivity = (activityId) => {
    actions.getSuggestDetail(activityId);
  };

  const onSearch = (event) => {
    // const { value } = e.target;
    let inputText = event.target.value;
    inputText = inputText.toLowerCase();
    console.log(inputText);

    const activityFiltered = RecomendedActivity.activity_list.filter(activity => {
      return activity.Activity_NameTH.replaceAll(" ", "").toLowerCase().includes(inputText);
    });

    console.log(activityFiltered);
    setActivityData(prevState => ({ ...prevState, activity_list: activityFiltered }));
    // const new_data = {
    //   ...inputfilter.filter_input,
    //   search_text: value,
    // };
    // actions.setFilter(new_data);
    // actions.getSuggestActivity(new_data);
  };

  useEffect(() => {
    // console.log(`[useEffect]: setState activityData`, RecomendedActivity);
    setActivityData(RecomendedActivity);
  }, [RecomendedActivity]);

  useEffect(() => {
    // console.log(`[useEffect]: getSuggestActivity`);
    actions.getSuggestActivity(inputfilter.filter_input);
  }, []);


  return (
    <div className="suggest-activity relative ">
      <div className="suggest-content">
        <Search
          disabled={!activityData}
          // onSearch={onSearch}
          onChange={onSearch}
          placeholder="ค้นหากิจกรรม. . ."
          style={{ width: "50%", position: "absolute", zIndex: 1 }}
        />
        {
          activityData ? (
            <Tabs type="card">
              <TabPane tab="รายการ" key="1">
                <ActivityTable
                  data={activityData}
                  onClickActivity={onClickActivity}
                />
              </TabPane>
              {/* <TabPane tab="Knowledge graph" key="2">
                <div className="full-btn">
                  <Link onClick={() => onRoutingKnowledgeGraph()}>Full view</Link>
                </div>
                <KnowledgeGraph
                  activityData={activityData?.knowledge_graph}
                />
              </TabPane> */}
            </Tabs>
          ) : (
            <Loading>
              <div className="w-full h-11/12 bg-white"></div>
            </Loading>
          )
        }
      </div>
      <div className="suggest-detail sticky top-4">
        <ActivityDetailById 
          data={activityDataById}
        />
      </div>
    </div>
  );
}
