import React from 'react';

function SkeletonLineLoading() {
    return (
        <div className="animate-pulse flex items-center w-full space-x-2">
            <div className="h-1 bg-blue-100 dark:bg-blue-300 w-full"></div>
        </div>
    );
}

export default SkeletonLineLoading;