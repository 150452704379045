import React from "react";
import { useHistory } from "react-router-dom";
import AuthService from "../services/auth.service";

import CardBorderTriangle from "../components/CardBorderTriangle";

// images
import globalFooterImage from "../assets/img/global.png";
import icon1 from "../assets/img/landing-icons-01.png";
import icon2 from "../assets/img/landing-icons-02.png";
import icon3 from "../assets/img/landing-icons-03.png";
import icon4 from "../assets/img/landing-icons-04.png";
import icon5 from "../assets/img/landing-icons-05.png";

export default function Home() {
  const history = useHistory();

  /**
   * routing page
   * @param {string} uri path URI for routing
   * @param {boolean} externalPath External URI for redirect
   */
  const onRouting = (uri, externalPath) => {
    if (externalPath) {
      window.location.replace(uri);
    }

    history.push(uri);
  };


  const cardMenuList = [
    {
      key: "01",
      title: "วิเคราะห์เป้าส่งออก",
      detail: "ค้นหากลุ่มสินค้าหรือตลาดที่การส่งออกไม่เป็นไปตามเป้า",
      image: icon1,
      // path: "https://activityanalytics.ditp.go.th/Export",
      path: "/potential-dashboard",
      externalPath: false
    },
    {
      key: "02",
      title: "วิเคราะห์โอกาสทางการตลาด",
      image: icon2,
      detail: "วิเคราะห์สินค้าหรือตลาดที่มีศักยภาพสูงและคุ้มค่าในการสนับสนุนผู้ประกอบการ",
      path: "/PotentialProduct",
      externalPath: false
    },
    {
      key: "03",
      title: "วิเคราะห์กิจกรรม",
      image: icon4,
      detail: "วิเคราะห์กิจกรรมที่เคยจัดในกลุ่มสินค้าหรือกลุ่มผู้ประกอบการตามศักยภาพเพื่อตรวจสอบความสำเร็จของกิจกรรม",
      path: "/Activity",
      externalPath: false
    },
    {
      key: "04",
      title: "วิเคราะห์ผู้ประกอบการ",
      image: icon3,
      detail: "วิเคราะห์ผู้ประกอบการที่เคยเข้าร่วมกิจกรรม เพื่อแสดงศักยภาพของผู้ประกอบการโดยแสดงค่าดัชนีในแต่ละมิติ",
      path: "/Exporter",
      externalPath: false
    },
    {
      key: "05",
      title: "แนะนำกิจกรรม",
      image: icon5,
      detail:
        "แนะนำกิจกรรมที่เหมาะสมให้กับผู้ประกอบการโดยอ้างอิงจากความสำเร็จของผู้ประกอบการและกิจกรรมในอดีต",
      path: "/SuggestActivity",
      externalPath: false
    },
  ];

  return (
    <div className='relative min-h-screen h-full w-full pt-[10vh]'>
      <div className='header-container w-full text-center font-bold text-base xl:text-xl mb-11 mx-auto px-'>
        {/* Header Container */}
        <h1 className='inline xl:block'>
          ระบบวิเคราะห์ผลสัมฤทธิ์ของผู้ประกอบการรายย่อย (SMEs)
        </h1>
        <h1 className='inline pl-2 xl:block xl:pl-0'>
          สู่ภาคการค้าระหว่างประเทศ
        </h1>
      </div>

      {/* Card Container */}
      <div
        className='
        w-full mx-auto mb-auto lg:w-full xl:w-11/12 lg:px-0 xl:my-[10vh]
        flex flex-col xl:flex-row justify-start xl:justify-center items-center 
        gap-y-20 lg:gap-20 xl:gap-14 2xl:gap-16
      '
      >
        {cardMenuList.map((menu) => (
          <CardBorderTriangle
            onClick={() => onRouting(menu.path, menu.externalPath)}
            className={
              "hover:shadow hover:rounded-xl hover:shadow-primary-grey transition-shadow"
            }
            key={menu.key}
            data={menu}
          />
        ))}
      </div>

      {/* Footer */}
      <footer className='overflow-hidden w-full xl:absolute xl:bottom-0 bg-transparent z-0'>
        <img src={globalFooterImage} className='w-full 2xl:w-5/6 h-full lg:h-[40vh] object-fill mx-auto' />
      </footer>
    </div>
  );
}
