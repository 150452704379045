import { useEffect, useState } from "react";

/**
 * render component but have a delay
 * @param {number} param0 time for render delay in ms
 * @returns components
 */
export default function DelayRenderComponent({
  delay = 500,
  children,
  ...props
}) {
  // isDelay status
  const [isDelayed, setIsDelayed] = useState(true);

  /**
   * setTimeout for delay rendering components
   */
  useEffect(() => {
    const timeoutId = setTimeout(() => setIsDelayed(false), delay);
    return () => clearTimeout(timeoutId);
  }, []);

  return <>{!isDelayed && children}</>;
}
