import React, { useState, useEffect } from "react";
import { Transfer, Table, Card, Typography, Button } from "antd";
import difference from "lodash/difference";
import { useHistory } from "react-router-dom";
import { bindActionCreators } from "redux";
import { actionCreators } from "../resources/index";
import { useDispatch, useSelector } from "react-redux";

const { Title } = Typography;

const TableTransfer = ({ leftColumns, rightColumns, ...restProps }) => (
  <Transfer
    {...restProps}
    titles={["ผู้ประกอบการ", "ผู้ประกอบการที่แนะนำ"]}
    locale={{
      itemUnit: "ผู้ประกอบการ",
      itemsUnit: "ผู้ประกอบการ",
      notFoundContent: "ไม่พบข้อมูล",
      searchPlaceholder: "ค้นหาผู้ประกอบการ. . .",
    }}
  >
    {({
      direction,
      filteredItems,
      onItemSelectAll,
      onItemSelect,
      selectedKeys: listSelectedKeys,
      disabled: listDisabled,
    }) => {
      const columns = direction === "left" ? leftColumns : rightColumns;

      const rowSelection = {
        getCheckboxProps: (item) => ({
          disabled: listDisabled || item.disabled,
        }),
        onSelectAll(selected, selectedRows) {
          const treeSelectedKeys = selectedRows
            .filter((item) => !item.disabled)
            .map(({ key }) => key);
          const diffKeys = selected
            ? difference(treeSelectedKeys, listSelectedKeys)
            : difference(listSelectedKeys, treeSelectedKeys);
          onItemSelectAll(diffKeys, selected);
        },
        onSelect({ key }, selected) {
          onItemSelect(key, selected);
        },
        selectedRowKeys: listSelectedKeys,
      };

      return (
        <Table
          rowSelection={rowSelection}
          columns={columns}
          dataSource={filteredItems}
          size="small"
          style={{ pointerEvents: listDisabled ? "none" : null }}
          onRow={({ key, disabled: itemDisabled }) => ({
            onClick: () => {
              if (itemDisabled || listDisabled) return;
              onItemSelect(key, !listSelectedKeys.includes(key));
            },
          })}
        />
      );
    }}
  </Transfer>
);

const leftTableColumns = [
  { key: "Tax_No", dataIndex: "Tax_No", title: "เลขประจำตัวผู้เสียภาษี" },
  {
    key: "Corporate_NameTH",
    dataIndex: "title",
    title: "ชื่อผู้ประกอบการ",
  },
  // {
  //   dataIndex: "score",
  //   key: "score",
  //   title: "ค่าดัชนี",
  //   // render: (row) => {
  //   //   return <Text> {row.toFixed(2)}</Text>;
  //   // },
  // },
];
const rightTableColumns = [
  { key: "activity_id", dataIndex: "Tax_No", title: "เลขประจำตัวผู้เสียภาษี" },
  {
    key: "title",
    dataIndex: "title",
    title: "ชื่อผู้ประกอบการ",
  },
];

export default function ManageTrasfer(props) {
  const history = useHistory();
  const [targetKeys, setTargetKeys] = useState(props.originTargetKeys);
  const [disabled, setDisabled] = useState(false);
  const dispatch = useDispatch();
  const response = useSelector((state) => state.updateExporter.updateExporter);
  const { upDateExporter } = bindActionCreators(actionCreators, dispatch);

  useEffect(() => {
    console.log("another", props.originTargetKeys);
    console.log("anotherData", props.exporter);
    console.log("status", props.status);
    if (props.originTargetKeys) {
      setTargetKeys(props.originTargetKeys);
    }
    if (props.status == "SENDED") {
      setDisabled(true);
    }
  }, [props]);
  // useEffect(() => {
  //   const originTargetKeys = props.data
  //     .filter((item) => props.exporterList.includes(item.title))
  //     .map((item) => item.key);
  //   setTargetKeys(originTargetKeys);
  // }, [props.data]);

  // useEffect((data) => {
  //   if (data) {
  //     const defaultkey = data
  //       .filter((item) => props.checktitle.includes(item.title))
  //       .map((item) => item.key);
  //     setTargetKeys(defaultkey);
  //   }
  // }, []);

  const onChange = (nextTargetKeys) => {
    console.log("whatNext", nextTargetKeys);
    setTargetKeys(nextTargetKeys);
  };

  const setRecomendedExporter = () => {
    const packData = {
      activity_id: props.activityId,
      activity_name: props.activityName,
      tax_no_list: targetKeys,
    };
    upDateExporter(packData);
  };
  useEffect(() => {
    if (response) {
      if (response.success === true) {
        window.location.replace("/StatusSMS");
      }
    }
  }, [response]);

  return (
    <div className="manage-trasfer">
      <Card
        actions={[
          <Button
            type="primary"
            disabled={disabled}
            onClick={setRecomendedExporter}
          >
            บันทึก
          </Button>,
        ]}
      >
        <Title style={{ fontSize: 24 }}>
          การจัดการการส่ง SMS | {props.activityId} {" : "}
          {props.activityName}
        </Title>
        <TableTransfer
          titile={["ผู้ประกอบการ", "ผู้ประกอบการที่แนะนำ"]}
          dataSource={props.exporter}
          targetKeys={targetKeys}
          showSearch={true}
          disabled={disabled}
          onChange={onChange}
          filterOption={(inputValue, item) =>
            item.title.indexOf(inputValue) !== -1 ||
            item.Tax_No.indexOf(inputValue) !== -1
          }
          leftColumns={leftTableColumns}
          rightColumns={rightTableColumns}
        />
      </Card>
    </div>
  );
}
