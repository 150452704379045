import { ACTIONS_TYPE } from "../variables";

const reducer = (state, action) => {
  switch (action.type) {
    case "exporter":
      return { ...state, exporter: action.payload };
    case ACTIONS_TYPE.SUMMARY_GROUP:
      return { ...state, [ACTIONS_TYPE.SUMMARY_GROUP]: action.payload };
    case ACTIONS_TYPE.SUMMARY_BY_ROWS:
      return { ...state, [ACTIONS_TYPE.SUMMARY_BY_ROWS]: action.payload };
    case ACTIONS_TYPE.TEXT_EXTRA_FILTER:
      return { ...state, [ACTIONS_TYPE.TEXT_EXTRA_FILTER]: action.payload };
    case ACTIONS_TYPE.TEXT_EXTRA_FILTER:
      return { ...state, [ACTIONS_TYPE.TEXT_EXTRA_FILTER]: action.payload };
    case ACTIONS_TYPE.ACTIVITY_COMPARE_LIST:
      return { ...state, [ACTIONS_TYPE.ACTIVITY_COMPARE_LIST]: action.payload };
    case "exporterMovement":
      return { ...state, exporterMovement: action.payload };
    case "exporterMovementSummary":
        return { ...state, exporterMovementSummary: action.payload };
    case "filter":
      return { ...state, filter: action.payload };
    case "details":
      return { ...state, details: action.payload };
    case "inputfilter":
      return { ...state, filter_input: action.payload };
    case "activity":
      return { ...state, activity: action.payload };
    case "activity_D":
      return { ...state, activity_D: action.payload };
    case "potentialP":
      return { ...state, potential: { product: action.payload } };
    case "potentialC":
      return { ...state, potential: { country: action.payload } };
    case "suggestActivity":
      return { ...state, suggestActivity: action.payload };
    case "suggestDetail":
      return { ...state, suggestDetail: action.payload };
    case "potentialDetail":
      return { ...state, potential_detail: { product: action.payload } };
    case "potentialCountry":
      return { ...state, potential_Country: { country: action.payload } };
    case "user":
      return { ...state, user: action.payload };
    case "compareActivity":
      return { ...state, compare: action.payload };
    case "exporterData":
      return { ...state, exporterData: action.payload };
    case "statusSMS":
      return { ...state, statusSMS: action.payload };
    case "smsActivity":
      return { ...state, smsActivity: action.payload };
    case "updateExporter":
      return { ...state, updateExporter: action.payload };
    case "settingScore":
      return { ...state, statusSetting: action.payload };
    case "weightSetting":
      return { ...state, weightSetting: action.payload };
    case "delayStatus":
      return { ...state, delayStatus: action.payload };
    case "exporter_statistics":
      return { ...state, exporter_statistics: action.payload };
    case "timeData":
      return { ...state, time_data: action.payload };
    case "searchExporter":
      return { ...state, searchExporter: action.payload };
    default:
      return { ...state };
  }
};

export default reducer;
