import React, { useState, useEffect } from "react";
import { Form, InputNumber, Button, Modal, Spin, Alert, Row, Col  } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";

import { actionCreators } from "../resources/index";
import { strengthDescription, outComeDescription } from "../resources/variables/index";

export default function Scoreweight(props) {
  const [disabled, setDisabled] = useState(true);
  const [defWeight, setDefWeight] = useState();
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { weightSetting } = bindActionCreators(actionCreators, dispatch);
  const status = useSelector((state) => state.weightSetting.weightSetting);

  useEffect(() => {
    setDefWeight(props.defScore);
  }, []);

  useEffect(() => {
    if (status === 200) {
      setLoading(false);
      setVisible(true);
      setTimeout(() => {
        setVisible(false);
      }, 1500);
    }
  }, [status]);

  const onFinish = (values) => {
    //Post weight to API here !!!!
    weightSetting(props.defScore.id, values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  function confirm() {
    Modal.confirm({
      title: "ยืนยันการตั้งค่า",
      icon: <ExclamationCircleOutlined />,
      content: "ต้องการยืนยันที่จะตั้งค่าน้ำหนัก ?",
      okText: "ตกลง",
      cancelText: "ยกเลิก",
      onOk() {
        form.submit();
        setLoading(true);
      },
    });
  }

  return (
    <div className="score-weight">
      {visible ? (
        <div className="target-alert">
          <Alert
            message={"บันทึกค่าสำเร็จ"}
            type="success"
            showIcon
            style={{ width: "20rem" }}
          />
        </div>
      ) : null}
      {defWeight ? (
        <Form
          form={form}
          name="weight"
          initialValues={{
            EV: defWeight.EV,
            EY: defWeight.EY,
            EP: defWeight.EP,
            EC: defWeight.EC,
            IP: defWeight.IP,

            CAGR: defWeight.CAGR,
            DV: defWeight.DV,
            CV: defWeight.CV,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 14 }}
          labelAlign="left"
        >
          <Form.Item
            wrapperCol={{
              offset: 8,
              pull: 8,
              span: 16,
            }}
          >
            <Button type="primary" onClick={confirm}>
              บันทึก
            </Button>
            <Button
              style={{
                marginLeft: 5,
                color: "white",
                backgroundColor: "#F2A83C",
              }}
              onClick={() => setDisabled(!disabled)}
            >
              แก้ไข
            </Button>
          </Form.Item>
          <Spin tip="กำลังตั้งค่า" spinning={loading}>
            <div>

              <h1 className="text-base">ค่าความแข็งแกร่ง (Strength)</h1>
              {
                strengthDescription.map((element, index) => {
                  return (
                    <>
                        <Form.Item name={element.key} label={`${element.key}: ${element.description}`}>
                              <InputNumber
                                defaultValue={defWeight[element.key]}
                                disabled={disabled}
                                style={{ width: 150 }}
                              />
                        </Form.Item>
                    </>

                  );
              })}

              <h1 className="text-base mt-10">ค่าผลลัพท์ (Outcome)</h1>
              {
                outComeDescription.map((element, index) => {
                  return (
                    <>
                        <Form.Item name={element.key} label={`${element.key}: ${element.description}`}>
                              <InputNumber
                                defaultValue={defWeight[element.key]}
                                disabled={disabled}
                                style={{ width: 150 }}
                              />
                        </Form.Item>
                    </>

                  );
              })}

            </div>
          </Spin>
        </Form>
      ) : null}
    </div>
  );
}
