import "../../assets/CSS/exporter-table-component.css";
import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";

// Lib
import qs from "qs";
import XLSX from 'xlsx';

// utils, common
import services from "../../resources/variables/services";

// components
import { DownloadOutlined, SearchOutlined } from '@ant-design/icons';
import { Table, Tooltip, Typography, Row, Col, Input, Button, Modal } from "antd";
import Loading from "../Loading";

// hooks
import useScreenSize from '../../hooks/useScreenSize';


export default function ExporterTable({ rowSelection, ...props }) {
	const exporter = useSelector((state) => state.exporter);
	const dataTableCleanedRef = useRef([]);
	const [toggleModal, setToggleModal] = useState({ exportCSV: { isLoading: false, isOpen: false } });
	let initialDataTable = [];
	if (exporter.exporter) {
		// remove demo-data
		const dataShow = exporter.exporter?.score_table?.filter((item) => {
			return !item.Corporate_NameTH.includes("ทดสอบ");
		});
		// add attribute 'mean' for column 'average'
		initialDataTable = services.formatExporterData(dataShow);
	}

	const [dataTableState, setDataTableState] = useState(initialDataTable);
	const [currentPage, setCurrentPage] = React.useState(1);
	const currentScreen = useScreenSize();
	const  [dataTableRowsWithRowNumbers, setDataTableRowsWithRowNumbers] = useState([]);
	// console.log(currentScreen);

	/**
	 * Iterator for create each Column of Table with config
	 */
	const columns = [
		{
			title: <Tooltip className="font-bold text-xs md:text-sm" title={"No."}>No.</Tooltip>,
			dataIndex: "row_no",
			ellipsis: true,
			showSorterTooltip: false,
			sorter: (a, b) => a.index > b.index,
			align: "center",
			width: (currentScreen === 'tablet' || currentScreen === 'mobile') ? '10%' : currentScreen === 'laptop' ? '5%' : "5%",
			responsive: ['xs', "md"],
		},
		{
			title: <Tooltip className="font-bold text-xs md:text-sm" title={"ชื่อบริษัท"}>Name</Tooltip >,
			dataIndex: "Corporate_NameTH",
			ellipsis: true,
			align: "center",
			sorter: (a, b) => a.Corporate_NameTH > b.Corporate_NameTH,
			width: (currentScreen === 'tablet' || currentScreen === 'mobile') ? "60%" : currentScreen === 'laptop' ? '20%' : "20%",
			responsive: ["xs", "sm"],
		},
		{
			title: <Tooltip className="font-bold" title={"ค่าดัชนีเฉลี่ยของผู้ประกอบการจากด้านความเข้มแข็ง (Strength) และความเติบโต (Outcome)"}>
				<span className="hidden 1xl:block">
					Average Score
				</span>
				<span className="block text-xs lg:text-sm 1xl:hidden">
					{
						(currentScreen === 'laptop' || currentScreen === 'desktop') ? 'Average' : 'AVG'
					}
				</span>
			</Tooltip>,
			dataIndex: "average",
			ellipsis: true,
			defaultSortOrder: "descend",
			sorter: (a, b) => b.mean - a.mean,
			showSorterTooltip: false,
			align: "center",
			responsive: ["xs", "sm"],
			width: (currentScreen === 'tablet' || currentScreen === 'mobile') ? '18%' : currentScreen === 'laptop' ? '10%' : '8%',
			cssClass: "bg-group-mean-score",
		},
		{
			title: <Tooltip className="font-bold" title={"ค่าดัชนีของผู้ประกอบการในด้านการความเข้มแข็ง"}>Strength</Tooltip>,
			group: "group-strength-score",
			responsive: ["xl"],
			children: [
				{
					title: <Tooltip className="font-bold" title={"EV (Export Value) : ยอดการส่งออก (บาท/ปี)"}>EV</Tooltip>,
					dataIndex: "EV",
					ellipsis: true,
					width: "6%",
					align: "center",
					showSorterTooltip: false,
					defaultSortOrder: false,
					sorter: (a, b) => a.EV - b.EV,
					responsive: ["xl"],
				},
				{
					title: <Tooltip className="font-bold" title={"EY (Export Year) : ประสบการณ์ในการส่งออกของผู้ประกอบการ (ปี)"}>EY</Tooltip>,
					dataIndex: "EY",
					ellipsis: true,
					width: "6%",
					align: "center",
					showSorterTooltip: false,
					defaultSortOrder: false,
					sorter: (a, b) => a.EY - b.EY,
					responsive: ["xl"],
				},
				{
					title: <Tooltip className="font-bold" title={"EP (Export Product) : ความหลากหลายของสินค้าส่งออก (จำนวนประเภทของ HScode(4)/ปี)"}>EP</Tooltip>,
					dataIndex: "EP",
					ellipsis: true,
					width: "6%",
					align: "center",
					showSorterTooltip: false,
					defaultSortOrder: false,
					sorter: (a, b) => a.EP - b.EP,
					responsive: ["xl"],
				},
				{
					title: <Tooltip className="font-bold" title={"EC (Export Country) : จำนวนตลาดส่งออก"}>EC</Tooltip>,
					dataIndex: "EC",
					ellipsis: true,
					width: "6%",
					align: "center",
					showSorterTooltip: false,
					defaultSortOrder: false,
					sorter: (a, b) => a.EC - b.EC,
					responsive: ["xl"],
				},
				{
					title: <Tooltip className="font-bold" title={"IP (Intellectual Property) : จำนวนการจดทะเบียนเครื่องหมายการค้าหรือสิทธิบัตร (ครั้ง/ปี)"}>IP</Tooltip>,
					dataIndex: "IP",
					ellipsis: true,
					width: "6%",
					showSorterTooltip: false,
					defaultSortOrder: false,
					sorter: (a, b) => a.IP - b.IP,
					responsive: ["xl"],
					align: "center",
				},
				{
					title: <Tooltip className="font-bold m-0" title={"ค่าดัชนีของผู้ประกอบการในด้านการความเข้มแข็ง"}>Score</Tooltip>,
					dataIndex: "strength",
					ellipsis: true,
					width: "6%",
					align: "right",
					showSorterTooltip: false,
					defaultSortOrder: false,
					sorter: (a, b) => a.strength - b.strength,
					responsive: ["md"],
				},
			],
		},

		{
			title: (
				<Tooltip className="font-bold" title={"ค่าดัชนีของผู้ประกอบการในด้านการความเติบโต"}>Outcome</Tooltip>
			),
			group: "group-outcome-score",
			responsive: ["xl"],
			children: [
				{
					title: <Tooltip className="font-bold" title={"CAGR (Compound Annual Growth Rate) : อัตราการเติบโตต่อปีแบบทบต้น (คำนวณจากปีที่มูลค่าการส่งออกครบ 12 เดือนแล้วเท่านั้น)"}>CAGR</Tooltip>,
					dataIndex: "CAGR",
					ellipsis: true,
					width: "6%",
					align: "center",
					showSorterTooltip: false,
					defaultSortOrder: false,
					sorter: (a, b) => a.CAGR - b.CAGR,
					responsive: ["xl"],
				},
				{
					title: <Tooltip className="font-bold" title={"DV (Different Export Value) : ค่าผลต่างของมูลค่าจากปีที่แล้ว"}>DV</Tooltip>,
					dataIndex: "DV",
					ellipsis: true,
					width: "6%",
					align: "center",
					showSorterTooltip: false,
					defaultSortOrder: false,
					sorter: (a, b) => a.DV - b.DV,
					responsive: ["xl"],
				},
				{
					title: <Tooltip className="font-bold" title={"1-CV (Coefficient of Variation) : ค่าความผันผวน"}>1-CV</Tooltip>,
					dataIndex: "CV",
					ellipsis: true,
					width: "6%",
					align: "center",
					showSorterTooltip: false,
					defaultSortOrder: false,
					sorter: (a, b) => a.CV - b.CV,
					responsive: ["xl"],
				},
				{
					title: <Tooltip className="font-bold" title={"ค่าดัชนีของผู้ประกอบการในด้านการความเติบโต"}>Score</Tooltip>,
					dataIndex: "outcome",
					ellipsis: true,
					width: "6%",
					align: "right",
					showSorterTooltip: false,
					defaultSortOrder: false,
					sorter: (a, b) => a.outcome - b.outcome,
					responsive: ["xs"],
				},
			],
		},
		{
			title: <Tooltip className="font-bold text-xs md:text-sm" title={"ค่าดัชนีของผู้ประกอบการในด้านการความเติบโต"}>Outcome</Tooltip>,
			dataIndex: "outcome",
			ellipsis: true,
			width: (currentScreen === 'tablet' || currentScreen === 'mobile') ? '18%' : currentScreen === 'laptop' ? '15%' : '6%',
			align: "center",
			showSorterTooltip: false,
			defaultSortOrder: false,
			render: (value) => <Typography.Text className="text-xs lg:text-sm">{parseFloat(value).toFixed(2)}</Typography.Text>,
			sorter: (a, b) => a.outcome - b.outcome,
			cssClass: "bg-group-outcome-score ",
			responsive: ["xs", 'sm'],
		},
		{
			title: <Tooltip className="font-bold m-0 text-xs md:text-sm" title={"ค่าดัชนีของผู้ประกอบการในด้านการความเข้มแข็ง"}>Strength</Tooltip>,
			dataIndex: "strength",
			ellipsis: true,
			width: (currentScreen === 'tablet' || currentScreen === 'mobile') ? '18%' : currentScreen === 'laptop' ? '15%' : '6%',
			align: "center",
			showSorterTooltip: false,
			cssClass: "bg-group-strength-score ",
			render: (value) => <Typography.Text className="text-xs lg:text-sm">{parseFloat(value).toFixed(2)}</Typography.Text>,
			sorter: (a, b) => a.strength - b.strength,
			responsive: ["xs", 'sm', 'md'],
		},
	];


	const columnsModal = [
		{
			title: <Tooltip className="font-bold text-xs md:text-sm" title={"หมายเลขนิติบุคคล (Tax ID)"}>หมายเลขนิติบุคคล</Tooltip >,
			dataIndex: "หมายเลขนิติบุคคล",
			ellipsis: true,
			align: "left",
			sorter: (a, b) => a['หมายเลขนิติบุคคล'] > b['หมายเลขนิติบุคคล'],
			width: (currentScreen === 'tablet' || currentScreen === 'mobile') ? "10%" : currentScreen === 'laptop' ? '10%' : "10%",
			responsive: ["xs", "sm"],
		},
		{
			title: <Tooltip className="font-bold text-xs md:text-sm" title={"ชื่อผู้ประกอบการ (Corperate Name)"}>ชื่อผู้ประกอบการ</Tooltip >,
			dataIndex: "ชื่อผู้ประกอบการ",
			ellipsis: true,
			align: "left",
			sorter: (a, b) => a['ชื่อผู้ประกอบการ'] > b['ชื่อผู้ประกอบการ'],
			width: (currentScreen === 'tablet' || currentScreen === 'mobile') ? "60%" : currentScreen === 'laptop' ? '20%' : "20%",
			responsive: ["xs", "sm"],
		},
		{
			title: <Tooltip className="font-bold" title={"ค่าดัชนีเฉลี่ยของผู้ประกอบการจากด้านความเข้มแข็ง (Strength) และความเติบโต (Outcome)"}>
				<span className="hidden 1xl:block">
					Average Score
				</span>
				<span className="block text-xs lg:text-sm 1xl:hidden">
					{
						(currentScreen === 'laptop' || currentScreen === 'desktop') ? 'Average' : 'AVG'
					}
				</span>
			</Tooltip>,
			dataIndex: "Average",
			ellipsis: true,
			defaultSortOrder: "descend",
			sorter: (a, b) => b.Average - a.Average,
			showSorterTooltip: false,
			align: "center",
			responsive: ["xs", "sm"],
			width: (currentScreen === 'tablet' || currentScreen === 'mobile') ? '18%' : currentScreen === 'laptop' ? '10%' : '8%',
			cssClass: "bg-group-mean-score",
		},
		{
			title: <Tooltip className="font-bold" title={"ค่าดัชนีของผู้ประกอบการในด้านการความเข้มแข็ง"}>Strength</Tooltip>,
			group: "group-strength-score",
			responsive: ["xl"],
			children: [
				{
					title: <Tooltip className="font-bold text-group-strength-score" title={"EV (Export Value) : ยอดการส่งออก (บาท/ปี)"}>EV</Tooltip>,
					dataIndex: "EV",
					ellipsis: true,
					width: "6%",
					align: "center",
					showSorterTooltip: false,
					defaultSortOrder: false,
					sorter: (a, b) => a.EV - b.EV,
					responsive: ["xl"],
				},
				{
					title: <Tooltip className="font-bold text-group-strength-score" title={"EY (Export Year) : ประสบการณ์ในการส่งออกของผู้ประกอบการ (ปี)"}>EY</Tooltip>,
					dataIndex: "EY",
					ellipsis: true,
					width: "6%",
					align: "center",
					showSorterTooltip: false,
					defaultSortOrder: false,
					sorter: (a, b) => a.EY - b.EY,
					responsive: ["xl"],
				},
				{
					title: <Tooltip className="font-bold text-group-strength-score" title={"EP (Export Product) : ความหลากหลายของสินค้าส่งออก (จำนวนประเภทของ HScode(4)/ปี)"}>EP</Tooltip>,
					dataIndex: "EP",
					ellipsis: true,
					width: "6%",
					align: "center",
					showSorterTooltip: false,
					defaultSortOrder: false,
					sorter: (a, b) => a.EP - b.EP,
					responsive: ["xl"],
				},
				{
					title: <Tooltip className="font-bold text-group-strength-score" title={"EC (Export Country) : จำนวนตลาดส่งออก"}>EC</Tooltip>,
					dataIndex: "EC",
					ellipsis: true,
					width: "6%",
					align: "center",
					showSorterTooltip: false,
					defaultSortOrder: false,
					sorter: (a, b) => a.EC - b.EC,
					responsive: ["xl"],
				},
				{
					title: <Tooltip className="font-bold text-group-strength-score" title={"IP (Intellectual Property) : จำนวนการจดทะเบียนเครื่องหมายการค้าหรือสิทธิบัตร (ครั้ง/ปี)"}>IP</Tooltip>,
					dataIndex: "IP",
					ellipsis: true,
					width: "6%",
					showSorterTooltip: false,
					defaultSortOrder: false,
					sorter: (a, b) => a.IP - b.IP,
					responsive: ["xl"],
					align: "center",
				},
				{
					title: <Tooltip className="font-bold text-group-strength-score m-0" title={"ค่าดัชนีของผู้ประกอบการในด้านการความเข้มแข็ง"}>Score</Tooltip>,
					dataIndex: "Strength",
					ellipsis: true,
					width: "6%",
					align: "right",
					showSorterTooltip: false,
					defaultSortOrder: false,
					sorter: (a, b) => a.Strength - b.Strength,
					responsive: ["md"],
				},
			],
		},
		{
			title: (
				<Tooltip className="font-bold" title={"ค่าดัชนีของผู้ประกอบการในด้านการความเติบโต"}>Outcome</Tooltip>
			),
			group: "group-outcome-score",
			responsive: ["xl"],
			children: [
				{
					title: <Tooltip className="font-bold text-group-outcome-score" title={"CAGR (Compound Annual Growth Rate) : อัตราการเติบโตต่อปีแบบทบต้น (คำนวณจากปีที่มูลค่าการส่งออกครบ 12 เดือนแล้วเท่านั้น)"}>CAGR</Tooltip>,
					dataIndex: "CAGR",
					ellipsis: true,
					width: "6%",
					align: "center",
					showSorterTooltip: false,
					defaultSortOrder: false,
					sorter: (a, b) => a.CAGR - b.CAGR,
					responsive: ["xl"],
				},
				{
					title: <Tooltip className="font-bold text-group-outcome-score" title={"DV (Different Export Value) : ค่าผลต่างของมูลค่าจากปีที่แล้ว"}>DV</Tooltip>,
					dataIndex: "DV",
					ellipsis: true,
					width: "6%",
					align: "center",
					showSorterTooltip: false,
					defaultSortOrder: false,
					sorter: (a, b) => a.DV - b.DV,
					responsive: ["xl"],
				},
				{
					title: <Tooltip className="font-bold text-group-outcome-score" title={"1-CV (Coefficient of Variation) : ค่าความผันผวน"}>1-CV</Tooltip>,
					dataIndex: "1-CV",
					ellipsis: true,
					width: "6%",
					align: "center",
					showSorterTooltip: false,
					defaultSortOrder: false,
					sorter: (a, b) => a['1-CV'] - b['1-CV'],
					responsive: ["xl"],
				},
				{
					title: <Tooltip className="font-bold text-group-outcome-score" title={"ค่าดัชนีของผู้ประกอบการในด้านการความเติบโต"}>Score</Tooltip>,
					dataIndex: "Outcome",
					ellipsis: true,
					width: "6%",
					align: "right",
					showSorterTooltip: false,
					defaultSortOrder: false,
					sorter: (a, b) => a.Outcome - b.Outcome,
					responsive: ["xs"],
				},
			],
		},
		// {
		// 	title: <Tooltip className="font-bold text-xs md:text-sm" title={"ค่าดัชนีของผู้ประกอบการในด้านการความเติบโต"}>Outcome</Tooltip>,
		// 	dataIndex: "outcome",
		// 	ellipsis: true,
		// 	width: (currentScreen === 'tablet' || currentScreen === 'mobile') ? '18%' : currentScreen === 'laptop' ? '15%' : '6%',
		// 	align: "center",
		// 	showSorterTooltip: false,
		// 	defaultSortOrder: false,
		// 	render: (value) => <Typography.Text className="text-xs lg:text-sm">{parseFloat(value).toFixed(2)}</Typography.Text>,
		// 	sorter: (a, b) => a.outcome - b.outcome,
		// 	cssClass: "bg-group-outcome-score ",
		// 	responsive: ["xs", 'sm'],
		// },
		// {
		// 	title: <Tooltip className="font-bold m-0 text-xs md:text-sm" title={"ค่าดัชนีของผู้ประกอบการในด้านการความเข้มแข็ง"}>Strength</Tooltip>,
		// 	dataIndex: "strength",
		// 	ellipsis: true,
		// 	width: (currentScreen === 'tablet' || currentScreen === 'mobile') ? '18%' : currentScreen === 'laptop' ? '15%' : '6%',
		// 	align: "center",
		// 	showSorterTooltip: false,
		// 	cssClass: "bg-group-strength-score ",
		// 	render: (value) => <Typography.Text className="text-xs lg:text-sm">{parseFloat(value).toFixed(2)}</Typography.Text>,
		// 	sorter: (a, b) => a.strength - b.strength,
		// 	responsive: ["xs", 'sm', 'md'],
		// },
	];

	/**
	 * open page '/ExporterDetail' for show Exporter-Detail, Statistics, etc.
	 * @param {Object} data data from row is clicked on column 'Name'
	 */
	const onClick = (data) => {
		console.log(data);
		const statisticScoreQs = qs.stringify(exporter.exporter.statistic_score, { delimiter: ',' });
		const exporterDataQs = qs.stringify(data, { delimiter: ',' });
		const { average, outcome, strength } = data;
		const URL_EXPORTER_DETAIL = `/ExporterDetail/?statistic_score=${statisticScoreQs}&exporter=${exporterDataQs}&average=${average}&outcome=${outcome}&strength=${strength}`;
		const win = window.open(URL_EXPORTER_DETAIL, "_blank");
		// win.focus();
	};

	const onSearchChange = (event) => {
		const { value } = event.target;
		const searchInputText = value.toLowerCase();
		console.log(searchInputText, !(!!searchInputText));

		if (!searchInputText) {
			// const initialDataTable = services.formatExporterData(exporter?.exporter?.score_table || []);
			// setDataTableState(initialDataTable);
			setDataTableState(dataTableRowsWithRowNumbers || []);
		} else {
			// const exporterFiltered = exporter?.exporter?.score_table
			// 	.filter(exporter => {
			// 		const exportFoundById = exporter?.Tax_No === searchInputText;
			// 		const exporterFoundByName = exporter?.Corporate_NameTH
			// 			.replace(/\s/g, "")
			// 			.toLowerCase()
			// 			.includes(searchInputText.replace(/\s/g, "").toLowerCase());
			// 		return exportFoundById || exporterFoundByName;
			// 	})
			// 	.sort((a, b) => parseFloat(b.average) - parseFloat(a.average));
			// setDataTableState(exporterFiltered);
			
			const exporterFiltered = exporter?.exporter?.score_table
				.filter(exporter => {
					const exportFoundById = exporter?.Tax_No === searchInputText;
					const exporterFoundByName = exporter?.Corporate_NameTH
						.replace(/\s/g, "")
						.toLowerCase()
						.includes(searchInputText.replace(/\s/g, "").toLowerCase());
					return exportFoundById || exporterFoundByName;
				})
				.sort((a, b) => parseFloat(b.average) - parseFloat(a.average));
			let data = exporterFiltered.map((item) => {
				return ({
					...item,
					row_no : dataTableRowsWithRowNumbers.find(row => row?.Tax_No === item?.Tax_No)?.row_no || ''
				})
			})
			console.log(data)
			setDataTableState(data);
		}
	};

	/**
	 * function for render (conditionally) data each column, each row of Table
	 * @param {any} value data in column such as "name's exporter", "strength score", "DV score" etc. (depends on column)
	 * @param {object} record data in row (attribute following by dataIndex of <Column>)
	 * @param {number} index index of row start at 0 index (0, 1, 2, 3, ..., n)
	 * @param {object} currentColumn data about current column
	 * @returns Component based on condition each column
	 */
	const displayValueOfColumn = (value, record, index, currentColumn) => {
		// defualts settings tailwindcss class
		// console.log(value, record, index, currentColumn);

		let TEXT_ALIGN = "text-center";
		let TEXT_COLOR = "text-black";
		let TEXT_DISPLAY = value;

		const dataIndex = currentColumn.dataIndex;

		// if the current column dataIndex in list is needed to display float with 2 decimal (iterate from Array.map()) 
		const INDEX_NEEDED_PARSE_FLOAT_LIST = ["mean", "strength", "outcome", "average"];
		if (INDEX_NEEDED_PARSE_FLOAT_LIST.includes(dataIndex)) {
			TEXT_DISPLAY = parseFloat(value).toFixed(2);
			TEXT_ALIGN = "text-xs md:text-sm text-right font-bold";

		} else if (dataIndex === "Corporate_NameTH") {
			TEXT_ALIGN = "text-left";
			TEXT_COLOR = "text-xs md:text-sm text-primary-dark hover:text-primary";

		} 
		// else if (dataIndex === "index") {
		// 	// RESET INDEX ORDERING AFTER SORT COLUMN
		// 	TEXT_DISPLAY = (currentPage - 1) * 10 + index + 1;
		// }

		// updated tailwindcss className
		const CLASS_NAME_CSS = `${TEXT_ALIGN} ${TEXT_COLOR}`;

		// if the column is 'name', wrap component value with <Link> from ant design component (open new page 'ExpoterDetail')
		const COMPONENT_RENDER = dataIndex === "Corporate_NameTH" ? (
			<Typography.Link
				ellipsis={true}
				className={`w-full text-xs lg:text-sm tracking-wider ${CLASS_NAME_CSS}`}
				onClick={() => onClick(record)}
			>
				<Tooltip
					placement="topLeft"
					align={"left"}
					title={TEXT_DISPLAY}
				>
					{TEXT_DISPLAY}
				</Tooltip>
			</Typography.Link>
		) : (
			<Typography.Paragraph
				className={`w-full text-xs lg:text-sm  ${CLASS_NAME_CSS}`}
			>
				<Tooltip
					placement="topLeft"
					align={"left"}
					title={TEXT_DISPLAY}
				>
					{TEXT_DISPLAY}
				</Tooltip>
			</Typography.Paragraph>
		);

		return COMPONENT_RENDER;
	};

	const handleModalTable = () => {
		setToggleModal({ ...toggleModal, exportCSV: { ...toggleModal.exportCSV, isOpen: true } });
		dataTableCleanedRef.current = dataTableState
			.sort((a, b) => b.Average - a.Average)
			.map(row => {
				return {
					"หมายเลขนิติบุคคล": row.Tax_No,
					"ชื่อผู้ประกอบการ": row.Corporate_NameTH,
					"Average": parseFloat(row.average).toFixed(2),
					EV: parseFloat(row.EV).toFixed(2),
					EY: parseFloat(row.EY).toFixed(2),
					EP: parseFloat(row.EP).toFixed(2),
					EC: parseFloat(row.EC).toFixed(2),
					IP: parseFloat(row.IP).toFixed(2),
					Strength: parseFloat(row.strength).toFixed(2),
					CAGR: parseFloat(row.CAGR).toFixed(2),
					DV: parseFloat(row.DV).toFixed(2),
					"1-CV": parseFloat(row.CV).toFixed(2),
					Outcome: parseFloat(row.outcome).toFixed(2)
				};
			});
	};

	const handleExportExcelFile = () => {
		// console.log(dataTableState);
		// console.log(dataTableCleanedRef.current);
		setToggleModal({ ...toggleModal, exportCSV: { ...toggleModal.exportCSV, isLoading: true } });
		setTimeout(() => {
			const worksheet = XLSX.utils.json_to_sheet(dataTableCleanedRef.current);
			const workbook = XLSX.utils.book_new();
			XLSX.utils.book_append_sheet(workbook, worksheet, "ผู้ประกอบการ");

			XLSX.writeFile(workbook, "exporter-export.xlsx", { compression: true });
			setToggleModal({ ...toggleModal, exportCSV: { ...toggleModal.exportCSV, isLoading: false } });
		}, 800);
	};

	/**
	 * wait data from fetching, then render table of Expoters, otherwise show loading component
	 * @param {Array} exporter
	 * @returns HTML Componennt
	 */
	const waitTable = (exporter) => {
		if (exporter.exporter) {
			return (
				<Table
					bordered
					size={"middle"}
					sticky={{ offsetScroll: 50, offsetHeader: 0 }}
					// defaultSortOrder={""}
					dataSource={dataTableState}
					rowKey={"index"}
					rowSelection={rowSelection}
					pagination={{
						showSizeChanger: true,
						onChange(current) {
							setCurrentPage(current);
						},
					}}
				>
					{columns.map((column, idx) => {
						if (column?.children) {
							// group column
							return (
								<Table.ColumnGroup
									key={column.title}
									title={column.title}
									align={column?.align || "center"}
									responsive={column?.responsive}
									ellipsis={true}
									className="font-bold marker:text-center"
								>
									{column.children.map((colChild, idxChild) => (
										<>
											{(colChild.dataIndex.toLowerCase() === "strength" || colChild.dataIndex.toLowerCase() === "outcome") ? (
												<Table.Column
													ellipsis={true}
													showSorterTooltip={false}
													key={colChild.key}
													responsive={colChild.responsive}
													defaultSortOrder={colChild?.defaultSortOrder}
													sortDirections={["ascend", "descend"]}
													sorter={colChild.sorter}
													width={column?.width}
													align={colChild.align || "center"}
													className={`bg-${column.group} font-bold text-sm text-center`}
													title={colChild.title}
													dataIndex={colChild.dataIndex}
													render={(score) => (
														<span className="font-bold text-sm">
															{parseFloat(score).toFixed(2)}
														</span>
													)}
												/>
											) : (
												<Table.Column
													showSorterTooltip={false}
													ellipsis={true}
													key={colChild.key}
													responsive={colChild.responsive}
													defaultSortOrder={colChild?.defaultSortOrder}
													sortDirections={["ascend", "descend"]}
													sorter={colChild.sorter}
													width={column?.width}
													align={colChild.align || "center"}
													className={`text-center font-bold text-${column.group}`}
													title={colChild.title}
													dataIndex={colChild.dataIndex}
													render={(score) => (
														<span className="text-black text-sm font-normal">
															{parseFloat(score).toFixed(2)}
														</span>
													)}
												/>
											)}
										</>
									))}
								</Table.ColumnGroup>
							);
						} else {
							// single column

							// if width-screen is true = skip render single column of 'strength' and 'outcome', then rendering into group column instead
							if ((currentScreen === 'laptop' || currentScreen === 'desktop') && (column.dataIndex === 'strength' || column.dataIndex === 'outcome')) {
								return;
							}
							return (
								<Table.Column
									ellipsis={true}
									defaultSortOrder={column?.defaultSortOrder}
									responsive={column.responsive}
									sortDirections={["descend", "ascend"]}
									sorter={column?.sorter}
									width={column?.width}
									showSorterTooltip={false}
									key={column?.dataIndex}
									className={`${column?.cssClass && column?.cssClass}`}
									align={column.align || "center"}
									title={column.title}
									dataIndex={column?.dataIndex}
									render={(value, record, index) => displayValueOfColumn(value, record, index, column)}
								/>
							);
						}
					})}
				</Table>
			);
		} else {
			return (
				<div className="loading">
					<Loading spinning={true} size="large">
						<Table dataSource={[]} columns={columns} />
					</Loading>
				</div>
			);
		}
	};

	useEffect(() => {
		// console.log(`exporter changed `, exporter);
		setCurrentPage(1);
		// setDataTableState(services.formatExporterData(exporter?.exporter?.score_table) || []);
		let data = services.formatExporterData(exporter?.exporter?.score_table).map((item, index) => {
			return ({
				...item,
				row_no: index + 1
			})
		})
		setDataTableState(data || []);
		setDataTableRowsWithRowNumbers(data || []);
	}, [exporter.exporter]);

	return (
		<div className="w-full my-3 mx-0 p-0">
			<Row className="my-2" gutter={[8, 8]}>
				<Col span={9}>
					<div className="h-full flex justify-start gap-2">
						<h1 className='lg:w-fit md:text-base xl:text-base font-bold my-auto'>
							รายชื่อผู้ประกอบการ
						</h1>
						<Button
							type={'primary'}
							style={{ width: 'fit-content', display: 'inline' }}
							onClick={() => handleModalTable()}
						>
							<p className="flex justify-center items-center gap-2">
								<DownloadOutlined /> ส่งออกข้อมูล
							</p>
						</Button>
						<Modal
							centered
							width={2000}
							style={{ borderRadius: '8px' }}
							title="ส่งออกข้อมูลผู้ประกอบการ"
							open={toggleModal.exportCSV.isOpen}
							onOk={() => setToggleModal({ ...toggleModal, exportCSV: { ...toggleModal.exportCSV, isOpen: false } })}
							onCancel={() => setToggleModal({ ...toggleModal, exportCSV: { ...toggleModal.exportCSV, isOpen: false } })}
							footer={[
								<Button
									key="back"
									type={'text'}
									onClick={() => setToggleModal({ ...toggleModal, exportCSV: { ...toggleModal.exportCSV, isOpen: false } })}
								>
									ปิด
								</Button>,
								<Button
									key="submit"
									type="primary"
									loading={toggleModal.exportCSV.isLoading}
									onClick={() => handleExportExcelFile()}
								>
									<p className="flex justify-center items-center gap-2">
										<DownloadOutlined /> ส่งออกข้อมูล
									</p>
								</Button>
							]}
						>
							<Table
								dataSource={dataTableCleanedRef.current}
								columns={columnsModal}
							/>
						</Modal>
					</div>
				</Col>
				<Col span={15}>
					<Input
						placeholder="ค้นหาผู้ประกอบการ ..."
						onChange={onSearchChange}
					/>
				</Col>
				<Col span={24}>
					{waitTable(exporter)}
				</Col>
			</Row>
		</div>
	);
}
