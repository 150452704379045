import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { bindActionCreators } from "redux";
import { actionCreators } from "../resources/index";
import { useDispatch, useSelector } from "react-redux";
import ManageTrasfer from "../components/ManageTrasfer";
import { Loading } from "../resources/variables";
// import { keys } from "vega-lite";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function ManageSMS() {
  const query = useQuery();
  const dispatch = useDispatch();
  const { smsActivityId } = bindActionCreators(actionCreators, dispatch);
  const exporter = useSelector((state) => state.exporter.exporter);
  const activity = useSelector((state) => state.smsActivity.smsActivity);
  const [exporterList, setExporterList] = useState();
  const activitySMS = [];
  const [originTargetKeys, setOriginTargetKeys] = useState([]);
  let exporterlist = [];

  async function activitySMSpush(data) {
    for (let i = 0; i < data.length; i++) {
      activitySMS.push({
        key: data[i].Tax_No,
        Tax_No: data[i].Tax_No,
        title: data[i].Corporate_NameTH,
        // score: data[i].score,
      });
    }
  }

  async function SMSdatapush(data) {
    console.log("inFun", data);
    const checkTitle = activitySMS.map((item) => item.title);
    for (let i = 0; i < data.length; i++) {
      if (!checkTitle.includes(data[i].exporter_name)) {
        activitySMS.push({
          key: data[i].tax_no,
          Tax_No: data[i].tax_no,
          title: data[i].exporter_name,
        });
      }
    }
  }

  // async function waitData() {
  //   if (exporter) {
  //     console.log("wait", exporter);
  //   }
  // }

  useEffect(() => {
    const activityId = query.get("activity");
    smsActivityId(activityId);
  }, []);

  useEffect(() => {
    Getdata();
  }, [exporter]);

  const Getdata = () => {
    if (exporter) {
      activitySMSpush(exporter.score_table);
      console.log("exporter", activitySMS);
      if (activitySMS && activity) {
        SMSdatapush(activity.tax_no_list);
        exporterlist = activity.tax_no_list.map((item) => {
          return item.exporter_name;
        });
        setExporterList(activitySMS);
        console.log("accc", activitySMS);
        const originTargetKeys = activitySMS
          .filter((item) => exporterlist.includes(item.title))
          .map((item) => item.key);
        setOriginTargetKeys(originTargetKeys);
      }
    }
  };

  // useEffect(() => {
  //   if (exporter) {
  //     activitySMSpush(exporter.score_table);
  //     originsKeyspush();
  //   }
  //   // activitySMS.concat(SMSdata);
  // }, [activity]);

  // useEffect(() => {
  //   if (activitySMS != [0]) {
  //
  //   }
  // }, [activity]);

  // useEffect(() => {
  //   if (exporter) {
  //     activitySMSpush(exporter.score_table);
  //   }
  //   if (activity) {
  //     SMSdatapush(activity.tax_no_list);
  //   }
  // }, [activity]);

  return (
    <div className="manage-sms">
      {exporter && activity ? (
        <ManageTrasfer
          exporter={exporterList}
          originTargetKeys={originTargetKeys}
          status={activity.status}
          activityName={activity.activity_nameTh}
          activityId={activity.activity_id}
        />
      ) : (
        <Loading tip={"กำลังโหลดข้อมูล"} />
      )}
    </div>
  );
}
