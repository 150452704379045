import React, { useState, useEffect } from "react";
import { Form, Table, InputNumber, Button, Modal, Spin, Alert } from "antd";

import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../resources/index";

const col = [
  { key: "index", title: "Lv1", dataIndex: "lv1", width: 200 },
  {
    key: "index",
    title: "ระยะเวลาหน่วง (เดือน)",
    width: 50,
    render: (row) => {
      return (
        <Form.Item name={row.index}>
          <InputNumber defaultValue={row.delay} />
        </Form.Item>
      );
    },
  },
];

export default function DelaySetting(props) {
  const [formDelay] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const dispatch = useDispatch();
  const { delaySetting } = bindActionCreators(actionCreators, dispatch);
  const status = useSelector((state) => state.delayStatus.delayStatus);

  useEffect(() => {
    if (status === 200) {
      setLoading(false);
      setVisible(true);
      setTimeout(() => {
        setVisible(false);
      }, 1500);
    }
  }, [status]);

  const onFinish = (values) => {
    delaySetting(values);
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  function confirmDelay() {
    Modal.confirm({
      title: "ยืนยันการตั้งค่า",
      icon: <ExclamationCircleOutlined />,
      content: "ต้องการยืนยันที่จะตั้งค่าการหน่วงผลลัพธ์ ?",
      okText: "ตกลง",
      cancelText: "ยกเลิก",
      onOk() {
        formDelay.submit();
        setLoading(true);
      },
    });
  }

  return (
    <div className="delay-content">
      {visible ? (
        <div className="target-alert">
          <Alert
            message={"บันทึกค่าสำเร็จ"}
            type="success"
            showIcon
            style={{ width: "20rem" }}
          />
        </div>
      ) : null}
      {props.data && props.dataInit && (
        <Form
          form={formDelay}
          name="DelaySetting"
          onFinish={onFinish}
          initialValues={props.dataInit}
          onFinishFailed={onFinishFailed}
          style={{ position: "relative" }}
        >
          {console.log("delayValue", props.dataInit)}
          <Spin tip="กำลังตั้งค่า" spinning={loading}>
            <Table columns={col} dataSource={props.data} pagination={false} />
          </Spin>
          <Button
            type="primary"
            onClick={confirmDelay}
            style={{
              position: "absolute",
              width: 70,
              right: "16%",
              bottom: "-50px",
            }}
          >
            บันทึก
          </Button>
        </Form>
      )}
    </div>
  );
}
