import React from "react";

import {
  Row,
  Col,
} from "antd";

import { strengthDescription, outComeDescription } from "../../resources/variables/index";

export default function ActivityRole() {

  return (
    <div className='chart-desciption mt-auto w-full h-full'>
      <h1 className='text-base leading-none font-medium'>
        ปัจจัยที่ใช้ในการคำนวณค่าดัชนี้ผู้ประกอบการ
      </h1>
      <p className='leading-loose text-sm font-medium'>Strength</p>
      <div className='w-full'>
        {strengthDescription.map((element, index) => {
          return (
            <Row key={index.toString()} className=''>
              <Col span={3} className='text-left text-xs'>
                <p>{element.key}</p>
              </Col>
              <Col span={21}>
                <p className='pl-2 tracking-normal text-xs'>
                  {element.description}
                </p>
              </Col>
            </Row>
          );
        })}
      </div>
      <br />
      <p className='leading-loose font-medium text-sm'>Outcome</p>
      <div className='w-full'>
        {outComeDescription.map((element, index) => {
          return (
            <Row key={index.toString()} className=''>
              <Col span={3} className='text-left text-xs text'>
                {element.key}
              </Col>
              <Col span={21}>
                <p className='pl-2 tracking-normal text-xs'>
                  {element.description}
                </p>
              </Col>
            </Row>
          );
        })}
      </div>
    </div>

  );
}
