import React from "react";
import PropsType from "prop-types";
import { Descriptions, Row } from "antd";

/**
 * table description about exporter's information
 * @param {*} param0
 * @returns
 */
function Description({ title, exporterDetail, ...props }) {
  const exporterInfoList = [
    {
      th: "ชื่อผู้ประกอบการ",
      td: exporterDetail?.Corporate_NameTH,
    },
    {
      th: "เลขประจำตัวผู้เสียภาษี",
      td: exporterDetail?.Tax_No,
    },
    {
      th: "ทุนจดทะเบียน",
      td:
        `${exporterDetail?.DBD_CapitalValue.toString().replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ","
        )}` || "ไม่มีข้อมูล",
    },
    {
      th: "ขนาดผู้ประกอบการตามมูลค่าการส่งออก",
      td: exporterDetail?.ComSize,
    },
    {
      th: "ขนาดผู้ประกอบการตามมูลค่าการตามเกณฑ์ สสว.",
      td: exporterDetail?.ComSizeSME,
    },
    {
      th: "ข้อมูลธุรกิจ",
      td: exporterDetail?.DBD_ObjectDetail,
    },
    {
      th: "สถานะ",
      td: exporterDetail?.DBD_juristicStatus,
    },
    {
      th: "ประเภทสมาชิกกรม",
      td: exporterDetail?.ditp_member_type,
    },
    {
      th: "ภูมิภาค",
      td: exporterDetail?.Region_Name_Th,
    },
    {
      th: "จังหวัด",
      td: exporterDetail?.ProvinceName,
    },
    {
      th: "อีเมล",
      td: exporterDetail?.email,
    },
    {
      th: "จำนวนรางวัลที่ได้จากสำนักงานนวัตกรรมแห่งชาติ",
      td: exporterDetail?.NIA_award,
    },
    {
      th: "จำนวนเครื่องหมายการค้า",
      td: exporterDetail?.DIP_trade_mark,
    },
    {
      th: "จำนวนการพบคู่ค้า",
      td: exporterDetail?.n_business_matching,
    },
  ];

  return (
    <>
      <Descriptions
        title={title || "ข้อมูลผู้ประกอบการ"}
        bordered
        size={"small"}
        column={1}
      >
        {exporterInfoList.map((row, index) => {
          return (
            <Descriptions.Item key={index.toString()} label={row.th}>
              {row.td}
            </Descriptions.Item>
          );
        })}
      </Descriptions>
    </>
  );
}

Description.propsType = {
  title: PropsType.string,
  exporterDetail: PropsType.object.isRequired,
};

export default Description;
