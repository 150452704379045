// ----- Packages/Library -----
import React, { useState, useEffect } from "react";
import qs from "qs";
import { useLocation } from "react-router-dom";

// ----- Redux -----
import { bindActionCreators } from "redux";
import { useSelector, useDispatch } from "react-redux";
import { actionCreators } from "../resources/index";

// ----- Styles -----
import "../assets/CSS/mod_activity.css";

// ----- Components -----
import { Spin, Table, Typography, Card, Modal, Button } from "antd";
import MovementGraph from "../components/movement/MovementGraph";
import MovementDetail from "../components/movement/MovementDetail";
import DebounceSelect from "../components/movement/DebounceSelect";
import SkeletonContentLoading from "../components/SkeletonContentLoading";

// ----- Utilities -----
import { instance, domainName } from "../resources/actions/index";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function ActivityIndex() {
  // Hooks
  const query = useQuery();
  const dispatch = useDispatch();
  // selectors, actions
  const exporterMovement = useSelector((state) => state.exporterMovement);
  const exporterMovementSummary = useSelector(
    (state) => state.exporterMovementSummary
  );
  const inputfilter = useSelector((state) => state.filter_input);
  const { getExporterMovement, getExporterMovementSummary } =
    bindActionCreators(actionCreators, dispatch);

  // useState
  const [selectedExporter, setSelectedExporter] = useState([]);
  const [exportDetail, setExportDetail] = useState(null);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState([]);

  useEffect(() => {
    getExporterMovement(inputfilter.filter_input);
    getExporterMovementSummary(inputfilter.filter_input);
    const exporterQs = query.get("exporter");
    if (exporterQs) {
      let exporterData = qs.parse(exporterQs, { delimiter: "," });
      exporterData = Object.values(exporterData);
      setSelectedExporter(exporterData);
    }
  }, []);

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onClickSummary = (data) => {
    const exporterDataQs = qs.stringify({ Tax_No: data }, { delimiter: "," });

    const win = window.open(
      `/ExporterDetail/?exporter=${exporterDataQs}`,
      "_blank"
    );
    win.focus();
  };

  const onClickGroup = (data) => {
    setModalData(data);
    setIsModalOpen(true);
  };

  // console.log(inputfilter.filter_input)

  const listExporterColumn = [
    {
      title: "เลขนิติบุคคล",
      dataIndex: "tax_no",
      key: "tax_no",
      width: "200px",
      sorter: (a, b) => a.tax_no.localeCompare(b.tax_no),
      render: (text) => {
        return (
          <Typography.Link ellipsis={true} onClick={() => onClickSummary(text)}>
            {text}
          </Typography.Link>
        );
      },
    },
    {
      title: "ชื่อบริษัท",
      dataIndex: "name_th",
      key: "name_th",
      width: "300px",
      sorter: (a, b) => a.name_th.localeCompare(b.name_th),
    },
    {
      // title: `มูลค่าการส่งออก ปี ${(inputfilter.filter_input & inputfilter.filter_input['date_export']['end_date'])? inputfilter.filter_input['date_export']['end_date'].slice(4) : ''}`,
      title: `มูลค่าการส่งออกทั้งหมดในปีสุดท้ายตาม filter`,
      dataIndex: "value_baht",
      key: "value_baht",
      width: "300px",
      align: "right",
      sorter: (a, b) => a.value_baht - b.value_baht,
      render: (value) => {
        return (
          <div>{value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
        );
      },
    },
  ];

  const stableColumns = [
    {
      title: "กลุ่มสินค้าที่ส่งออกมากที่สุด",
      dataIndex: "group_name",
      key: "group_name",
      width: "200px",
      sorter: (a, b) => a.group_name.localeCompare(b.group_name),
    },
    {
      title: "จำนวนบริษัท",
      dataIndex: "count",
      sorter: (a, b) => a.count - b.count,
      align: "center",
      width: "100px",
      responsive: ["md"],
    },
    {
      title: "",
      dataIndex: "test",
      align: "center",
      width: "100px",
      responsive: ["md"],
      render: (_, record) => {
        return (
          <Button onClick={() => onClickGroup(record.row_list)}>
            ดูผู้ประกอบการ
          </Button>
        );
      },
    },
  ];

  const comeInColumns = [
    {
      title: "กลุ่มสินค้าก่อนหน้า",
      dataIndex: "group_name",
      key: "group_name",
      width: "200px",
      sorter: (a, b) => a.group_name.localeCompare(b.group_name),
    },
    {
      title: "จำนวนบริษัท",
      dataIndex: "count",
      sorter: (a, b) => a.count - b.count,
      align: "center",
      width: "100px",
      responsive: ["md"],
    },
    {
      title: "",
      dataIndex: "test",
      align: "center",
      width: "100px",
      responsive: ["md"],
      render: (_, record) => {
        return (
          <Button onClick={() => onClickGroup(record.row_list)}>
            ดูผู้ประกอบการ
          </Button>
        );
      },
    },
  ];

  const comeOutColumns = [
    {
      title: "กลุ่มสินค้าใหม่",
      dataIndex: "group_name",
      key: "group_name",
      width: "200px",
      sorter: (a, b) => a.group_name.localeCompare(b.group_name),
    },
    {
      title: "จำนวนบริษัท",
      dataIndex: "count",
      sorter: (a, b) => a.count - b.count,
      align: "center",
      width: "100px",
      responsive: ["md"],
    },
    {
      title: "",
      dataIndex: "test",
      align: "center",
      width: "100px",
      responsive: ["md"],
      render: (_, record) => {
        return (
          <Button onClick={() => onClickGroup(record.row_list)}>
            ดูผู้ประกอบการ
          </Button>
        );
      },
    },
  ];

  const generateTableColumns = (columns) => {
    const columnsResult = [];
    columns.forEach((item, i) => {
      const data = {
        title: item,
        dataIndex: item,
        sorter: (a, b) => a[item] - b[item],
      };
      if (item == "description") {
        data["width"] = "40%";
      }
      columnsResult.push(data);
    });
    return columnsResult;
  };

  // when click on search select box
  const handleChange = (value) => {
    // console.log('handleChange:', value);
    setSelectedExporter(value);
  };

  async function fetchExporterList(keyword) {
    return await instance
      .get(`${domainName}/exporter?search=${keyword}`)
      .then((res) => {
        let result = res.data.results;
        result = result.map((item) => ({
          label: `${item.tax_no}: ${item.name_th}`,
          value: item.tax_no,
        }));
        console.log(result);
        return result;
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  // when click on point in graph area
  const getClickData = (taxNo, year) => {
    setExportDetail({});

    instance
      .get(
        `${domainName}/exporter/movement_detail?tax_no=${taxNo}&year=${year}`
      )
      .then((res) => {
        let data = res.data;
        setExportDetail(data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  // when click in see movement button in detail (right side)
  const onClickSeeMovement = (data) => {
    // console.log(data);

    // add data to end of array state
    setSelectedExporter((selectedExporter) => [...selectedExporter, data]);
  };

  const waitMovementData = () => {
    if (!exporterMovement.exporterMovement) {
      return (
        <div className='hasdf-[]'>
          <Spin size='large' spinning={false}>
            <div className='h-[50vh]'>
              <SkeletonContentLoading />
            </div>
          </Spin>
        </div>
      );
    } else {
      return (
        <div>
          <div className='pb-3'>
            <DebounceSelect
              mode='multiple'
              value={selectedExporter}
              placeholder='ค้นหาผู้ประกอบการ'
              fetchOptions={fetchExporterList}
              onChange={handleChange}
              className='w-full'
            />
          </div>

          <MovementGraph
            filteredCompany={exporterMovement.exporterMovement["filtered"]}
            nonFilteredCompany={
              exporterMovement.exporterMovement["non_filtered"]
            }
            selectedCompany={selectedExporter}
            getClickData={getClickData}
          />
        </div>
      );
    }
  };

  const waitMovementSummary = () => {
    if (!exporterMovementSummary.exporterMovementSummary) {
      return (
        <div className='flex flex-col gap-4'>
          {[...Array(3)].map((v, idx) => {
            return (
              <Spin key={idx} spinning={false}>
                <div className='h-[20vh] bg-slate-100'>
                  <SkeletonContentLoading />
                </div>
              </Spin>
            );
          })}
        </div>
      );
    } else {
      return (
        <div>
          {/* in/out table */}
          <div className='mt-5'>
            <Card
              title={
                <div className='text-xl'>จำนวนผู้ประกอบการที่เข้าออกกลุ่ม</div>
              }
            >
              <div className='mt-5 pl-5'>
                <div className='text-sm'>
                  จำนวนผู้ประกอบการที่เข้าออกกลุ่มจำแนกตามสินค้าของกรม
                  (ตัวกรองสินค้าเท่านั้นที่มีผล){" "}
                </div>
                {/* <div className="text-xs">จำนวนบริษัททั้งหมด: {exporterMovementSummary.exporterMovementSummary['in_out_table']['size']} </div> */}
                <Table
                  size={"small"}
                  columns={generateTableColumns(
                    exporterMovementSummary.exporterMovementSummary[
                      "in_out_table"
                    ]["columns"]
                  )}
                  dataSource={
                    exporterMovementSummary.exporterMovementSummary[
                      "in_out_table"
                    ]["data"]
                  }
                  pagination={{
                    pageSize: 5,
                    position: ["bottomCenter"],
                  }}
                  // className='w-9/12'
                />
              </div>
            </Card>
          </div>

          {/* top hscode2 */}
          <div className='mt-5'>
            <Card
              title={
                <div className='text-xl'>
                  จำนวนผู้ประกอบการที่ส่งออกสินค้าในแต่ละกลุ่ม
                </div>
              }
            >
              <div className='mt-5 pl-5'>
                <div className='text-sm'>
                  จำแนกกลุ่มโดยใช้สินค้า hscode4 ที่ผู้ประกอบการส่งออกมากที่สุด{" "}
                </div>
                <div className='text-xs'>
                  จำนวนบริษัททั้งหมด:{" "}
                  {
                    exporterMovementSummary.exporterMovementSummary[
                      "top_hscode"
                    ]["size"]
                  }{" "}
                </div>
                <Table
                  size={"small"}
                  columns={generateTableColumns(
                    exporterMovementSummary.exporterMovementSummary[
                      "top_hscode"
                    ]["columns"]
                  )}
                  dataSource={
                    exporterMovementSummary.exporterMovementSummary[
                      "top_hscode"
                    ]["data"]
                  }
                  pagination={{
                    pageSize: 5,
                    position: ["bottomCenter"],
                  }}
                  // className='w-9/12'
                />
              </div>
            </Card>
          </div>

          {/* in/out group */}
          <div className='mt-5'>
            <Card
              title={
                <div className='text-xl'>ข้อมูลการเข้า-ออกกลุ่มตาม Filter</div>
              }
            >
              <div className='mt-5 pl-5'>
                <div className='text-sm'>
                  บริษัทที่อยู่ในกลุ่มเดิม :{" "}
                  {
                    exporterMovementSummary.exporterMovementSummary["stable"]
                      .size
                  }{" "}
                  ราย
                </div>
                <Table
                  size={"small"}
                  columns={stableColumns}
                  dataSource={
                    exporterMovementSummary.exporterMovementSummary["stable"]
                      .data
                  }
                  pagination={{
                    pageSize: 5,
                    position: ["bottomCenter"],
                  }}
                  // className='w-9/12'
                />

                <div className='text-sm'>
                  บริษัทใหม่ที่เข้ากลุ่ม :{" "}
                  {
                    exporterMovementSummary.exporterMovementSummary["come_in"]
                      .size
                  }{" "}
                  ราย
                </div>
                <Table
                  size={"small"}
                  columns={comeInColumns}
                  dataSource={
                    exporterMovementSummary.exporterMovementSummary["come_in"]
                      .data
                  }
                  pagination={{
                    pageSize: 5,
                    position: ["bottomCenter"],
                  }}
                  // className='w-9/12'
                />

                <div className='text-sm'>
                  บริษัทที่ออกจากกลุ่ม :{" "}
                  {
                    exporterMovementSummary.exporterMovementSummary["come_out"]
                      .size
                  }{" "}
                  ราย
                </div>
                <Table
                  size={"small"}
                  columns={comeOutColumns}
                  dataSource={
                    exporterMovementSummary.exporterMovementSummary["come_out"]
                      .data
                  }
                  pagination={{
                    pageSize: 5,
                    position: ["bottomCenter"],
                  }}
                  // className='w-9/12'
                />
              </div>
            </Card>

            <Modal
              title='รายชื่อบริษัทในกลุ่ม'
              visible={isModalOpen}
              onOk={handleOk}
              onCancel={handleCancel}
              width={1000}
            >
              <div className='text-sm'>รายชื่อบริษัทในกลุ่ม</div>
              <Table
                size={"small"}
                columns={listExporterColumn}
                dataSource={modalData}
                pagination={{
                  pageSize: 10,
                  position: ["bottomCenter"],
                }}
                //
              />
            </Modal>
          </div>
        </div>
      );
    }
  };

  return (
    <div className={`flex flex-col`}>
      {/* in/out group */}
      <div
        id={`group-movement-summary-pivot`}
        className={`${
          !exporterMovementSummary.exporterMovementSummary &&
          "bg-white p-3 rounded-t-lg"
        }`}
      >
        {waitMovementSummary()}
      </div>

      {/* scatter plot */}
      <div
        id='group-movement-scatter-plot'
        className={`flex flex-row items-stretch space-x-4 w-full ${
          !exporterMovement.exporterMovement
            ? "bg-white p-3 rounded-b-lg"
            : "mt-5"
        }`}
      >
        <div className='w-3/4 h-full'>{waitMovementData()}</div>
        <div className='w-1/4'>
          {!exporterMovement?.exporterMovement ? (
            <SkeletonContentLoading />
          ) : (
            <MovementDetail
              detail={exportDetail}
              onClickSeeMovement={onClickSeeMovement}
            />
          )}
        </div>
      </div>
    </div>
  );
}
