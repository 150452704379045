import React from "react";
// variables
import { initialSummaryGroupData } from "../../resources/variables";
// components
import { Row, Col } from "antd";
import StatsCard from "./StatsCard";

export default function ExporterStatistic({ isLoadded, dataList, ...props }) {
  /**
   * assign data from api
   * @param {Array<object>} initialData summary initial data
   * @param {Object<object>} keyObject  data object from fetch /summary_group/ api
   * @returns data computed
   */
  const computeSummaryGroup = (initialData, keyObject) => {
    // create empty array for return computed data
    const dataComputed = [];
    // number of card
    for (let index = 0; index < initialData.length; index++) {
      const card = initialData[index];

      // compare key, then get data by key
      const data = keyObject[card.key];

      // iterate for assign each children of each card  (pass value by referrence)
      for (let j = 0; j < card.children.length; j++) {
        const key = card.children[j].key;
        // check type of variable, if === object, iterate for assign new data into key children
        card.children[j].value = (typeof data === "object") ? data[key] : data;
        if (card.key === 'export_value') {
          card.children[j].value = card.children[j].value / 1_000_000;
        }
        card.children[j].value = Math.floor(card.children[j].value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
      dataComputed.push(card);
    }

    return dataComputed;
  };

  /**
   * compute and return component group of summary exporter data
   * @param {Array<object>} summmaryData data from fetch api Summary-group
   * @returns HTML component 
   */
  const waitComputeComponent = (summmaryData) => {
    // if (isLoadded && dataList) {
    // destructoring object, then reset object tree-level
    const { num_company, ...partialData } = summmaryData;
    const keyObject = { ...num_company, ...partialData };

    // get computed data, then render card in summary-group component
    const statisticsGroupCard = computeSummaryGroup([...initialSummaryGroupData], keyObject);

    return (
      <>
        <div className="summary-group">
          <div className="hidden xl:flex justify-start items-baseline my-1">
            <h1 className="inline-block text-sm md:text-base font-bold xl:text-base">
              สถิติพื้นฐาน
            </h1>
            <span className="text-primary-grey text-xs md:text-sm xl:text-sm px-2">
              (ในช่วงการส่งออกที่เลือก)
            </span>
          </div>

          <Row className="card-large-group my-2" gutter={[8, 8]}>
            {/* iterating for create card */}
            {statisticsGroupCard.map((statElem) => {
              return (
                <Col key={statElem.title} {...statElem.gridCols}>
                  <StatsCard
                    key={statElem.title}
                    isLoading={!isLoadded}
                    multipleValue={statElem.children.length > 1}
                    title={statElem.title}
                    body={statElem.children}
                  />
                </Col>
              );
            })}
          </Row>
          <p className="text-xs text-primary-grey text-right w-full">
            ขนาดผู้ประกอบการตามมูลค่าการส่งออก
          </p>
        </div>
      </>
    );
  };

  return <div className="detail">{waitComputeComponent(dataList)}</div>;
}
