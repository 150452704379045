import React, { useEffect, useState } from "react";
import "../assets/CSS/mod_sms.css";
import { Typography } from "antd";
import TableSMS from "../components/TableSMS";
import { domainName } from "../resources/actions";
import axios from "axios";

const { Title } = Typography;

export default function CheckSMS() {
  const [SMS, setSMS] = useState();

  useEffect(() => {
    axios
      .get(`${domainName}/sms/logs/`, { withCredentials: true })
      .then((res) => setSMS(res.data))
      .catch((err) => console.log(err));
  }, []);

  return (
    <div className="sms-status">
      <Title>ตรวจสอบสถานะของ SMS</Title>
      <div className="sms-content">
        <TableSMS data={SMS} />
      </div>
    </div>
  );
}
