import { useState, useEffect } from "react";
import { Table, Tooltip, Typography } from "antd";
import Loading from "./Loading";

const textTooltip = `ค่าดัชนีของกิจกรรม คำนวณจากค่าดัชนีเฉลี่ยของผู้ประกอบการที่เข้าร่วมกิจกรรมนั้นๆ ซึ่งคิดมาจากปัจจัยที่มีผลต่อความสำเร็จของผู้ประกอบการ อาทิ จำนวนสินค้าที่ส่งออกได้, ประเทศที่ส่งออกได้, ประสบการณ์ในการส่งออก, อัตราเติบโตของมูลค่าส่งออก  โดยมีค่าสูงสุดเท่ากับ 10 และค่าต่ำสุดเท่ากับ 0`;

export default function RecommendTable(props) {
  const [currentPage, setCurrentPage] = useState(1);
  const activityDataList = props.data?.activity_list || [];

  const onClick = (activityId) => {
    props.onClickActivity(activityId);
  };

  const columns = [
    {
      title: "ลำดับ",
      dataIndex: "index",
      width: "4.5rem",
      align: "center",
      render: (value, item, index) => (currentPage - 1) * 10 + index + 1,
    },
    {
      title: "ชื่อกิจกรรม",
      dataIndex: "Activity_NameTH",
      render: (activity, row) => (
        <h1
          className='text-primary hover:text-sky-800 hover:font-bold active:text-sky-600 hover:cursor-pointer'
          onClick={() => onClick(row.Activity_id)}
        >
          {activity}
        </h1>
      ),
      sorter: (a, b) => a.Activity_NameTH.localeCompare(b.Activity_NameTH),
    },
    {
      title: "ระดับกิจกรรม",
      dataIndex: "Activity_LevelName",
      render: (activityLevelName) => <Typography.Text>{activityLevelName === "กลาง-ปลาย" ? "กลางน้ำ-ปลายน้ำ" : activityLevelName}</Typography.Text>,
      // sorter: (a, b) => a.Activity_LevelName.localeCompare(b.Activity_LevelName),
      filters: [
        {
          text: "ต้นน้ำ",
          value: "ต้นน้ำ",
        },
        {
          text: "กลางน้ำ",
          value: "กลางน้ำ",
        },
        {
          text: "กลางน้ำ-ปลายน้ำ",
          value: "กลาง-ปลาย",
        },
        {
          text: "ปลายน้ำ",
          value: "ปลายน้ำ",
        },
      ],
      onFilter: (value, record) => value === record.Activity_LevelName,
    },
    {
      title: (
        <Tooltip title={textTooltip}>
          <Typography.Text>ค่าดัชนี</Typography.Text>
        </Tooltip>
      ),
      showSorterTooltip: false,
      dataIndex: "score",
      render: (value) => {
        return <Typography.Text>{value > 0 ? value.toFixed(2) : value}</Typography.Text>;
      },
      sorter: (a, b) => a.score - b.score,
    },
  ];

  useEffect(() => {
    setCurrentPage(1);
  }, [props.data]);

  // return <div className="activity-table relative">{waitData()}</div>;
  return (
    <div className="activity-table relative">
      {activityDataList ? (
        <Table
          bordered
          columns={columns}
          dataSource={activityDataList}
          pagination={{
            showSizeChanger: true,
            onChange: (page) => setCurrentPage(page),
          }}
        />
      ) : (
        <Loading message={"กำลังโหลดข้อมูล"}>
          <Table bordered columns={columns} dataSource={[]} loading={true} />
        </Loading>
      )}
    </div>
  );
}
