import React, { useEffect, useRef } from "react";
// import "../assets/CSS/mod_potentialC.css";
import TooltipIcon from "../assets/img/tooltip.png";
import { useSelector, useDispatch } from "react-redux";
import Treemap from "../components/TreemapP";
import { bindActionCreators } from "redux";
import { actionCreators } from "../resources/index";
import PotentialCDetail from "../components/sub-components/PotentialCDetail";
import Loading from "../components/Loading";
import { Tooltip, Typography } from "antd";

const { Text, Link } = Typography;

export default function PotentialC() {
  const { potential, filter_input, date_data } = useSelector((state) => ({
    potential: state?.potential?.potential,
    filter_input: state?.filter_input?.filter_input,
    date_data: state?.time_data?.time_data
  }));
  const dispatch = useDispatch();
  const actions = bindActionCreators(actionCreators, dispatch);
  const parentTreemapRef = useRef();

  useEffect(() => {
    if (!potential?.country) {
      actions.postPotentialC(filter_input);
    }
  }, []);

  const hier = {
    ditp: ["lv1", "lv2", "lv3"],
    zone: ["ZoneTH", "CountryNameTH"],
  };

  const parameter = {
    measure1: "potential",
    measure2: "actual_value",
    numerator: "actual_value",
    denominator: "potential",
  }; // percent = numerator/denominator

  return (
    <div className="potential-country grid grid-cols-10 gap-3 h-full">
      <div className="country-content col-span-7 flex flex-col justify-start">
        <div className="items-center w-full  bg-slate-50" ref={parentTreemapRef}>
          {filter_input &&
            filter_input.country_activity &&
            potential &&
            potential.country > [0] ? (
            <div className="treemap-group relative">
              <Treemap
                data={potential.country}
                hier={hier[filter_input.country_activity.type]}
                parameter={parameter}
                parentLayout={parentTreemapRef.current}
              />
              <Tooltip
                placement="bottomRight"
                color={'white'}
                title={(
                  <div className="tooltip-potential bg-white">
                    <Text style={{ fontSize: "18px", fontWeight: "bold" }}>
                      คำอธิบายสีของ Treemap
                    </Text>
                    <br />
                    <Text>
                      - สีแต่ละสีเป็นการแบ่งกลุ่มของประเทศ
                      โดยประเทศที่อยู่ในกลุ่มเดียวกันจะมีสีเดียวกัน
                    </Text>
                    <br />
                    <Text>
                      - สีเข้มหมายถึง มูลค่าส่งออกจริง และสีอ่อน หมายถึง
                      ศักยภาพที่ยังส่งออกได้
                    </Text>
                  </div>
                )}
              >
                <img
                  className="info-potential"
                  src={TooltipIcon}
                  style={{
                    width: "2rem",
                    marginBottom: "2%",
                    position: "absolute",
                    top: "0px",
                    right: "0px",
                    backgroundColor: "white",
                    opacity: '0.8'
                  }}
                  alt="tooltip" />
              </Tooltip>
            </div>
          ) : (
            <div className="w-full h-full bg-slate-50 col-span-7 flex flex-col justify-center rounded-md">
              {potential && potential.country < [1]
                ? <p className="text-center">"ไม่พบข้อมูล"</p>
                : <Loading>
                  <div className="h-[60vh] w-full"></div>
                </Loading>
              }
            </div>
          )
          }
        </div>
        <div className="items-start bg-slate-50 w-full p-4">
          {filter_input &&
            filter_input.country_activity &&
            potential &&
            potential.country > [0] ? (
            <div className="description">
              <Text style={{ fontSize: "18px", fontWeight: "bold" }}>ที่มา</Text>
              <br />
              <Text>
                - ข้อมูลมูลค่าการส่งออกปัจจุบัน : กรมส่งเสริมการค้าระหว่างประเทศ
              </Text>
              <br />
              <Text>
                - ข้อมูลศักยภาพการส่งออก (Export Potential) : ข้อมูลจาก
                <Link
                  href="https://exportpotential.intracen.org/en/products/tree-map?fromMarker=i&exporter=764&toMarker=w&market=w&whatMarker=k"
                  target="_blank"
                >
                  {" "}
                  International Trade Centre (ITC){" "}
                </Link>
              </Text>
            </div>
          ) : null}
        </div>
      </div>
      <div className="country-details col-span-3 w-full items-start">
        <PotentialCDetail
          year={date_data}
          start_mount={date_data?.start_date}
          end_mount={date_data?.end_date}
        />
      </div>
    </div>
  );
}
