import React from "react";
import { useSelector } from "react-redux";

// components
import RadarChart from "./sub-components/RadarChart";
import SkeletonLineLoading from "./SkeletonLineLoading";
import Loading from "./Loading";

export default function ExporterDetails({ selectedMean, ...props }) {
  const exporter = useSelector((state) => state.exporter);

  return (
    <div className="export-details">

      <h1 className='text-base leading-none'>
        กราฟเรดาร์แสดงค่าดัชนีศักยภาพผู้ประกอบการ
      </h1>
      {
        !!props.radarChartLoading && <SkeletonLineLoading /> // Skeleton Line Loading, when click checkbox from ExporterNew Component
      }
      {exporter.exporter && exporter.exporter.statistic_score ? (
        <>
          <div className="w-11/12 lg:w-5/6 2xl:w-2/3 3xl:w-1/2 mx-auto">
            <RadarChart
              selectedMean={selectedMean > [0] ? selectedMean.radar_selected_mean : []}
              mean={exporter.exporter.statistic_score.mean}
              max={exporter.exporter.statistic_score.max}
            />
          </div>
        </>
      ) : (
        <div className="lg:w-full lg:h-full p-24 bg-slate-50 rounded-2xl">
          <Loading />
        </div>
      )}
      <br />
    </div>
  );
}
