import React from "react";

function SkeletonContentLoading() {
  return (
    <div className='animate-pulse flex items-center w-full h-full space-x-2'>
      <div className='h-full w-full bg-slate-200 rounded-md'></div>
    </div>
  );
}

export default SkeletonContentLoading;
