import React, { useEffect, useRef } from "react";
import TooltipIcon from "../assets/img/tooltip.png";
// import "../assets/CSS/mod_potentialP.css";
import TreemapP from "../components/TreemapP";
import PotentialPDetail from "../components/sub-components/PotentialPDetail";
import Loading from "../components/Loading";
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../resources/index";
import { Tooltip, Typography } from "antd";

const { Text, Link } = Typography;

function PotentialP() {
  const { potential, filter_input, date_data } = useSelector((state) => ({
    potential: state?.potential?.potential,
    filter_input: state?.filter_input?.filter_input,
    date_data: state?.time_data?.time_data
  }));
  // const filter_input = useSelector((state) => state.filter_input.filter_input);
  // const date_data = useSelector((state) => state.time_data.time_data);
  const dispatch = useDispatch();
  // console.log(`[potential]\t`, potential);
  // console.log(`[filter_input]\t`, filter_input);
  // console.log(`[date_data]\t`, date_data);
  const actions = bindActionCreators(actionCreators, dispatch);

  const parentTreemapRef = useRef();

  useEffect(() => {
    console.log(`[useEffect]: postPotentialP`);
    if (!potential?.product) {
      actions.postPotentialP(filter_input);
    }
  }, []);


  const hier = {
    ditp: ["lv1", "lv2", "lv3"],
    show_code: ["L1", "L2", "L3", "L4", "L5"],
    hscode: ["HSCode2", "HSCode4", "HSCode6"],
  };

  const parameter = {
    measure1: "potential",
    measure2: "actual_value",
    numerator: "actual_value",
    denominator: "potential",
  }; // percent = numerator/denominator

  return (
    <div className="potential-product text-pa grid grid-cols-10 gap-3 h-full">
      <div className="product-content col-span-7 flex flex-col justify-start items-start">
        <div className="items-center w-full  bg-slate-50" ref={parentTreemapRef}>
          {potential && potential.product > [0] ? (
            <div className="treemap-group relative">
              <TreemapP
                data={potential.product}
                hier={hier[filter_input.product_activity.type]}
                parameter={parameter}
                parentLayout={parentTreemapRef.current}
              />
              <Tooltip
                placement="bottomRight"
                color={'white'}
                title={(
                  <div className="tooltip-potential text-white">
                    <Text style={{ fontSize: "18px", fontWeight: "bold" }}>
                      คำอธิบายสีของ Treemap
                    </Text>
                    <br />
                    <Text>
                      - สีแต่ละสีเป็นการแบ่งกลุ่มของประเทศ
                      โดยประเทศที่อยู่ในกลุ่มเดียวกันจะมีสีเดียวกัน
                    </Text>
                    <br />
                    <Text>
                      - สีเข้มหมายถึง มูลค่าส่งออกจริง และสีอ่อน หมายถึง
                      ศักยภาพที่ยังส่งออกได้
                    </Text>
                  </div>
                )}
              >
                <img
                  className="info-potential"
                  src={TooltipIcon}
                  style={{
                    width: "2rem",
                    marginBottom: "2%",
                    position: "absolute",
                    top: "0px",
                    right: "0px",
                    backgroundColor: "white",
                    opacity: '0.8'
                  }}
                  alt="tooltip" />
              </Tooltip>
            </div>
          ) : (
            <div className="w-full h-full bg-slate-50 col-span-7 flex flex-col justify-center rounded-md">
              {potential && potential.product < [1]
                ? <p className="text-center">"ไม่พบข้อมูล"</p>
                : <Loading>
                  <div className="h-[60vh] w-full"></div>
                </Loading>
              }
            </div>
          )}
        </div>
        <div className="items-start bg-slate-50 w-full p-4">
          {filter_input &&
            filter_input.product_activity &&
            potential &&
            potential.product > [0] ? (
            <div className="description">
              <Text style={{ fontSize: "18px", fontWeight: "bold" }}>ที่มา</Text>
              <br />
              <Text>
                - ข้อมูลมูลค่าการส่งออกปัจจุบัน : กรมส่งเสริมการค้าระหว่างประเทศ
              </Text>
              <br />
              <Text>
                - ข้อมูลศักยภาพการส่งออก (Export Potential) : ข้อมูลจาก
                <Link
                  href="https://exportpotential.intracen.org/en/products/tree-map?fromMarker=i&exporter=764&toMarker=w&market=w&whatMarker=k"
                  target="_blank"
                >
                  {" "}
                  International Trade Centre (ITC){" "}
                </Link>
              </Text>
            </div>
          ) : null}
        </div>
      </div>
      <div className="product-details col-span-3 w-full">
        <PotentialPDetail
          year={date_data}
          start_mount={date_data?.start_date}
          end_mount={date_data?.end_date}
        />
      </div>
    </div>
  );
}

export default PotentialP;
