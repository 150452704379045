/**
 * get Array of Data, number of charactors can display, then format to string (text-extra)
 * @param {Array} arr list of data for concat string
 * @param {Number} maxLength maximum character can display, otherwise set ellipsis (...)
 * @returns text-extra such as ไทย, จีน, ... +23
 */
const formatArrayWithEllipsis = (arr, maxLength = 50) => {
    if (arr.length === 0) {
        return '';
    }

    if (arr.length === 1) {
        return arr.toString();
    }

    if (arr.length === 2) {
        return arr.join(", ");
    }

    const ellipsis = '...';
    let result = '';

    for (let i = 0; i < arr.length; i++) {
        const text = arr[i];

        if (i > 0) {
            result += ', ';
        }

        const remainingSpace = maxLength - result.length;
        if (remainingSpace >= text.length) {
            result += text;
        } else {
            result += ellipsis;
            break;
        }
    }

    const remainingCount = arr.length - result.split(', ').length;
    if (remainingCount > 0) {
        result += ` +${remainingCount}`;
    }

    return result;
};


/**
 * convert deep/multi-level Array to 1D-Array (Flat Array)
 * @param {Array} data data with multiple-level such as array2d, array3d etc.
 * @param {Array} List empty list for store data after flatted level of Array (pass by reference)
 */
const listGenerator = (data, List) => {
    for (let i = 0; i < data.length; i++) {
        const node = data[i];
        const { title, key, ori_key } = node;
        if (List) {
            List.push({ key, ori_key, title: title });
        }
        if (node.children) {
            listGenerator(node.children, List);
        }
    }
};


/**
 * get parent of key's node such as Node(key="left-a-1") has parent is Node("a")
 * @param {String} key key of filter object
 * @param {Object} tree Filter Object
 * @returns key's parent of node
 */
const getParentKey = (key, tree) => {
    let parentKey;
    for (let i = 0; i < tree.length; i++) {
        const node = tree[i];
        if (node.children) {
            if (node.children.some((item) => item.key === key)) {
                parentKey = node.key;
            } else if (getParentKey(key, node.children)) {
                parentKey = getParentKey(key, node.children);
            }
        }
    }
    return parentKey;
};


const filterFamilyTreeByName = (tree, searchText) => {
    const searchLower = searchText.toLowerCase();
    return tree.filter((element) => {
        let nameLower = null;
        if (!!element?.title) {
            nameLower = element.title.toLowerCase();
        } else {
            return false;
        }
        if (nameLower.includes(searchLower)) {
            return true;
        } else if (element.children) {
            element.children = filterFamilyTreeByName(
                element.children,
                searchText
            );
            return element.children.length > 0;
        }
        return false;
    });
};

const formatExporterData = (exporterList) => {
    const data = exporterList?.map((rowData) => {
        const score = (rowData.outcome + rowData.strength) / 2;
        return {
            ...rowData,
            mean: parseFloat(score),
        };
    }).sort((a, b) => b.mean - a.mean);

    return data || [];
};

const getFlatInitialKeyByFilter = (filters, multilevel = false) => {
    let initializeCheckedKeys = [];
    if (!multilevel) {
        initializeCheckedKeys = filters.map(item => item.key);
    } else {
        listGenerator(filters, initializeCheckedKeys);
        initializeCheckedKeys = initializeCheckedKeys
            .filter(item => item.key.includes("leaf"))
            .map(item => item.key);
    }

    return initializeCheckedKeys;
};

/**
 * search element object by string
 * @param {String} text string input for search element object in array
 * @param {Array<Object>} arr array of object
 * @param {String} propertyTarget target property for search
 */
const searchObjectByString = (text, arr, propertyTarget) => {
    const textLowerCase = text.toLocaleLowerCase();
    return arr?.filter(item => {
        return item[propertyTarget]
            .replace(/\s/g, "")
            .toLocaleLowerCase()
            .includes(textLowerCase.replace(/\s/g, ""));
    });
};

export default {
    formatArrayWithEllipsis,
    listGenerator,
    getParentKey,
    filterFamilyTreeByName,
    formatExporterData,
    getFlatInitialKeyByFilter,
    searchObjectByString
};
