import "../assets/CSS/mod_fullDetail.css";
import "../assets/CSS/mod_line.css";

// React/Package
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";

// antd
import { Card, Table, Typography, Tooltip, Spin, Badge } from "antd";

// hooks
import useQuery from "../hooks/useQuery";

// utils
import { ExporterSelfCol, indicatorObj } from "../resources/variables/index";
import { domainName } from "../resources/actions/index";

// components
import LineChart from "../components/sub-components/LineChart";
import RadarChart from "../components/sub-components/RadarChart";
import BarChart from "../components/sub-components/BarChart";
import { default as ExporterDescription } from "../components/exporter/Description";
import Description from "../components/Description";
import SkeletonContentLoading from "../components/SkeletonContentLoading";
import DelayRenderComponent from "../components/DelayRenderComponent";

// ----- Settings/Declaration -----
const { Text } = Typography;
const badgeColors = [
  {
    key: 1,
    bdColor: `bg-primary-dark`,
    color: `bg-primary`,
  },
  {
    key: 2,
    bdColor: `bg-primary-dark`,
    color: `bg-primary`,
  },
  {
    key: 3,
    bdColor: `bg-primary-grey-dark`,
    color: `bg-primary-grey`,
  },
  {
    key: 4,
    bdColor: `bg-primary-grey-dark`,
    color: `bg-primary-grey`,
  },
  {
    key: 5,
    bdColor: `bg-primary-red-dark`,
    color: `bg-primary-red`,
  },
  {
    key: 6,
    bdColor: `bg-primary-red-dark`,
    color: `bg-primary-red`,
  },
];
/**
 * list of export produc (HS Code 4)
 */
const columns_product = [
  {
    title: "HSCode4",
    dataIndex: "HSCode4",
    key: "HSCode4",
    sorter: (a, b) => a.HSCode4.localeCompare(b.HSCode4),
  },
  {
    title: "มูลค่าส่งออก ทั้งหมด (บาท)",
    width: "150px",
    dataIndex: "ValueBaht",
    key: "ValueBaht",
    align: "right",
    render: (value) => {
      return (
        <Text>{value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Text>
      );
    },
    sorter: (a, b) => a.ValueBaht - b.ValueBaht,
  },
  {
    title: "คำอธิบาย",
    dataIndex: "thdescriptions4",
    key: "thdescriptions4",
    render: (text) => {
      return (
        <Tooltip title={text}>
          <Typography.Paragraph
            ellipsis={{
              rows: 3,
            }}
          >
            {text}
          </Typography.Paragraph>
        </Tooltip>
      );
    },
  },
];

const currentYear = new Date().getFullYear();

// Initialize an empty array to store the objects
const yearArray = [];

// Loop from 2017 to the current year and create objects
for (let year = 2017; year <= currentYear; year++) {
  yearArray.push({
    text: year.toString(),
    value: year,
  });
}

/**
 * group of product export (group by DITP Lv. product)
 */
const columns_ditp_product = [
  {
    title: "ปี",
    dataIndex: "year",
    width: "150px",
    key: "year",
    filters: yearArray,
    filterSearch: true,
    onFilter: (value, record) => record.year == value,
    sorter: (a, b) => a.year - b.year,
  },
  {
    title: "กลุ่มสินค้าระดับ 1",
    width: "150px",
    dataIndex: "lv1",
    key: "lv1",
    align: "right",
  },
  {
    title: "กลุ่มสินค้าระดับ 2",
    width: "150px",
    dataIndex: "lv2",
    key: "lv2",
    align: "right",
  },
  {
    title: "กลุ่มสินค้าระดับ 3",
    width: "150px",
    dataIndex: "lv3",
    key: "lv3",
    align: "right",
  },
  {
    title: "มูลค่าส่งออกทั้งหมด (บาท)",
    width: "200px",
    dataIndex: "value_baht",
    key: "value_baht",
    align: "right",
    render: (value) => {
      return (
        <Text>{value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Text>
      );
    },
    sorter: (a, b) => a.value_baht - b.value_baht,
  },
];

/**
 * variable for descripe about each indicator
 */
const DESCRIPTION_TITLE = "ค่าดัชนีแต่ละมิติ";
/**
 * display data with format from antd component 'Descriptions'
 */
const DESCRIPTION_ITEMS = [
  {
    key: "EV",
    title: "EV : ค่าดัชนียอดส่งออก (บาท/ปี)",
    textExtra: "ค่าดัชนียอดส่งออก (บาท/ปี)  (Exporter Value: EV)",
    data: "-",
    group: "strength",
  },
  {
    key: "EY",
    title: "EY : ค่าดัชนีประสบการณ์ในการส่งออก",
    textExtra:
      "ค่าดัชนีีประสบการณ์ในการส่งออกของผู้ประกอบการ (ปี) (Export Year : EY)",
    data: "-",
    group: "strength",
  },
  {
    key: "EP",
    title: "EP : ค่าดัชนีประเภทของสินค้าส่งออก HSCode 4",
    textExtra: "ค่าดัชนีประเภทของสินค้าส่งออก จำนวนประเภทของ HSCode 4 ต่อปี",
    data: "-",
    group: "strength",
  },
  {
    key: "EC",
    title: "EC : ค่าดัชนีจำนวนตลาดส่งออก",
    textExtra: "ค่าดัชนีจำนวนตลาดส่งออก (จำนวนประเทศ/ปี) (Export Country)",
    data: "-",
    group: "strength",
  },
  {
    key: "IP",
    title: "IP : ค่าดัชนีจำนวนการจดทะเบียนเครื่องหมายการค้า หรือสิทธิบัตร",
    textExtra:
      "ค่าดัชนีจำนวนการจดทะเบียนเครื่องหมายการค้า หรือสิทธิบัตร (ครั้ง/ปี) (Intellectual Property : IP)",
    data: "-",
    group: "strength",
  },
  {
    key: "CAGR",
    title: "CAGR : ค่าดัชนีอัตรการเติบโตต่อปี",
    textExtra:
      "ค่าดัชนีอัตราการเติบโตต่อปีแบบทบต้น โดยคำนวณจากปีที่มูลค่าการส่งออกครบ 12 เดือนแล้วเท่านั้น (Compound Annual Growth Rate : CAGR)",
    data: "-",
    group: "outcome",
  },
  {
    key: "DV",
    title: "DV : ค่าดัชนีผลต่างของมูลค่าจากปีที่แล้ว",
    textExtra:
      "ค่าดัชนีค่าผลต่างของมูลค่าจากปีที่แล้ว (Different Export Value : DV)",
    data: "-",
    group: "outcome",
  },
  {
    key: "CV",
    title: "CV : ค่าดัชนีความผันผวน (1-CV)",
    textExtra: "ค่าดัชนีด้านความผันผวน 1-CV (Coefficient of Variation : CV)",
    data: "-",
    group: "outcome",
  },
  {
    key: "strength",
    title: "Strength : ค่าดัชนีด้านความเข้มแข็ง",
    textExtra:
      "ค่าดัชนีด้านความเข้มแข็งของผู้ประกอบการ (ค่าเฉลี่ยจาก EV, EY, EP, EC, IP)",
    data: "-",
    group: "average",
  },
  {
    key: "outcome",
    title: "Outcome : ค่าดัชนีด้านการเติบโต",
    textExtra:
      "ค่าดัชนีด้านการเติบโตของผู้ประกอบการ (ค่าเฉลี่ยจาก CAGR, DV, CV)",
    data: "-",
    group: "average",
  },
  {
    key: "average",
    title: "Average : ค่าดัชนีเฉลี่ย",
    textExtra: "ค่าดัชนีเฉลี่ย (ค่าเฉลี่ยจาก Strength และ Outcome)",
    data: "-",
    group: "average",
  },
];

/**
 * display score 'strength', 'outcome', 'average' as a Table Component
 * @param {object{ average = 0, strength = 0, outcome = 0 }} ReactComponent for rendering table with these data
 * @returns {React.ReactElement} table component
 */
function ExporterStatsTable({ average = 0, strength = 0, outcome = 0 }) {
  const TABLE_ROW_DATA_ARR = [
    {
      average,
      strength,
      outcome,
    },
  ];
  const TABLE_COLUMN_ARR = [
    {
      title: (
        <Tooltip
          className=" cursor-default"
          title={
            "ค่าดัชนีเฉลี่ยของผู้ประกอบการจากด้านความเข้มแข็ง และความเติบโต"
          }
        >
          ค่าเฉลี่ย (Average)
        </Tooltip>
      ),
      dataIndex: "average",
      key: "average",
      render: (value) => parseFloat(value || 0).toFixed(2),
    },
    {
      title: (
        <Tooltip
          className=" cursor-default"
          title={"ค่าดัชนีของผู้ประกอบการในด้านการความเข้มแข็ง"}
        >
          Strength
        </Tooltip>
      ),
      dataIndex: "strength",
      key: "strength",
      render: (value) => parseFloat(value || 0).toFixed(2),
    },
    {
      title: (
        <Tooltip
          className=" cursor-default"
          title={"ค่าดัชนีของผู้ประกอบการในด้านการความเติบโต"}
        >
          Outcome
        </Tooltip>
      ),
      dataIndex: "outcome",
      key: "outcome",
      render: (value) => parseFloat(value || 0).toFixed(2),
    },
  ];

  return (
    <Table
      pagination={{
        position: ["none", "none"],
      }}
      dataSource={TABLE_ROW_DATA_ARR}
      columns={TABLE_COLUMN_ARR}
    />
  );
}

export default function ExporterDetail() {
  const [sourceKey, setSourceKey] = useState({
    isValid: false,
    isError: false,
    message: `กำลังยืนยันข้อมูล`,
  });
  // state of information's exporter such as detail-about-exporter, history-export, financial-statement
  const [exporterInfo, setExporterInfo] = useState({
    info: {
      data: null,
      loading: true,
      message: null,
      error: false,
    },
    indicatorRadarChart: {
      data: {
        score: {
          EV: 0,
          EY: 0,
          EP: 0,
          EC: 0,
          IP: 0,

          CAGR: 0,
          DV: 0,
          CV: 0,
        },
        mean: {
          EV: 0,
          EY: 0,
          EP: 0,
          EC: 0,
          IP: 0,

          CAGR: 0,
          DV: 0,
          CV: 0,
        },
        max: {
          EV: 0,
          EY: 0,
          EP: 0,
          EC: 0,
          IP: 0,

          CAGR: 0,
          DV: 0,
          CV: 0,
        },
      },
      loading: true,
      message: null,
      error: false,
    },
    indicator: {
      data: null,
      loading: true,
      message: null,
      error: false,
    },
    indicatorDescription: {
      data: null,
      loading: true,
      message: null,
      error: false,
    },
    financialStatement: {
      data: [],
      loading: true,
      message: null,
      error: false,
    },
  });
  const [exporterData, setExporterData] = useState({});
  const [exporterDetail, setExporterDetail] = useState(undefined);
  const [exporterCountryExport, setExporterCountryExport] = useState([]);
  const [exporterFinancialStatement, setExporterFinancialStatement] = useState(
    []
  );
  const pageOptions = [5, 10, 20, 50];
  const [perPage, setPerpage] = useState(5);

  // receive data from querystring params
  const query = useQuery(useLocation().search);
  const source = query.get("source"); // SHA256(tax_no + SHA256(prefix_key))

  /**
   * financial statement of exporter data and config
   */
  const data = {
    labels: exporterInfo.financialStatement.data.map(
      (statement) => statement?.year || "-"
    ),
    datasets: [
      {
        label: "รายได้ธุรกิจ",
        data: exporterInfo.financialStatement.data.map(
          (statement) => statement?.revenue || "-"
        ),
        fill: false,
        backgroundColor: "rgba(22, 104, 178, 0.6)",
        borderColor: "rgb(22, 104, 178)",
        pointBackgroundColor: "rgb(22, 104, 178)",
        pointBorderColor: "#fff",
        pointHoverBackgroundColor: "#fff",
        pointHoverBorderColor: "rgb(22, 104, 178)",
      },
    ],
  };
  const config = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: "bottom",
        align: "start",
      },
    },
  };

  /**
   * [useEffect] for validation source key
   */
  useEffect(() => {
    async function sourceKeyValidation() {
      const payload = { key: source };
      const configHeaders = {
        headers: {
          Authorization: `Api-Key ${source}`,
        },
      };
      axios
        .post(`${domainName}/crm/validate/`, payload, configHeaders)
        .then((response) => {
          const { payload } = response.data;
          setSourceKey({
            isValid: payload.status,
            isError: false,
            message: payload.message,
          });
        })
        .catch((err) => {
          console.log(err.message);
          const { response } = err;
          setSourceKey((prevSourceKey) => {
            let message = (
              <div className="m-3">
                <p className="font-black text-lg text-teal-900 mb-1">
                  ยืนยันข้อมูลไม่ถูกต้อง
                </p>
                <p className="font-normal text-slate-500 text-sm">
                  ({`${response.status}: ${response.statusText}`})
                </p>
              </div>
            );

            if (response.status === 400) {
               message = (
                 <div className="m-3">
                   <p className="font-black text-lg text-teal-900 mb-1">
                     ไม่พบข้อมูลผู้ประกอบการเข้าร่วมกิจกรรม
                   </p>
                 </div>
               );
            }

            return {
              isError: true,
              isValid: false,
              message,
            };
          });
        });
    }
    if (!sourceKey.isValid) {
      sourceKeyValidation();
    }
  }, []);

  /**
   * [useEffect] for fetching all data about exporter
   */
  useEffect(() => {
    /**
     * fetching infomation about exporter (depends of query 'source')
     */
    function getExporterDetail() {
      axios
        .get(`${domainName}/crm/${source}/`, {
          withCredentials: true,
          headers: {
            Authorization: `Api-Key ${source}`,
          },
        })
        .then((res) => {
          console.log(res.data);
          setExporterInfo((prev) => {
            const currentState = {
              ...prev,
              info: {
                ...prev.info,
                loading: false,
                error: false,
                message: null,
                data: { ...res.data.exporter_detail },
              },
              financialStatement: {
                ...prev.financialStatement,
                loading: false,
                error: false,
                message: null,
                data: [...res.data.financial_statement],
              },
            };
            console.log(currentState);
            return currentState;
          });
          setExporterData(res.data);
          setExporterDetail(res.data);
          setExporterCountryExport(res.data?.export_country || []);
          setExporterFinancialStatement(res.data?.financial_statement || []);
        })
        .catch((err) => {
          const { message: errMessage } = err;
          const { response } = err;
          console.log(err.message);
          console.log(err.response);
          setExporterInfo((prev) => {
            const currentState = {
              ...prev,
              info: {
                ...prev.info,
                loading: false,
                error: true,
                message: `${errMessage}: ${response?.data?.message || ""}`,
                data: null,
              },
              financialStatement: {
                ...prev.financialStatement,
                loading: false,
                error: true,
                message: `${errMessage}: ${response?.data?.message || ""}`,
                data: [],
              },
            };
            console.log(currentState);
            return currentState;
          });
        });
    }

    /**
     * fetching score indicators's exporter (depends on query 'source')
     */
    function getExporterScore() {
      axios
        .post(
          `${domainName}/crm/${source}/score/`,
          {},
          {
            withCredentials: true,
            headers: {
              Authorization: `Api-Key ${source}`,
            },
          }
        )
        .then((res) => {
          const { payload } = res.data;
          const { exporter_data, statistic_score: statisticScore } = payload;
          const { index, Tax_No, Corporate_NameTH, ...indicatorScore } =
            exporter_data;

          setExporterInfo((prev) => {
            const currentState = {
              ...prev,
              indicatorRadarChart: {
                ...prev.indicatorRadarChart,
                data: {
                  score: indicatorScore,
                  mean: statisticScore.mean,
                  max: statisticScore.max,
                },
                loading: false,
                error: false,
                message: "",
              },
              indicator: {
                ...prev.indicator,
                loading: false,
                error: false,
                message: null,
                data: {
                  average: indicatorScore.average,
                  strength: indicatorScore.strength,
                  outcome: indicatorScore.outcome,
                },
              },
              indicatorDescription: {
                ...prev.indicatorDescription,
                loading: false,
                error: false,
                message: "",
                data: {
                  title: DESCRIPTION_TITLE,
                  items: DESCRIPTION_ITEMS.map((elm) => {
                    const score2digits = parseFloat(
                      indicatorScore[elm.key]
                    ).toFixed(2);
                    if (score2digits >= 10) {
                      elm.data = parseFloat(score2digits).toFixed();
                    } else {
                      elm.data = score2digits;
                    }

                    return elm;
                  }),
                },
              },
            };
            console.log(currentState);
            return currentState;
          });
        })
        .catch((err) => {
          const { message: errMessage } = err;
          const { response } = err;
          console.log(err.message);
          console.log(err.response);
          const message = `${errMessage}: ${response?.data?.detail || ""}`;
          setExporterInfo((prev) => {
            const currentState = {
              ...prev,
              indicator: {
                ...prev.indicator,
                loading: true,
                error: true,
                data: null,
                message,
              },
              indicatorRadarChart: {
                ...prev.indicatorRadarChart,
                data: null,
                loading: true,
                error: true,
                message,
              },
              indicatorDescription: {
                ...prev.indicatorDescription,
                loading: false,
                error: true,
                message,
                data: {
                  title: DESCRIPTION_TITLE,
                  items: DESCRIPTION_ITEMS,
                },
              },
            };
            console.log(currentState);
            return currentState;
          });
        });
    }

    if (sourceKey.isValid) {
      getExporterDetail();
      getExporterScore();
    }
  }, [sourceKey]);

  const onShowSizeChange = (current, pageSize) => {
    console.log(current, pageSize);
    setPerpage(pageSize);
  };

  /**
   * computing and render about
   * @returns {React.Component}
   */
  const waitData = () => {
    return (
      <div className="flex flex-col gap-4">
        <Card
          title={
            <h1 className="font-bold text-lg">
              ข้อมูลผู้ประกอบการและกราฟเรดาร์แสดงค่าดัชนีศักยภาพ
            </h1>
          }
          style={{
            width: "100%",
            minHeight: "60vh",
            height: "fit-content",
            paddingBottom: "4rem",
          }}
        >
          <div className="w-full mx-auto grid grid-cols-12 gap-4 px-[24px] py-5">
            <div className="col-span-full order-1 lg:col-span-4 flex flex-col justify-start">
              {exporterInfo.info.loading ? (
                <>
                  <h1 className="font-bold text-[16px] mb-5">
                    ข้อมูลผู้ประกอบการ
                  </h1>
                  <SkeletonContentLoading />
                </>
              ) : (
                <>
                  {exporterInfo.info.error && (
                    <span className="font-bold text-red-400 m-10">
                      {exporterInfo.info.message}
                    </span>
                  )}
                  <ExporterDescription
                    title={"ข้อมูลผู้ประกอบการ"}
                    exporterDetail={exporterInfo.info.data}
                  />
                </>
              )}
            </div>
            <div className="col-span-full order-3 lg:col-span-4 w-5/6 h-5/6 mx-auto lg:w-full lg:h-full radar flex flex-col">
              <h1 className="font-bold text-[16px] mb-5">
                กราฟเรดาร์แสดงค่าศักยภาพของผู้ประกอบการ
              </h1>
              {exporterInfo.indicator.error && (
                <span className="font-bold text-red-400 m-10">
                  {exporterInfo.indicator.message}
                </span>
              )}
              {exporterInfo.indicator.loading ? (
                <div className="min-h-10 h-1/5">
                  <SkeletonContentLoading />
                </div>
              ) : (
                <ExporterStatsTable {...exporterInfo.indicator.data} />
              )}
              <div className="my-auto bg-slate-50 rounded-md  lg:h-[80%] lg:flex lg:flex-col lg:justify-center ">
                {exporterInfo.indicatorRadarChart.error && (
                  <span className="font-bold text-red-400 m-10">
                    {exporterInfo.indicatorRadarChart.message}
                  </span>
                )}
                {exporterInfo.indicatorRadarChart.loading ? (
                  <>
                    <SkeletonContentLoading />
                  </>
                ) : (
                  <RadarChart
                    exporterLabel="ค่าดัชนีของผู้ประกอบการ"
                    selectedMean={exporterInfo.indicatorRadarChart.data.score}
                    mean={exporterInfo.indicatorRadarChart.data.mean}
                    max={exporterInfo.indicatorRadarChart.data.max}
                  />
                )}
              </div>
            </div>

            <div
              className={`col-span-full order-4 lg:col-span-4 flex flex-col justify-start min-h-60`}
            >
              {exporterInfo.indicatorDescription.loading ? (
                <>
                  <h1 className="font-bold text-[16px] mb-5">
                    ค่าดัชนีแต่ละมิติ
                  </h1>
                  <SkeletonContentLoading />
                </>
              ) : (
                <>
                  {exporterInfo.indicatorDescription.error && (
                    <span className="font-bold text-red-400 m-10">
                      {exporterInfo.indicatorDescription.message}
                    </span>
                  )}
                  <Description {...exporterInfo.indicatorDescription.data} />
                </>
              )}
            </div>
            <div className={`col-span-full order-2 lg:order-last min-h-60`}>
              <h1 className="font-bold text-[16px] mb-8">
                รายได้ธุรกิจย้อนหลัง{" "}
                {`${exporterFinancialStatement.find(Boolean)?.year || ""}-${
                  exporterFinancialStatement[
                    exporterFinancialStatement.length - 1
                  ]?.year || ""
                }`}
              </h1>
              {exporterInfo.financialStatement.loading ? (
                <>
                  <SkeletonContentLoading />
                </>
              ) : (
                <>
                  {exporterInfo.financialStatement.error && (
                    <span className="font-bold text-red-400 m-10">
                      {exporterInfo.financialStatement.message}
                    </span>
                  )}
                  <BarChart data={data} config={config} />
                </>
              )}
            </div>
          </div>
        </Card>

        <Card
          title={
            <h1 className="font-bold text-lg">
              การเข้าร่วมกิจกรรมและกราฟแสดงมูลค่าการส่งออก
            </h1>
          }
          bordered={false}
          style={{ width: "100%", minHeight: "50vh", paddingBottom: "4rem" }}
        >
          <div className="grid grid-cols-12 gap-4 w-full mx-auto px-[24px] py-8">
            <div className={`col-span-full lg:col-span-6 min-h-80`}>
              <h1 className="font-bold text-[16px] mb-8">
                ตารางประวัติการเข้าร่วมกิจกรรม{" "}
                {exporterInfo.info.error && (
                  <span className="font-bold text-red-400 m-10">
                    {exporterInfo.info.message}
                  </span>
                )}
              </h1>
              {!exporterDetail?.activity_attend ? (
                <SkeletonContentLoading />
              ) : (
                <Table
                  size={"small"}
                  columns={ExporterSelfCol}
                  dataSource={exporterDetail?.activity_attend}
                  pagination={{
                    pageSize: 10,
                    position: ["bottomCenter"],
                  }}
                />
              )}
            </div>

            {
              <div className={"col-span-full lg:col-span-6 min-h-80"}>
                {!exporterDetail?.export_value ? (
                  <>
                    <h1 className="font-bold text-[16px] mb-8">
                      กราฟเส้นแสดงแสดงมูลค่าการส่งออก
                      {exporterInfo.info.error && (
                        <span className="font-bold text-red-400 m-10">
                          {exporterInfo.info.message}
                        </span>
                      )}
                    </h1>
                    <SkeletonContentLoading />
                  </>
                ) : (
                  <>
                    <h1 className="font-bold text-[16px] mb-2 px-4 mx-auto">
                      กราฟเส้นแสดงแสดงมูลค่าการส่งออก
                    </h1>
                    <div className="graph line w-full px-5 mx-auto">
                      <LineChart data={exporterDetail} />
                    </div>
                  </>
                )}
              </div>
            }
          </div>
        </Card>

        <Card
          title={
            <h1 className="font-bold text-lg">
              สินค้าที่ส่งออกทั้งหมด (ทุกปี)
            </h1>
          }
          bordered={false}
          style={{ width: "100%", minHeight: "60vh", paddingBottom: "4rem" }}
        >
          <div
            className={`w-full mx-auto grid grid-cols-12 gap-4 px-[24px] py-5 marker: ${
              !exporterData?.export_product &&
              !exporterData?.export_ditp_product &&
              "gap-y-20"
            }`}
          >
            <div class="col-span-full lg:col-span-6 min-h-60">
              <h1 className="font-bold text-[16px] mb-6">
                ตารางสินค้าที่ส่งออก
                {exporterInfo.info.error && (
                  <span className="font-bold text-red-400 m-10">
                    {exporterInfo.info.message}
                  </span>
                )}
              </h1>
              {!exporterData?.export_product ? (
                <SkeletonContentLoading />
              ) : (
                <Table
                  size={"small"}
                  columns={columns_product}
                  dataSource={exporterData?.export_product}
                  pagination={{
                    showSizeChanger: true,
                    onShowSizeChange,
                    pageSize: perPage,
                    pageSizeOptions: pageOptions,
                  }}
                />
              )}
            </div>

            <div class="col-span-full lg:col-span-6 min-h-60">
              <h1 className="font-bold text-[16px] mb-6">
                สินค้าตามกลุ่มเป้าส่งออกทั้งหมด
                {exporterInfo.info.error && (
                  <span className="font-bold text-red-400 m-10">
                    {exporterInfo.info.message}
                  </span>
                )}
              </h1>
              {!exporterData?.export_ditp_product ? (
                <SkeletonContentLoading />
              ) : (
                <Table
                  size={"small"}
                  columns={columns_ditp_product}
                  dataSource={exporterData?.export_ditp_product}
                  pagination={{
                    showSizeChanger: true,
                    onShowSizeChange,
                    pageSize: perPage,
                    pageSizeOptions: pageOptions,
                  }}
                />
              )}
            </div>

            <div class="col-span-full lg:col-span-12 min-h-40">
              <h1 className="font-bold text-[16px] mb-4">
                ประเทศที่ส่งออกทั้งหมด (ทุกปี)
                {exporterInfo.info.error && (
                  <span className="font-bold text-red-400 m-10">
                    {exporterInfo.info.message}
                  </span>
                )}
              </h1>
              {/* if loading */}
              {!exporterData?.export_product &&
              !exporterData?.export_ditp_product ? (
                <>
                  <SkeletonContentLoading />
                </>
              ) : (
                <div className="p-5">{exporterCountryExport.join(", ")}</div>
              )}
            </div>
          </div>
        </Card>
      </div>
    );
    // }
  };

  return (
    <div className={`w-full`}>
      <Spin
        spinning={!sourceKey.isValid || sourceKey.isError}
        tip={sourceKey.message}
        size={"large"}
      >
        <div className="w-[90%] mx-auto">{waitData()}</div>
      </Spin>
    </div>
  );
}
