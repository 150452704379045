import React from "react";
import Loading from "../Loading";

function StatsCard({
  styleClassName = "",
  multipleValue = false,
  title = "Card title",
  body = [],
  isLoading = false,
  ...props
}) {
  const CONTAINER_CLASS = `px-2 py-3 shadow h-full bg-white rounded-md flex flex-col justify-between items-baseline	 content-stretch justify-items-stretch hover:cursor-default ${styleClassName}`;

  return (
    <div className={CONTAINER_CLASS}>
      <h1 className='text-xs xl:text-sm w-full'>{title}</h1>
      {isLoading ? (
        <Loading spinSize='small' />
      ) : (
        <div
          className={`flex w-full content-end  ${
            multipleValue ? " flex-wrap justify-evenly" : "justify-center"
          }`}
        >
          {body.map((child, idx) => (
            <div
              key={`${child.title}-${idx.toString()}`}
              className={`inline-block `}
            >
              <span className='text-xs text-primary-grey'>{child.title}</span>
              <span className='px-2 text-sm xl:text-xl'>
                {child.value}
                {` ${child.unitCount && child.unitCount}`}
              </span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default StatsCard;
