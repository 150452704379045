import "../assets/CSS/mod_filter.css";
import React from "react";
import { Card, Button } from "antd";
import { useLocation } from "react-router-dom";
// import Activity from "./sub-components/Activity";
// import Exporter from "./sub-components/Exporter";
// import ValueExport from "./sub-components/ValueExport";
import BasicFilter from "./sub-components/BasicFilter";
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../resources/index";


export default function Filter() {
  const location = useLocation();
  const dispatch = useDispatch();
  const inputfilter = useSelector((state) => state.filter_input);
  // console.log(inputfilter);
  const LocalFilter = JSON.parse(localStorage.getItem("filter"));
  const actions = bindActionCreators(actionCreators, dispatch);
  const queryParam = new URLSearchParams(location.search);

  const postData = (payload) => {
    if (location.pathname.includes("/PotentialProduct")) {
      actions.postPotentialP(payload);
    } else if (location.pathname.includes("/PotentialCountry")) {
      actions.postPotentialC(payload);
    } else if (location.pathname.includes("/Activity")) {
      actions.getActivity(payload);
      // console.log("payload", payload);
      actions.getActivityDetail(undefined);
    } else if (location.pathname.includes("/Exporter")) {
      const activityIds = queryParam.get('activities')?.split(",")?.map(id => Number(id)) || [];
      const FILTER_FOR_EXPORTER = {
        ...payload,
        activity_id: activityIds
      };
      actions.postExporter(FILTER_FOR_EXPORTER);
      actions.getSummaryGroupByFilters(FILTER_FOR_EXPORTER);
    } else if (location.pathname.includes("/SuggestActivity")) {
      actions.getSuggestActivity(payload);
    } else if (location.pathname.includes("/Movement")) {
      actions.getExporterMovement(payload);
      actions.getExporterMovementSummary(payload);
    } else if (location.pathname.includes("/ManageSMS")) {
      actions.postExporter(payload);
    }
  };


  return (
    <div className="filter h-full overflow-y-scroll relative">
      {/* sticky from relative's parent (App.js) */}
      <div className="site-card-border-less-wrapper">
        <Card bordered={false}>
          <BasicFilter
            filter={LocalFilter}
            startDate={inputfilter.filter_input}
          />

          {/* Search Button */}
          <div className="sticky bottom-0 w-full p-2 bg-transparent backdrop-blur-3xl">
              <Button
                type="primary"
                style={{ width: '70%', borderRadius: '8px', margin: "auto", display: "block" }}
                onClick={() => postData(inputfilter.filter_input)}
              >
                ค้นหา
              </Button>
            </div>
        </Card>
      </div>
    </div>
  );
}
