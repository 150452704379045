import React, { useState, useEffect } from "react";
import { Card, Divider, Typography } from "antd";
import DelaySetting from "../components/DelaySetting";
import axios from "axios";
// import { delayData } from "../resources/variables/index";
import { domainName } from "../resources/actions/index";

const { Title } = Typography;

export default function ResultDelay() {
  const [delayData, setDelayData] = useState();
  const [dataInit, setDataInit] = useState();

  useEffect(() => {
    axios
      .get(`${domainName}/score-setting/delay/`, { withCredentials: true })
      .then((res) => {
        setDelayData(res.data);

        const init_value = {};
        res.data.forEach((element) => {
          init_value[element.index] = element.delay;
        });

        setDataInit(init_value);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <div className="result-delay">
      <Card
        style={{
          width: "100%",
          height: "100%",
          padding: "2.5rem 5rem 5rem 5rem",
        }}
      >
        <Title style={{ fontSize: 26 }}>ตั้งค่าการหน่วงผลลัพธ์</Title>
        <Divider />
        <DelaySetting data={delayData} dataInit={dataInit} />
      </Card>
    </div>
  );
}
