import React from "react";

function NotFound404() {
  return (
    <Container>
      <h1 className='font-bold text-xl text-center lg:text-2xl text-slate-300 p-10'>
        ไม่พบหน้าที่ค้นหา <br />
        (404 Page Not Found)
      </h1>
    </Container>
  );
}

const Container = ({ children, ...props }) => {
  return (
    <div className='w-full h-[90vh] bg-slate-50 rounded-md flex flex-col justify-center items-center'>
      {children}
    </div>
  );
};

export default NotFound404;
