import React, { useEffect, useState } from "react";
import vegaEmbed from "vega-embed";
import { Checkbox, Tag } from "antd";
import exportValueSpec from "../../assets/vegaSpec/exportValue.json";


const ACTIVITY_MAPPER_COLOR = [
  { name: "เสวนา/ฝึกอบรม/สัมมนา/E-Learning", color: "#AEC7E8", isCheckbox: true, isGroup: true },
  { name: "THAILAND EXHIBITION/Thailand Week/Top Thai Brands", color: "#C5B0D5", isCheckbox: true, isGroup: true },
  { name: "โครงการ/กิจกรรมพิเศษ", color: "#1F77B4", isCheckbox: true, isGroup: false },
  { name: "เสวนา", color: "#AEC7E8", isCheckbox: false, isGroup: false },
  { name: "ฝึกอบรม และสัมมนา", color: "#AEC7E8", isCheckbox: false, isGroup: false },
  { name: "งานแสดงสินค้านานาชาติในประเทศ", color: "#FFBB78", isCheckbox: true, isGroup: false },
  { name: "Online Business Matching", color: "#2CA02C", isCheckbox: true, isGroup: false },
  { name: "คณะผู้แทนการค้าไทยเยือนต่างประเทศ", color: "#98DF8A", isCheckbox: true, isGroup: false },
  { name: "พัฒนาออกแบบ", color: "#D62728", isCheckbox: true, isGroup: false },
  { name: "Top Thai Brands", color: "#C5B0D5", isCheckbox: false, isGroup: false },
  { name: "คณะผู้แทนการค้าต่างประเทศเยือนไทย", color: "#9467BD", isCheckbox: true, isGroup: false },
  { name: "THAILAND EXHIBITION", color: "#C5B0D5", isCheckbox: false, isGroup: false },
  { name: "Thailand Week", color: "#C5B0D5", isCheckbox: false, isGroup: false },
  { name: "ฝึกอบรม", color: "#AEC7E8", isCheckbox: false, isGroup: false },
  { name: "E-Learning", color: "#AEC7E8", isCheckbox: false, isGroup: false },
  { name: "สัมมนา", color: "#AEC7E8", isCheckbox: false, isGroup: false },
  { name: "งานแสดงสินค้าในต่างประเทศ", color: "#7F7F7F", isCheckbox: true, isGroup: false },
];



export default function LineChart(props) {
  // console.log(`[LineChart] props: \n`, props);
  const [activityOncheck, setActivityOncheck] = useState([]);
  const [colorData, setColorData] = useState();

  const initialMapperColorToActivity = () => {
    const activityMappedColorList = props.data.activity_attend.map((activity) => {
      const colorPicked = ACTIVITY_MAPPER_COLOR.find(activityTypeObj => activityTypeObj.name === activity.Activity_TypeNameTH);
      activity.color = colorPicked?.color; // mapped color
      return activity;
    });

    setActivityOncheck(activityMappedColorList);
    setColorData(activityMappedColorList);
    return activityMappedColorList;
  };


  useEffect(() => {
    initialMapperColorToActivity();
  }, []);

  const onCheck = (event) => {
    // object destructuring
    const { checked, value: checkboxName } = event.target;
    // console.log("onCheck", checked, checkboxName);

    let activityFiltered = [];  // for store activiy after filtered for display vega line chart
    const activityMapper = ACTIVITY_MAPPER_COLOR.find(obj => obj.name === checkboxName); // checked that activity is merged before (group of activity) 
    // console.log(activityMapper);

    if (checked) {
      // activity source is not merged
      if (!activityMapper.isGroup) {
        activityFiltered = colorData.filter(obj => obj.Activity_TypeNameTH === checkboxName);
        // console.log(activityFiltered);

      } else {
        const activityNameSplit = activityMapper.name.split("/"); // saperate name of group activity into arr
        // console.log(activityNameSplit); //  ['เสวนา', 'ฝึกอบรม', 'สัมมนา', 'E-Learning']

        // iteration for filtering that activityType has includes saperate name activity
        activityNameSplit.forEach(name => {
          const _filteredActivityAreContainsName = colorData.filter(obj => String(obj.Activity_TypeNameTH).includes(name));
          activityFiltered.push(..._filteredActivityAreContainsName);
        });

        // remove duplicated activity object
        activityFiltered = activityFiltered.reduce((unique, o) => {
          if (!unique.some(obj => obj.Activity_id === o.Activity_id)) {
            unique.push(o);
          }
          return unique;
        }, []);
      }

      // push new activities into state
      setActivityOncheck([...activityOncheck, ...activityFiltered]);
    }

    if (!checked) {
      if (!activityMapper.isGroup) {
        activityFiltered = activityOncheck.filter(obj => obj.Activity_TypeNameTH !== checkboxName);

      } else {
        const activityNameSplit = activityMapper.name.split("/"); // saperate name of group activity into arr
        // console.log(activityNameSplit); //  ['เสวนา', 'ฝึกอบรม', 'สัมมนา', 'E-Learning']

        // iteration for filtering that activityType has includes saperate name activity
        activityNameSplit.forEach(name => {
          const _filteredActivityAreContainsName = colorData.filter(obj => String(obj.Activity_TypeNameTH).includes(name));
          activityFiltered.push(..._filteredActivityAreContainsName);
        });

        // remove duplicated activity object
        activityFiltered = activityFiltered.reduce((unique, o) => {
          if (!unique.some(obj => obj.Activity_id === o.Activity_id)) {
            unique.push(o);
          }
          return unique;
        }, []);

        // get list of activity-id for remove
        const uncheckActivityIds = activityFiltered.map(obj => obj.Activity_id);
        activityFiltered = activityOncheck.filter(obj => !uncheckActivityIds.includes(obj.Activity_id));
        // console.log(activityFiltered);
      }
      // set activity after filter-out
      setActivityOncheck(activityFiltered);
    }

  };

  var yourVlSpec = exportValueSpec;
  yourVlSpec['layer'][0]['data']['values'] = props.data.export_value;
  yourVlSpec['layer'][1]['data']['values'] = activityOncheck;

  vegaEmbed("#vis", yourVlSpec, {
    actions: false,
    renderer: "svg",
  });

  // useEffect(() => console.log(activityOncheck), [activityOncheck]);

  return (
    <div className="line-chart">
      <div className="chart">
        <div id="vis"></div>
      </div>
      <div className="panel-control w-10/12 mx-auto my-11">
        {ACTIVITY_MAPPER_COLOR.map((item, index) => (
          <>
            {
              item.isCheckbox && (
                <Checkbox
                  key={index}
                  defaultChecked={true}
                  value={item.name}
                  onChange={(e) => onCheck(e)}
                >
                  <Tag color={item.color} style={{ height: 10 }}></Tag>
                  {item.name}
                </Checkbox>
              )
            }
          </>
        ))}
      </div>
    </div>
  );
}
