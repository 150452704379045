import React from "react";
import Chart from "chart.js/auto";
// import { Radar } from "react-chartjs-2";

import { DIMENSION_NAME_LIST } from "../../resources/variables/index";

export default function RadarChart(props) {

  /**
   * find description of chart label
   * @param {String} key label of chart such as EV, CAGR etc.
   * @param {Array<Object>} sourceData list of dimension object
   * @returns {String} description about label
   */
  const getDimensionDescriptionByKey = (key, sourceData = DIMENSION_NAME_LIST) => {
    const dimensionObject = sourceData.find(item => item.key === key);
    return dimensionObject?.description || key;
  };

  const optionsChart = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: "bottom",
        align: "start",
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            // console.log(`[Chart.js] tooltip\t`, context);
            const descriptionLabelTooltip = getDimensionDescriptionByKey(context.label);
            // console.log(descriptionLabelTooltip);
            return `${context.formattedValue} : ${descriptionLabelTooltip}`;
          }
        }
      }
    },
  };

  const dataChart = {
    labels: DIMENSION_NAME_LIST.map(indicator => indicator.key),
    datasets: [
      {
        label: props.exporterLabel ? props.exporterLabel : "ค่าดัชนีของผู้ประกอบการที่เลือก",
        data: DIMENSION_NAME_LIST.map((item) => props.selectedMean[item.key]),
        fill: true,
        backgroundColor: "rgba(153, 88, 181, 0.2)",
        borderColor: "rgb(153, 88, 181)",
        pointBackgroundColor: "rgb(153, 88, 181)",
        pointBorderColor: "#fff",
        pointHoverBackgroundColor: "#fff",
        pointHoverBorderColor: "rgb(153, 88, 181)",
      },
      {
        label: "ค่าเฉลี่ยของกลุ่ม",
        data: DIMENSION_NAME_LIST.map((item) => props.mean[item.key]),
        fill: true,
        backgroundColor: "rgba(237, 42, 40, 0.2)",
        borderColor: "rgb(237, 42, 40)",
        pointBackgroundColor: "rgb(237, 42, 40)",
        pointBorderColor: "#fff",
        pointHoverBackgroundColor: "#fff",
        pointHoverBorderColor: "rgb(54, 162, 235)",
      },
      {
        label: "ค่าสูงสุดของกลุ่ม",
        data: DIMENSION_NAME_LIST.map((item) => props.max[item.key]),
        fill: true,
        backgroundColor: "rgba(22, 104, 178, 0.2)",
        borderColor: "rgb(22, 104, 178)",
        pointBackgroundColor: "rgb(22, 104, 178)",
        pointBorderColor: "#fff",
        pointHoverBackgroundColor: "#fff",
        pointHoverBorderColor: "rgb(22, 104, 178)",
      },
    ],
  };

  const initializeChart = (model) => {
    const chartInstance = new Chart(
      document.getElementById("canvas").getContext("2d"),
      model
    );

    return chartInstance;
  };

  // watching on ExporterTable selection rows
  React.useEffect(() => {
    const model = { type: "radar", data: dataChart, options: optionsChart };
    const instance = initializeChart(model);
    return () => instance.destroy();
  }, [props.selectedMean]);

  return (
    <>
      <div id='chart-container' className={'cssClass'}>
        <canvas
          id='canvas'
        ></canvas>
      </div>
    </>
  );
}
