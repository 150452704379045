import React from "react";
import { Card, Typography, Tooltip, Button } from "antd";
// import { useSelector } from "react-redux";

const { Text } = Typography;

export default function RecommendDetail({ data, ...props }) {
  const DATA_LIST = [
    {
      title: "ชื่อกิจกรรม",
      value: data?.Activity_NameTH || "-"
    },
    {
      title: "รหัสกิจกรรม",
      value: data?.Activity_Id || "-"
    },
    {
      title: "ประเภทของกิจกรรม",
      value: data?.Activity_TypeNameTH || "-"
    },
    {
      title: "หน่วยงานที่จัด",
      value: data?.Department_Name_Th || "-"
    },
    {
      title: "ประเทศที่จัด",
      value: data?.CountryNameTH || "-"
    },
    {
      title: "สถานที่จัด",
      value: data?.Location_Name_Th || "-"
    },
    {
      title: "วันที่เริ่มกิจกรรม",
      value: data?.Activity_Start_Date.substring(0, 10) || "-"
    },
    {
      title: "วันที่สิ้นสุดกิจกรรม",
      value: data?.Activity_End_Date.substring(0, 10) || "-"
    }

  ];

  return (
    <div className="detail">
      {
        data ? (
          <Card
            bodyStyle={{
              minHeight: "50vh",
              maxHeight: "80vh",
              overflowY: "scroll",
              overflow: "hidden"
            }}
            actions={[
              <Button
                onClick={() => window.open(data.RegisterActivityURL, "_blank")}
                type="primary"
                className="mt-3"
              >
                ไปยังหน้าสมัคร
              </Button>,
            ]}
            title={
              <Tooltip title={data.Activity_NameTH}>
                รายละเอียดของกิจกรรม
              </Tooltip>
            }
          >
            <div className="table p-6">
              {
                DATA_LIST.map((detail, index) => (
                  <div key={index.toString()} className="t-row text-sm">
                    <div className="t-row l">
                      {detail.title} <Text>:</Text>
                    </div>
                    <div className="t-row r">{detail.value}</div>
                  </div>
                ))
              }
            </div>
          </Card>
        ) : (
          <Card
            style={{
              height: 700,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <p className="text-primary-grey-dark">
              คลิกชื่อกิจกรรมที่ท่านต้องการดูรายละเอียด
            </p>
          </Card>
        )
      }
    </div>
  );
}
