import { Card } from "antd";
import React from "react";
import KnowledgeGraph from "../components/KnowledgeGraph";

export default function KnowledgeFullview() {
  const RecomendedActivity = JSON.parse(
    localStorage.getItem("suggestActivity")
  );
  return (
    <div className="Knowledge-graph">
      <Card>
        <KnowledgeGraph activityData={RecomendedActivity.knowledge_graph} />
      </Card>
    </div>
  );
}
