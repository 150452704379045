import React from "react";
import { Spin, Typography, Tooltip, Tag } from "antd";
import {
  LoadingOutlined,
  CloseOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import moment from "moment";

const { Text } = Typography;
const NodataIcon = <CloseOutlined style={{ fontSize: 24 }} />;
const loadingIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

export const ACTIONS_TYPE = {
  EXPORTER: "exporter",
  SUMMARY_GROUP: "summary_group",
  SUMMARY_BY_ROWS: "summary_by_rows",
  ACTIVITY_DETAIL: "activity_D",
  ACTIVITY_COMPARE_LIST: "ACTIVITY_COMPARE_LIST",
  TEXT_EXTRA_FILTER: "text_extra_filter",
};

export const ROUTE_URL = {
  HOME: "/",
  CRM_DETAIL: "/crm-exporter-detail",
  SELF_ASSESSMENT: "/SelfAssessment",
};

export const initialSummaryGroupData = [
  {
    key: "export_value",
    title: "มูลค่าการส่งออกของกลุ่ม (ล้านบาท)",
    gridCols: {
      xs: {
        span: 24,
        order: 2,
      },
      md: {
        span: 12,
        order: 2,
      },
      lg: {
        span: 9,
        order: 2,
      },
    },
    children: [
      {
        key: "max",
        title: "มากสุด",
        value: 152,
        unitCount: "",
      },
      {
        key: "mean",
        title: "เฉลี่ย",
        value: 10,
        unitCount: "",
      },
      {
        key: "min",
        title: "น้อยสุด",
        value: 1,
        unitCount: "",
      },
    ],
  },
  {
    key: "cagr",
    title: (
      <p className='flex gap-x-4'>
        มูลค่า CAGR ของกลุ่ม (เปอร์เซ็นต์){" "}
        <Tooltip
          placement={"bottom"}
          title={"CAGR คำนวณจากปีที่มูลค่าการส่งออกครบ 12 เดือนแล้วเท่านั้น"}
        >
          <ExclamationCircleOutlined />
        </Tooltip>
      </p>
    ),
    gridCols: {
      xs: {
        span: 24,
        order: 4,
      },
      md: {
        span: 12,
        order: 4,
      },
      lg: {
        span: 9,
        order: 4,
      },
    },
    children: [
      {
        key: "max",
        title: "มากสุด",
        value: "80%",
        unitCount: "",
      },
      {
        key: "mean",
        title: "เฉลี่ย",
        value: "5%",
        unitCount: "",
      },
      {
        key: "min",
        title: "น้อยสุด",
        value: "-20%",
        unitCount: "",
      },
    ],
  },
  {
    key: "export_country",
    title: "จำนวนประเทศที่ส่งออกของกลุ่ม (ประเทศ)",
    gridCols: {
      xs: {
        span: 24,
        order: 2,
      },
      md: {
        span: 12,
        order: 2,
      },
      lg: {
        span: 7,
        order: 2,
      },
    },
    children: [
      {
        key: "max",
        title: "มากสุด",
        value: 152,
        unitCount: "",
      },
      {
        key: "mean",
        title: "เฉลี่ย",
        value: 10,
        unitCount: "",
      },
      {
        key: "min",
        title: "น้อยสุด",
        value: 1,
        unitCount: "",
      },
    ],
  },
  {
    key: "export_product",
    title: "จำนวนสินค้าที่ส่งออกของกลุ่ม (รายการ)",
    gridCols: {
      xs: {
        span: 24,
        order: 3,
      },
      md: {
        span: 12,
        order: 3,
      },
      lg: {
        span: 8,
        order: 3,
      },
    },
    children: [
      {
        key: "max",
        title: "มากสุด",
        value: 152,
        unitCount: "",
      },
      {
        key: "mean",
        title: "เฉลี่ย",
        value: 10,
        unitCount: "",
      },
      {
        key: "min",
        title: "น้อยสุด",
        value: 1,
        unitCount: "",
      },
    ],
  },
  {
    key: "all",
    title: "จำนวนบริษัททั้งหมดของกลุ่ม (ราย)",
    gridCols: {
      xs: {
        span: 24,
        order: 5,
      },
      md: {
        span: 12,
        order: 5,
      },
      lg: {
        span: 3,
        order: 5,
      },
    },
    children: [
      {
        key: "all",
        title: "",
        value: 1209,
        unitCount: "",
      },
    ],
  },
  {
    key: "S",
    title: "บริษัทขนาด S (ราย)",
    gridCols: {
      xs: {
        span: 24,
        order: 6,
      },
      md: {
        span: 8,
        order: 7,
      },
      lg: {
        span: 3,
        order: 6,
      },
    },
    children: [
      {
        key: "S",
        title: "",
        value: 1714,
        unitCount: "",
      },
    ],
  },
  {
    key: "M",
    title: "บริษัทขนาด M (ราย)",
    gridCols: {
      xs: {
        span: 24,
        order: 7,
      },
      md: {
        span: 8,
        order: 8,
      },
      lg: {
        span: 3,
        order: 7,
      },
    },
    children: [
      {
        key: "M",
        title: "",
        value: 519,
        unitCount: "",
      },
    ],
  },
  {
    key: "L",
    title: "บริษัทขนาด L (ราย)",
    gridCols: {
      xs: {
        span: 24,
        order: 8,
      },
      md: {
        span: 8,
        order: 9,
      },
      lg: {
        span: 3,
        order: 8,
      },
    },
    children: [
      {
        key: "L",
        title: "",
        value: 334,
        unitCount: "",
      },
    ],
  },
  {
    key: "non_export",
    title: "บริษัทที่ไม่มีมูลค่าการส่งออก (ราย)",
    gridCols: {
      xs: {
        span: 24,
        order: 9,
      },
      md: {
        span: 12,
        order: 6,
      },
      lg: {
        span: 3,
        order: 9,
      },
    },
    children: [
      {
        key: "non_export",
        title: "",
        value: 0,
        unitCount: "",
      },
    ],
  },
];

export const Loading = (props) => {
  return (
    <div className='loading'>
      <div className='container'>
        <Spin
          indicator={props.tip === "ไม่มีข้อมูล" ? NodataIcon : loadingIcon}
          size='large'
          tip={props.tip}
        ></Spin>
      </div>
    </div>
  );
};

export const ExporterSelfCol = [
  {
    title: "วันที่",
    dataIndex: "Activity_Start_Date",
    key: "date",
    width: "120px",
    render: (history) => {
      return history.substring(0, 10);
    },
    sorter: (a, b) =>
      moment(b.Activity_Start_Date).unix() -
      moment(a.Activity_Start_Date).unix(),
  },
  {
    title: "ชื่อกิจกรรม",
    dataIndex: "Activity_NameTH",
    key: "activityName",
  },
];

export const ACTIVITY_COMPARISON_COLUMNS = [
  {
    title: "รหัสกิจกรรม",
    dataIndex: "Activity_id",
    sorter: (a, b) => a.Activity_id - b.Activity_id,
  },
  {
    title: "ชื่อกิจกรรม",
    dataIndex: "Activity_NameTH",
  },
  {
    title: "ระดับกิจกรรม",
    dataIndex: "Activity_LevelName",
    render: (activityLevelName) => (
      <Text>
        {activityLevelName === "กลาง-ปลาย"
          ? "กลางน้ำ-ปลายน้ำ"
          : activityLevelName}
      </Text>
    ),
    // sorter: (a, b) => a.Activity_LevelName.localeCompare(b.Activity_LevelName),
    filters: [
      {
        text: "ต้นน้ำ",
        value: "ต้นน้ำ",
      },
      {
        text: "กลางน้ำ-ปลายน้ำ",
        value: "กลาง-ปลาย",
      },
      {
        text: "ปลายน้ำ",
        value: "ปลายน้ำ",
      },
    ],
    onFilter: (value, record) => record.Activity_LevelName === value,
  },
  {
    title: "ค่าดัชนี",
    dataIndex: "score",
    // width: "7.5rem",
    align: "center",
    render: (value) => {
      return <Text>{value.toFixed(2)}</Text>;
    },
    sorter: (a, b) => a.score - b.score,
  },
  {
    title: "IP",
    dataIndex: "IP",
    sorter: (a, b) => a["IP"] - b["IP"],
    width: "5%",
    render: (value) => <Typography.Text>{value.toFixed(2)}</Typography.Text>,
  },
  {
    title: "EV",
    dataIndex: "EV",
    sorter: (a, b) => a["EV"] - b["EV"],
    width: "5%",
    render: (value) => <Typography.Text>{value.toFixed(2)}</Typography.Text>,
  },
  {
    title: "EC",
    dataIndex: "EC",
    sorter: (a, b) => a["EC"] - b["EC"],
    width: "5%",
    render: (value) => <Typography.Text>{value.toFixed(2)}</Typography.Text>,
  },
  {
    title: "EP",
    dataIndex: "EP",
    sorter: (a, b) => a["EP"] - b["EP"],
    width: "5%",
    render: (value) => <Typography.Text>{value.toFixed(2)}</Typography.Text>,
  },
  {
    title: "EY",
    dataIndex: "EY",
    sorter: (a, b) => a["EY"] - b["EY"],
    width: "5%",
    render: (value) => <Typography.Text>{value.toFixed(2)}</Typography.Text>,
  },
  {
    title: "DV",
    dataIndex: "DV",
    sorter: (a, b) => a["DV"] - b["DV"],
    width: "5%",
    render: (value) => <Typography.Text>{value.toFixed(2)}</Typography.Text>,
  },
  {
    title: "CAGR",
    dataIndex: "CAGR",
    sorter: (a, b) => a["CAGR"] - b["CAGR"],
    width: "5%",
    render: (value) => <Typography.Text>{value.toFixed(2)}</Typography.Text>,
  },
  {
    title: "CV",
    dataIndex: "CV",
    sorter: (a, b) => a["CV"] - b["CV"],
    width: "5%",
    render: (value) => <Typography.Text>{value.toFixed(2)}</Typography.Text>,
  },
  {
    title: "strength",
    dataIndex: "strength",
    sorter: (a, b) => a["strength"] - b["strength"],
    width: "5%",
    render: (value) => <Typography.Text>{value.toFixed(2)}</Typography.Text>,
  },
  {
    title: "outcome",
    dataIndex: "outcome",
    sorter: (a, b) => a["outcome"] - b["outcome"],
    width: "5%",
    render: (value) => <Typography.Text>{value.toFixed(2)}</Typography.Text>,
  },
];

export const activityCompareColumns = [
  {
    title: "ลำดับ",
    dataIndex: "index",
    align: "center",
    defaultSortOrder: "decend",
    sortDirections: ["decend"],
  },
  {
    title: <Tooltip title='ชื่อกิจกรรม'>ชื่อกิจกรรม</Tooltip>,
    dataIndex: "Activity_NameTH",
    defaultSortOrder: "decend",

    render: (exporter) => {
      return (
        <Tooltip title={exporter}>
          {exporter ? exporter.substring(0, 15) + "..." : "ไม่มีข้อมูล"}
        </Tooltip>
      );
    },
  },
  {
    title: "ค่าดัชนี",
    dataIndex: "score",
    showSorterTooltip: false,
    defaultSortOrder: "decend",

    sorter: (a, b) => a.score - b.score,
    align: "center",
    render: (value) => {
      return (
        <Text>
          {value.toFixed(2)}
          {value <= 2 ? (
            <Tag color='#D33625' style={{ height: 10, marginLeft: 5 }} />
          ) : value <= 4 ? (
            <Tag color='#F4840C' style={{ height: 10, marginLeft: 5 }} />
          ) : value <= 6 ? (
            <Tag color='#F3D303' style={{ height: 10, marginLeft: 5 }} />
          ) : value < 8 ? (
            <Tag color='#B0CD00' style={{ height: 10, marginLeft: 5 }} />
          ) : value >= 8 ? (
            <Tag color='#5DBB0B' style={{ height: 10, marginLeft: 5 }} />
          ) : null}
        </Text>
      );
    },
  },
];

export const compareColumn = [
  {
    title: "ลำดับ",
    dataIndex: "index",
    align: "center",
    defaultSortOrder: "decend",
    sortDirections: ["decend"],
  },
  {
    title: <Tooltip title='ชื่อกิจกรรม'>ชื่อกิจกรรม</Tooltip>,
    dataIndex: "Activity_NameTH",
    defaultSortOrder: "decend",

    render: (exporter) => {
      return (
        <Tooltip title={exporter}>
          {exporter ? exporter.substring(0, 15) + "..." : "ไม่มีข้อมูล"}
        </Tooltip>
      );
    },
  },
  {
    title: "ค่าดัชนี",
    dataIndex: "score",
    showSorterTooltip: false,
    defaultSortOrder: "decend",

    sorter: (a, b) => a.score - b.score,
    align: "center",
    render: (value) => {
      return (
        <Text>
          {value.toFixed(2)}
          {value <= 2 ? (
            <Tag color='#D33625' style={{ height: 10, marginLeft: 5 }} />
          ) : value <= 4 ? (
            <Tag color='#F4840C' style={{ height: 10, marginLeft: 5 }} />
          ) : value <= 6 ? (
            <Tag color='#F3D303' style={{ height: 10, marginLeft: 5 }} />
          ) : value < 8 ? (
            <Tag color='#B0CD00' style={{ height: 10, marginLeft: 5 }} />
          ) : value >= 8 ? (
            <Tag color='#5DBB0B' style={{ height: 10, marginLeft: 5 }} />
          ) : null}
        </Text>
      );
    },
  },
  {
    title: <Tooltip title='จำนวนกิจกรรมต้นน้ำที่เข้าร่วม'>ALS</Tooltip>,
    dataIndex: "Activity_LevelStart",
    align: "right",
    showSorterTooltip: false,
    defaultSortOrder: "decend",
    render: (value) => {
      return <Text>{value.toFixed(2)}</Text>;
    },
    sorter: (a, b) => a.Activity_LevelStart - b.Activity_LevelStart,
  },
  {
    title: <Tooltip title='จำนวนกิจกรรมกลางน้ำที่เข้าร่วม'>ALM</Tooltip>,
    dataIndex: "Activity_LevelMiddle",
    align: "right",
    showSorterTooltip: false,
    defaultSortOrder: "decend",
    render: (value) => {
      return <Text>{value.toFixed(2)}</Text>;
    },
    sorter: (a, b) => a.Activity_LevelMiddle - b.Activity_LevelMiddle,
  },
  {
    title: <Tooltip title='จำนวนกิจกรรมปลายน้ำเข้าร่วม'>ALE</Tooltip>,
    dataIndex: "Activity_LevelEnd",
    align: "right",
    showSorterTooltip: false,
    defaultSortOrder: "decend",
    render: (value) => {
      return <Text>{value.toFixed(2)}</Text>;
    },
    sorter: (a, b) => a.Activity_LevelEnd - b.Activity_LevelEnd,
  },
  {
    title: <Tooltip title='ต้นทุนในการเข้าร่วมกิจกรรม'>PA</Tooltip>,
    dataIndex: "payment_amt",
    align: "right",
    showSorterTooltip: false,
    defaultSortOrder: "decend",
    render: (value) => {
      return <Text>{value.toFixed(2)}</Text>;
    },
    sorter: (a, b) => a.payment_amt - b.payment_amt,
  },
  {
    title: <Tooltip title='จำนวนรางวัล PM Award ที่ได้รับ'>PMA</Tooltip>,
    dataIndex: "CountPMAward",
    align: "right",
    showSorterTooltip: false,
    defaultSortOrder: "decend",
    render: (value) => {
      return <Text>{value.toFixed(2)}</Text>;
    },
    sorter: (a, b) => a.CountPMAward - b.CountPMAward,
  },
  {
    title: <Tooltip title='จำนวนตรามาตรฐาน T-Mark ที่ได้รับ'>TMA</Tooltip>,
    dataIndex: "CountTMark",
    align: "right",
    showSorterTooltip: false,
    defaultSortOrder: "decend",
    render: (value) => {
      return <Text>{value.toFixed(2)}</Text>;
    },
    sorter: (a, b) => a.CountTMark - b.CountTMark,
  },
  {
    title: <Tooltip title='จำนวนตราสัญลักษณ์ Demark ที่ได้รับ'>DMA</Tooltip>,
    dataIndex: "CountDMark",
    align: "right",
    showSorterTooltip: false,
    defaultSortOrder: "decend",
    render: (value) => {
      return <Text>{value.toFixed(2)}</Text>;
    },
    sorter: (a, b) => a.CountDMark - b.CountDMark,
  },
  {
    title: <Tooltip title='ยอดส่งออก'>EV</Tooltip>,
    dataIndex: "ExportValueBaht",
    align: "right",
    showSorterTooltip: false,
    defaultSortOrder: "decend",
    render: (value) => {
      return <Text>{value.toFixed(2)}</Text>;
    },
    sorter: (a, b) => a.ExportValueBaht - b.ExportValueBaht,
  },
  {
    title: <Tooltip title='อัตราการเติบโตยอดส่งออก'>EG</Tooltip>,
    dataIndex: "ExportGrowth",
    align: "right",
    showSorterTooltip: false,
    defaultSortOrder: "decend",
    render: (value) => {
      return <Text>{value.toFixed(2)}</Text>;
    },
    sorter: (a, b) => a.ExportGrowth - b.ExportGrowth,
  },
  {
    title: <Tooltip title='จำนวนตลาดส่งออก'>EC</Tooltip>,
    dataIndex: "ExportCountCountry",
    align: "right",
    showSorterTooltip: false,
    defaultSortOrder: "decend",
    render: (value) => {
      return <Text>{value.toFixed(2)}</Text>;
    },
    sorter: (a, b) => a.ExportCountCountry - b.ExportCountCountry,
  },
  {
    title: <Tooltip title='ความหลากหลายของสินค้าส่งออก'>EP</Tooltip>,
    dataIndex: "CountHSCode6",
    align: "right",
    showSorterTooltip: false,
    defaultSortOrder: "decend",
    render: (value) => {
      return <Text>{value.toFixed(2)}</Text>;
    },
    sorter: (a, b) => a.CountHSCode6 - b.CountHSCode6,
  },
  {
    title: <Tooltip title='จำนวนปี(ประสบการณ์)ที่เคยส่งออก'>EY</Tooltip>,
    dataIndex: "ExportYear",
    align: "right",
    showSorterTooltip: false,
    defaultSortOrder: "decend",
    render: (value) => {
      return <Text>{value.toFixed(2)}</Text>;
    },
    sorter: (a, b) => a.ExportYear - b.ExportYear,
  },
];

export const delayData = [
  {
    index: 1,
    lv1: "เกษตรและอาหาร",
    lv2: "สินค้าเกษตร",
    lv3: "ข้าว",
  },
  {
    index: 2,
    lv1: "เกษตรและอาหาร",
    lv2: "สินค้าเกษตร",
    lv3: "ยางพารา",
  },
  {
    index: 3,
    lv1: "เกษตรและอาหาร",
    lv2: "สินค้าเกษตร",
    lv3: "ผลิตภัณฑ์มันสำปะหลัง",
  },
  { index: 4, lv1: "เกษตรและอาหาร", lv2: "สินค้าเกษตร", lv3: "น้ำตาล" },
  {
    index: 5,
    lv1: "เกษตรและอาหาร",
    lv2: "อาหาร",
    lv3: "อาหารทะเลแช่แข็ง/กระป๋อง/แปรรูป (ไม่รวมกุ้ง)",
  },
  {
    index: 6,
    lv1: "เกษตรและอาหาร",
    lv2: "อาหาร",
    lv3: "กุ้งสด แช่เย็น แช่แข็ง และแปรรูป ",
  },
  {
    index: 7,
    lv1: "เกษตรและอาหาร",
    lv2: "อาหาร",
    lv3: "ไก่สด แช่เย็น แช่แข็ง และแปรรูป",
  },
  {
    index: 8,
    lv1: "เกษตรและอาหาร",
    lv2: "อาหาร",
    lv3: "ผัก ผลไม้กระป๋อง และแปรรูป",
  },
  {
    index: 9,
    lv1: "เกษตรและอาหาร",
    lv2: "อาหาร",
    lv3: "ผัก ผลไม้สดแช่เย็น แช่แข็ง และแห้ง",
  },
  { index: 10, lv1: "เกษตรและอาหาร", lv2: "อาหาร", lv3: "อาหารอื่นๆ" },
  { index: 11, lv1: "อุตสาหกรรมหนัก", lv2: "อิเล็กทรอนิกส์", lv3: "" },
  { index: 12, lv1: "อุตสาหกรรมหนัก", lv2: "เครื่องใช้ไฟฟ้า", lv3: "" },
  { index: 13, lv1: "อุตสาหกรรมหนัก", lv2: "ยานพาหนะ", lv3: "" },
  {
    index: 14,
    lv1: "อุตสาหกรรมหนัก",
    lv2: "อุปกรณ์และส่วนประกอบยานยนต์",
    lv3: "",
  },
  {
    index: 15,
    lv1: "อุตสาหกรรมหนัก",
    lv2: "เม็ดพลาสติก และผลิตภัณฑ์",
    lv3: "",
  },
  { index: 16, lv1: "อุตสาหกรรมหนัก", lv2: "วัสดุก่อสร้าง", lv3: "" },
  { index: 17, lv1: "อุตสาหกรรมหนัก", lv2: "ผลิตภัณฑ์ยาง", lv3: "" },
  {
    index: 18,
    lv1: "อุตสาหกรรมหนัก",
    lv2: "เครื่องจักรกลและส่วนประกอบ",
    lv3: "",
  },
  { index: 19, lv1: "แฟชั่น", lv2: "อัญมณีและเครื่องประดับ หักทองคำ", lv3: "" },
  { index: 20, lv1: "แฟชั่น", lv2: "เครื่องนุ่งห่ม", lv3: "" },
  { index: 21, lv1: "แฟชั่น", lv2: "ผ้าผืนและเส้นด้าย", lv3: "" },
  {
    index: 22,
    lv1: "แฟชั่น",
    lv2: "เครื่องหนัง/รองเท้า/เครื่องเดินทาง",
    lv3: "",
  },
  { index: 23, lv1: "ไลฟ์สไตล์", lv2: "เฟอร์นิเจอร์และชิ้นส่วน", lv3: "" },
  {
    index: 24,
    lv1: "ไลฟ์สไตล์",
    lv2: "ของขวัญของชำร่วยของตกแต่งบ้าน",
    lv3: "",
  },
  {
    index: 25,
    lv1: "ไลฟ์สไตล์",
    lv2: "เครื่องใช้บนโต๊ะอาหารและในครัว",
    lv3: "",
  },
  { index: 26, lv1: "ไลฟ์สไตล์", lv2: "เคหะสิ่งทอ", lv3: "" },
  { index: 27, lv1: "ไลฟ์สไตล์", lv2: "ของเล่น", lv3: "" },
  {
    index: 28,
    lv1: "ไลฟ์สไตล์",
    lv2: "เครื่องเขียน เครื่องใช้สำนักงานทำจากพลาสติก",
    lv3: "",
  },
  {
    index: 29,
    lv1: "สุขภาพ/ความงาม",
    lv2: "เครื่องสำอาง/สบู่/ผลิตภัณฑ์รักษาผิว",
    lv3: "",
  },
  { index: 30, lv1: "สุขภาพ/ความงาม", lv2: "เภสัชภัณฑ์", lv3: "" },
  {
    index: 31,
    lv1: "สุขภาพ/ความงาม",
    lv2: "เครื่องมือแพทย์และอุปกรณ์",
    lv3: "",
  },
  { index: 32, lv1: "สินค้าอื่นๆ", lv2: "น้ำมันสำเร็จรูป", lv3: "" },
  { index: 33, lv1: "สินค้าอื่นๆ", lv2: "เคมีภัณฑ์", lv3: "" },
  { index: 34, lv1: "สินค้าอื่นๆ", lv2: "ทองคำยังไม่ขึ้นรูป", lv3: "" },
  { index: 35, lv1: "สินค้าอื่นๆ", lv2: "น้ำมันดิบ", lv3: "" },
  { index: 36, lv1: "สินค้าอื่นๆ", lv2: "เลนส์", lv3: "" },
];

// indicator_column
export const strengthDescription = [
  {
    key: "EV",
    description: "ยอดการส่งออก (บาท/ปี)",
    title: (
      <Tooltip title={"EV (Export Value) : ยอดการส่งออก (บาท/ปี)"}>EV</Tooltip>
    ),
  },
  {
    key: "EY",
    description: "ประสบการณ์ในการส่งออกของผู้ประกอบการ (ปี)",
    title: (
      <Tooltip
        title={"EY (Export Year) : ประสบการณ์ในการส่งออกของผู้ประกอบการ (ปี)"}
      >
        EY
      </Tooltip>
    ),
  },
  {
    key: "EP",
    description: "ความหลากหลายของสินค้าส่งออก (จำนวนประเภทของ HScode(4)/ปี)",
    title: (
      <Tooltip
        title={
          "EP (Export Product) : ความหลากหลายของสินค้าส่งออก (จำนวนประเภทของ HScode(4)/ปี)"
        }
      >
        EP
      </Tooltip>
    ),
  },
  {
    key: "EC",
    description: "จำนวนตลาดส่งออก",
    title: (
      <Tooltip title={"EC (Export Country) : จำนวนตลาดส่งออก"}>EC</Tooltip>
    ),
  },
  {
    key: "IP",
    description: "จำนวนการจดทะเบียนเครื่องหมายการค้าหรือสิทธิบัตร (ครั้ง/ปี)",
    title: (
      <Tooltip
        title={
          "IP (Intellectual Property) : จำนวนการจดทะเบียนเครื่องหมายการค้าหรือสิทธิบัตร (ครั้ง/ปี)"
        }
      >
        IP
      </Tooltip>
    ),
  },
];

export const outComeDescription = [
  {
    key: "CAGR",
    description:
      "อัตราการเติบโตต่อปีแบบทบต้น (คำนวณจากปีที่มูลค่าการส่งออกครบ 12 เดือนแล้วเท่านั้น)",
    title: (
      <Tooltip
        title={
          "CAGR (Compound Annual Growth Rate) : อัตราการเติบโตต่อปีแบบทบต้น (คำนวณจากปีที่มูลค่าการส่งออกครบ 12 เดือนแล้วเท่านั้น)"
        }
      >
        CAGR
      </Tooltip>
    ),
  },
  {
    key: "DV",
    description: "ค่าผลต่างของมูลค่าจากปีที่แล้ว",
    title: (
      <Tooltip
        title={"DV (Different Export Value) : ค่าผลต่างของมูลค่าจากปีที่แล้ว"}
      >
        DV
      </Tooltip>
    ),
  },
  {
    key: "CV",
    description: "ค่าความผันผวน",
    title: (
      <Tooltip title={"CV (Coefficient of Variation) : ค่าความผันผวน"}>
        CV
      </Tooltip>
    ),
  },
  // {
  //   title: "DEP",
  //   fullTitle: "DEP",
  //   description: "จำนวนสินค้าที่เพิ่มขึ้น หรือลดลง",
  // },
  // {
  //   title: "DEC",
  //   fullTitle: "DEC",
  //   description: "จำนวนตลาดที่เพิ่มขึ้น หรือลดลง",
  // },
  // {
  //   title: "CE",
  //   fullTitle: "CE",
  //   description: "เปรียบเทียบกับผู้ประกอบการที่ส่งออกทั้งหมด",
  // },
];

export const DIMENSION_NAME_LIST = [
  ...strengthDescription.map((item) => item),
  ...outComeDescription.map((item) => item),
];

// import { in}
export const indicatorObj = {
  EV: 0,
  EY: 0,
  EP: 0,
  EC: 0,
  IP: 0,

  CAGR: 0,
  DV: 0,
  CV: 0,
};
