import React, { useLayoutEffect, useEffect, useState } from "react";
import "../assets/CSS/treemap.css";
import * as d3 from "d3";
import { Button } from "antd";
import { useLocation } from "react-router-dom";
import {
  useDispatch,
  // useSelector
} from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../resources/index";

export default function TreemapP({parentLayout, ...props}) {
  const dispatch = useDispatch();
  const location = useLocation();
  const actions = bindActionCreators(actionCreators, dispatch);
  
  const [grandData, setGrandData] = useState();
  // const [treemapWidth, setTreemapWidth] = useState(parentLayout?.offsetWidth || 300);

  const color_range = [
    "#7fc97f",
    "#beaed4",
    "#fdc086",
    // "#ffff99",
    "#9966CC",
    "#386cb0",
    "#f0027f",
    "#bf5b17",
    "#666666",
  ]; //['#03A9F4', '#ADD8E6', '#ffb300'];

  useEffect(() => {
    if (location.pathname === "/PotentialProduct") {
      actions.postPotentialPDetail(grandData);
    } else if (location.pathname === "/PotentialCountry") {
      actions.postPotentialCDetail(grandData);
    }
  }, [grandData]);

  var tooltip_measures = [
    {
      key: "tooltip_measure_1",
      title: "Export Potential: ",
      measure: "potential",
      format: ",.0f",
    },
    {
      key: "tooltip_measure_2",
      title: "Actual Export: ",
      measure: "actual_value",
      format: ",.0f",
    },
    {
      key: "tooltip_measure_3",
      title: "Export Percent: ",
      measure: "percent",
      format: ",.0%",
    },
  ];

  function buildHierarchy(
    flat_data,
    level_names,
    measure_1,
    measure_2 = null,
    measure_3 = null,
    percent = false,
    numerator = null,
    denom = null,
    color_measure = null,
    tooltip_measures = null
  ) {
    var nest = d3.nest().rollup(function (leaves) {
      var sums = {};
      sums[measure_1] = d3.sum(leaves, (l) => l[measure_1]);
      if (measure_2) {
        sums[measure_2] = d3.sum(leaves, (l) => l[measure_2]);
      }
      if (measure_3) {
        sums[measure_3] = d3.sum(leaves, (l) => l[measure_3]);
      }
      return sums;
    });
    // If first level is not a unique grandparent, create one!
    var first_level = d3.map(flat_data, (d) => d[level_names[0]]).keys();
    if (first_level.length > 1) {
      nest = nest.key(function (d) {
        return "All";
      });
    }
    // Add all level columns into nesting structure.
    level_names.forEach(function (level) {
      nest = nest.key(function (d) {
        return d[level];
      });
    });
    var data = nest.entries(flat_data)[0];

    // Start the d3.hierarchy magic.
    var root = d3
      .hierarchy(data, (d) => d.values)
      // Computes a 'value' property for all nodes.
      .sum((d) => (d["value"] ? d["value"][measure_1] : 0))
      .sort(function (a, b) {
        return b.value - a.value;
      });

    if (measure_2) {
      // Compute the second measure for all nodes, starting with leaves.
      root.leaves().forEach(function (leaf) {
        leaf[measure_2] = leaf.data.value[measure_2];
        // Accumulate this second_measure up the tree.
        leaf.ancestors().forEach(function (node, idx) {
          if (idx == 0) {
            return;
          } // excluding itself
          node[measure_2] = leaf[measure_2] + (node[measure_2] || 0);
        });
      });
    }

    if (measure_3) {
      // Compute the second measure for all nodes, starting with leaves.
      root.leaves().forEach(function (leaf) {
        leaf[measure_3] = leaf.data.value[measure_3];
        // Accumulate this second_measure up the tree.
        leaf.ancestors().forEach(function (node, idx) {
          if (idx == 0) {
            return;
          } // excluding itself
          node[measure_3] = leaf[measure_3] + (node[measure_3] || 0);
        });
      });
    }

    // Prune the tree - remove repeated leaf nodes
    prune(root);

    function prune(node) {
      if (!node.children) {
        return;
      }
      // if (node.children.length > 1) {
      if (node.children[0].data.key !== "0") {
        node.children.forEach((n) => prune(n));
      } else {
        delete node.children;
      }
    }

    // Compute the aggregate measure for all nodes, starting with leaves.
    root.each(function (node) {
      node[measure_1] = node.value;
      if (percent) {
        // Repeat the size_measure so that it is available for computation.
        // node["percent"] = node[numerator] / node[denom]; //|| 0;
        node["percent"] = node[denom] / node[numerator]; //|| 0;
        // node.percent = node.percent;//Math.min(1, Math.max(0, node.percent));
      }

      if (color_measure) {
        node["color_metric"] = node[color_measure];
      }
    });
    return root;
  }

  function drawChart(
    hierData,
    color_measure = null,
    color_range = null,
    tooltip_measures = null,
    treemapWidth = 1000,
  ) {
    // Clear any existing svg elements
    d3.selectAll("svg").remove();
    var aspect = width / height;

    var chart = d3.select("#chart");
    d3.select(window).on("resize", function () {
      var targetWidth = chart.node().getBoundingClientRect().width;
      chart.attr("width", targetWidth);
      chart.attr("height", targetWidth / aspect);
    });

    var margin = { top: 32, right: 0, bottom: 0, left: 0 },
      // width = window.innerWidth,
      // width = 840,
      width = treemapWidth, 
      height = 700 - margin.top - margin.bottom,
      formatNumber = d3.format(",d"),
      padding = 2,
      transitioning;

    var x = d3.scaleLinear().domain([0, width]).range([0, width]);

    var y = d3.scaleLinear().domain([0, height]).range([0, height]);

    // A function that computes x0, x1, y0, y1 for nodes in a given hierarchy.
    const treemap = d3.treemap().size([width, height]).paddingOuter(padding);

    var svg = d3
      .select("#chart")
      .append("svg")

      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.bottom + margin.top)
      .style("margin-left", -margin.left + "px")
      .style("margin.right", -margin.right + "px")
      .append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")")
      .style("shape-rendering", "crispEdges");

    var grandparent = svg.append("g").attr("class", "grandparent");

    grandparent
      .append("rect")
      .attr("x", padding * 2)
      .attr("y", -margin.top)
      .attr("width", width - padding * 4)
      .attr("height", margin.top);

    grandparent
      .append("text")
      .attr("x", 6)
      .attr("y", 6 - margin.top)
      .attr("dy", ".75em")
      .style("font-size", "16");
    // console.log("beforeColor", hierData);
    var color;
    var color_accessor;
    if (color_measure != null) {
      // console.log("Colordomain", colorDomain());
      color = d3
        .scaleOrdinal()
        .range(color_range || ["#ffb300", "#ADD8E6", "#03A9F4"])
        .domain(colorDomain());
      color_accessor = function (d) {
        return color(d.parent.data.key);
      };
    } else {
      color = d3.scaleOrdinal().range(color_range || d3.schemeCategory10);
      color_accessor = function (d) {
        return color(d.data.parent.key);
      };
    }

    treemap(hierData);
    display(hierData);
    //   console.log("hierData", hierData)

    function colorDomain() {
      var root = hierData;
      let color_values = [];
      // console.log("dataIn color", root);

      if (!root.children) {
        color_values = [root.color_metric];
        return color_values;
      }
      root.children.forEach((child) => {
        if (child.children && child.children.length) {
          child.children.forEach((grandchild) => {
            // console.log("child", grandchild);
            color_values.push(grandchild.parent.data.key);
            // color_values.push(grandchild.potential);
          });
          // console.log("colorval", color_values);
        } else {
          // console.log("what child", child);
          color_values.push(child.potential);
        }
      });
      return [
        d3.min(color_values),
        d3.mean(color_values),
        d3.max(color_values),
      ];
    }

    function display(d) {
      if (d) {
        // console.log("ifData", d);
        setGrandData(d);
      }
      var grandparent_color_metric = d.data.key;
      grandparent
        // Attach zoom function -- works after 1st zoom, when node has 'parent'
        .datum(d.parent)
        .on("click", zoom)
        .select("text")
        // Name for grandparent is the hierarchical path
        .text(
          name(d) +
            ": " +
            // (d.value / 1000000)
            //   .toFixed(2)
            //   .toString()
            //   .replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
            // " ล้านบาท"
            `${
              d.value > 999 && d.value < 1000000
                ? (d.value / 1000)
                    .toFixed()
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " แสนบาท"
                : d.value > 1000000
                ? (d.value / 1000000)
                    .toFixed()
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " ล้านบาท"
                : null
            }`
        )
        .style("fill", function (d) {
          return "#fff";
        });
      grandparent
        .select("rect")
        .on("click", () => {
          console.log(d);
          if (location.pathname === "/PotentialProduct") {
            actions.postPotentialPDetail(d);
          } else if (location.pathname === "/PotentialCountry") {
            actions.postPotentialCDetail(d);
          }
        })
        .style("fill", function (d) {
          // console.log("color test", grandparent_color_metric);
          return color(grandparent_color_metric);
        });

      // g1 = main graphical element to hold the tree, inserted after the top g.
      // g1 is "the grandparent", even though the top g holds the text about it.
      var g1 = svg.insert("g", ".grandparent").datum(d).attr("class", "depth");

      // Within g1, create g's for the "parents" (children of grandparent)
      // console.log("whatdata", d);
      // debugger
      var g = g1
        .selectAll("g")
        // .data(function (d) {
        //   console.log("grand", d);
        //   return d.children || d;
        // })
        .data(d.children)
        // .data(d)
        .enter()
        .append("g")
        .on("click", (d) => {
          if (location.pathname === "/PotentialProduct") {
            actions.postPotentialPDetail(d);
          } else if (location.pathname === "/PotentialCountry") {
            actions.postPotentialCDetail(d);
          }
        });

      // // Attach zoom function to to parents who have children.
      g.filter(function (d) {
        return d.children;
      })
        .classed("hasChildren", true)
        .on("click", buttonClick);

      // Within each parent g, add g's to hold rect + text for each child.
      var children = g
        .selectAll(".child")
        .data(function (d) {
          // console.log("children", d);
          return d.children || [d];
        })
        .enter()
        .append("g");
      // console.log("COloracc", d);
      children
        .append("rect")
        .attr("class", "child")
        .call(rect)
        .style("fill", color_accessor)
        // .style("fill", function (d) {
        //   console.log("color potential", color);
        //   return color(d.parent.data.key);
        // })
        // .on("mousemove", (d) => mousemove(d))
        .on("mouseout", mouseout);
      // .append("title")
      //   .text(function(d) {return d.data.key + " (" + formatNumber(d.value) + ")";});

      children
        .append("rect")
        .attr("class", "child2")
        .call(rect2)
        .style("fill", color_accessor)
        // .on("click", buttonClick)
        // .on("mousemove", (d) => mousemove(d))
        .on("mouseout", mouseout);
      children
        .append("text")
        .attr("class", "ctext")
        .text(function (d) {
          return d.data.key;
        })
        .style("font-size", "16")
        .call(childrenText);

      // Even though all children are rects, parents are also presented as rects.
      g.append("rect").attr("class", "parent").call(rect);
      // g.append("rect").attr("class", "parent2").call(rect2);
      var t = g.append("text").attr("class", "ptext").attr("dy", ".75em");
      t.append("tspan").text(function (d) {
        return d.data.key;
      });
      t.append("tspan")
        .attr("dy", "1.0em")
        .text(function (d) {
          // return formatNumber(d.value);
          // return (
          //   (d.value / 1000000)
          //     .toFixed()
          //     .toString()
          //     .replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " ล้านบาท"
          // );

          return d.value > 999 && d.value < 1000000
            ? (d.value / 1000)
                .toFixed()
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " แสนบาท"
            : d.value > 1000000
            ? (d.value / 1000000)
                .toFixed()
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " ล้านบาท"
            : null;
        })
        .style("font-size", "16");
      t.call(parentText);

      // Draw/move child nodes on top of parent nodes.
      svg.selectAll(".depth").sort(function (a, b) {
        return a.depth - b.depth;
      });

      // FIXME: Move to private function
      function buttonClick(d) {
        var xPosition = d3.event.pageX - 270;
        var yPosition = d3.event.pageY - 100;

        d3.select("#Card")
          .style("position", "absolute")
          .style("left", xPosition + "px")
          .style("top", yPosition + "px");
        d3.select("#Card #Content")
          .style("display", "flex")
          .style("flex-direction", "column");
        d3.select("#button1").on("click", () => {
          console.log("clickDrilldown", d);
          zoom(d);
          d3.select("#Card").classed("hidden", true);
        });
        d3.select("#button2").on("click", () => {
          console.log("clickDetail", d);
          // const dataPush = {
          //   ...props.data,
          //   d,
          // };
          if (location.pathname === "/PotentialProduct") {
            actions.postPotentialPDetail(d);
          } else if (location.pathname === "/PotentialCountry") {
            actions.postPotentialCDetail(d);
          }

          d3.select("#Card").classed("hidden", true);
        });

        // tooltip_measures.forEach((m) => {
        //   d3.select("#Card #" + m.key + "_title").text(m.title);
        //   d3.select("#Card #" + m.key).text(
        //     m.format ? d3.format(m.format)(d[m.measure]) : d[m.measure]
        //   );
        // });
        d3.select("#Card").classed("hidden", false);
      }

      function zoom(d) {
        if (transitioning || !d) return;
        transitioning = true;
        var g2 = display(d);
        // At this point in time, g1 with its rects are still in place.
        // The parent that has just been promoted to grandparent now has 2 levels
        // of rects visualized, but still positioned exactly where it was.

        var t1 = g1.transition().duration(750);
        var t2 = g2.transition().duration(750);

        // Enable anti-aliasing during the transition.
        svg.style("shape-rendering", null);

        // // Draw/move child nodes on top of parent nodes.
        // svg.selectAll(".depth").sort(function(a, b) {return a.depth - b.depth;});

        // Update the domain only after entering new elements (within display()).
        x.domain([d.x0, d.x1]);
        y.domain([d.y0, d.y1]);

        // This moves all rects into the right position & width/height.
        t1.selectAll("rect").call(rect);

        t2.selectAll(".child").call(rect);

        t2.selectAll(".child2").call(rect2);
        t2.selectAll(".parent").call(rect);

        // Fade-in entering text.
        g2.selectAll("text").style("fill-opacity", 0).style("font-size", "16");

        // Transition texts to the new view.
        t1.selectAll(".ptext").call(parentText).style("fill-opacity", 0);
        t1.selectAll(".ctext").call(childrenText).style("fill-opacity", 0);
        t2.selectAll(".ptext").call(parentText).style("fill-opacity", 1);
        t2.selectAll(".ctext").call(childrenText).style("fill-opacity", 1);

        // Remove the old node when the transition is finished.
        t1.remove().on("end", function () {
          svg.style("shape-rendering", "crispEdges");
          transitioning = false;
        });
      }

      return g;
    }

    function parentText(text) {
      text.selectAll("tspan").attr("x", function (d) {
        return x(d.x0) + 6;
      });
      text
        .attr("x", function (d) {
          return x(d.x0) + 6;
        })
        .attr("y", function (d) {
          return y(d.y0) + 14;
        })
        .style("fill", function (d) {
          return "#fff";
        })
        .style("opacity", function (d) {
          return this.getComputedTextLength() < x(d.x1) - x(d.x0) - 6 ? 1 : 0;
        });
    }

    function childrenText(text) {
      text
        .attr("x", function (d) {
          return x(d.x1) - this.getComputedTextLength() - 8;
        })
        .attr("y", function (d) {
          return y(d.y1) - 8;
        })
        .style("fill", function (d) {
          return "#fff";
        })
        .style("opacity", function (d) {
          return this.getComputedTextLength() < x(d.x1) - x(d.x0) - 6 ? 1 : 0;
        });
    }

    function rect(rect) {
      rect
        .attr("x", function (d) {
          return x(d.x0);
        })
        .attr("y", function (d) {
          return y(d.y0);
        })
        .attr("width", function (d) {
          return x(d.x1) - x(d.x0);
        })
        .attr("height", function (d) {
          return y(d.y1) - y(d.y0);
        })
        .style("opacity", function (d) {
          return 0.7;
        });
    }

    function rect2(rect) {
      rect
        .attr("x", function (d) {
          return x(d.x0);
        })
        .attr("y", function (d) {
          return y(d.y0) + 2.5;
        })
        .attr("width", function (d) {
          // return (x(d.x1) - x(d.x0)) * (d.actual_value / d.potential);
          return d.actual_value > d.potential
            ? x(d.x1) - x(d.x0)
            : (x(d.x1) - x(d.x0)) * (d.actual_value / d.potential);
        })
        .attr("height", function (d) {
          return y(d.y1) - y(d.y0) - 5;
        })
        .style("opacity", function (d) {
          return 1;
        });
    }

    function name(d) {
      return d.parent ? name(d.parent) + " / " + d.data.key : d.data.key;
    }

    function mousemove(d) {
      var xPosition = d3.event.pageX + 5;
      var yPosition = d3.event.pageY + 5;

      d3.select("#tooltip")
        .style("background-color", color(d.color_metric))
        .style("left", xPosition + "px")
        .style("top", yPosition + "px");
      d3.select("#tooltip #heading").text(d.data.key);
      tooltip_measures.forEach((m) => {
        d3.select("#tooltip #" + m.key + "_title").text(m.title);
        d3.select("#tooltip #" + m.key).text(
          m.format ? d3.format(m.format)(d[m.measure]) : d[m.measure]
        );
      });

      d3.select("#tooltip").classed("hidden", false);
    }

    function mouseout() {
      d3.select("#tooltip").classed("hidden", true);
    }
  }

  // useEffect(() => {
  //   function handleWindowResize() {
  //     setTreemapWidth(parentLayout?.offsetWidth);
  //   }

  //   console.log("offsetWidth2:", parentLayout?.offsetWidth)
  //   // setWidth(ref.current.offsetWidth);
  //   // setHeight(ref.current.offsetHeight);
  //   window.addEventListener('resize', handleWindowResize);

  //   return () => {
  //     window.removeEventListener('resize', handleWindowResize);
  //   };
  // }, []);

  // const create  

  const createTreeMap = () => {
    var hierarchical_data = buildHierarchy(
      // data,
      props.data,
      props.hier,
      props.parameter["measure1"],
      props.parameter["measure2"],
      null,
      true, // Tell it to compute a percentage
      props.parameter["measure1"], // numerator
      props.parameter["measure2"], // denominator
      "percent", // color_measure
      tooltip_measures
    );
    
    // console.log('parentLayout?.offsetWidth: ', parentLayout?.offsetWidth)
    drawChart(hierarchical_data, "percent", color_range, tooltip_measures, parentLayout?.offsetWidth);
  }

  useEffect(() => {
    createTreeMap();

    window.addEventListener('resize', createTreeMap);

    return () => {
      window.removeEventListener('resize', createTreeMap);
    };
  }, [props.data]);

  return (
    <div className="treemap">
      <p id="chart"></p>
      <div id="Card" class="hidden">
        <div id="Content">
          <Button id="button1">ดูข้อมูลในกลุ่ม</Button>
          <Button id="button2">ดูข้อมูลเพิ่มเติม</Button>
        </div>
      </div>
      <div id="tooltip" class="hidden">
        <p>
          <strong id="heading"></strong>
        </p>
        <p>
          <span id="tooltip_measure_1_title"></span>
          <span id="tooltip_measure_1"></span>
        </p>
        <p>
          <span id="tooltip_measure_2_title"></span>
          <span id="tooltip_measure_2"></span>
        </p>
        <p>
          <span id="tooltip_measure_3_title"></span>
          <span id="tooltip_measure_3"></span>
        </p>
      </div>
    </div>
  );
}
