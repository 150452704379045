import React, { useState, useRef, useEffect } from "react";
import { Input, Tree, Typography } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../resources/index";
import services from "../../resources/variables/services";
const { Text } = Typography;
const { Search } = Input;



export default function ExporterFilter(props) {
  // All State can use
  const regionRef = useRef();
  const filter = JSON.parse(localStorage.getItem("filter"));
  const [autoExpandParent, setAutoExpandParent] = useState(true);
  const inputfilter = useSelector((state) => state.filter_input);
  const dispatch = useDispatch();
  const actions = bindActionCreators(
    actionCreators,
    dispatch
  );
  const SUB_TYPE_IS_MULTI_LEVEL = ['exporter_region'];   //  these subType has multi-level of tree (children)
  let initializeCheckedKeys = props.dataFilter.map(item => item.key);
  // console.log(initializeCheckedKeys);
  if (SUB_TYPE_IS_MULTI_LEVEL.includes(props.type)) {
    initializeCheckedKeys = [];
    services.listGenerator(props.dataFilter, initializeCheckedKeys);
    initializeCheckedKeys = initializeCheckedKeys
      .filter(item => item.key.includes("leaf"))
      .map(item => item.key);
    // console.log(initializeCheckedKeys);
    // initializeCheckedKeys.push(props.dataFilter.find(itemFilter => itemFilter.key === '9999'))?.key;
  }
  // console.log(initializeCheckedKeys);
  const [checkedKeys, setCheckedKeys] = useState(initializeCheckedKeys);
  const [treeFilter, setTreeFilter] = useState(props.dataFilter);
  const [treeExpand, setTreeExpand] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [scroll, setScroll] = useState("");
  const region = [];

  const MAX_LENGTH = {
    exporter_region: 143,
    exporter_size: 5,
    exporter_size_sme: 6,
    exporter_member_type: 10,
    exporter_award: 4
  };

  const onCheckedKey = (checkedKeyList, event) => {
    // console.log(event);
    // console.log(props.type, checkedKeyList);

    const storeFlatObject = [];
    const filterObjectList = props.dataFilter; // default filter-items structures is contain [{key:str, title:str}]
    let textExtra = null;
    let textExtraFull = [];
    let checkedChildrenKeysValue = [...checkedKeyList];
    services.listGenerator(filterObjectList, storeFlatObject);

    // console.log(`[setCheckedKeys]: current-state\t`, checkedKeys);
    // console.log(`[setCheckedKeys]: current-key\t`, checkedChildrenKeysValue);
    checkedChildrenKeysValue = [...new Set([...checkedKeys, ...checkedChildrenKeysValue])];
    // console.log(`[setCheckedKeys]: merge key\t`, checkedChildrenKeysValue);

    // let checkedKeysComputed = [];
    if (event.checked) {
    } else {
      let removeItemList = [];
      if (event.node?.children) {
        services.listGenerator(event.node.children, removeItemList);
        // console.log(removeItemList);
      }
      removeItemList = removeItemList.map(item => item.key);
      removeItemList.push(event.node.key); // push themself for remove
      // console.log(removeItemList);      
      checkedChildrenKeysValue = checkedKeys.filter(key => !removeItemList.includes(key));
    }

    let checkedKeyFilterList = storeFlatObject.filter(item => checkedChildrenKeysValue.includes(item.key));
    // textExtraFull = storeFlatObject.filter(filter => checkedChildrenKeysValue.includes(filter.key));
    // console.log(checkedKeyFilterList);
    if (SUB_TYPE_IS_MULTI_LEVEL.includes(props.type)) {
      checkedKeyFilterList = checkedKeyFilterList.filter(obj => obj.key.includes("leaf"));
    }
    textExtraFull = checkedKeyFilterList.map((obj) => obj.title);


    /**
     * if length of checked-keys less than length of 'all-key'
     * the textExtra display only checked-keys, otherwise textExtra display "ทั้งหมด"
    */
    if (checkedKeyFilterList.length >= MAX_LENGTH[props.type]) {
      textExtra = "ทั้งหมด";
    } else {
      textExtra = services.formatArrayWithEllipsis(textExtraFull);
    }
    textExtraFull = textExtraFull.join(", ");

    // console.log(textExtra);
    // console.log(textExtraFull);

    checkedChildrenKeysValue = checkedKeyFilterList.map(item => item.key);
    console.log(checkedChildrenKeysValue);
    setCheckedKeys(checkedChildrenKeysValue);
    props.onCheckedKeys(textExtra, textExtraFull, props.parentType, props.type);  // update State of Parent 'setTextExtraFilter' (received from props)



    const Size_input = {
      ...inputfilter.filter_input,
      [props.type]: checkedChildrenKeysValue,
    };
    // console.log("Size", Size_input);
    actions.setFilter(Size_input);
    // actions.postExporter(Size_input);
  };

  const onSearch = (e, data, list) => {
    const { value } = e.target;
    const textSearchKeyword = value.toLowerCase();
    const IS_NULL_KEYWORD = !(!!textSearchKeyword);
    // console.log(props.type);
    if (IS_NULL_KEYWORD) {
      setTreeFilter(props.dataFilter);
      setTreeExpand([]);
    } else {
      const dataInitial = JSON.parse(JSON.stringify(props.dataFilter));
      console.log(textSearchKeyword, dataInitial);
      const filteredTree = services.filterFamilyTreeByName(
        dataInitial,
        textSearchKeyword
      );

      let parentKeyList = [];
      services.listGenerator(filteredTree, parentKeyList);
      parentKeyList = parentKeyList
        .filter(item => {
          if (props.type === 'product_activity' || props.type === "country_activity" || props.type === 'exporter_region') {
            return !item.key.startsWith("leaf");
          }
          return true;
        })
        .map(item => item.key);
      // console.log(parentKeyList);
      setTreeExpand(parentKeyList);
      setTreeFilter(filteredTree);
    }


    // const scroll = data
    //   .map((items) => {
    //     if (items.title.indexOf(value) > -1) {
    //       return items.key;
    //     }
    //     return null;
    //   })
    //   .filter((item, i, self) => item && self.indexOf(item) === i);

    // setSearchValue(value);

    // // setActivityScroll(scroll[0]);

    // console.log("scroll", scroll);
  };

  const checkAll = (data, type) => {
    let checkedAllKeys = [];
    services.listGenerator(data, checkedAllKeys);
    console.log(checkedAllKeys);
    const objTemp = checkedAllKeys.find(item => item.key === '9999');
    // console.log("type, length", props.type, checkedAllKeys.length);
    // console.log("data", data, checkedAllKeys.length);
    if (SUB_TYPE_IS_MULTI_LEVEL.includes(props.type)) {
      checkedAllKeys = checkedAllKeys.filter(item => item.key.includes("leaf"));
    }
    checkedAllKeys.push(objTemp);
    const fullTextExtra = checkedAllKeys
      .map(item => item.title)
      .join(", ");
    let checkedKeyItems = checkedAllKeys.map((item) => item.key);
    // console.log("checkedKeyItems", checkedKeyItems);
    const new_data = {
      ...inputfilter.filter_input,
      [type]: checkedKeyItems,
    };

    // const fullTextExtra = checkedAllKeys.filter
    console.log(checkedKeyItems.length);
    setCheckedKeys(checkedKeyItems);
    props.onCheckedKeys("ทั้งหมด", fullTextExtra, props.parentType, props.type);
    actions.setFilter(new_data);
    // actions.postExporter(new_data);
  };

  const unCheckall = (type) => {
    // console.log(type);
    const new_data = {
      ...inputfilter.filter_input,
      [type]: [],
    };

    setCheckedKeys([]);
    props.onCheckedKeys("", "-", props.parentType, props.type);
    actions.setFilter(new_data);
  };

  return (
    <div className="exporter">
      {
        <div className="exporter-size">
          <div>
            {/* ขนาดผู้ประกอบการ */}
            <Search
              onChange={(e) => onSearch(e, props.dataFilter, region)}
              allowClear
              placeholder="ค้นหา. . ."
            // style={{ width: 260 }}
            />
            <div className="check-tree">
              <Text onClick={() => checkAll(props.dataFilter, props.type)}>
                เลือกทั้งหมด
              </Text>
              <Text onClick={() => unCheckall(props.type)}>ไม่เลือกทั้งหมด</Text>
            </div>
            <Tree
              checkable
              height={200}
              onCheck={onCheckedKey}
              checkedKeys={checkedKeys}
              expandedKeys={treeExpand}
              // onSelect={(selectedKeys, info) => setTreeExpand(prevKeys => [...prevKeys, ...selectedKeys])}
              onExpand={(expandedKeys, info) => setTreeExpand(expandedKeys)}
              treeData={treeFilter}
            />
          </div>
        </div>
      }
    </div>
  );
}
