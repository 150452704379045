import React from "react";
import { Card, Typography, Button } from "antd";

import Loading from "../Loading";

const { Text } = Typography;

export default function MovementDetail(props) {

  const onClickSeeMovement = () => {
    let data = {
      key: props.detail.Tax_No,
      label: `${props.detail.Tax_No}: ${props.detail.Corporate_NameTH}`,
      value: props.detail.Tax_No,
    };
    
    props.onClickSeeMovement(data);
  }

  const waitADtail = () => {

    
    if (props.detail) {
      // show loading 
      if (Object.keys(props.detail).length === 0) {
        return (
          <Card
              style={{
                height: "100%",
                paddingTop: "20rem",
                paddingBottom: "20rem"
              }}
              title={<Text>{props.detail.Corporate_NameTH}</Text>}
            >            
              <Loading spinning={true} size="large" tip="กำลังโหลดข้อมูลผู้ประกอบการ">
              </Loading>
            </Card>
        )

      } else {
        return (
          <Card
            style={{
              minHeight: "40rem",
              height: "100%",
            }}
            title={<Text>{props.detail.Corporate_NameTH}</Text>}
          >
            <div className="table-auto text-xs pl-3">
              <div className="t-row">
                <div className="t-row l">
                  ชื่อผู้ประกอบการ <Text>:</Text>
                </div>
                <div className="t-row r">
                  {props.detail.Corporate_NameTH}
                </div>
              </div>
              <div className="t-row">
                <div className="t-row l">
                  เลขประจำตัวผู้เสียภาษี <Text>:</Text>
                </div>
                <div className="t-row r">{props.detail.Tax_No}</div>
              </div>
              {/* <div className="t-row">
                <div className="t-row l">
                  ทุนจดทะเบียน <Text>:</Text>
                </div>

                <div className="t-row r">
                  {props.detail.DBD_CapitalValue
                    ? `${props.detail.DBD_CapitalValue.toString().replace(
                        /\B(?=(\d{3})+(?!\d))/g,
                        ","
                      )} บาท`
                    : "ไม่มีข้อมูล"}
                </div>
              </div> */}
              <div className="t-row">
                <div className="t-row l">
                  ขนาดผู้ประกอบการ <Text>:</Text>
                </div>
                <div className="t-row r">
                  {props.detail.ComSize}
                </div>
              </div>
              {/* <div className="t-row">
                <div className="t-row l">
                  ข้อมูลธุรกิจ<Text>:</Text>
                </div>
                <div className="t-row r">
                  {props.detail.DBD_ObjectDetail
                    ? props.detail.DBD_ObjectDetail
                    : "ไม่มีข้อมูล"}
                </div>
              </div>
              <div className="t-row">
                <div className="t-row l">
                  สถานะ <Text>:</Text>
                </div>
                <div className="t-row r">
                  {props.detail.DBD_juristicStatus
                    ? props.detail.DBD_juristicStatus
                    : "ไม่มีข้อมูล"}
                </div>
              </div> */}
              {/* <div className="t-row">
                <div className="t-row l">
                  ภูมิภาค <Text>:</Text>
                </div>
                <div className="t-row r">
                  {props.detail.Region_Name_Th}
                </div>
              </div> */}
              <div className="t-row">
                <div className="t-row l">
                  จังหวัด <Text>:</Text>
                </div>
                <div className="t-row r">
                  {props.detail.ProvinceName}
                </div>
              </div>
              {/* <div className="t-row">
                <div className="t-row l">
                  อีเมล <Text>:</Text>
                </div>
                <div className="t-row r">
                  {props.detail.email
                    ? props.detail.email
                    : "ไม่มีข้อมูล"}
                </div>
              </div> */}
            </div>

            <div className="ml-5 my-5">
                <div className="text-sm  mb-2">
                  การส่งออก (เฉพาะปี {props.detail.export_summary.year})
                </div>

                <div className="text-xs">
                  <div className="mb-2">สินค้าที่ส่งออก 5 อันดับแรก :</div>
                  <div>
                    <ol className="space-y-1">
                      {props.detail.export_summary.top5_export_product.map((item, index) => (
                        <li>{index+1}. {item}</li>
                      ))}
                    </ol>
                  </div>
                </div>
                
                {/* <div className="text-xs">
                  ประเทศที่ส่งออก 5 อันดับแรก :
                  <div>
                    <ol>
                      {props.detail.export_summary.top5_export_country.map((item, index) => (
                        <li>{index+1}. {item}</li>
                      ))}
                    </ol>
                  </div>
                </div> */}    
              </div>   

              <div className="flex justify-center">
                <Button onClick={onClickSeeMovement} type="primary" className="mt-3">
                  ดูการเคลื่อนที่ในแต่ละปี
                </Button>
              </div>   
          </Card>    
        );
      }
    
    // display init text
    } else {
      return (
        <Card
          style={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <p className="text-primary-grey-dark text-xl">
            คลิกบริษัทที่ท่านต้องการดูรายละเอียด
          </p>
        </Card>
      );
    }
  };

  return <div className="detail h-full">{waitADtail()}</div>;
}
