import React from "react";
import { Route } from "react-router-dom";

import authService from "../services/auth.service";

/**
 * route protection for any route that need to authentication/authorization
 * @param {object} param0 props
 * @returns render route or redirect to auth url
 */
export default function ProtectedRoute({ children, ...props }) {
  const LDAP_TOKEN = authService.getTokenLDAPByCookie();
  const SSO_TOKEN = authService.getTokenSSOByCookie();
  // console.log(LDAP_TOKEN, SSO_TOKEN)

  // check token authentication is exists.
  if (!LDAP_TOKEN && !SSO_TOKEN) {
    authService.clearCurrentUser();
    window.location = authService.getLoginURL();
    return <></>;
  }

  // check token is not expired.
  if (authService.isTokenExpired(LDAP_TOKEN)) {
    console.log(`LDAP token is expired.`);
    authService.clearCurrentUser();
    authService.clearCookieUser();
  }

  return <Route {...props}></Route>;
}
