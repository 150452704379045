import { Card } from "antd";
import React, { useEffect, useState } from "react";
import SelfData from "../components/SelfData";
import { ExporterSelfCol } from "../resources/variables/index";
import AuthService from "../services/auth.service";
import { bindActionCreators } from "redux";
import { actionCreators } from "../resources/index";
import { useDispatch, useSelector } from "react-redux";

export default function SelfAssessment() {
  const [userProfile, setUserProfile] = useState(AuthService.getCurrentUser);
  const dispatch = useDispatch();
  const { getExporterData } = bindActionCreators(actionCreators, dispatch);
  const exporter = useSelector((state) => state.exporterData);

  useEffect(() => {
    getExporterData(userProfile?.Tax_no);
  }, []);


  return (
    <div
      className="self-assessment"
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Card style={{ minWidth: "80%", minHeight: "51rem" }}>
        {exporter ? (
          <SelfData
            detail={exporter.exporterData}
            historyCol={ExporterSelfCol}
            data={exporter.exporterData}
          />
        ) : null}
      </Card>
    </div>
  );
}
