// ----- Packages/Library -----
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

// ----- Components -----
import DelayRenderComponent from "../components/DelayRenderComponent";

export default function RedirectPage() {
  const { pathname } = useLocation();
  const badgeColors = [
    {
      key: 1,
      bdColor: `bg-primary-dark`,
      color: `bg-primary`,
    },
    {
      key: 2,
      bdColor: `bg-primary-dark`,
      color: `bg-primary`,
    },
    {
      key: 3,
      bdColor: `bg-primary-grey-dark`,
      color: `bg-primary-grey`,
    },
    {
      key: 4,
      bdColor: `bg-primary-grey-dark`,
      color: `bg-primary-grey`,
    },
    {
      key: 5,
      bdColor: `bg-primary-red-dark`,
      color: `bg-primary-red`,
    },
    {
      key: 6,
      bdColor: `bg-primary-red-dark`,
      color: `bg-primary-red`,
    },
  ];

  useEffect(() => {
    /**
     * mapping pathname's location for redirect to URL
     * @returns timeoutId for avoid side-effect
     */
    function redirectExternal() {
      // key for redirect to URL
      const pathRef = {
        "/potential-dashboard": "https://activityanalytics.ditp.go.th/Export",
      };

      // setTimeout 0.5s for animation rendering
      const timeoutId = setTimeout(() => {
        window.location.replace(pathRef[pathname]);
      }, 1000);

      return timeoutId;
    }

    const timeoutId = redirectExternal();
    return () => clearTimeout(timeoutId);
  });

  return (
    <div className='h-[90vh] bg-slate-50 rounded-lg flex flex-col justify-center items-center'>
      <div className='loading-group'>
        <h1 className='text-sm text-center animate-pulse'>กำลังโหลด ...</h1>
        <div className='loading-badge-group flex my-4'>
          {badgeColors.map((badge) => {
            return (
              <DelayRenderComponent delay={badge.key * 50}>
                <Badge
                  key={badge.key.toString()}
                  bdColor={badge.bdColor}
                  color={badge.color}
                />
              </DelayRenderComponent>
            );
          })}
        </div>
      </div>
    </div>
  );
}

/**
 * badge color for dot-bounce loading animation
 * @param {string} bdColor border color for ping a circle 
 * @param {string} color dot color
 * @param {*} props *args
 * @returns 
 */
const Badge = ({ bdColor, color, ...props }) => {
  return (
    <span class={`relative flex h-8 w-3 m-1 animate-bounce`}>
      <span
        class={`animate-ping absolute inline-flex h-3 w-full rounded-full ${bdColor} opacity-50`}
      ></span>
      <span class={`relative inline-flex rounded-full h-3 w-3 ${color}`}></span>
    </span>
  );
};
