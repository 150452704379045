import React from "react";
import vegaEmbed from "vega-embed";
import { useLocation } from "react-router-dom";

export default function KnowledgeGraph({ activityData }) {
  const location = useLocation();

  const Graph = {
    $schema: "https://vega.github.io/schema/vega/v4.json",
    width: location.pathname === "/KnowledgeGraph" ? 1000 : 820,
    height: location.pathname === "/KnowledgeGraph" ? 1000 : 700,
    autosize: "auto",
    signals: [
      {
        name: "labels",
        value: true,
      },
      {
        name: "radius",
        value: location.pathname === "/KnowledgeGraph" ? 285 : 185,
        bind: { input: "range", min: 20, max: 600 },
      },
      {
        name: "extent",
        value: 360,
        bind: { input: "range", min: 0, max: 360, step: 1 },
      },
      {
        name: "rotate",
        value: 0,
      },
      {
        name: "layout",
        value: "tidy",
      },
      {
        name: "links",
        value: "line",
      },
      {
        name: "originX",
        update: "width / 2",
      },
      {
        name: "originY",
        update: "height / 2",
      },
    ],
    data: [
      {
        name: "tree",
        values: activityData,
        transform: [
          {
            type: "stratify",
            key: "id",
            parentKey: "parent",
          },
          {
            type: "tree",
            method: {
              signal: "layout",
            },
            size: [
              1,
              {
                signal: "radius",
              },
            ],
            as: ["alpha", "radius", "depth", "children"],
          },
          {
            type: "formula",
            expr: "(rotate + extent * datum.alpha + 270) % 360",
            as: "angle",
          },
          {
            type: "formula",
            expr: "PI * datum.angle / 180",
            as: "radians",
          },
          {
            type: "formula",
            expr: "inrange(datum.angle, [90, 270])",
            as: "leftside",
          },
          {
            type: "formula",
            expr: "originX + datum.radius * cos(datum.radians)",
            as: "x",
          },
          {
            type: "formula",
            expr: "originY + datum.radius * sin(datum.radians)",
            as: "y",
          },
        ],
      },
      {
        name: "links",
        source: "tree",
        transform: [
          {
            type: "treelinks",
          },
          {
            type: "linkpath",
            shape: {
              signal: "links",
            },
            orient: "radial",
            sourceX: "source.radians",
            sourceY: "source.radius",
            targetX: "target.radians",
            targetY: "target.radius",
          },
        ],
      },
    ],
    scales: [
      {
        name: "color",
        type: "sequential",
        range: {
          scheme: "category10",
        },
        domain: {
          data: "tree",
          field: "depth",
        },
        zero: true,
      },
    ],
    marks: [
      {
        type: "path",
        from: {
          data: "links",
        },
        encode: {
          update: {
            x: {
              signal: "originX",
            },
            y: {
              signal: "originY",
            },
            path: {
              field: "path",
            },
            stroke: {
              value: "#ccc",
            },
          },
        },
      },
      {
        type: "symbol",
        from: {
          data: "tree",
        },
        encode: {
          enter: {
            size: {
              // value: 500,
              signal: "datum.size",
            },
            stroke: {
              value: "#fff",
            },
          },
          update: {
            x: {
              field: "x",
            },
            y: {
              field: "y",
            },
            fill: {
              scale: "color",
              field: "depth",
            },
          },
        },
      },
      {
        type: "text",
        from: {
          data: "tree",
        },
        encode: {
          enter: {
            text: {
              field: "name",
            },
            fontSize: {
              value: 12,
            },
            baseline: {
              value: "middle",
            },
            limit: {
              value: 150,
            },
          },
          update: {
            x: {
              field: "x",
            },
            y: {
              field: "y",
            },
            dx: {
              signal: "(datum.leftside ? -1 : 1) * 6",
            },
            angle: {
              signal: "datum.leftside ? datum.angle - 180 : datum.angle",
            },
            align: {
              signal: "datum.leftside ? 'right' : 'left'",
            },
            opacity: {
              signal: "labels ? 1 : 0",
            },
          },
        },
      },
    ],
  };

  vegaEmbed("#Graph", Graph, {
    actions: false,
    renderer: "svg",
  });

  console.log("activityData", activityData);

  return (
    <div className="knowledge-graph">
      <div id="Graph"></div>
    </div>
  );
}

const mockup = [
  {
    id: 1,
    name: "Exporter Name",
  },
  {
    id: 2,
    name: "analytics",
    parent: 1,
  },
  {
    id: 3,
    name: "cluster",
    parent: 2,
  },
  {
    id: 4,
    name: "AgglomerativeCluster",
    parent: 2,
    size: 3938,
  },
  {
    id: 5,
    name: "CommunityStructure",
    parent: 2,
    size: 3812,
  },
  {
    id: 6,
    name: "HierarchicalCluster",
    parent: 1,
    size: 6714,
  },
  {
    id: 7,
    name: "MergeEdge",
    parent: 6,
    size: 743,
  },
  {
    id: 8,
    name: "graph",
    parent: 6,
  },
  {
    id: 9,
    name: "BetweennessCentrality",
    parent: 6,
    size: 3534,
  },
  {
    id: 10,
    name: "LinkDistance",
    parent: 1,
    size: 5731,
  },
  {
    id: 11,
    name: "MaxFlowMinCut",
    parent: 10,
    size: 7840,
  },
  {
    id: 12,
    name: "ShortestPaths",
    parent: 10,
    size: 5914,
  },
  {
    id: 13,
    name: "SpanningTree",
    parent: 10,
    size: 3416,
  },
  {
    id: 14,
    name: "optimization",
    parent: 1,
  },
  {
    id: 15,
    name: "AspectRatioBanker",
    parent: 14,
    size: 7074,
  },
  {
    id: 16,
    name: "animate",
    parent: 14,
  },
  {
    id: 17,
    name: "Easing",
    parent: 14,
    size: 17010,
  },
  {
    id: 18,
    name: "FunctionSequence",
    parent: 1,
    size: 5842,
  },
  {
    id: 19,
    name: "interpolate",
    parent: 18,
  },
  {
    id: 20,
    name: "ArrayInterpolator",
    parent: 18,
    size: 1983,
  },
];
