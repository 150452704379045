import axios from "axios";
import Cookies from "js-cookie";
import { domainName } from "../resources/actions";
import { ROUTE_URL } from "../resources/variables";

class AuthService {
  #LOGIN_URL;
  #LOGOUT_URL;

  constructor() {
    this.#LOGIN_URL = "https://activityanalytics.ditp.go.th/Account/Login";
    this.#LOGOUT_URL =
      "http://activityanalytics.ditp.go.th/Identity/Account/Logout?returnUrl=%2F";
  }

  /**
   * send request with 'Cookie' DITP in request headers to decoding, then get payload such as 'roles' and etc. from backend
   */
  async fetchCurrentUser() {
    try {
      const response = await axios.get(`${domainName}/userdata/`, {
        withCredentials: true,
      });
      // console.log(response.data);
      localStorage.setItem("user", JSON.stringify(response.data));
      return this.getCurrentUser();
    } catch (error) {
      console.log(`[error]:\t`, error);
      return null;
    }
  }

  /**
   * remove 'user' object from LocalStorage, then redirect to 'logout' by external service
   */
  logout() {
    localStorage.removeItem("user");
    window.location.replace(this.#LOGOUT_URL);
  }

  /**
   * return data (decoded) from LocalStorage
   * @returns {object | null} data obj that decoded from JWT
   */
  getCurrentUser() {
    const user = JSON.parse(localStorage.getItem("user"));
    return user;
  }

  /**
   * remove current user from localStorage
   * @returns {void}
   */
  clearCurrentUser() {
    localStorage.removeItem("user");
  }

  /**
   * clear authentication LDAP and SSO cookies
   * @param {boolean} reload if true, reload current page when removed cookie
   */
  clearCookieUser(reload = true) {
    try {
      Cookies.remove("_aspNetCoreJwtToken", {
        domain: ".ditp.go.th",
      });
      Cookies.remove("_aspNetCoreSSOToken", {
        domain: ".ditp.go.th",
      });
    } catch (error) {
      console.log(error);
    }

    if (reload) {
      window.location.reload();
    }
  }

  /**
   * get URL for login
   * @returns {String} url for login
   */
  getLoginURL() {
    return this.#LOGIN_URL;
  }

  /**
   * get token from cookie 'LDAP'
   * @param {string} domain domain name of cookie
   * @returns {string | undefined} value from cookie or undefined
   */
  getTokenLDAPByCookie(domain = ".ditp.go.th") {
    const COOKIE_DOMAIN = { domain };
    const COOKIE_TOKEN_NAME = "_aspNetCoreJwtToken";
    return Cookies.get(COOKIE_TOKEN_NAME, { domain: COOKIE_DOMAIN });
  }

  /**
   * get token from cookie 'SSO'
   * @param {string} domain domain name of cookie
   * @returns {string | undefined} value from cookie or undefined
   */
  getTokenSSOByCookie(domain = ".ditp.go.th") {
    const COOKIE_DOMAIN = { domain };
    const COOKIE_TOKEN_NAME = "_aspNetCoreSSOToken";
    return Cookies.get(COOKIE_TOKEN_NAME, { domain: COOKIE_DOMAIN });
  }

  /**
   * docode JWT to javascript object
   * @param {string} token jwt
   * @param {boolean} all if true return fully object, otherwise payload onlysettings jwtHeader
   * @returns {object} payload decoded from JWT
   */
  getJwtDecoded(jwt, all = false) {
    const token = {
      raw: jwt,
      header: null,
      payload: null,
    };
    token.header = JSON.parse(window.atob(jwt.split(".")[0]));
    token.payload = JSON.parse(window.atob(jwt.split(".")[1]));
    if (all) return token;
    return token.payload;
  }

  /**
   * check JWT is expire
   * @param {string} token JWT
   * @returns {boolean} true if token is expired, otherwise false
   */
  isTokenExpired(token) {
    if (!token) return false;
    try {
      const decoded = this.getJwtDecoded(token);
      const currentTime = Date.now();
      const expireTime = decoded.exp * 1000;
      const isExpired = currentTime >= expireTime;
      // console.log(currentTime, expireTime, isExpired);
      return isExpired;
    } catch (error) {
      console.log(error);
      return true;
    }
  }
}

export default new AuthService();
