import React, { useEffect, useState } from "react";
import { ACTIVITY_COMPARISON_COLUMNS } from "../resources/variables";
import { Table } from "antd";


export default function ActivityCompare() {

  const [tableData, setTableData] = useState([]);
  console.log(tableData);
  useEffect(() => {
    setTableData(JSON.parse(localStorage.getItem("activity_compare", [])));
    return () => localStorage.removeItem('activity_compare');
  }, []);

  return (
    <div className="activity-compare min-h-screen h-full w-full">
      {
        <Table
          columns={ACTIVITY_COMPARISON_COLUMNS}
          dataSource={tableData}
        />
      }
    </div>
  );
}
