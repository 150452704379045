import React, { useEffect, useState } from "react";
import vegaEmbed from "vega-embed";
// import { useSelector } from "react-redux";
import { Card, Checkbox, Tag } from "antd";
import "../../assets/CSS/mod_line.css";

export default function NewLineChart({ selectedMean }) {
  // const exportValue = useSelector((state) => state.detail);
  // const LocalExport = JSON.parse(localStorage.getItem("detail"));
  //state
  const [checkedName, setCheckedName] = useState([]);
  const [activityOncheck, setActivityOncheck] = useState([]);
  const [colorData, setColorData] = useState();

  const matchColor = () => {
    const addColor = selectedMean.activity_attend.map((activity) => {
      activity.color = dict[activity.Activity_TypeNameTH];
      // activity.tooltip = `${activity.Activity_NameTH},${activity.Participants}`;
      return activity;
    });
    console.log("addcolor", addColor);
    setColorData(addColor);
    return addColor;
  };

  useEffect(() => {
    matchColor();
  }, []);

  useEffect(() => {
    if (checkedName) {
      mapColor();
    }
  }, [checkedName]);

  const mapColor = () => {
    if (colorData) {
      const filterData = colorData.filter((items) => {
        return checkedName.includes(items.Activity_TypeNameTH);
      });

      setActivityOncheck(filterData);
      console.log("activityOncheck", checkedName);
    }
  };

  const onCheck = (event) => {
    const { checked, value } = event.target;
    console.log("onCheck", checked, value);

    setCheckedName((checkedName) =>
      checked
        ? [...checkedName, value]
        : checkedName.filter((item) => item !== value)
    );

    // const newData = checkedName;
    // if (e.target.checked == true) {
    //   newData.push(data);
    //   setCheckedName(newData);
    // } else {
    //   const index = newData.indexOf(data);
    //   if (index > -1) {
    //     const remove = newData.splice(index, 2);
    //     setCheckedName(remove);
    //     console.log("remove", remove);
    //   }
    // }
    // console.log("newdata", newData);
    // const filterData = colorData.filter((items) => {
    //   return newData.includes(items.Activity_TypeNameTH);
    // });
    // console.log("checked", filterData);
    // setActivityOncheck(filterData);
  };

  var yourVlSpec = {
    $schema: "https://vega.github.io/schema/vega-lite/v4.json",
    width: 245,
    height: 150,
    layer: [
      {
        data: {
          name: "export-growthValue",
          values: selectedMean.export_value,
        },
        transform: [{ calculate: "datum['growthRate']/100", as: "growthRate" }],
        encoding: {
          x: {
            field: "date",
            timeUnit: "yearmonth",
            title: "Date",
          },
        },
        layer: [
          {
            mark: {
              type: "line",
              point: true,
            },
            encoding: {
              y: {
                field: "exportValue",
                type: "quantitative",
                axis: {
                  titleColor: "#1264a3",
                  format: ".2s",
                  // labelExpr: "datum['exportValue']",
                  // labelExpr: "replace(datum.exportValue, 'G', 'B')",
                  // labelExpr: "replace(datum.exportValue, 'G', 'B')",
                },
              },
              color: { value: "#85A9C5" },
            },
          },
          {
            mark: {
              type: "line",
              opacity: 0.6,
              point: true,
              interpolate: "monotone",
            },
            encoding: {
              y: {
                field: "growthRate",
                type: "quantitative",
                // scale: { domain: [-1, 3] },
                axis: { titleColor: "#169256", format: "$,.2~%" },
              },
              color: { value: "#85C5A6" },
            },
          },
          {
            mark: "rule",
            encoding: {
              opacity: {
                condition: { value: 0.3, selection: "hover" },
                value: 0,
              },
              tooltip: [
                {
                  timeUnit: "year",
                  field: "date",
                },
                {
                  field: "exportValue",
                  type: "quantitative",
                  format: ",",
                },
                {
                  field: "growthRate",
                  type: "quantitative",
                  format: ".2~%",
                },
              ],
            },
            selection: {
              hover: {
                type: "single",
                fields: ["date"],
                nearest: true,
                on: "mouseover",
                empty: "none",
                clear: "mouseout",
              },
            },
          },
        ],
        resolve: { scale: { y: "independent" } },
      },
      {
        data: {
          name: "activity",
          values: activityOncheck,
        },
        mark: {
          type: "rule",
          strokeDash: [9, 3],
          strokeWidth: 5,
          tooltip: true,
        },
        encoding: {
          x: {
            field: "Activity_Start_Date",
            timeUnit: "yearmonthdate",
          },
          color: {
            field: "color",
            type: "ordinal",
            scale: null,
            legend: null,
          },
          opacity: {
            value: 1,
          },
          tooltip: [
            {
              title: "ชื่อกิจกรรม",
              field: "Activity_NameTH",
            },
            { title: "บริษัทที่เข้าร่วม", field: "Participants" },
          ],
        },
      },
    ],
    config: {
      axisY: { titleFontSize: 16, labelFontSize: 12 },
      axisX: { titleFontSize: 16, labelFontSize: 12 },
      legend: { titleFontSize: 16, labelFontSize: 14 },
    },
  };
  vegaEmbed("#vis", yourVlSpec, {
    actions: false,
    renderer: "svg",
  });
  return (
    <div className="line-chart">
      <Card style={{ height: "100%" }}>
        <div className="chart">
          <div id="vis"></div>
        </div>
        <div className="panel-control">
          {Colorpicker.map((item, index) => (
            <Checkbox
              value={item.name}
              onChange={(e) => onCheck(e)}
              key={index}
            >
              <Tag color={item.color} style={{ height: 10 }}></Tag>
              {item.name}
            </Checkbox>
          ))}
        </div>
      </Card>
    </div>
  );
}

const Colorpicker = [
  { name: "โครงการ/กิจกรรมพิเศษ", color: "#1F77B4" },
  { name: "เสวนา", color: "#AEC7E8" },
  { name: "ฝึกอบรม และสัมมนา", color: "#FF7F0E" },
  { name: "งานแสดงสินค้านานาชาติในประเทศ", color: "#FFBB78" },
  { name: "Online Business Matching", color: "#2CA02C" },
  { name: "คณะผู้แทนการค้าไทยเยือนต่างประเทศ", color: "#98DF8A" },
  { name: "พัฒนาออกแบบ", color: "#D62728" },
  { name: "Top Thai Brands", color: "#FF9896" },
  { name: "คณะผู้แทนการค้าต่างประเทศเยือนไทย", color: "#9467BD" },
  { name: "THAILAND EXHIBITION", color: "#C5B0D5" },
  { name: "Thailand Week", color: "#8C564B" },
  { name: "ฝึกอบรม", color: "#C49C94" },
  { name: "E-Learning", color: "#E377C2" },
  { name: "สัมมนา", color: "#F7B6D2" },
  { name: "งานแสดงสินค้าในต่างประเทศ", color: "#7F7F7F" },
];

const dict = {
  "โครงการ/กิจกรรมพิเศษ": "#1F77B4",
  เสวนา: "#AEC7E8",
  "ฝึกอบรม และสัมมนา": "#FF7F0E",
  งานแสดงสินค้านานาชาติในประเทศ: "#FFBB78",
  "Online Business Matching": "#2CA02C",
  คณะผู้แทนการค้าไทยเยือนต่างประเทศ: "#98DF8A",
  พัฒนาออกแบบ: "#D62728",
  "Top Thai Brands": "#FF9896",
  คณะผู้แทนการค้าต่างประเทศเยือนไทย: "#9467BD",
  "THAILAND EXHIBITION": "#C5B0D5",
  "Thailand Week": "#8C564B",
  ฝึกอบรม: "#C49C94",
  "E-Learning": "#E377C2",
  สัมมนา: "#F7B6D2",
  งานแสดงสินค้าในต่างประเทศ: "#7F7F7F",
};

const export_value = [
  {
    date: "2013",
    exportValue: 17398221,
    growthRate: null,
  },
  {
    date: "2014",
    exportValue: 13276305,
    growthRate: 76.31,
  },
  {
    date: "2015",
    exportValue: 45591534,
    growthRate: 343.41,
  },
  {
    date: "2016",
    exportValue: 45779488,
    growthRate: 100.41,
  },
  {
    date: "2017",
    exportValue: 67962586,
    growthRate: 148.46,
  },
  {
    date: "2018",
    exportValue: 56060969,
    growthRate: 82.49,
  },
  {
    date: "2019",
    exportValue: 76200926,
    growthRate: 135.93,
  },
  {
    date: "2020",
    exportValue: 51134584,
    growthRate: 67.1,
  },
  {
    date: "2021",
    exportValue: 44234595,
    growthRate: 86.51,
  },
];

const activity_attend = [
  {
    Activity_id: 10005,
    Activity_NameTH:
      "โครงการคณะผู้แทนการค้าสินค้าอุตสาหกรรมและอาหารเยือนภูมิภาคแอฟริกา (ราชอาณาจักรโมร็อกโก สาธารณรัฐตูนิเซีย และสหพันธ์สาธารณรัฐประชาธิปไตยเอธิโอเปีย)",
    Activity_TypeNameTH: "คณะผู้แทนการค้าไทยเยือนต่างประเทศ",
    Activity_Start_Date: "2018-09-19T20:00:00",
  },
  {
    Activity_id: 10035,
    Activity_NameTH:
      "โครงการคณะผู้แทนการค้าสินค้าอุตสาหกรรมเดินทางไปเจรจาการค้าที่สาธารณรัฐฟิลิปปินส์ ",
    Activity_TypeNameTH: "คณะผู้แทนการค้าไทยเยือนต่างประเทศ",
    Activity_Start_Date: "2018-08-22T05:30:00",
  },
  {
    Activity_id: 10078,
    Activity_NameTH:
      "โครงการการเข้าร่วมงานแสดงสินค้า FACIM 2018 และการจัดคณะผู้แทนการค้าสินค้าอุตสาหกรรมและอาหารเยือนภูมิภาคแอฟริกา (สาธารณรัฐโมซัมบิก สหสาธารณรัฐแทนซาเนีย และสาธารณรัฐเคนย่า) ",
    Activity_TypeNameTH: "คณะผู้แทนการค้าไทยเยือนต่างประเทศ",
    Activity_Start_Date: "2018-08-26T00:00:00",
  },
  {
    Activity_id: 10097,
    Activity_NameTH: "AAPEX 2018",
    Activity_TypeNameTH: "งานแสดงสินค้าในต่างประเทศ",
    Activity_Start_Date: "2018-10-30T09:51:00",
  },
  {
    Activity_id: 10270,
    Activity_NameTH: "AUTOMECHANIKA DUBAI 2019",
    Activity_TypeNameTH: "งานแสดงสินค้าในต่างประเทศ",
    Activity_Start_Date: "2019-06-10T10:00:00",
  },
  {
    Activity_id: 10642,
    Activity_NameTH:
      "โครงการจัดคณะผู้แทนการค้าอุตสาหกรรมก่อสร้างและธุรกิจบริการที่เกี่ยวเนื่องเยือนประเทศโอมานและประเทศบาห์เรน",
    Activity_TypeNameTH: "คณะผู้แทนการค้าไทยเยือนต่างประเทศ",
    Activity_Start_Date: "2019-06-17T00:00:00",
  },
  {
    Activity_id: 10305,
    Activity_NameTH:
      "โครงการคณะผู้แทนการค้าสินค้าอุตสาหกรรมไปเจรจาการค้าที่ ลาว กัมพูชาและเวียดนาม",
    Activity_TypeNameTH: "คณะผู้แทนการค้าไทยเยือนต่างประเทศ",
    Activity_Start_Date: "2019-01-13T00:00:00",
  },
  {
    Activity_id: 10833,
    Activity_NameTH: "Mini Thailand Week 2020 , Manila Philippines",
    Activity_TypeNameTH: "Thailand Week",
    Activity_Start_Date: "2020-03-05T10:00:00",
  },
  {
    Activity_id: 10395,
    Activity_NameTH: "Top Thai Brands 2019 Hochiminh City Vietnam",
    Activity_TypeNameTH: "Top Thai Brands",
    Activity_Start_Date: "2019-05-09T09:00:00",
  },
  {
    Activity_id: 10452,
    Activity_NameTH: "Mini Thailand Week 2019 แขวงอุดมไซ สปป.ลาว",
    Activity_TypeNameTH: "Thailand Week",
    Activity_Start_Date: "2019-04-03T00:00:00",
  },
  {
    Activity_id: 10554,
    Activity_NameTH: "Top Thai Brands 2019 ณ เมืองการาจี ประเทศปากีสถาน",
    Activity_TypeNameTH: "Top Thai Brands",
    Activity_Start_Date: "2019-07-20T10:00:00",
  },
  {
    Activity_id: 10563,
    Activity_NameTH:
      "โครงการคณะผู้แทนการค้าสินค้าอุตสาหกรรมและอุตสาหกรรมอาหารเยือนภูมิภาคอเมริกาใต้ (ชิลี/เอกวาดอร์/เปรู) ",
    Activity_TypeNameTH: "คณะผู้แทนการค้าไทยเยือนต่างประเทศ",
    Activity_Start_Date: "2019-06-14T00:00:00",
  },
  {
    Activity_id: 10707,
    Activity_NameTH:
      "โครงการจัดคณะผู้แทนการค้าสร้างเครือข่ายและเจรจาการค้าสินค้าอุตสาหกรรมเยือนฟิลิปปินส์",
    Activity_TypeNameTH: "คณะผู้แทนการค้าไทยเยือนต่างประเทศ",
    Activity_Start_Date: "2019-07-07T00:00:00",
  },
  {
    Activity_id: 10490,
    Activity_NameTH: "Top Thai Brands 2019 ณ กรุงโคลัมโบ ประเทศศรีลังกา",
    Activity_TypeNameTH: "Top Thai Brands",
    Activity_Start_Date: "2019-06-22T10:00:00",
  },
  {
    Activity_id: 10757,
    Activity_NameTH:
      "Top Thai Brands 2019 ณ กรุงโคลัมโบ ประเทศศรีลังกา (กำหนดการใหม่)",
    Activity_TypeNameTH: "Top Thai Brands",
    Activity_Start_Date: "2019-09-06T10:00:00",
  },
  {
    Activity_id: 10585,
    Activity_NameTH:
      "โครงการคณะผู้แทนการค้าไทยสร้างพันธมิตรและเครือข่ายการค้าการลงทุนในมาเลเซียฝั่งตะวันออก (รัฐซาบาห์ และซาราวัก)",
    Activity_TypeNameTH: "คณะผู้แทนการค้าไทยเยือนต่างประเทศ",
    Activity_Start_Date: "2019-08-20T00:00:00",
  },
  {
    Activity_id: 10762,
    Activity_NameTH:
      "โครงการคณะผู้แทนการค้าสินค้าอุตสาหกรรมและอุตสาหกรรมอาหารเยือนภูมิภาคแอฟริกา (ยูกันดา,แอฟริกาใต้,มอริเชียส)",
    Activity_TypeNameTH: "คณะผู้แทนการค้าไทยเยือนต่างประเทศ",
    Activity_Start_Date: "2019-08-21T06:00:00",
  },
  {
    Activity_id: 10396,
    Activity_NameTH: "Top Thai Brands 2019 Hanoi Vietnam",
    Activity_TypeNameTH: "Top Thai Brands",
    Activity_Start_Date: "2019-05-23T09:00:00",
  },
  {
    Activity_id: 10874,
    Activity_NameTH: "Mini Thailand Week 2020 แขวงหลวงน้ำทา",
    Activity_TypeNameTH: "Thailand Week",
    Activity_Start_Date: "2020-02-05T09:00:00",
  },
  {
    Activity_id: 10875,
    Activity_NameTH: "Mini Thailand Week 2020 , Cebu Philippines",
    Activity_TypeNameTH: "Thailand Week",
    Activity_Start_Date: "2020-06-18T10:00:00",
  },
  {
    Activity_id: 10862,
    Activity_NameTH: "Top Thai Brands 2020 Hochiminh City Vietnam",
    Activity_TypeNameTH: "Top Thai Brands",
    Activity_Start_Date: "2020-08-06T09:00:00",
  },
  {
    Activity_id: 10907,
    Activity_NameTH:
      "งานแสดงสินค้าชิ้นส่วน อะไหล่ยานยนต์ และอุปกรณ์ตกแต่ง 2563 (TAPA 2020)",
    Activity_TypeNameTH: "งานแสดงสินค้านานาชาติในประเทศ",
    Activity_Start_Date: "2020-09-03T10:00:59",
  },
  {
    Activity_id: 10944,
    Activity_NameTH:
      "คณะผู้แทนการค้าสินค้าอุตสาหกรรมและอุตสาหกรรมอาหารเยือนภูมิภาคแอฟริกา (เคนยา/ยูกันดา/แอฟริกาใต้)",
    Activity_TypeNameTH: "คณะผู้แทนการค้าไทยเยือนต่างประเทศ",
    Activity_Start_Date: "2020-03-09T00:00:00",
  },
  {
    Activity_id: 10832,
    Activity_NameTH: "Top Thai Brands 2020 Phnompenh Cambodia",
    Activity_TypeNameTH: "Top Thai Brands",
    Activity_Start_Date: "2020-02-06T10:00:00",
  },
  {
    Activity_id: 10861,
    Activity_NameTH: "Top Thai Brands 2020 Yangon Myanmar",
    Activity_TypeNameTH: "Top Thai Brands",
    Activity_Start_Date: "2020-03-12T10:00:00",
  },
  {
    Activity_id: 10253,
    Activity_NameTH: "Top Thai Brands 2019 Phnompenh Cambodia",
    Activity_TypeNameTH: "Top Thai Brands",
    Activity_Start_Date: "2019-01-31T09:00:00",
  },
  {
    Activity_id: 10258,
    Activity_NameTH: "Top Thai Brands 2019 Yangon Myanmar",
    Activity_TypeNameTH: "Top Thai Brands",
    Activity_Start_Date: "2019-02-21T10:00:00",
  },
  {
    Activity_id: 10262,
    Activity_NameTH: "Top Thai Brands 2019 Vientiane Laos",
    Activity_TypeNameTH: "Top Thai Brands",
    Activity_Start_Date: "2019-03-06T10:00:00",
  },
  {
    Activity_id: 10286,
    Activity_NameTH: "Top Thai Brands 2019 ณ กรุงธากา บังกลาเทศ",
    Activity_TypeNameTH: "Top Thai Brands",
    Activity_Start_Date: "2019-03-27T10:00:00",
  },
  {
    Activity_id: 10885,
    Activity_NameTH: "Top Thai Brands 2020 ณ กรุงโคลัมโบ ประเทศศรีลังกา",
    Activity_TypeNameTH: "Top Thai Brands",
    Activity_Start_Date: "2020-03-06T10:00:00",
  },
  {
    Activity_id: 10890,
    Activity_NameTH: "Top Thai Brands 2020 ณ กรุงธากา บังกลาเทศ",
    Activity_TypeNameTH: "Top Thai Brands",
    Activity_Start_Date: "2020-08-19T11:00:00",
  },
  {
    Activity_id: 7294,
    Activity_NameTH: "Top Thai Brands Siemreap 2016",
    Activity_TypeNameTH: "Top Thai Brands",
    Activity_Start_Date: "2016-11-03T09:00:00",
  },
  {
    Activity_id: 9006,
    Activity_NameTH: "Thailand Trade Exhibition 2013, เสียมเรียบ",
    Activity_TypeNameTH: "THAILAND EXHIBITION",
    Activity_Start_Date: "2013-01-31T00:00:00",
  },
  {
    Activity_id: 9090,
    Activity_NameTH: "Thailand Sourcing & Business Matching 2013, Kathmandu",
    Activity_TypeNameTH: "THAILAND EXHIBITION",
    Activity_Start_Date: "2013-08-29T00:00:00",
  },
  {
    Activity_id: 9291,
    Activity_NameTH:
      "คณะผู้แทนการค้าเดินทางไปเจรจาการค้า/การลงทุน ณ ประเทศคาซัคสถาน (เมืองอัลมาดีและแอสตานา)",
    Activity_TypeNameTH: "คณะผู้แทนการค้าไทยเยือนต่างประเทศ",
    Activity_Start_Date: "2014-09-08T00:00:00",
  },
  {
    Activity_id: 9591,
    Activity_NameTH:
      "โครงการคณะผู้แทนการค้าเยือนสาธารณรัฐเคนยา และสาธารณรัฐโมซัมบิก",
    Activity_TypeNameTH: "คณะผู้แทนการค้าไทยเยือนต่างประเทศ",
    Activity_Start_Date: "2016-08-24T00:00:00",
  },
  {
    Activity_id: 9597,
    Activity_NameTH:
      "โครงการคณะผู้แทนการค้าสินค้า Top Thai Brand ไปเจรจาการค้าที่ภูมิภาคอเมริกาใต้ (โคลอมเบีย/เปรู/อาร์เจนตินา)",
    Activity_TypeNameTH: "คณะผู้แทนการค้าไทยเยือนต่างประเทศ",
    Activity_Start_Date: "2016-09-18T00:00:00",
  },
  {
    Activity_id: 7280,
    Activity_NameTH: "Top Thai Brands 2016 ณ กรุงพนมเปญ",
    Activity_TypeNameTH: "Top Thai Brands",
    Activity_Start_Date: "2016-02-03T10:00:00",
  },
  {
    Activity_id: 10597,
    Activity_NameTH: "งานแสดงสินค้า THE BIG 5 SHOW 2019\t          ",
    Activity_TypeNameTH: "งานแสดงสินค้าในต่างประเทศ",
    Activity_Start_Date: "2019-11-25T00:00:00",
  },
  {
    Activity_id: 7288,
    Activity_NameTH: "Top Thai Brands โฮจิมินห์ 2016",
    Activity_TypeNameTH: "Top Thai Brands",
    Activity_Start_Date: "2016-05-05T09:00:00",
  },
  {
    Activity_id: 8758,
    Activity_NameTH:
      "งานแสดงสินค้ายานยนต์ ชิ้นส่วน อะไหล่ยานยนต์ และอุปกรณ์ตกแต่ง 2561 (TAPA 2018)",
    Activity_TypeNameTH: "งานแสดงสินค้านานาชาติในประเทศ",
    Activity_Start_Date: "2018-04-05T10:00:17",
  },
  {
    Activity_id: 12687,
    Activity_NameTH: "THE BIG 5 SHOW 2021",
    Activity_TypeNameTH: "งานแสดงสินค้าในต่างประเทศ",
    Activity_Start_Date: "2021-09-12T00:00:00",
  },
  {
    Activity_id: 8693,
    Activity_NameTH: "THE BIG 5 SHOW 2017",
    Activity_TypeNameTH: "งานแสดงสินค้าในต่างประเทศ",
    Activity_Start_Date: "2017-11-26T11:00:00",
  },
  {
    Activity_id: 9000,
    Activity_NameTH: "Thailand Trade Show 2013, Dhaka",
    Activity_TypeNameTH: "THAILAND EXHIBITION",
    Activity_Start_Date: "2013-03-07T00:00:00",
  },
  {
    Activity_id: 8743,
    Activity_NameTH: "Thailand Week 2018 ณ กรุงธากา บังกลาเทศ",
    Activity_TypeNameTH: "Thailand Week",
    Activity_Start_Date: "2018-04-23T11:00:00",
  },
  {
    Activity_id: 9059,
    Activity_NameTH: "AUTOMECKHANIKA DUBAI 2013",
    Activity_TypeNameTH: "งานแสดงสินค้าในต่างประเทศ",
    Activity_Start_Date: "2013-06-11T00:00:00",
  },
  {
    Activity_id: 8900,
    Activity_NameTH: "Thailand Week 2018 เมืองการาจี ประเทศปากีสถาน",
    Activity_TypeNameTH: "Thailand Week",
    Activity_Start_Date: "2018-09-01T00:00:00",
  },
  {
    Activity_id: 9045,
    Activity_NameTH: "Thailand Trade Exhibition 2013, เวียงจันทน์",
    Activity_TypeNameTH: "THAILAND EXHIBITION",
    Activity_Start_Date: "2013-07-03T00:00:00",
  },
  {
    Activity_id: 9387,
    Activity_NameTH:
      "คณะผู้แทนการค้าเดินทางไปเจรจาการค้า/การลงทุน ณ สหรัฐอาหรับเอมิเรตส์ จอร์แดน และคูเวต ",
    Activity_TypeNameTH: "คณะผู้แทนการค้าไทยเยือนต่างประเทศ",
    Activity_Start_Date: "2015-04-28T00:00:00",
  },
  {
    Activity_id: 9435,
    Activity_NameTH:
      "โครงการจัดคณะผู้แทนการค้าเดินทางเยือนสาธารณรัฐอิสลามปากีสถาน",
    Activity_TypeNameTH: "คณะผู้แทนการค้าไทยเยือนต่างประเทศ",
    Activity_Start_Date: "2015-08-11T00:00:00",
  },
  {
    Activity_id: 9663,
    Activity_NameTH: "โครงการคณะผู้แทนการค้าไปเจรจาการค้าที่สาธารณรัฐคาซัคสถาน",
    Activity_TypeNameTH: "คณะผู้แทนการค้าไทยเยือนต่างประเทศ",
    Activity_Start_Date: "2017-06-25T00:00:00",
  },
  {
    Activity_id: 9578,
    Activity_NameTH:
      "โครงการคณะผู้แทนการค้าธุรกิจบริการก่อสร้างและอุตสาหกรรมที่เกี่ยวเนื่องเยือนประเทศรัสเซีย",
    Activity_TypeNameTH: "คณะผู้แทนการค้าไทยเยือนต่างประเทศ",
    Activity_Start_Date: "2016-08-30T00:00:00",
  },
  {
    Activity_id: 9593,
    Activity_NameTH:
      "โครงการคณะผู้แทนการค้าธุรกิจโรงแรมและอุตสาหกรรมที่เกี่ยวข้องไปเจรจาการค้า ณ สาธารณรัฐอิสลามอิหร่าน",
    Activity_TypeNameTH: "คณะผู้แทนการค้าไทยเยือนต่างประเทศ",
    Activity_Start_Date: "2016-09-08T08:00:00",
  },
  {
    Activity_id: 9659,
    Activity_NameTH:
      "โครงการคณะผู้แทนการค้าสินค้าเกษตรและอุตสาหกรรมเยือนภูมิภาคแอฟริกา (สาธารณรัฐโมซัมบิก และสาธารณรัฐแอฟริกาใต้)",
    Activity_TypeNameTH: "คณะผู้แทนการค้าไทยเยือนต่างประเทศ",
    Activity_Start_Date: "2017-03-25T00:00:00",
  },
  {
    Activity_id: 9765,
    Activity_NameTH:
      "โครงการคณะผู้แทนการค้าไปเจรจาการค้า การลงทุน ณ สหพันธรัฐรัสเซีย",
    Activity_TypeNameTH: "คณะผู้แทนการค้าไทยเยือนต่างประเทศ",
    Activity_Start_Date: "2017-09-03T00:00:00",
  },
  {
    Activity_id: 8380,
    Activity_NameTH: "Thailand Week 2016 ณ กรุงธากา",
    Activity_TypeNameTH: "Thailand Week",
    Activity_Start_Date: "2016-03-16T10:00:00",
  },
  {
    Activity_id: 8421,
    Activity_NameTH: "Mini Thailand Week 2018 Salavan Laos",
    Activity_TypeNameTH: "Thailand Week",
    Activity_Start_Date: "2018-05-02T10:00:00",
  },
  {
    Activity_id: 8690,
    Activity_NameTH: "AAPEX 2017",
    Activity_TypeNameTH: "งานแสดงสินค้าในต่างประเทศ",
    Activity_Start_Date: "2017-10-31T00:00:00",
  },
  {
    Activity_id: 8701,
    Activity_NameTH: "Top Thai Brands 2018 , Hochiminhcity Vietnam   ",
    Activity_TypeNameTH: "Top Thai Brands",
    Activity_Start_Date: "2018-05-10T10:00:00",
  },
  {
    Activity_id: 8871,
    Activity_NameTH: "Top Thai Brands 2018 Yangon Myanmar",
    Activity_TypeNameTH: "Top Thai Brands",
    Activity_Start_Date: "2018-06-07T00:00:00",
  },
  {
    Activity_id: 9178,
    Activity_NameTH:
      "โครงการคณะผู้แทนการค้าสินค้าศักยภาพไปเจรจาการค้าตลาดรัสเซีย (Thailand Week Russia 2013)",
    Activity_TypeNameTH: "คณะผู้แทนการค้าไทยเยือนต่างประเทศ",
    Activity_Start_Date: "2013-09-14T00:00:00",
  },
  {
    Activity_id: 7291,
    Activity_NameTH: "Top Thai Brands เวียงจันทน์ สปป.ลาว",
    Activity_TypeNameTH: "Top Thai Brands",
    Activity_Start_Date: "2016-06-29T10:00:00",
  },
  {
    Activity_id: 9072,
    Activity_NameTH: "THE BIG 5 SHOW 2013",
    Activity_TypeNameTH: "งานแสดงสินค้าในต่างประเทศ",
    Activity_Start_Date: "2013-11-25T00:00:00",
  },
  {
    Activity_id: 9272,
    Activity_NameTH:
      "โครงการจัดคณะผู้แทนการค้าสินค้าอุตสาหกรรมหนักไปเจรจาการค้าที่อเมริกากลาง (เม็กซิโก คอสตาริกา และสาธา",
    Activity_TypeNameTH: "คณะผู้แทนการค้าไทยเยือนต่างประเทศ",
    Activity_Start_Date: "2014-08-18T00:00:00",
  },
  {
    Activity_id: 9275,
    Activity_NameTH:
      "โครงการคณะผู้แทนการค้าไปเจรจาการค้าที่ภูมิภาคแอฟริกา (ประเทศเคนยา และไนจีเรีย)",
    Activity_TypeNameTH: "คณะผู้แทนการค้าไทยเยือนต่างประเทศ",
    Activity_Start_Date: "2014-07-27T00:00:00",
  },
  {
    Activity_id: 9720,
    Activity_NameTH:
      "โครงการคณะผู้แทนการค้าสินค้าเกษตร อาหารและอุตสาหกรรมเยือนภูมิภาคลาตินอเมริกา",
    Activity_TypeNameTH: "คณะผู้แทนการค้าไทยเยือนต่างประเทศ",
    Activity_Start_Date: "2017-06-11T00:00:00",
  },
  {
    Activity_id: 8899,
    Activity_NameTH: "Thailand Week 2018 กรุงเตหะราน ประเทศอิหร่าน",
    Activity_TypeNameTH: "Thailand Week",
    Activity_Start_Date: "2018-07-23T00:00:00",
  },
  {
    Activity_id: 9009,
    Activity_NameTH: "Thailand Trade Show 2013, Singapore(Suntec)",
    Activity_TypeNameTH: "THAILAND EXHIBITION",
    Activity_Start_Date: "2013-07-24T00:00:00",
  },
  {
    Activity_id: 10858,
    Activity_NameTH: "Top Thai Brands 2020 Vientiane Laos",
    Activity_TypeNameTH: "Top Thai Brands",
    Activity_Start_Date: "2020-03-04T10:00:00",
  },
  {
    Activity_id: 7279,
    Activity_NameTH: "งาน Top Thai Brands 2016 กัมพูชา",
    Activity_TypeNameTH: "Top Thai Brands",
    Activity_Start_Date: "2016-02-03T10:00:00",
  },
  {
    Activity_id: 9247,
    Activity_NameTH:
      "คณะผู้แทนการค้าสินค้าอาหารและอุตสาหกรรมหนักไปภูมิภาคอาเซียน(พม่า)",
    Activity_TypeNameTH: "คณะผู้แทนการค้าไทยเยือนต่างประเทศ",
    Activity_Start_Date: "2014-07-18T00:00:00",
  },
  {
    Activity_id: 9379,
    Activity_NameTH: "Thailand Week  2015, Hanoi",
    Activity_TypeNameTH: "THAILAND EXHIBITION",
    Activity_Start_Date: "2015-08-12T00:00:00",
  },
];
