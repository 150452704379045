import React from "react";
import { Card, Typography, Button } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../resources/index";
import services from "../../resources/variables/services";

const { Text } = Typography;

export default function PotentialCDetail(props) {
  let history = useHistory();
  const dispatch = useDispatch();
  const actions = bindActionCreators(actionCreators, dispatch);
  const { inputfilter, Potentialetail, textExtraFilter, state } = useSelector((state) => ({
    inputfilter: state?.filter_input?.filter_input,
    Potentialetail: state?.potential_Country?.potential_Country,
    textExtraFilter: state?.text_extra_filter?.text_extra_filter,
    state: {
      ...state
    }
  }));
  // console.log(Potentialetail);
  const filter = JSON.parse(localStorage.getItem("filter"));
  const calPotential = () => {
    const sum =
      Potentialetail.country.potential - Potentialetail.country.actual_value;
    // return sum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return sum;
  };

  const calPercent = () => {
    const percent =
      (1 -
        Potentialetail.country.actual_value /
        Potentialetail.country.potential) *
      100;
    return percent.toFixed(1);
  };

  const activityJoin = (data) => {
    console.log("ActivityThat", data);
    const ditpCountryObjList = [];
    services.listGenerator(filter.ditp_country, ditpCountryObjList);
    const countryObj = ditpCountryObjList.find(obj => obj.title == data.key);
    let countryKeys = [];
    let countryNames = [];
    if (String(data.key).toLowerCase() === 'all') {
      console.log("[all]");
      const ditpCountryFiltered = ditpCountryObjList.filter(obj => obj.key.startsWith('leaf'));
      countryKeys = ditpCountryFiltered.map(obj => obj.key);
      countryNames = ditpCountryFiltered.map(obj => obj.title);
    }
    else if (countryObj && countryObj?.key.startsWith("leaf")) {
      countryKeys.push(countryObj.key);
      countryNames.push(countryObj.title);
    } else if (countryKeys && !countryObj?.key.startsWith("leaf")) {
      const ditpCountryFiltered = ditpCountryObjList
        .filter(country => country.key.includes(countryObj.key))
        .filter(country => country.key.startsWith(`leaf-${countryObj.key}-`));
      countryKeys = ditpCountryFiltered.map(country => country.key);
      countryNames = ditpCountryFiltered.map(country => country.title);
    }
    // console.log(countryObj);
    // console.log(countryKeys, countryNames);
    // console.log(textExtraFilter);

    const updatedTextExtra = {
      ...textExtraFilter,
      activity: {
        ...textExtraFilter.activity,
        country_activity: {
          ellipsisText: String(data.key).toLowerCase() === 'all' ? "ทั้งหมด" : services.formatArrayWithEllipsis(countryNames),
          fullText: countryNames.join(", ")
        }
      }
    };

    const updatedFilterPayload = {
      ...inputfilter,
      country_activity: {
        ...inputfilter.country_activity,
        value: countryKeys,
      },
    };

    actions.setTextExtraFilter(updatedTextExtra);
    actions.setFilter(updatedFilterPayload);
    history.push("/Activity");
  };

  const suggestActivity = (data) => {
    console.log("SuggestThat", data);
    const ditpCountryObjList = [];
    services.listGenerator(filter.ditp_country, ditpCountryObjList);
    const countryObj = ditpCountryObjList.find(obj => obj.title == data.key);
    let countryKeys = [];
    let countryNames = [];

    if (String(data.key).toLowerCase() === 'all') {
      console.log("[all]");
      const ditpCountryFiltered = ditpCountryObjList.filter(obj => obj.key.startsWith('leaf'));
      countryKeys = ditpCountryFiltered.map(obj => obj.key);
      countryNames = ditpCountryFiltered.map(obj => obj.title);
    }
    else if (countryObj && countryObj?.key.startsWith("leaf")) {
      countryKeys.push(countryObj.key);
      countryNames.push(countryObj.title);

    } else if (countryKeys && !countryObj?.key.startsWith("leaf")) {
      const ditpCountryFiltered = ditpCountryObjList
        .filter(country => country.key.includes(countryObj.key))
        .filter(country => country.key.startsWith(`leaf-${countryObj.key}-`));
      countryKeys = ditpCountryFiltered.map(country => country.key);
      countryNames = ditpCountryFiltered.map(country => country.title);
    }

    console.log(countryObj);
    console.log(countryKeys, countryNames);
    console.log(textExtraFilter);

    const updatedTextExtra = {
      ...textExtraFilter,
      activity: {
        ...textExtraFilter.activity,
        country_activity: {
          ellipsisText: String(data.key).toLowerCase() === 'all' ? "ทั้งหมด" : services.formatArrayWithEllipsis(countryNames),
          fullText: countryNames.join(", ")
        }
      }
    };

    const updatedFilterPayload = {
      ...inputfilter,
      country_activity: {
        ...inputfilter.country_activity,
        value: countryKeys,
      },
    };

    actions.setTextExtraFilter(updatedTextExtra);
    actions.setFilter(updatedFilterPayload);
    history.push("/SuggestActivity");
  };

  const dateDetails = (date) => {
    if (date) {
      const [year, mount, day] = date.split('-');
      const th_Mount = {
        "01": "ม.ค.",
        "02": "ก.พ.",
        "03": "มี.ค.",
        "04": "เม.ย.",
        "05": "พ.ค.",
        "06": "มิ.ย.",
        "07": "ก.ค.",
        "08": "ส.ค.",
        "09": "ก.ย.",
        "10": "ต.ค.",
        "11": "พ.ย.",
        "12": "ธ.ค.",
      };

      return {
        year_detail: parseInt(year) + 543,
        mount_detail: th_Mount[mount]
      };
    }
  };

  const waitDetail = () => {
    if (Potentialetail) {
      const currentYear = new Date().getFullYear();
      return (
        <Card
          bodyStyle={{
            minHeight: '50vh'
          }}
          title={
            <Text>
              มูลค่าส่งออกปี {dateDetails(props.year?.start_date)?.year_detail} เดือน {dateDetails(props.start_mount)?.mount_detail} - {dateDetails(props.end_mount)?.mount_detail} <br /> กลุ่มประเทศ :{" "}
              {Potentialetail.country ? Potentialetail.country.data.key : null}
            </Text>
          }
          actions={[
            <div className="flex flex-col items-center gap-3">
              <Button
                onClick={() => activityJoin(Potentialetail.country.data)}
                style={{ width: "50%", borderRadius: "8px" }}
              >
                กิจกรรมที่เคยเข้าร่วม
              </Button>
              <Button
                onClick={() => suggestActivity(Potentialetail.country.data)}
                style={{ width: "50%", borderRadius: "8px" }}
              >
                กิจกรรมที่แนะนำ
              </Button>
            </div>
          ]}
        >
          {Potentialetail.country ? (
            <div className="table">
              <div className="t-row">
                <div className="t-row l">
                  ศักยภาพการส่งออก <Text>:</Text>
                </div>
                <div className="t-row r">
                  {Potentialetail.country.potential > 999 &&
                    Potentialetail.country.potential < 1000000
                    ? (Potentialetail.country.potential / 1000)
                      .toFixed()
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " แสนบาท"
                    : Potentialetail.country.potential > 1000000
                      ? (Potentialetail.country.potential / 1000000)
                        .toFixed()
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " ล้านบาท"
                      : null}
                </div>
              </div>
              {console.log(Potentialetail.country.actual_value)}
              <div className="t-row">
                <div className="t-row l">
                  มูลค่าส่งออกจริง <Text>:</Text>
                </div>
                <div className="t-row r">
                  {Potentialetail.country.actual_value > 999 &&
                    Potentialetail.country.actual_value < 1000000
                    ? (Potentialetail.country.actual_value / 1000)
                      .toFixed()
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " แสนบาท"
                    : Potentialetail.country.actual_value > 1000000
                      ? (Potentialetail.country.actual_value / 1000000)
                        .toFixed()
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " ล้านบาท"
                      : null}
                </div>
              </div>

              <div className="t-row">
                <div className="t-row l">
                  ศักยภาพที่ยังส่งออกได้ <Text>:</Text>
                </div>
                <div className="t-row r">
                  {calPotential() < 0
                    ? "ส่งออกเกินค่าศักยภาพจริง"
                    : calPotential() > 999 && calPotential() < 1000000
                      ? (calPotential() / 1000)
                        .toFixed()
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
                      " แสนบาท".replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
                      " แสนบาท"
                      : calPotential() > 1000000
                        ? (calPotential() / 1000000)
                          .toFixed()
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " ล้านบาท"
                        : null}
                  <br />
                  {calPercent() < 0 ? null : `(${calPercent()}%)`}
                </div>
              </div>
              {/* <div className="t-row">
              <div className="t-row l">
                ส่งออกแล้ว <Text>:</Text>
              </div>
              <div className="t-row r">
                {calPotential()} {"บาท"}
              </div>
            </div> */}
            </div>
          ) : null}
        </Card>
      );
    } else {
      return (
        <Card
          style={{
            height: 700,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <div className="text-lg text-primary-grey-dark text-center w-full">
            คลิกที่กล่องข้อมูลที่ต้องการดูรายละเอียด
          </div>
        </Card>
      );
    }
  };

  return <div className="potential-country-detail">{waitDetail()}</div>;
}
