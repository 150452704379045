import React, { useEffect } from "react";
import Plotly from 'plotly.js-dist-min';

export default function MovementGraph({ 
  filteredCompany, 
  nonFilteredCompany,
  selectedCompany, 
  getClickData,
}) {

  function generateLineArrow(data) {
    let annotations = [];
    let annotation = null;
    
    for (let i = 0; i < data.length; i++) {
        let companyData = data[i].data;
        let color = data[i].color;

        for (let j = 0; j < companyData.x.length - 1; j++) {
          
          annotation = {
            x: companyData.x[j],
            y: companyData.y[j],
            xref: 'x',
            yref: 'y',
            ax: companyData.x[j + 1],
            ay: companyData.y[j + 1],
            axref: 'x',
            ayref: 'y',
            showarrow: true,
            arrowside: 'start',
            arrowhead: 2,
            arrowsize: 1.5,
            arrowwidth: 5,
            // arrowcolor: color,
            // bordercolor: 'black',
            // borderpad: 2,
            // text: 'Arrow 1'
          };
          annotations.push(annotation)
  
        }
    }
    return annotations;
  }

  function generateCompanyTrace(data) {
    let traces = [];
    let trace = null;
    // debugger;
    for (let i = 0; i < data.length; i++) {
        trace = {
          name: data[i].name,
          x: data[i].data.x,
          y: data[i].data.y,
          text: data[i].data.text,
          mode: 'markers',
          type: 'scattergl',
          hoverinfo: 'all', // Show custom text in tooltips
          hoverlabel: {
            align: 'left'
          },
          marker: {
            // color: data[i].color,
            size: 15,
            line: {
              color: 'black',
              width: 4
            }
          },
        };
        traces.push(trace)

    }
    return traces;
  }

  function getSelectedData(filteredCompany, nonFilteredCompany, selectedData) {
    let result = selectedData.map(selectedItem => {
      
      let filterData = {}

      // create init object for plotly structure
      Object.entries(filteredCompany).forEach(([key, value]) => {
        filterData[key] = [];
      });
      
      // check for filteredCompany
      filteredCompany.no.forEach((item, index) => {
        if (item == selectedItem.value) {          
          // copy all key in object
          Object.entries(filteredCompany).forEach(([key, value]) => {
            filterData[key].push(value[index]);
          });
        }
      });

      // check for nonFilteredCompany
      nonFilteredCompany.no.forEach((item, index) => {
        if (item == selectedItem.value) {          
          // copy all key in object
          Object.entries(nonFilteredCompany).forEach(([key, value]) => {
            filterData[key].push(value[index]);
          });
        }
      });

      return {
        name: selectedItem.label,
        data: filterData,
        color: 'green',
      }

    });
    return result
  }

  const getTextLabel = (data) => {
    let textData = data.top_hscode2.map( (a, i) => {
      return `<b>tax no</b>: ${data.no[i]} <br>
<b>name</b>: ${data.name[i]} <br>
<b>year</b>: ${data.year[i]} <br>
<b>top1 Product</b>: ${data.top1_product[i]} <br>
<b>top2 Product</b>: ${data.top2_product[i]} <br>
<b>top3 Product</b>: ${data.top3_product[i]} <br>
`
    }
  );
    
    return textData
  };

  const onClickPoint = (data) => {
    let indexData = data.points[0].pointIndex;
    let customData = data.points[0].data.customdata;
    let traceIndex = data.points[0].curveNumber;
    let colors = data.points[0].data.marker.color
    
    
    let year = customData.year[indexData];
    let taxNo = customData.no[indexData];

    // var pn='',
    //   tn='',
    //   colors=[];

    // for(var i=0; i < data.points.length; i++){
    //   pn = data.points[i].pointNumber;
    //   tn = data.points[i].curveNumber;
    //   colors = data.points[i].data.marker.color;
    // };
    // debugger;
    // colors[indexData] = '#C54C82';

    // debugger;
    // var update = {'marker':{color: colors, size:16}};
    // let update = {
    //   marker: {
    //     size: 15,
    //     line: {
    //       color: 'black',
    //       width: 4
    //     }
    //   },
    // }
    
    // line: {
    //   color: 'black',
    //   width: 4
    // }

    // Plotly.restyle('container', update, [traceIndex]);

    // set to show loading
    getClickData(taxNo, year);

    // alert('Closest point clicked:\n\n'+pts);
  };

  useEffect(() => {
    // https://stackoverflow.com/questions/70161880/how-to-customize-hover-tooltip customdata
    // https://community.plotly.com/t/how-to-get-value-from-an-array-on-hovertemplate/54708 plotly hover with array

     
    // create trace text
    let textFilteredTrace = getTextLabel(filteredCompany);
    let textNonFilteredTrace = getTextLabel(nonFilteredCompany);

    // Create the scatter plot
    const filteredTrace = {
      name: 'บริษัทที่กรอง',
      x: filteredCompany.x,
      y: filteredCompany.y,
      text: textFilteredTrace,
      customdata: filteredCompany,
      mode: 'markers',
      type: 'scattergl',
      hoverinfo: 'text', // Show custom text in tooltips
      hoverlabel: {
        align: 'left'
      },
      marker: {
        color: filteredCompany.top_hscode2,
        size: 8,
        colorscale: 'Portland', // change from here: https://plotly.com/javascript/colorscales/

        // color scale bar
        showscale: true,
        colorbar: {
          title: 'top1 hscode2',
          titleside: 'top',
          x: 0,
          y: 0.15,
          len: 0.4
        },
      },      
    };

    const nonFilteredTrace = {
      name: 'บริษัทที่ไม่ได้กรอง',
      x: nonFilteredCompany.x,
      y: nonFilteredCompany.y,
      text: textNonFilteredTrace,
      customdata: nonFilteredCompany,
      mode: 'markers',
      type: 'scattergl',
      hoverinfo: 'text', // Show custom text in tooltips
      hoverlabel: {
        align: 'left'
      },
      opacity: 0.1,
      marker: {
        color: 'grey', //data.top_hscode2,
        size: 8,
      },      
    };

    const layout = {
      // title: 'Scatter Plot with 50,000 Points',
      showlegend: true,
      legend: {
        x: 0,
        xanchor: 'left',
        y: 1,
        font: {
          //family: 'sans-serif',
          size: 15,
          //color: '#000'
        },
      },
      xaxis: {
        title: 'X-axis',
        //"visible": false
      },
      yaxis: {
        title: 'Y-axis',
        //"visible": false
      },
      // width: 500,
      height: 800,
      margin: {
        l: 0,
        r: 0,
        b: 0,
        t: 0,
        pad: 0
      },
      hovermode:'closest',
      hoverdistance: 20,
    };

    const config = {
      responsive: true,
      scrollZoom: true, // Enable zooming with the mouse scroll wheel
    };

    Plotly.newPlot('container', [nonFilteredTrace, filteredTrace], layout, config);

    
    let myPlot = document.getElementById('container')
    myPlot.on('plotly_click', onClickPoint);

  }, []);


  useEffect(() => {

    // prepareData
    let companayData = getSelectedData(filteredCompany, nonFilteredCompany, selectedCompany);
    let selectedCompanyTrace = generateCompanyTrace(companayData);
    let selectedCompanyAnnotate = generateLineArrow(companayData); 

    const layout = {
      annotations: selectedCompanyAnnotate
    }

    let myPlot = document.getElementById('container')

    let nTrace = myPlot.data.length;

    let removeTrace = [...Array(nTrace).keys()].slice(2); // index of old selected Company
    Plotly.deleteTraces('container', removeTrace);
    Plotly.addTraces('container', selectedCompanyTrace);
    Plotly.relayout('container', layout);

  }, [selectedCompany]);

  return (
    <div style={{ width: "100%", height: "100%" }} id="container"></div>
  );
}
