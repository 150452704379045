import "../assets/CSS/mod_fullDetail.css";
import "../assets/CSS/mod_line.css";

import React, { useEffect, useState } from "react";
import { Card, Table, Typography, Tooltip } from "antd";
import { useLocation } from "react-router-dom";
import axios from "axios";
import qs from "qs";
import { domainName } from "../resources/actions/index";
import LineChart from "../components/sub-components/LineChart";
import RadarChart from "../components/sub-components/RadarChart";
import BarChart from "../components/sub-components/BarChart";

import { ACTIONS_TYPE, ExporterSelfCol } from "../resources/variables/index";

import Loading from "../components/Loading";
import { default as ExporterDescription } from "../components/exporter/Description";
import Description from "../components/Description";

const { Text } = Typography;

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const columns_product = [
  {
    title: "HSCode4",
    dataIndex: "HSCode4",
    key: "HSCode4",
    sorter: (a, b) => a.HSCode4.localeCompare(b.HSCode4),
  },
  {
    title: "มูลค่าส่งออก ทั้งหมด (บาท)",
    width: "150px",
    dataIndex: "ValueBaht",
    key: "ValueBaht",
    align: "right",
    render: (value) => {
      return (
        <Text>{value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Text>
      );
    },
    sorter: (a, b) => a.ValueBaht - b.ValueBaht,
  },
  {
    title: "คำอธิบาย",
    dataIndex: "thdescriptions4",
    key: "thdescriptions4",
    render: (text) => {
      return (
        <Tooltip title={text}>
          <Typography.Paragraph
            ellipsis={{
              rows: 3
            }}
          >
            {text}
          </Typography.Paragraph>
        </Tooltip>
      );
    }
  },
];

const currentYear = new Date().getFullYear();

// Initialize an empty array to store the objects
const yearArray = [];

// Loop from 2017 to the current year and create objects
for (let year = 2017; year <= currentYear; year++) {
  yearArray.push({
    text: year.toString(),
    value: year,
  });
}

const columns_ditp_product = [
  {
    title: "ปี",
    dataIndex: "year",
    width: "150px",
    key: "year",
    filters: yearArray,
    filterSearch: true,
    onFilter: (value, record) => record.year == value,
    sorter: (a, b) => a.year - b.year,
  },
  {
    title: "กลุ่มสินค้าระดับ 1",
    width: "150px",
    dataIndex: "lv1",
    key: "lv1",
    align: "right",
  },
  {
    title: "กลุ่มสินค้าระดับ 2",
    width: "150px",
    dataIndex: "lv2",
    key: "lv2",
    align: "right",
  },
  {
    title: "กลุ่มสินค้าระดับ 3",
    width: "150px",
    dataIndex: "lv3",
    key: "lv3",
    align: "right",
  },
  {
    title: "มูลค่าส่งออกทั้งหมด (บาท)",
    width: "200px",
    dataIndex: "value_baht",
    key: "value_baht",
    align: "right",
    render: (value) => {
      return (
        <Text>{value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Text>
      );
    },
    sorter: (a, b) => a.value_baht - b.value_baht,
  },
];


export default function ExporterDetail() {
  // console.log(textExtraFilter);
  const [exporterDetail, setExporterDetail] = useState();
  const [exporterCountryExport, setExporterCountryExport] = useState([]);
  const [exporterFinancialStatement, setExporterFinancialStatement] = useState([]);
  const [perPage, setPerpage] = useState(5);
  const pageOptions = [5, 10, 20, 50];
  const textExtraFilter = JSON.parse(localStorage.getItem([ACTIONS_TYPE.TEXT_EXTRA_FILTER]));
  
  // receive data from querystring params
  const query = useQuery();
  const statisticScore = qs.parse(query.get("statistic_score"), { delimiter: ',' });
  const exporterData = qs.parse(query.get("exporter"), { delimiter: ',' });
  const average = parseFloat(query.get("average"));
  const outcome = parseFloat(query.get("outcome"));
  const strength = parseFloat(query.get("strength"));

  const EXPORTER_FILTERED = [
    {
      title: "ประเทศที่จัดกิจกรรม",
      textExtra: textExtraFilter?.exportValue?.country_activity?.ellipsisText || "-",
      data: (
        <Tooltip title={`${textExtraFilter?.activity?.country_activity?.fullText || "-"}`}>
          {textExtraFilter?.activity?.country_activity?.ellipsisText || "-"}
        </Tooltip>
      )
    },
    {
      title: "สินค้าที่จัดกิจกรรม",
      textExtra: textExtraFilter?.exportValue?.product_activity?.ellipsisText || "-",
      data: (
        <Tooltip title={`${textExtraFilter?.activity?.product_activity?.fullText || "-"}`}>
          {textExtraFilter?.activity?.product_activity?.ellipsisText || "-"}
        </Tooltip>
      )
    },
    {
      title: "ประเภทกิจกรรม",
      textExtra: textExtraFilter?.exportValue?.activity_type?.ellipsisText || "-",
      data: (
        <Tooltip title={`${textExtraFilter?.activity?.activity_type?.fullText || "-"}`}>
          {textExtraFilter?.activity?.activity_type?.ellipsisText || "-"}
        </Tooltip>
      )
    },
    {
      title: "คำสำคัญของกิจกรรม",
      textExtra: textExtraFilter?.activity?.activity_keyword?.ellipsisText || "-",
      data: (
        <Tooltip title={textExtraFilter?.activity?.activity_keyword?.fullText || "-"}>
          {textExtraFilter?.activity?.activity_keyword?.ellipsisText || "-"}
        </Tooltip>
      )
    },
    {
      title: "ช่วงเวลาที่จัดกิจกรรม",
      textExtra: textExtraFilter?.activity?.timeRange?.ellipsisText || "-",
      data: (
        <Tooltip title={textExtraFilter?.activity?.timeRange?.fullText || "-"}>
          {textExtraFilter?.activity?.timeRange?.ellipsisText || "-"}
        </Tooltip>
      )
    },
    {
      title: "สินค้าที่ผู้ประกอบการส่งออก",
      textExtra: textExtraFilter?.exportValue?.product_export?.ellipsisText || "-",
      data: (
        <Tooltip title={`${textExtraFilter?.exportValue?.product_export?.fullText || "-"}`}>
          {textExtraFilter?.exportValue?.product_export?.ellipsisText || "-"}
        </Tooltip>
      )
    },
    {
      title: "ประเทศที่ผู้ประกอบการส่งออก",
      textExtra: textExtraFilter?.exportValue?.country_export?.ellipsisText || "-",
      data: (
        <Tooltip title={`${textExtraFilter?.exportValue?.country_export?.fullText || "-"}`}>
          {textExtraFilter?.exportValue?.country_export?.ellipsisText || "-"}
        </Tooltip>
      )
    },
    {
      title: "ขนาดมูลค่าการส่งออกของผู้ประกอบการ",
      textExtra: textExtraFilter?.exportValue?.exporter_size?.ellipsisText || "-",
      data: (
        <Tooltip title={`${textExtraFilter?.exporter?.exporter_size?.fullText || "-"}`}>
          {textExtraFilter?.exporter?.exporter_size?.ellipsisText || "-"}
        </Tooltip>
      )
    },
    {
      title: "ขนาดผู้ประกอบการตามเกณฑ์ สสว.",
      textExtra: textExtraFilter?.exportValue?.exporter_size_sme?.ellipsisText || "-",
      data: (
        <Tooltip title={`${textExtraFilter?.exporter?.exporter_size_sme?.fullText || "-"}`}>
          {textExtraFilter?.exporter?.exporter_size_sme?.ellipsisText || "-"}
        </Tooltip>
      )
    },
    {
      title: "ประเภทสมาชิกของผู้ประกอบการ",
      textExtra: textExtraFilter?.exportValue?.exporter_member_type?.ellipsisText || "-",
      data: (
        <Tooltip title={`${textExtraFilter?.exporter?.exporter_member_type?.fullText || "-"}`}>
          {textExtraFilter?.exporter?.exporter_member_type?.ellipsisText || "-"}
        </Tooltip>
      )
    },
    {
      title: "ช่วงเวลาที่ผู้ประกอบการส่งออก",
      textExtra: textExtraFilter?.exportValue?.timeRange?.ellipsisText || "-",
      data: (
        <Tooltip title={`${textExtraFilter?.exportValue?.timeRange?.fullText || "-"}`}>
          {textExtraFilter?.exportValue?.timeRange?.ellipsisText || "-"}
        </Tooltip>
      )
    },
    {
      title: "รางวัลของผู้ประกอบการ",
      textExtra: textExtraFilter?.exportValue?.exporter_award?.ellipsisText || "-",
      data: (
        <Tooltip title={`${textExtraFilter?.exporter?.exporter_award?.fullText || "-"}`}>
          {textExtraFilter?.exporter?.exporter_award?.ellipsisText || "-"}
        </Tooltip>
      )
    },
    {
      title: "ภูมิภาคของผู้ประกอบการ",
      textExtra: textExtraFilter?.exportValue?.exporter_region?.ellipsisText || "-",
      data: (
        <Tooltip title={`${textExtraFilter?.exporter?.exporter_region?.fullText || "-"}`}>
          {textExtraFilter?.exporter?.exporter_region?.ellipsisText || "-"}
        </Tooltip>
      )
    }
  ];

  const data = {
    labels: exporterFinancialStatement.map(statement => statement?.year || "-"),
    datasets: [
      {
        label: "รายได้ธุรกิจ",
        data: exporterFinancialStatement.map(statement => statement?.revenue || "-"),
        fill: false,
        backgroundColor: "rgba(22, 104, 178, 0.6)",
        borderColor: "rgb(22, 104, 178)",
        pointBackgroundColor: "rgb(22, 104, 178)",
        pointBorderColor: "#fff",
        pointHoverBackgroundColor: "#fff",
        pointHoverBorderColor: "rgb(22, 104, 178)",
      },
    ],
  };
  const config = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: "bottom",
        align: "start",
      }
    },
  };

  useEffect(() => {
    let taxnumber = exporterData.Tax_No;
    axios
      .get(`${domainName}/exporter/${taxnumber}/`, { withCredentials: true })
      .then((res) => {
        setExporterDetail(res.data);
        setExporterCountryExport(res.data?.export_country || []);
        setExporterFinancialStatement(res.data?.financial_statement || []);
      })
      .catch((err) => console.log(err));
  }, []);

  const onShowSizeChange = (current, pageSize) => {
    console.log(current, pageSize);
    setPerpage(pageSize);
  };

  // useEffect(() => {

  // }, [exporterFinancialStatement]);

  const waitRadarChart = () => {
    // console.log('waitRadarChart:', statisticScore, Object.keys(statisticScore).length === 0);
    if (statisticScore && Object.keys(statisticScore).length !== 0) {
      return (
        <RadarChart
          exporterLabel="ค่าดัชนีของผู้ประกอบการ"
          selectedMean={exporterData}
          mean={statisticScore.mean}
          max={statisticScore.max}
        />
      );
    }
  };


  const waitData = () => {
    if (exporterDetail && exporterData) {
      return (
        <div className="flex flex-col gap-4">
          <Card title={
            <h1 className="font-bold text-lg">
              ข้อมูลผู้ประกอบการและกราฟเรดาร์แสดงค่าดัชนีศักยภาพ
            </h1>
          }
            style={{ width: "100%" }}
          >
            <div className="w-full mx-auto grid grid-cols-12 gap-4 px-[24px] py-5">
              <div className="col-span-full order-1 lg:col-span-4 flex flex-col justify-start">
                <ExporterDescription
                  title={"ข้อมูลผู้ประกอบการ"}
                  exporterDetail={exporterDetail.exporter_detail}
                />
              </div>
              {/* <div className="col-span-full order-3 lg:col-span-4 w-5/6 h-5/6 mx-auto lg:w-full lg:h-full radar flex flex-col">
                <h1 className="font-bold text-[16px] mb-0">
                  กราฟเรดาร์แสดงค่าศักยภาพของผู้ประกอบการ
                </h1>
                <div className="my-auto bg-slate-50 rounded-md lg:mt-5 lg:h-full lg:flex lg:flex-col lg:justify-center ">
                  {waitRadarChart()}
                </div>
              </div> */}
              <div className="col-span-full order-3 lg:col-span-4 w-5/6 h-5/6 mx-auto lg:w-full lg:h-full radar flex flex-col">
                <h1 className="font-bold text-[16px] mb-5">
                  กราฟเรดาร์แสดงค่าศักยภาพของผู้ประกอบการ
                </h1>
                <Table
                  pagination={{
                    position: ['none', 'none'],
                  }}
                  dataSource={[
                    {
                      average,
                      strength,
                      outcome
                    }
                  ]}
                  columns={[
                    {
                      title: <Tooltip className=" cursor-default" title={"ค่าดัชนีเฉลี่ยของผู้ประกอบการจากด้านความเข้มแข็ง และความเติบโต"}>ค่าเฉลี่ย (Average)</Tooltip>,
                      dataIndex: "average",
                      key: "average",
                      render: (value) => parseFloat(value || 0).toFixed(2) 
                    },
                    {
                      title: <Tooltip className=" cursor-default" title={"ค่าดัชนีของผู้ประกอบการในด้านการความเข้มแข็ง"}>Strength</Tooltip>,
                      dataIndex: "strength",
                      key: "strength",
                      render: (value) => parseFloat(value || 0).toFixed(2)
                    },
                    {
                      title: <Tooltip className=" cursor-default" title={"ค่าดัชนีของผู้ประกอบการในด้านการความเติบโต"}>Outcome</Tooltip>,
                      dataIndex: "outcome",
                      key: "outcome",
                      render: (value) => parseFloat(value || 0).toFixed(2)
                    },
                  ]}
                />
                <div className="my-auto bg-slate-50 rounded-md  lg:h-[80%] lg:flex lg:flex-col lg:justify-center ">
                  {waitRadarChart()}
                </div>
              </div>

              <div className="col-span-full order-4 lg:col-span-4 flex flex-col justify-start">
                <Description
                  title={"ตัวกรองที่เลือก"}
                  items={EXPORTER_FILTERED}
                />
              </div>
              <div className="col-span-full  order-2 lg:order-last">
                <h1 className="font-bold text-[16px] mb-8">
                  รายได้ธุรกิจย้อนหลัง {`${exporterFinancialStatement.find(Boolean)?.year || ""}-${exporterFinancialStatement[exporterFinancialStatement.length - 1]?.year || ""}`}
                </h1>
                <BarChart
                  data={data}
                  config={config}
                />
              </div>
            </div>
          </Card>


          <Card
            title={
              <h1 className="font-bold text-lg">
                การเข้าร่วมกิจกรรมและกราฟแสดงมูลค่าการส่งออก
              </h1>
            }
            bordered={false}
            style={{ width: "100%" }}
          >
            <div className="grid grid-cols-12 gap-4 w-full mx-auto px-[24px] py-8">
              {/* <div className={`col-span-full lg:col-span-${USER_PERMISSION.IS_ADMINISTRATOR ? 6 : 'full'} mx-auto`}> */}
              <div className={`col-span-full lg:col-span-6`}>
                <h1 className="font-bold text-[16px] mb-8">
                  ตารางประวัติการเข้าร่วมกิจกรรม
                </h1>
                <Table
                  size={"small"}
                  columns={ExporterSelfCol}
                  dataSource={exporterDetail.activity_attend}
                  pagination={{
                    pageSize: 10,
                    position: ["bottomCenter"],
                  }}
                />
              </div>

              {
                // USER_PERMISSION.IS_ADMINISTRATOR && (
                  <div className={"col-span-full lg:col-span-6"}>
                    <h1 className="font-bold text-[16px] mb-2 px-4 mx-auto">
                      กราฟเส้นแสดงแสดงมูลค่าการส่งออก
                    </h1>
                    <div className="graph line w-full px-5 mx-auto">
                      <LineChart data={exporterDetail} />
                    </div>
                  </div>
                // )
              }
            </div>

          </Card>


          {
            // USER_PERMISSION.IS_ADMINISTRATOR && (
              <div>
                <Card
                  title="สินค้าที่ส่งออกทั้งหมด (ทุกปี)"
                  bordered={false}
                  style={{ width: "100%" }}
                >
                  <div className="w-full mx-auto grid grid-cols-12 gap-4 px-[24px] py-5">
                    <div className="col-span-full lg:col-span-6">
                      <h1 className="font-bold text-[16px] mb-6">
                        ตารางสินค้าที่ส่งออก
                      </h1>
                      <Table
                        size={"small"}
                        columns={columns_product}
                        dataSource={exporterDetail.export_product}
                        pagination={{
                          showSizeChanger: true,
                          onShowSizeChange,
                          pageSize: perPage,
                          pageSizeOptions: pageOptions
                        }}
                      />
                    </div>

                    <div className="col-span-full lg:col-span-6">
                      <h1 className="font-bold text-[16px] mb-6">
                        สินค้าตามกลุ่มเป้าส่งออกทั้งหมด
                      </h1>
                      <Table
                        size={"small"}
                        columns={columns_ditp_product}
                        dataSource={exporterDetail.export_ditp_product}
                        pagination={{
                          showSizeChanger: true,
                          onShowSizeChange,
                          pageSize: perPage,
                          pageSizeOptions: pageOptions
                        }}
                      />
                    </div>

                    <div className="col-span-full lg:col-span-12">
                      <h1 className="font-bold text-[16px] mb-0">
                        ประเทศที่ส่งออกทั้งหมด (ทุกปี)
                      </h1>
                      <div className="p-5">
                        {
                          exporterCountryExport.join(", ")
                        }
                        {/* {exporterCountryExport.map((country, index) => {
                            return (
                              <Tag key={`${index.toString()}-${country}`} style={{ margin: '4px' }}>
                                {country}
                              </Tag>
                            );
                          })} */}
                      </div>
                    </div>
                  </div>
                </Card>
                {/* 
                <Card
                  title="สินค้าตามกลุ่มเป้าส่งออกทั้งหมด"
                  bordered={false}
                  style={{ width: "100%" }}
                >
                  <div className="w-full mx-auto grid grid-cols-12 gap-4 px-[24px] py-5">
                    <div className="col-span-full lg:col-span-6">
                      <h1 className="font-bold text-[16px] mb-6">
                        ตารางสินค้าที่ส่งออก
                      </h1>
                      <Table
                        size={"small"}
                        columns={columns_ditp_product}

                        dataSource={exporterDetail.export_ditp_product}
                        pagination={{
                          showSizeChanger: true,
                          onShowSizeChange,
                          pageSize: perPage,
                          pageSizeOptions: pageOptions
                        }}
                      />
                    </div>
                  </div>
                </Card> */}
              </div>
            // )
          }
        </div>
      );
    } else {
      return (
        <Loading>
          <div className="w-full h-[90vh] bg-white"></div>
        </Loading>
      );
    }
  };

  return (
    // <div className="full-detail">
    <div className="w-full">
      <div className="w-[90%] mx-auto">
        {waitData()}
      </div>
    </div>
  );
}
