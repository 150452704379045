import React, {
  useState,
  // useEffect
} from "react";
import {
  Table,
  Typography,
  // Modal,
  Select,
  Button,
  Alert,
  Tooltip,
} from "antd";
import { useHistory } from "react-router";
import { bindActionCreators } from "redux";
import { actionCreators } from "../resources/index";
import { useDispatch, useSelector } from "react-redux";

const { Text, Link } = Typography;
const { Option } = Select;
export default function TableSMS(props) {
  const history = useHistory();
  // const [dataRow, setDataRow] = useState();
  const [visible, setVisible] = useState(false);
  const [statusRow, setStatusRow] = useState("");
  const dispatch = useDispatch();
  const status = useSelector((state) => state.statusSMS.statusSMS);
  const { upDateStatusSMS } = bindActionCreators(actionCreators, dispatch);

  const columns = [
    // {
    //   title: "",
    //   key: "",
    //   dataIndex: "index",
    //   width: 50,
    // },
    {
      title: <Tooltip title="วันที่ข้อมูลเข้า">วันที่ข้อมูลเข้า</Tooltip>,
      key: "DofData",
      width: "7.25rem",
      dataIndex: "created_at",
      render: (DofData) => {
        return (
          <Tooltip title={DofData.substring(0, 10)}>
            <Text>{DofData.substring(0, 10)}</Text>
          </Tooltip>
        );
      },
    },
    {
      title: <Tooltip title="ชื่อกิจกรรม">ชื่อกิจกรรม</Tooltip>,
      key: "activity_nameTh",
      dataIndex: "activity_nameTh",
      // width: "20rem",
      render: (activity) => {
        return (
          <Tooltip title={activity}>
            <Text>{activity}</Text>
          </Tooltip>
        );
      },
    },
    {
      title: (
        <Tooltip title="ผู้ประกอบการที่แนะนำ">ผู้ประกอบการที่แนะนำ</Tooltip>
      ),
      key: "Company",
      dataIndex: "",
      width: "10rem",
      render: (row) => {
        return (
          <Link onClick={() => manageExporter(row)}>
            คลิกเพื่อแก้ไขผู้ประกอบการ
          </Link>
        );
      },
    },
    {
      title: <Tooltip title="วันที่ส่ง SMS">วันที่ส่ง SMS</Tooltip>,
      key: "DofSend",
      dataIndex: "send_date",
      width: "10rem",
      render: (DofSend) => {
        return (
          <Tooltip title={DofSend && DofSend.substring(0, 10)}>
            <Text>{DofSend && DofSend.substring(0, 10)}</Text>
          </Tooltip>
        );
      },
    },
    {
      title: <Tooltip title="สถานะ">สถานะ</Tooltip>,
      key: "status",

      dataIndex: "status",
      width: "6.25rem",
      render: (status, row) => {
        return status == "SENDED" ? (
          <Select defaultValue={status} style={{ width: "6.25rem" }} disabled>
            <Option value="SENDED">Sended</Option>
          </Select>
        ) : (
          <Select
            defaultValue={status}
            onChange={onStatusChange}
            style={{ width: "6.25rem" }}
          >
            <Option value="SENDED">Sended</Option>
            <Option value="PAUSE">Pause</Option>
            <Option value="WAITING">Waiting</Option>
          </Select>
        );
      },
    },
    {
      title: "",
      key: "row",
      dataIndex: "",
      width: "4.25rem",
      render: (row) => {
        return row.status == "SENDED" ? (
          <Button type="primary" disabled>
            บันทึก
          </Button>
        ) : (
          <Button type="primary" onClick={() => postRowStatus(row)}>
            บันทึก
          </Button>
        );
      },
    },
  ];

  const postRowStatus = (data) => {
    console.log("data", data);
    console.log("status", statusRow);
    const packData = {
      activity_id: data.activity_id,
      status: statusRow,
    };
    upDateStatusSMS(packData);
    setVisible(true);
    setTimeout(() => {
      setVisible(false);
    }, 1500);
  };

  const onStatusChange = (value) => {
    setStatusRow(value);
  };

  const manageExporter = (data) => {
    console.log("manage", data);
    history.push(`/ManageSMS?activity=${data.activity_id}`);
  };

  // const handleChange = (key, value) => {
  //   console.log(`selected ${key} ${value}`);
  //   const packData = {
  //     activity_id: key,
  //     status: value,
  //   };
  //   upDateStatusSMS(packData);
  // };

  return (
    <div className="sms-table">
      <Table columns={columns} dataSource={props.data} />
      <div>*หมายเหตุ:</div>
      <div>WAITING : สถานะรอการส่งอัตโนมัติโดยระบบ</div>
      <div>PAUSE : สถานะหยุดการส่งอัตโนมัติชั่วคราว</div>
      <div>SENDED : สถานะการส่ง SMS แล้ว</div>
      {status ? (
        visible ? (
          <div className="sms-aleart">
            <Alert
              message={status.description}
              type="success"
              showIcon
              style={{ width: "20rem" }}
            />
          </div>
        ) : null
      ) : null}
    </div>
  );
}
