import React, { useState, useEffect, useRef } from "react";
import { Tree, Input, Typography } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../resources/index";
import services from "../resources/variables/services";

const { Text } = Typography;
const { Search } = Input;

export default function ExportValueFilter({ onCheckedKeys, ...props }) {
    // All State can use
    const keyRef = useRef();
    const { inputfilter, filter } = useSelector((state) => ({
        inputfilter: state.filter_input,
        filter: state.filter
    }));
    const [activityId, setActivityId] = useState();
    const [searchValue, setSearchValue] = useState("");
    const [activityScroll, setActivityScroll] = useState("");
    const [treeFilter, setTreeFilter] = useState(props.dataFilter);
    const [treeExpand, setTreeExpand] = useState([]);

    const checkAllList = [];
    const dispatch = useDispatch();
    const actions = bindActionCreators(actionCreators, dispatch);
    const MAX_LENGTH = {
        country_export: 52,
        product_export: 43
    };

    //Activity Join State

    // Activity Type State

    let initializeCheckedKeys = [];
    services.listGenerator(props.dataFilter, initializeCheckedKeys);
    initializeCheckedKeys = initializeCheckedKeys
        .filter(item => item.key.includes("leaf"))
        .map(item => item.key);
    // console.log(initializeCheckedKeys);
    const [checkedKeys, setCheckedKeys] = useState(initializeCheckedKeys);

    // Activity Type Function
    const onSearch = (e, data) => {
        const { value } = e.target;
        const textSearchKeyword = value.toLowerCase();
        const IS_NULL_KEYWORD = !(!!textSearchKeyword);
        // console.log(props.dataFilter);

        // console.log(`[search-input] ${textSearchKeyword} (null = ${IS_NULL_KEYWORD})`, data);
        if (IS_NULL_KEYWORD) {
            setTreeFilter(props.dataFilter);
            setTreeExpand([]);
        } else {
            const dataInitial = JSON.parse(JSON.stringify(props.dataFilter));
            const filteredTree = services.filterFamilyTreeByName(
                dataInitial,
                textSearchKeyword
            );
            // console.log(filteredTree);

            let parentKeyList = [];
            services.listGenerator(filteredTree, parentKeyList);
            parentKeyList = parentKeyList
                .filter(item => !item.key.startsWith("leaf"))
                .map(item => item.key);
            console.log(parentKeyList);
            setTreeExpand(parentKeyList);
            setTreeFilter(filteredTree);
        }

        // return setTreeExpand([])
    };

    /**
     * update tree-items is checked or not, and update textExtra display following by checked-key
     * @param {Array} checkedKeysValue key of tree has click checked
     */
    const onCheck = (checkedKeysValue, event) => {
        // console.log(`onCheck (${props.type})`, checkedKeysValue, props.dataFilter);

        let textExtraEllipsis = null;
        let textExtraFull = null;
        const storeFlatObject = []; // store flat array of filter (1d array with all of elements)
        services.listGenerator(props.dataFilter, storeFlatObject); // convert multi-level of array to flat array (1D Array)

        checkedKeysValue = [...new Set([...checkedKeys, ...checkedKeysValue])];
        if (!event.checked) {
            let removeItemList = [];
            if (event.node?.children) {
                services.listGenerator(event.node.children, removeItemList);
            }
            removeItemList = removeItemList.map(item => item.key);
            removeItemList.push(event.node.key); // push themself for remove
            // console.log(removeItemList);      
            checkedKeysValue = checkedKeys.filter(key => !removeItemList.includes(key));
        }

        let checkedKeyFilterList = storeFlatObject
            .filter(filter => checkedKeysValue.includes(filter.key))
            .filter(obj => obj.key.includes("leaf"));

        /**
         * if length of checked-keys less than length of 'all-key'
         * the textExtraEllipsis display only checked-keys, otherwise textExtraEllipsis display "ทั้งหมด"
        */
        if (checkedKeysValue.length >= MAX_LENGTH[props.type]) {
            textExtraEllipsis = "ทั้งหมด";
        } else {
            textExtraEllipsis = checkedKeyFilterList.map((obj) => obj.title);
            textExtraEllipsis = services.formatArrayWithEllipsis(textExtraEllipsis);
        }
        textExtraFull = checkedKeyFilterList.map(item => item.title).join(", ");


        const TypeChecked = {
            ...inputfilter.filter_input,
            [props.type]: {
                type: "ditp",
                value: checkedKeysValue
            },
        };

        checkedKeysValue = checkedKeysValue.filter(key => key.includes("leaf"));
        // console.log(checkedKeysValue);
        setCheckedKeys(checkedKeysValue);
        onCheckedKeys(textExtraEllipsis, textExtraFull, "exportValue", props.type);
        actions.setFilter(TypeChecked);
    };

    // const handleChange = (value) => {
    //     console.log(`selected ${value}`);

    //     const ActivityJoin = {
    //         ...inputfilter.filter_input,
    //         activity_id: value,
    //     };

    //     actions.setFilter(ActivityJoin);
    //     setActivityId(props.activityId);
    // };

    const checkAll = (data) => {
        services.listGenerator(data, checkAllList);
        // console.log(checkAllList.filter(item => !item.key.includes("leaf")).length);
        // console.log(checkAllList.filter(item => item.key.includes("leaf")).length);
        let checkedKeyItems = checkAllList.map((item) => item.key);
        const filterCheckedUpdate = {
            ...inputfilter.filter_input,
            [props.type]: {
                type: "ditp",
                value: checkedKeyItems
            },
        };

        const textExtraFull = checkAllList
            .filter(item => item.key.includes("leaf"))
            .map(item => item.title)
            .join(", ");

        setCheckedKeys(checkedKeyItems);
        onCheckedKeys("ทั้งหมด", textExtraFull, "exportValue", props.type);
        actions.setFilter(filterCheckedUpdate);
    };

    const unCheckall = () => {
        const emptyExtraStringTooltip = "-";
        const filterCheckedUpdate = {
            ...inputfilter.filter_input,
            [props.type]: {
                type: "ditp",
                value: ["leaf-10000"]
            },
        };

        setCheckedKeys([]);
        setTreeExpand([]);
        onCheckedKeys("", emptyExtraStringTooltip, "exportValue", props.type);
        actions.setFilter(filterCheckedUpdate);
    };

    useEffect(() => {
        if (searchValue) {
            keyRef.current.scrollTo({ key: activityScroll, align: "top" });
        }
    }, [searchValue]);


    useEffect(() => {
        if (inputfilter.filter_input.acivity_id > []) {
            setActivityId(inputfilter.filter_input.acivity_id);
        }
    }, [inputfilter.filter_input]);

    return (
        <div className="activity">
            <div className="activity-type">
                <div className="check-tree">
                    <Text onClick={() => checkAll(props.dataFilter)}>เลือกทั้งหมด</Text>
                    <Text onClick={() => unCheckall()}>ไม่เลือกทั้งหมด</Text>
                </div>
                <Search
                    allowClear
                    style={{ marginBottom: 8 }}
                    onChange={(e) => onSearch(e, props.dataFilter)}
                    placeholder="Search"
                />
                <Tree
                    height={200}
                    ref={keyRef}
                    checkable
                    expandedKeys={treeExpand}
                    // onSelect={(selectedKeys, info) => setTreeExpand(prevKeys => [...prevKeys, ...selectedKeys])}
                    onExpand={(expandedKeys, info) => setTreeExpand(expandedKeys)}
                    onCheck={onCheck}
                    checkedKeys={checkedKeys}
                    treeData={treeFilter}
                />
            </div>
        </div>
    );
}
