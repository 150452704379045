import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { useHistory, useLocation } from "react-router-dom";
import { FilterFilled } from "@ant-design/icons";

import { Tabs, Button, Modal } from "antd";
import qs from "qs";

import { actionCreators } from "../resources/index";
// import "../assets/CSS/mod_export.css";
import ExporterTable from "../components/exporter/ExporterTable";
import ExporterDetails from "../components/ExporterDetails";
// import GroupData from "../components/GroupData";
import NewLineChart from "../components/sub-components/NewLineChart";
import ActivityRole from "../components/sub-components/ActivityRole";
import Filter from "../components/Filter";
import ExporterStatistic from "../components/exporter/ExporterStatistic";
import { domainName } from "../resources/actions";
import axios from "axios";

const { TabPane } = Tabs;
export default function Exporter() {
  const { exporter, inputfilter, summary_group } = useSelector((state) => {
    const data = {
      exporter: state.exporter,
      inputfilter: state.filter_input,
      summary_group: state.summary_group,
    };
    // console.log(data);
    return data;
  });
  // console.log(exporter, inputfilter, summary_group);
  const history = useHistory();
  const location = useLocation();
  // console.log(location);
  const dispatch = useDispatch();
  const actions = bindActionCreators(actionCreators, dispatch);

  const [modalToggle, setModalToggle] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedMean, setSelectedMean] = useState();
  const [radarChartLoading, setRadarChartLoading] = useState(false);
  const timeoutIdRef = useRef(null);
  const queryActivity = new URLSearchParams(location.search);
  const activityIds =
    queryActivity
      .get("activities")
      ?.split(",")
      ?.map((id) => Number(id)) || [];

      
  useEffect(() => {
    /**
     * data or statistics group by filter
     * @param {Object} payload filter settings for get data by filter panel
     */
    async function fetchDataForSummaryGroupScore(payload) {
      actions.getSummaryGroupByFilters(payload);
    }

    /**
     * list of exporter for display in table
     * @param {Object} payload filter settings for get data by filter panel
     */
    async function fetchExporterListTable(payload) {
      actions.postExporter(payload);
    }

    const payload = {
      ...inputfilter.filter_input,
      activity_id: activityIds,
    };
    fetchDataForSummaryGroupScore(payload);
    fetchExporterListTable(payload);
  }, []);

  
  /**
   * send exporters infomation are selected include dimensions, tax_id, strength-score, outcome-score etc. for display Radar Chart
   * @param {Array<number>} selectedRowKeys array of index-row is selected (checked) such as [1, 3, 5, 10]
   */
  const onSelectChange = (newSelectedRowKeys) => {
    // console.log("[exporter-table]: rowSelection (onSelectChange) ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);

    // filter data
    const selectRow = exporter.exporter.score_table.filter((expoter) => {
      return newSelectedRowKeys.includes(expoter.index);
    });

    // prevent muliple reqeusts, when click checked-box mulitple-time.
    clearTimeout(timeoutIdRef.current);
    setRadarChartLoading(true);
    timeoutIdRef.current = setTimeout(() => {
      // pack data to request payload
      const payload = { selected_list: selectRow };
      axios
        .post(`${domainName}/exporter/group_detail/`, payload, {
          withCredentials: true,
        })
        .then((res) => {
          console.log("response", res.data);
          setSelectedMean(res.data);
        })
        .catch(() => setSelectedMean([null]))
        .finally(() => setRadarChartLoading(false));
    }, 500);
    // console.log("updateTimeoutId : ", timeoutIdRef.current);
  };

  // send query string to movement page
  const gotoMovement = (selectedRowKeys) => {
    let selectRow = exporter.exporter.score_table.filter((expoter) => {
      return selectedRowKeys.includes(expoter.index);
    });

    selectRow = selectRow.map((item) => {
      return {
        key: item.Tax_No,
        label: `${item.Tax_No}: ${item.Corporate_NameTH}`,
        value: item.Tax_No,
      };
    });

    const selectQs = qs.stringify(selectRow, { delimiter: "," });
    const win = window.open(`/Movement?exporter=${selectQs}`, "_blank");
    win.focus();
  };

  const gotoSuggestActivity = () => {
    return history.push("/SuggestActivity");
  };

  const displayPageButton = () => {
    const SELECTED_ROW_IS_EMPTY = selectedRowKeys.length === 0;

    return (
      <>
        <Button
          onClick={gotoSuggestActivity}
          id='b-1'
          shape='round'
          type='primary'
          disabled={SELECTED_ROW_IS_EMPTY}
        >
          แนะนำกิจกรรม
        </Button>

        <Button
          onClick={() => gotoMovement(selectedRowKeys)}
          id='b-1'
          shape='round'
          type='primary'
          disabled={SELECTED_ROW_IS_EMPTY}
        >
          การเคลื่อนที่ของผู้ประกอบการ
        </Button>
      </>
    );
  };

  const toggleModal = () => {
    setModalToggle((prev) => !prev);
  };

  return (
    <div className='grid grid-cols-10 gap-3 h-full'>
      {/* MAIN */}
      <div className='exporter-content col-span-full xl:col-span-7'>
        <div className='flex xl:hidden justify-start items-baseline my-1'>
          <h1 className='inline-block text-sm md:text-base font-bold xl:text-base'>
            สถิติพื้นฐาน
          </h1>
          <span className='text-primary-grey text-xs md:text-sm xl:text-sm px-2 mr-auto'>
            (ในช่วงการส่งออกที่เลือก)
          </span>
          <Button type='primary' onClick={toggleModal} icon={<FilterFilled />}>
            ตัวกรอง
          </Button>
        </div>
        <Modal
          centered
          closable={true}
          maskClosable={true}
          destroyOnClose={true}
          title='ตัวกรอง'
          visible={modalToggle}
          width={"100%"}
          onCancel={toggleModal}
          footer={null}
        >
          <Filter />
        </Modal>
        {/* STATISTICS GROUP */}
        <ExporterStatistic
          isLoadded={!!summary_group?.summary_group}
          dataList={summary_group.summary_group || {}}
        />

        {/* TABLE */}
        <div className='head-group my-3 mt-5'>
          <ExporterTable
            data={exporter}
            rowSelection={{
              selectedRowKeys,
              onChange: onSelectChange,
            }}
          />
          <div className='flex justify-end gap-2'>{displayPageButton()}</div>
        </div>
      </div>

      {/* RIGHT SIDE */}
      <div className='exporter-detail relative col-span-full xl:col-span-3 m-h-[100vh]'>
        <div className='sticky top-4'>
          <Tabs type='card' style={{ height: "100%" }}>
            <TabPane tab='ศักยภาพผู้ประกอบการ' key='1'>
              <div className='side-container rounded bg-white p-5 h-full w-full flex flex-col lg:flex-row xl:flex-col'>
                <div className='sm:w-fit lg:w-1/3 xl:w-full mx-auto'>
                  <ExporterDetails
                    radarChartLoading={radarChartLoading}
                    selectedMean={selectedMean}
                  />
                </div>
                {/* <Divider style={{ margin: "8px 0px" }} /> */}
                <div className='activity-role-list w-full lg:w-1/2 xl:w-full'>
                  <ActivityRole />
                </div>
              </div>
            </TabPane>

            <TabPane
              tab='ประวัติการเข้าร่วม'
              disabled={selectedMean > [0] ? false : true}
              key='3'
            >
              <NewLineChart selectedMean={selectedMean} />
              <Button />
            </TabPane>
          </Tabs>
        </div>
      </div>
    </div>
  );
}
